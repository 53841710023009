import { toSnakeCase } from "utils/styleUtils";

export const generatePayload = (values, addressId, phoneId) => {
  const {
    first_name,
    last_name,
    email,
    phone_number,
    area,
    city,
    state,
    zip_code,
    street_number,
  } = values;
  return {
    customer: {
      first_name,
      last_name,
      email,
      addresses_attributes: {
        id: addressId,
        area,
        city,
        state: state.value,
        street_number,
        zip_code,
      },
    },
    phone: {
      id: phoneId,
      phone_number,
    },
  };
};

export const generatePayCycleUpdatePayload = values => {
  const { pay_cycle, pay_day, pay_days } = values;

  const payDay = String(pay_day) === pay_day ? pay_day : pay_day.toDateString();

  return {
    employment_detail: {
      pay_cycle: toSnakeCase(pay_cycle.value),
      pay_day: toSnakeCase(pay_cycle.value) === "twice_a_month" ? null : payDay,
      pay_days:
        toSnakeCase(pay_cycle.value) === "twice_a_month" ? pay_days : null,
    },
  };
};

export const generateEmploymentDetailsPayload = values => {
  const {
    monthly_income,
    current_employment_status,
    mode_of_salary,
    credit_limit,
    credit_status,
  } = values;

  return {
    employment_detail: {
      monthly_income: monthly_income.value,
      current_employment_status,
      mode_of_salary,
    },
    customer: {
      manual_credit_limit: credit_limit,
      credit_status: credit_status.value,
    },
  };
};

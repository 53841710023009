import React from "react";
import { Button } from "neetoui";
import { convertSnakeToTitle } from "utils/styleUtils";
import { isEmptyObject } from "common/utils";

const Documents = ({ documents }) => (
  <div className="px-4 lg:max-w-6xl lg:mx-auto">
    <div className="pb-2 font-semibold border-b border-gray-200">
      Lease Documents
    </div>
    {isEmptyObject(documents) && (
      <div className="flex justify-between my-4">
        <p className="font-normal text-gray-600">No documents</p>
      </div>
    )}
    <div className="my-4 space-y-2">
      {documents &&
        Object.keys(documents).map((docName, index) => (
          <div className="flex justify-between" key={index}>
            <p>{convertSnakeToTitle(docName)}</p>
            <Button
              style="icon"
              icon="ri-download-2-line"
              className="text-sm"
              title={`Download ${convertSnakeToTitle(docName)} PDF document`}
              href={documents[docName]}
            />
          </div>
        ))}
    </div>
  </div>
);

export default Documents;

import React, { useEffect, useState } from "react";
import { Label } from "neetoui";
import { Formik } from "formik";
import { Select as FormikSelect, Input as FormikInput } from "neetoui/formik";
import leasesApi from "apis/leases";
import paymentAmountsApi from "apis/payment-amounts";

import { formatCurrency } from "utils/styleUtils";
import { useParams } from "react-router-dom";

const Modify = ({ setModificationPane, formikRef }) => {
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [amountError, setAmountError] = useState("");
  const [leaseList, setLeaseList] = useState([]);
  const [selectedLease, setSelectedLease] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { id } = useParams();
  const initialValues = { amount: 0 };
  const leaseMaxAmount = selectedLease?.maximum_payable_amount;

  const handleSubmit = async () => {
    try {
      await paymentAmountsApi.update(id, selectedLease?.id, {
        payment_amount: paymentAmount,
      });

      setModificationPane(false);
    } catch (error) {
      logger.error(error);
    }
  };

  useEffect(() => {
    fetchLeases();
    setSelectedLease(leaseList[0]);
  }, []);

  const fetchLeases = async () => {
    try {
      const {
        data: { leases },
      } = await leasesApi.leases(id);
      setLeaseList(
        leases.map(lease => ({
          label: lease?.slug,
          value: lease?.id,
          ...lease,
        }))
      );
    } catch (error) {
      logger.error(error);
    }
  };

  const fetchLeaseEndDate = async () => {
    try {
      const {
        data: { details },
      } = await paymentAmountsApi.details(id, selectedLease?.id, {
        payment_amount: paymentAmount,
      });

      setEndDate(details?.end_date);
    } catch (error) {
      logger.error(error);
    }
  };

  const validAmount = () =>
    Number(paymentAmount) > 0 && Number(paymentAmount) <= leaseMaxAmount;

  useEffect(() => {
    const amountExceeded = Number(paymentAmount) > leaseMaxAmount;
    const error = amountExceeded
      ? `Payment amount should be less than ${leaseMaxAmount}.`
      : "";

    setAmountError(error);
    selectedLease && validAmount() && fetchLeaseEndDate();
  }, [paymentAmount, selectedLease]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => handleSubmit()}
      innerRef={formikRef}
    >
      {() => (
        <div className="space-y-4">
          <FormikSelect
            name="lease"
            label="Lease to be modified"
            className="py-4"
            placeholder="Select a lease"
            options={leaseList}
            value={selectedLease}
            onChange={value => setSelectedLease(value)}
          />
          <div className="flex justify-between">
            <Label>Original payment amount:</Label>
            <Label>{formatCurrency(selectedLease?.payment_amount)}</Label>
          </div>
          <FormikInput
            label="New payment amount"
            name="amount"
            className="py-2"
            error={amountError}
            value={paymentAmount}
            onChange={e => setPaymentAmount(e.target.value)}
          />
          <div className="flex justify-between">
            <Label>New Lease-End Date:</Label>
            <Label>{(validAmount() && endDate) || "_"}</Label>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default Modify;

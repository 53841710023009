import React, { useState } from "react";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import History from "./History";

const PaymentHistory = ({ payments }) => {
  const [pageIndex, setPageIndex] = useState(1);
  return (
    payments &&
    payments.length !== 0 && (
      <div className="flex flex-col items-center">
        <div className="justify-center w-3/6">
          <SubHeader
            paginationProps={{
              count: payments.length,
              pageNo: pageIndex,
              pageSize: 100,
              navigate: index => setPageIndex(index),
            }}
          />
        </div>
        <div className="flex justify-center w-full">
          <div className="w-3/6">
            {payments.map(payment => (
              <History payment={payment} key={payment.id} />
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default PaymentHistory;

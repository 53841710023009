import React from "react";
import { Button } from "@bigbinary/neetoui";
import PaymentFailedIcon from "common/images/FailedPayment";

const PaymentFailed = ({
  paymentAmount = "$100",
  leaseId = "1345BH",
  paymentType = "debit card ending 8495",
}) => (
  <div>
    <div className="flex justify-center py-8">
      <img src={PaymentFailedIcon} height="150em" width="150em"></img>
    </div>
    <p className="py-6 space-x-1 text-lg font-medium">
      <span>Payment worth</span>
      <span className="font-semibold text-purple-1000">{paymentAmount}</span>
      &nbsp;towards your lease
      <span className="font-semibold text-purple-1000">{leaseId}</span>
      &nbsp;using&nbsp;
      {paymentType} failed
    </p>
    <div className="flex justify-start py-2 space-x-4">
      <Button label="Change Payment Settings" style="secondary" />
      <Button label="Retry" />
    </div>
  </div>
);

export default PaymentFailed;

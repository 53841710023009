import React, { useEffect, useState } from "react";

import { formatCurrency } from "utils/styleUtils";
import AmountBox from "components/Admin/Customer/common/Amountbox";

const UpcomingInvoice = ({
  intialUpcomingValue,
  onUpcomingValueChange,
  formatAmounts,
  payCycle,
}) => {
  const [upcomingAmount, setUpcomingAmount] = useState();
  const [upcomingCheckbox, setUpcomingCheckbox] = useState();

  const details = intialUpcomingValue.details;

  const onUpcomingCheckboxChange = () => {
    setUpcomingCheckbox(state => !state);
    onUpcomingValueChange("checked");
  };

  useEffect(() => {
    setUpcomingAmount(intialUpcomingValue.amount);
    setUpcomingCheckbox(intialUpcomingValue.isChecked);
  }, [intialUpcomingValue]);

  const onUpcomingAmountChange = value => {
    setUpcomingAmount(value);
    onUpcomingValueChange("amount", value);
  };

  return (
    <AmountBox
      title={"Upcoming Autopay"}
      amount={upcomingAmount}
      formatAmounts={formatAmounts}
      onAmountChange={onUpcomingAmountChange}
      onCheckboxChange={onUpcomingCheckboxChange}
      isChecked={upcomingCheckbox}
      isValid={intialUpcomingValue.isValid}
    >
      <div className="flex justify-between">
        <p>{payCycle} Total</p>
        <p>{formatCurrency(details?.invoice_total_amount)}</p>
      </div>
      <div className="flex justify-between pb-1 border-b">
        <p>Partial Payment</p>
        <p>-{formatCurrency(details?.paid_amount_with_tax)}</p>
      </div>
      <div className="flex justify-between pt-1 font-semibold">
        <p>Upcoming Autopay</p>
        <p>{formatCurrency(details?.remaining_to_pay)}</p>
      </div>
    </AmountBox>
  );
};

export default UpcomingInvoice;

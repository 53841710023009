import React, { useState } from "react";
import { Tab } from "@bigbinary/neetoui";
import { SETTINGS_TAB } from "../constants";
import WorkflowSettings from "./Workflows";
import Exclusion from "./Exclusions";

const renderTabContents = currentTab => {
  switch (currentTab) {
  case "Workflows":
    return <WorkflowSettings />;
  case SETTINGS_TAB[1]:
    return <Exclusion />;
  case SETTINGS_TAB[2]:
    return <></>;
  case SETTINGS_TAB[3]:
    return <></>;
  }
};

const SettingsTab = () => {
  const [currentTab, setCurrentTab] = useState(SETTINGS_TAB[0]);

  const onTabChange = tab => {
    setCurrentTab(tab);
  };

  return (
    <>
      <div className="w-full border-b border-gray-200">
        <Tab className="w-full px-4">
          {SETTINGS_TAB.map((tab, index) => (
            <Tab.Item
              key={index}
              active={currentTab === tab}
              onClick={() => onTabChange(tab)}
            >
              {tab}
            </Tab.Item>
          ))}
        </Tab>
      </div>
      <div className="w-full">{renderTabContents(currentTab)}</div>
    </>
  );
};

export default SettingsTab;

import React from "react";
import { Toastr } from "@bigbinary/neetoui";
import NavBar from "./common/Navbar";
import NoDataImage from "common/images/no-data.svg";
import EmptyState from "components/Common/EmptyState";
import Leases from "./Leases";
import { Route, Switch } from "react-router-dom";
import Profile from "./Profile";
import PasswordChange from "./PasswordChange";
import LeaseDetails from "./Leases/Details";
import authenticationApi from "apis/customers/authentication";
import { resetAuthTokens, setAuthHeaders } from "apis/axios";
import { useAuthDispatch } from "contexts/auth";
import Payment from "../Payment";

const EmptyLease = () => (
  <EmptyState
    image={NoDataImage}
    title=""
    subtitle="There seems to be no active leases currently. Head to our storefront for some great offers."
    primaryAction={() => {}}
    primaryActionLabel="Shop Now"
  />
);

const MyAccountDesktopView = () => {
  const authDispatch = useAuthDispatch();

  const logout = async () => {
    try {
      setAuthHeaders();
      await authenticationApi.logout();
      authDispatch({
        type: "LOGOUT",
      });
      resetAuthTokens();
      window.location.href = "/login";
      Toastr.success("Logged out successfully");
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <div>
      <NavBar logout={logout} />
      <div className="w-full account-desktop">
        <Switch>
          <Route component={Leases} path={"/myaccount"} exact />
          <Route
            component={EmptyLease}
            path={"/myaccount/leases/empty"}
            exact
          />
          <Route
            component={LeaseDetails}
            path={"/myaccount/leases/:id"}
            exact
          />
          <Route component={Profile} path={"/myaccount/myprofile"} exact />
          <Route
            render={() => (
              <div className="overflow-auto">
                <Payment />
              </div>
            )}
            path="/myaccount/payment/:leaseId"
          />
          <Route
            component={PasswordChange}
            path={"/myaccount/changepassword"}
            exact
          />
        </Switch>
      </div>
    </div>
  );
};

export default MyAccountDesktopView;

import dayjs from "dayjs";

export const formatDate = (timestamp, options = {}) =>
  timestamp
    ? Intl.DateTimeFormat("en-US", options).format(new Date(timestamp))
    : "- -";

export const formatTime = timestamp =>
  timestamp ? new Date(timestamp).toLocaleTimeString("en-US") : "- -";

export const formatDateWithoutTimeZone = timestamp =>
  timestamp ? dayjs(timestamp, "MM-DD-YYYY").format("MM/DD/YYYY") : "- -";
// This method is to be used when timezone conversion is not required for the date or when we are just passing
// the date as MM-DD-YYY

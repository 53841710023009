import React, { useEffect, useState } from "react";
import { Badge, Button } from "neetoui";

import paymentsApi from "apis/leases/payments";
import { formatCurrency } from "utils/styleUtils";
import { Collapse } from "neetoui";
import classNames from "classnames";
import {
  filterOutFailedManualPayments,
  generatePayableTypeLabel,
  getInvoiceSlug,
  liveMode,
} from "common/utils";
import { stripePaymentLink } from "components/Admin/Customer/Tabs/utils";
import { formatDate } from "common/dateTimeFormatter";
import {
  generatePaymentStatusBadgeLabel,
  generatePaymentStatusBadgeColor,
} from "common/utils";

const PaymentRow = ({ payment, liveMode }) => {
  const [isCollapseOpen, setCollapse] = useState(false);
  return (
    <>
      <div
        className={classNames(["grid gap-2 px-2 py-3 invoice-table"], {
          "bg-gray-100": isCollapseOpen,
        })}
      >
        <div>
          <Button
            className="underline"
            label={payment?.stripe_id}
            style="link"
            target="_blank"
            href={stripePaymentLink(payment?.stripe_id, liveMode)}
          />
        </div>
        <div className="text-center">{formatDate(payment.paid_at)}</div>
        <div className="text-center">
          {`${formatCurrency(payment.amount)} from Card Ending in ${
            payment.payment_method.details.card.last4
          }`}
        </div>
        <div className="text-center">
          <div>
            <Badge color={generatePaymentStatusBadgeColor(payment?.status)}>
              {generatePaymentStatusBadgeLabel(payment?.status)}
            </Badge>
          </div>
        </div>
        <div className="w-32 text-center">
          <p
            className={classNames([" cursor-pointer"])}
            onClick={() => setCollapse(state => !state)}
          >
            {isCollapseOpen ? (
              <i className="ri-arrow-up-s-line"></i>
            ) : (
              <i className="ri-arrow-down-s-line"></i>
            )}
          </p>
        </div>
      </div>
      <Collapse open={isCollapseOpen}>
        <table>
          <thead className="table-header-group">
            <th className="px-4">Type</th>
            <th className="px-4">Invoice</th>
            <th className="px-4">Amount</th>
            <th className="px-4">Tax</th>
            <th className="px-4">Payment ID</th>
          </thead>
          <tbody>
            {payment.payable_payments.map(payable_payment => (
              <tr key={payable_payment.id}>
                <td className="px-4">
                  <p>
                    {generatePayableTypeLabel(payable_payment.payable_type)}
                  </p>
                </td>
                <td className="px-4">
                  <p>{getInvoiceSlug(payable_payment)}</p>
                </td>
                <td className="px-4">
                  <p>{formatCurrency(payable_payment.amount)}</p>
                </td>
                <td className="px-4">
                  <p>{formatCurrency(payable_payment.tax)}</p>
                </td>
                <td className="px-4">
                  <p>{payable_payment.slug}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Collapse>
    </>
  );
};

const PaymentTab = ({ leaseSlug }) => {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState(null);

  const fetchPayments = async () => {
    try {
      setLoading(true);
      const { data } = await paymentsApi.payments(leaseSlug);
      setPayments(data.payments);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    !payments && fetchPayments();
  }, []);

  return (
    !loading && (
      <div className="px-4">
        {payments?.length == 0 ? (
          <div className="flex justify-center w-full">
            <div className="m-4">No Payments</div>
          </div>
        ) : (
          <div className="my-4">
            <div>
              <div className="grid gap-4 px-2 invoice-table">
                <th className="w-32 uppercase">Payment id</th>
                <th className="text-center uppercase">payment date</th>
                <th className="text-center uppercase">amount</th>
                <th className="text-center uppercase">status</th>
                <th className="text-center uppercase"></th>
              </div>
              {payments &&
                filterOutFailedManualPayments(payments).map(payment => {
                  return (
                    <PaymentRow
                      payment={payment}
                      liveMode={liveMode()}
                      key={payment.id}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default PaymentTab;

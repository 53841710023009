export const DEFAULT_PAGE_SIZE = 20;
export const TOTAL_ROW_COUNT = 20;
export const SORT_BY_OPTIONS = [{ label: "Name" }, { label: "Date" }];
export const LOAD_SORT_PROPS = {};

export const COLLECTION_TABS = ["Collections", "Charge Offs"];

export const COLLECTION_TABLE_DATA = [
  {
    customer_name: "Charlie Smith",
    contact_no: "(803) 992-8121",
    overdue_amount: "$100",
    days_late: "5 days",
  },
  {
    customer_name: "Charlie Smith",
    contact_no: "(803) 992-8121",
    overdue_amount: "$100",
    days_late: "5 days",
  },
];

export const CHARGEOFF_TABLE_DATA = [
  {
    customer_name: "Charlie Smith",
    contact_no: "(803) 992-8121",
    chargeoff_amount: "$100",
    chargeoff_days: "3/3/2021",
  },
  {
    customer_name: "Charlie Smith",
    contact_no: "(803) 992-8121",
    chargeoff_amount: "$100",
    chargeoff_days: "3/3/2021",
  },
];

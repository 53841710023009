import React from "react";

const Header = () => {
  return (
    <div className="sticky w-full becca-tab max-w-lg mx-auto">
      <div className="flex flex-col justify-end px-4 pt-6 bg-white border-b border-gray-200">
        <h2 className="pb-2 font-semibold bg-white border-b border-purple-1000 w-max">
          Dashboard
        </h2>
      </div>
    </div>
  );
};

export default Header;

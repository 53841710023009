import React, { useState, forwardRef } from "react";
import { Input, Button } from "neetoui";
import { Form, Formik } from "formik";
import { usePlacesWidget } from "react-google-autocomplete";
import { Input as FormikInput, Select } from "neetoui/formik";

import { getOptions } from "utils/objectFormatter";
import { US_STATES } from "common/constants";
import formValidationSchemas from "./formValidationSchemas";
import addressesApi from "apis/customers/addresses";
import { GOOGLE_MAPS_API_KEY } from "components/SignUp/constant";
import { formValuesFromPlaces } from "common/utils";

const PersonalForm = forwardRef(({ address }, ref) => {
  const [showApartment, setShowApartment] = useState(!!address?.area);
  const [formValues, setFormValues] = useState({
    ...address,
    state: { value: address?.state, label: US_STATES[address?.state] },
  });

  const onAddressChange = (fieldName, value) => {
    setFormValues({ ...formValues, [fieldName]: value });
  };

  const { ref: placesRef } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    onPlaceSelected: place => {
      setFormValues({
        ...formValues,
        ...formValuesFromPlaces(place.address_components),
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
    },
  });

  const handleSubmit = async details => {
    try {
      const payload = {
        address: { ...details, state: details.state.value },
      };

      await addressesApi.update(address.id, payload);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      onSubmit={values => handleSubmit(values)}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
      validationSchema={formValidationSchemas.address}
      innerRef={ref}
    >
      {({ values, errors }) => (
        <Form>
          <div className="space-y-4">
            <div className="flex justify-between pb-1 border-b border-gray-300">
              <p className="text-base">Personal Information</p>
            </div>
            <Input
              placeholder="Street Address"
              name="street_number"
              type="text"
              ref={placesRef}
              value={values.street_number}
              onChange={e => onAddressChange("street_number", e.target.value)}
              error={errors.street_number}
            />
            {!showApartment && (
              <Button
                className="underline"
                style="link"
                label="Add Apt #, Unit, Floor (optional)"
                onClick={() => setShowApartment(true)}
              />
            )}
            {showApartment && (
              <FormikInput
                placeholder="Apartment Number, Unit, Floor (optional)"
                name="area"
                type="text"
                value={values.area}
                onChange={e => onAddressChange("area", e.target.value)}
                error={errors.area}
              />
            )}
            <Input
              placeholder="City"
              name="city"
              value={values.city}
              onChange={e => onAddressChange("city", e.target.value)}
              error={errors.city}
            />
            <Select
              placeholder="State"
              isDisabled={false}
              name="state"
              options={getOptions(US_STATES)}
              error={errors.state}
            />
            <Input
              placeholder="Zip code"
              name="zip_code"
              required
              value={values.zip_code}
              onChange={e => onAddressChange("zip_code", e.target.value)}
              error={errors.zip_code}
            />
            <p className="text-gray-400">
              P.O. boxes and temporary addresses are not supported.
            </p>
          </div>
        </Form>
      )}
    </Formik>
  );
});

export default PersonalForm;

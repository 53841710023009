import React from "react";
import {
  Button,
  Switch,
  Radio,
  Input,
  Checkbox,
  DateInput,
  Select,
} from "@bigbinary/neetoui";
// import PopoverPortal, {
//   PopOverParentComponent,
// } from "../Customer/common/PopOverComponent";

const SELECT_OPTIONS = [
  {
    label: "Karthik",
    value: "karthik",
  },
  {
    label: "Ajay",
    value: "ajay",
  },
];

const Components = () => {
  return (
    <div>
      <div className="flex flex-col px-6 space-y-6">
        <Button label="hello" />
        <Switch label="Switch" checked />
        <Checkbox label="checkbox" checked />
        <Radio label="Radio Options Stacked" stacked>
          <Radio.Item label="option1" value="option1" />
          <Radio.Item label="option2" value="option2" />
        </Radio>
        <Input label="Input" />
        <Select label="Select" options={SELECT_OPTIONS} />
        <DateInput />
      </div>
    </div>
  );
};

export default Components;

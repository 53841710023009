import React, { useState } from "react";
import { Button } from "neetoui";
import { Input } from "neetoui/formik";
import { Form, Formik } from "formik";

import VisaImage from "common/images/cardIcons/visa.svg";
import MasterCardImage from "common/images/cardIcons/mastercard.svg";
import DiscoverImage from "common/images/cardIcons/discover.svg";
import AmericanImage from "common/images/cardIcons/american.svg";
import NortonImage from "common/images/cardIcons/norton.svg";
import paymentMethodsApi from "apis/customers/payment-methods";
import { NEW_CARD_VALIDATION_SCHEMA } from "../constants";

import { useHistory, useParams } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";

const NewCardPage = ({ fetchPaymentMethods }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [cardValues, setCardValues] = useState({
    name: "",
    card_number: "",
    expiry_date: "",
    cvv: "",
    default_card: true,
  });

  useDocumentTitle(`Payment add new card ${id}`);

  const onCancel = () => {
    history.goBack();
  };

  const onExpiryDateChange = (e, props) => {
    let value = e.target.value.replace(/(\d{2})(\d{2})/, "$1/$2");
    props.handleChange(e);
    setCardValues(state => ({
      ...state,
      expiry_date: value,
    }));
  };

  const onCardChange = (e, props) => {
    let value = e.target.value.replace(
      /(\d{4})(\d{4})(\d{4})(\d+)/,
      "$1-$2-$3-$4"
    );
    props.handleChange(e);
    setCardValues(state => ({
      ...state,
      card_number: value,
    }));
  };

  const handleSubmit = async data => {
    try {
      setIsSubmitting(true);
      let transformedRequestData = {
        card: {
          customer_name: data.name,
          number: data.card_number.replace(/-/g, ""),
          exp_month: data.expiry_date.substring(0, 2),
          exp_year: data.expiry_date.substring(3, 5),
          cvc: data.cvv,
          is_default: data.default_card,
        },
      };
      await paymentMethodsApi.create(transformedRequestData);
      await fetchPaymentMethods();
      if (id != "default") {
        history.push(
          "/my-account/payment/" + id + "/" + data.card_number.slice(-4)
        );
      } else {
        history.goBack();
      }
    } catch (error) {
      logger.error(error);
    }
    setIsSubmitting(false);
  };

  return (
    <div className="p-4 overflow-y-auto">
      <div>
        <p className="text-2xl text-gray-800">Add a new card</p>
        <p className="text-sm text-gray-400">
          Add yout debit or credit card details.
        </p>
      </div>
      <Formik
        enableReinitialize={true}
        onSubmit={values => handleSubmit(values)}
        initialValues={{
          name: cardValues.name,
          card_number: cardValues.card_number,
          expiry_date: cardValues.expiry_date,
          cvv: cardValues.cvv,
          default_card: cardValues.default_card,
        }}
        validationSchema={NEW_CARD_VALIDATION_SCHEMA}
      >
        {props => (
          <Form>
            <div>
              <div className="flex justify-between mx-1 my-3">
                <div className="border border-gray-200 rounded-md">
                  <img src={VisaImage} />
                </div>
                <div className="border border-gray-200 rounded-md">
                  <img src={MasterCardImage} />
                </div>
                <div className="border border-gray-200 rounded-md">
                  <img src={DiscoverImage} />
                </div>
                <div className="border border-gray-200 rounded-md">
                  <img src={AmericanImage} />
                </div>
              </div>
              <div className="space-y-6">
                <Input
                  placeholder="Card Number"
                  name="card_number"
                  onChange={e => {
                    onCardChange(e, props);
                  }}
                />
                <Input
                  placeholder="Expiration Date MM/YY"
                  name="expiry_date"
                  onChange={e => {
                    onExpiryDateChange(e, props);
                  }}
                />
                <Input
                  placeholder="CVC / Security Code"
                  name="cvv"
                  onBlur={e => {
                    setCardValues(state => ({
                      ...state,
                      cvv: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="mt-4">
                <Button
                  label="Add Card"
                  icon="ri-bank-card-line"
                  fullWidth
                  type="sumbit"
                  loading={isSubmitting}
                />
                <div className="flex justify-start pt-2">
                  <Button
                    label="Cancel"
                    style="text"
                    onClick={() => onCancel()}
                  />
                </div>
                <p className="mt-2 text-xs text-gray-400">
                  Your card will not be charged until you place your order.
                </p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="py-2">
        <img src={NortonImage} />
      </div>
    </div>
  );
};

export default NewCardPage;

import React, { useState } from "react";
import { Label, Switch } from "@bigbinary/neetoui";

const ReportInfo = () => {
  const [switchState, setSwitchState] = useState(false);
  return (
    <div className="w-1/3 pt-12 pr-4 border-r">
      <p className="pb-3 text-xl font-bold border-b">Reporting Information</p>
      <div className="pt-3 space-y-1">
        <div className="flex ">
          <Label className="w-1/2 font-semibold">_Reporting:_</Label>
          <Switch
            checked={switchState}
            onClick={() => setSwitchState(state => !state)}
          />
        </div>
        <div className="flex">
          <Label className="w-1/2 font-semibold">TradeLine Identifier:</Label>
          <p>_123456_</p>
        </div>
        <div className="flex">
          <Label className="w-1/2 font-semibold">Tradeline opened:</Label>
          <p>_August 12,2021_</p>
        </div>
        <div className="flex">
          <Label className="w-1/2 font-semibold">Last update:</Label>
          <p>_August 12,2021_</p>
        </div>
      </div>
    </div>
  );
};

export default ReportInfo;

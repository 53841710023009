import React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Textarea } from "neetoui/formik";
import { Button, Toastr } from "neetoui";
import customerNotesApi from "apis/notes";

const AddNote = ({ setNotes }) => {
  const { id } = useParams();

  const validationSchema = Yup.object({
    content: Yup.string().required("Note content is required"),
  });

  const handleSubmit = async (requestData, resetForm) => {
    try {
      const { data: note } = await customerNotesApi.create(id, {
        note: {
          content: requestData.content,
        },
      });

      resetForm();
      setNotes(notes => [note, ...notes]);
      Toastr.success("Added note successfully.");
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Formik
      initialValues={{
        content: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values, resetForm);
      }}
    >
      <Form className="w-full p-8 space-y-6 bg-white border rounded-lg shadow-sm">
        <Textarea name="content" rows="5" />
        <div className="flex justify-end w-full py-6 space-x-4">
          <Button label="Add Note" name="submit" type="submit" />
        </div>
      </Form>
    </Formik>
  );
};

export default AddNote;

export const displayPayCycle = pay_cycle => {
  switch (pay_cycle) {
  case "Every Other Week":
    return "2 weeks";
  case "Twice A Month":
    return "fortnight";
  case "Monthly":
    return "month";
  default:
    return "week";
  }
};

import React, { useState } from "react";
import { Button, Tooltip } from "neetoui";

const InputErrorMessage = ({ error, amount }) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className="flex">
      {error}
      <span>
        <Tooltip
          content={isCopied ? "Copied" : "Copy amount"}
          position="top"
          size="small"
          className={"inline"}
        >
          <Button
            style="icon"
            size={"small"}
            icon={isCopied ? "ri-check-line" : "ri-file-copy-line"}
            onClick={() => {
              navigator.clipboard.writeText(amount);
              setIsCopied(true);
            }}
            onBlur={() => setIsCopied(false)}
          />
        </Tooltip>
      </span>
    </div>
  );
};

export default InputErrorMessage;

import React, { useState } from "react";
import { Switch, Alert } from "@bigbinary/neetoui";
import Rules from "./Rules";
import General from "./General";
import Actions from "./Actions";

const ConfirmAlertComponent = ({ state, stateChange, setSwitchState }) => (
  <Alert
    isOpen={state}
    icon="ri-alarm-warning-fill text-red-500"
    title="Update Status"
    message={
      <div>
        Are you sure you want to mark the workflow as inactive? This will
        prevent any subsequent triggers from this workflow.
      </div>
    }
    hideConfirmation
    submitButtonProps={{
      onClick: () => {
        setSwitchState(state => !state);
        stateChange(false);
      },
    }}
    onClose={() => stateChange(false)}
  />
);

const NewWorkflow = () => {
  const [AlertState, setAlertState] = useState(false);
  const [switchState, setSwitchState] = useState(true);

  return (
    <>
      <div className="w-full px-8 my-5">
        <div>
          <div className="flex justify-between pb-2 border-b border-dashed ">
            <p className="text-xl font-bold">
              Payment Failure 3 - SMS Notification
            </p>
            <Switch
              onChange={() =>
                switchState ? setAlertState(true) : setSwitchState(true)
              }
              checked={switchState}
            />
          </div>
          <General />
          <Rules />
          <Actions />
        </div>
      </div>
      <ConfirmAlertComponent
        state={AlertState}
        stateChange={setAlertState}
        setSwitchState={setSwitchState}
      />
    </>
  );
};

export default NewWorkflow;

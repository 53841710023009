import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal, PageLoader } from "neetoui";
import leasesApi from "apis/customers/leases";
import { formatCurrency } from "utils/styleUtils";
import { formatDateWithoutTimeZone } from "common/dateTimeFormatter";

const AutoPaymentModal = ({
  setIsAutoPayModalOpen,
  isAutoPayModalOpen,
  leaseDetails,
}) => {
  const [CardDetails, setCardDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardDropDownSelected, setCardDropdownSelected] = useState();

  const ChangeAutopaymentSettings = async () => {
    setIsSubmitting(true);
    try {
      await leasesApi.updatePaymentDetails(cardDropDownSelected.id);
      setIsAutoPayModalOpen(false, true);
    } catch (error) {
      logger.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getAutoPayDetails = async () => {
    setIsLoading(true);
    try {
      const { data } = await leasesApi.autoPayDetails();
      let cardDetails = data.payment_methods;
      let leaseCard = cardDetails[0];
      setCardDetails(cardDetails);
      if (leaseDetails.payment_method_id) {
        leaseCard = cardDetails.find(
          card => card.id === leaseDetails.payment_method_id
        );
      } else {
        leaseCard = cardDetails.find(card => card.is_default);
      }
      setCardDropdownSelected(leaseCard);
    } catch (error) {
      logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onCardSelect = item => {
    setCardDropdownSelected(item);
  };

  useEffect(() => {
    getAutoPayDetails();
  }, []);

  return (
    <Modal
      size="small"
      className="px-4 py-1 mx-3 rounded-md"
      isOpen={isAutoPayModalOpen}
      onClose={() => setIsAutoPayModalOpen(false, false)}
      autoHeight
    >
      <>
        {isLoading ? (
          <div className="w-full h-32 ">
            <PageLoader />
          </div>
        ) : (
          <div>
            <div className="py-3 font-semibold border-b border-gray-200">
              Autopay Details Confirmation
            </div>
            <div className="flex justify-between py-3 border-b border-gray-200">
              <p>Next Payment Date</p>
              <p>
                {formatDateWithoutTimeZone(
                  leaseDetails?.upcoming_invoice?.due_date
                )}
              </p>
            </div>
            <div className="flex justify-between py-3 border-b border-gray-200">
              <p>Pays Cycle</p>
              <p>{leaseDetails?.pay_cycle}</p>
            </div>
            <div className="flex justify-between py-3 border-b border-gray-200">
              <p>Payment Method</p>
              <Dropdown
                label={`Card  ${cardDropDownSelected?.details?.card?.last4}`}
                buttonStyle="text"
                buttonProps={{ className: "text-sm" }}
                popoverClassName="pb-2"
                position="bottom-right"
                closeOnSelect
              >
                {CardDetails.map((item, idx) => (
                  <li key={idx} onClick={() => onCardSelect(item)}>
                    {`Card  ${item?.details?.card?.last4}`}
                  </li>
                ))}
              </Dropdown>
            </div>
            <div className="flex justify-between py-3 border-b border-gray-200">
              <p>Payment Amount</p>
              <p>
                {formatCurrency(
                  parseFloat(leaseDetails?.payment_amount) +
                    parseFloat(leaseDetails?.tax_on_payment_amount)
                )}
              </p>
            </div>
            <div className="flex justify-end py-3 space-x-4">
              <Button
                label="Cancel"
                style="secondary"
                onClick={() => setIsAutoPayModalOpen(false, false)}
                disabled={isSubmitting}
              />
              <Button
                label="Sumbit"
                onClick={() => ChangeAutopaymentSettings()}
                loading={isSubmitting}
              />
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};

export default AutoPaymentModal;

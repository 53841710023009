import { useEffect } from "react";

const useDocumentTitle = title => {
  useEffect(() => {
    document.title = `${title} | Becca's Home`;
  }, [title]);

  return null;
};

export default useDocumentTitle;

import React from "react";
import { Button } from "neetoui";

import KiqQuestions from "../common/KiqQuestions";
import Spinner from "components/SignUp/Registration/Spinner";

const KiqQuestionsModal = ({
  kbaQuestions,
  handleKiqSubmit,
  setKiqModal,
  processing = false,
}) => (
  <div className="sticky bottom-0 z-40 w-full bg-white border border-gray-200 shadow-lg rounded-t-2xl ">
    {processing ? (
      <div className="absolute bottom-0 z-40 w-full max-w-lg bg-white border border-gray-200 shadow-lg rounded-t-xl">
        <div className="p-2">
          <div className="flex items-center justify-between">
            <Spinner />
          </div>
        </div>
      </div>
    ) : (
      <div className="p-4">
        <div className="flex items-center justify-end">
          <Button
            className="text-2xl"
            style="text"
            icon="ri-close-line"
            onClick={() => setKiqModal(false)}
          />
        </div>
        <KiqQuestions
          kbaQuestions={kbaQuestions}
          handleSubmit={handleKiqSubmit}
        />
      </div>
    )}
  </div>
);

export default KiqQuestionsModal;

import { APP_NAME } from "common/constants";
import React from "react";

import { Helmet } from "react-helmet";

const withTitle = ({ Component, title = undefined }) =>
  function PageTitle(props) {
    const pageTitle = title ? `${title} | ${APP_NAME}` : APP_NAME;

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Component {...props} />
      </>
    );
  };

export default withTitle;

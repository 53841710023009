import React from "react";
import { Button } from "@bigbinary/neetoui";

import PopperComponent from "components/Admin/Customer/common/PopOverComponent";

const DeliveryPopper = ({ deliveryStatus }) => {
  return (
    <PopperComponent
      ReferenceComponent={
        <Button className="underline" label={deliveryStatus} style="link" />
      }
    >
      {onMouseLeaveFunction => (
        <div className="w-64 p-2 bg-white border border-gray-200 shadow">
          <div className="flex justify-between py-1 border-b border-gray-200 ">
            <div className="font-bold ">Delivery Details</div>
            <div>
              <Button
                style="icon"
                icon="ri-close-line"
                onClick={() => {
                  onMouseLeaveFunction();
                }}
              />
            </div>
          </div>

          <div className="py-1 space-y-1">
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Delivery Partner:</p>
              <p className="font-semibold text-gray-600">__FedEx_</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Tracking ID:</p>
              <p className="font-semibold text-purple-1000">
                __123000192020FD_
              </p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Estimated Date:</p>
              <p className="font-semibold text-gray-600">__03/23/2021_</p>
            </div>
            <div className="flex justify-between">
              <p className="font-semibold text-gray-600">Status:</p>
              <p className="font-semibold text-gray-600">__Delivered_</p>
            </div>
          </div>
        </div>
      )}
    </PopperComponent>
  );
};

export default DeliveryPopper;

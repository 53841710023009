import React from "react";

import agentsApi from "apis/agents";
import { Checkbox, Tooltip, Avatar, Button, Switch } from "@bigbinary/neetoui";

const AgentsTableRow = ({
  fetchAgents,
  agent: { id, name, role, email, last_login, status },
  setAgentBeingEdited,
}) => {
  const handleStatusClick = async () => {
    try {
      const newStatus = status === "active" ? "inactive" : "active";
      await agentsApi.update(id, { status: newStatus });
      fetchAgents();
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <tr className="text-left">
      <td>
        <div className="flex flex-row items-center justify-center">
          <Checkbox />
        </div>
      </td>

      <td>
        <div className="flex items-center">
          <Avatar size={36} contact={{ name }} />{" "}
          <span className="ml-3">{name}</span>
        </div>
      </td>

      <td>{role}</td>
      <td>{email}</td>
      <td>{last_login || "Unregistered"}</td>

      <td>
        <Switch checked={status === "active"} onClick={handleStatusClick} />
      </td>

      <td>
        <div className="flex space-x-3">
          <Tooltip content="Edit" position="bottom">
            <Button
              style="icon"
              icon="ri-pencil-line"
              onClick={setAgentBeingEdited}
            />
          </Tooltip>
          <Tooltip content="Send Invitation" position="bottom">
            <Button style="icon" icon="ri-mail-send-line" />
          </Tooltip>
        </div>
      </td>
    </tr>
  );
};

export default AgentsTableRow;

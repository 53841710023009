import React, { useState, useEffect } from "react";

import { Input, Label, Select, Pane } from "@bigbinary/neetoui";

import agentsApi from "apis/agents";
import CountrySelect from "components/Common/CountrySelect";

import { initializeAgent, useAgent } from "./agent-reducer";
import { AGENT_ROLES, AgentsStatus } from "../constants";

const AgentForm = ({
  showPane,
  closePane,
  agentBeingEdited,
  refetchAgents,
}) => {
  const [status, setStatus] = useState(AgentsStatus.IDLE);

  const [
    { error, firstName, lastName, email, role, country, phone },
    agentDispatch,
  ] = useAgent(agentBeingEdited);
  const resetAgent = () => agentDispatch({ type: "RESET" });
  const setAgentAttributes = payload => agentDispatch({ type: "SET", payload });

  useEffect(() => {
    agentBeingEdited &&
      setAgentAttributes({ ...initializeAgent(agentBeingEdited) });
  }, [agentBeingEdited]);

  const handleAgent = async () => {
    try {
      setStatus(AgentsStatus.LOADING);
      const payload = {
        agent: {
          first_name: firstName,
          last_name: lastName,
          email,
          role: role?.value,
          phone: phone && `+${country.phone}${phone}`,
        },
      };

      agentBeingEdited
        ? await agentsApi.update(agentBeingEdited.id, payload)
        : await agentsApi.invite(payload);

      setStatus(AgentsStatus.SUCCESS);
      handlePaneClose();
      refetchAgents();
    } catch (error) {
      setStatus(AgentsStatus.ERROR);
      setAgentAttributes({ error: error?.response?.data?.error });
    }
  };

  const handlePaneClose = () => {
    resetAgent();
    closePane();
  };

  return (
    <Pane
      isOpen={showPane}
      title={agentBeingEdited ? "Edit Agent" : "New Agent"}
      showFooter
      onClose={handlePaneClose}
      cancelButtonProps={{
        label: "Cancel",
        onClick: handlePaneClose,
      }}
      submitButtonProps={{
        loading: status === AgentsStatus.LOADING,
        label: agentBeingEdited ? "Save Changes" : "Create New Agent",
        onClick: handleAgent,
      }}
    >
      <form className="px-6 pb-20 space-y-6 overflow-auto ">
        <div className="flex space-x-4">
          <Input
            label="First Name"
            value={firstName}
            onChange={e => setAgentAttributes({ firstName: e.target.value })}
            error={error?.first_name}
            required
          />

          <Input
            label="Last Name"
            value={lastName}
            onChange={e => setAgentAttributes({ lastName: e.target.value })}
            error={error?.last_name}
            required
          />
        </div>

        <Input
          label="Email"
          value={email}
          onChange={e => setAgentAttributes({ email: e.target.value })}
          error={error?.email}
          required
        />

        <Select
          isClearable
          isSearchable
          label="Role"
          options={AGENT_ROLES}
          defaultValue={role}
          onChange={role => setAgentAttributes({ role })}
          error={error?.role}
          required
        />

        <div className="flex space-x-4">
          <div>
            <Label className="mb-1">Country</Label>
            <CountrySelect
              onCountryChange={country => setAgentAttributes({ country })}
              error={error?.country}
              required
            />
          </div>

          <Input
            label="Phone Number"
            value={phone}
            onChange={e => setAgentAttributes({ phone: e.target.value })}
            error={error?.phone}
            required
          />
        </div>
      </form>
    </Pane>
  );
};

export default AgentForm;

import React, { useEffect, useState } from "react";

import Header from "./Header";
import ApprovalStatusCard from "./ApprovalStatusCard";
import LeaseList from "components/MyAccount";
import creditApplicationsApi from "apis/customers/credit-application";

const Dashboard = ({ storeURL, creditEnabled }) => {
  const [creditApplication, setCreditApplication] = useState({});

  const fetchCreditStatus = async () => {
    try {
      const {
        data: { credit_application },
      } = await creditApplicationsApi.fetchCreditStatus();

      setCreditApplication(credit_application);
    } catch (error) {
      logger.log(error);
    }
  };

  useEffect(() => {
    fetchCreditStatus();
  }, []);

  return (
    <div className="px-4">
      <Header />
      <div className="flex flex-col items-center justify-start my-4 lg:max-w-6xl lg:mx-auto lg:mt-8 space-y-6">
        <ApprovalStatusCard
          storeURL={storeURL}
          creditApplication={creditApplication}
          creditEnabled={creditEnabled}
        />
      </div>
      <LeaseList storeURL={storeURL} />
    </div>
  );
};

export default Dashboard;

import React from "react";
import { Route, Switch } from "react-router-dom";

import ProductDetails from "./ProductDetails";
import VendorItemList from "./VendorDetails";
import VendorsList from "./VendorList";

const ProductImport = () => {
  return (
    <Switch>
      <Route
        component={VendorsList}
        exact
        path={"/product-import/vendor-list"}
      />
      <Route
        exact
        component={VendorItemList}
        path={"/product-import/vendor-list/:vendor-id"}
      />
      <Route
        exact
        component={ProductDetails}
        path={
          "/product-import/vendor-list/:vendor-id/product/:product-id/:tab?"
        }
      />
    </Switch>
  );
};

export default ProductImport;

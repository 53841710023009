import React from "react";
import classnames from "classnames";
import { Label } from "neetoui";

const Card = ({ title, children, className = "", titleAction = null }) => (
  <div className={classnames(["bg-white p-3 rounded-md"], className)}>
    <div className="flex justify-between">
      <Label>{title}</Label>
      {titleAction}
    </div>
    {children}
  </div>
);

export default Card;

import React, { useState } from "react";
import { Header, SubHeader } from "@bigbinary/neetoui/layouts";
import { Button } from "@bigbinary/neetoui";

import {
  DEFAULT_PAGE_SIZE,
  LOAD_SORT_PROPS,
  SORT_BY_OPTIONS,
} from "../constants";
import ProductImportsList from "./ProductList";
import ImportPane from "./ImportPane";

const VendorList = () => {
  const [searchString, setSearchString] = useState();
  const [totalOrderCount] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [importPane, setimportPane] = useState(false);

  return (
    <>
      <div className="w-full">
        <Header
          title="Product Import"
          actionBlock={
            <div className="flex space-x-2">
              <Button
                style="secondary"
                label="Import File"
                onClick={() => {
                  setimportPane(true);
                }}
              />
            </div>
          }
        />
        <SubHeader
          searchProps={{
            value: searchString,
            onChange: e => setSearchString(e.target.value),
            clear: () => setSearchString(""),
          }}
          paginationProps={{
            count: totalOrderCount,
            pageNo: pageIndex,
            pageSize: DEFAULT_PAGE_SIZE,
            navigate: index => setPageIndex(index),
          }}
          sortProps={{
            options: SORT_BY_OPTIONS,
            onClick: LOAD_SORT_PROPS,
          }}
        />
        <ProductImportsList />
      </div>
      <ImportPane importPane={importPane} setImportPane={setimportPane} />
    </>
  );
};

export default VendorList;

import React from "react";

import { formatCurrency } from "utils/styleUtils";

const AcccountSummaryRightSide = ({ customer }) => (
  <>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800">Lifetime Value</p>
      <p className="text-sm font-medium text-gray-800">
        {formatCurrency(customer.lifetime_value)}
      </p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800">Credits Received</p>
      <p className="text-sm font-medium text-gray-800">
        {formatCurrency(customer.credits_received)}
      </p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800">Due Days Deferred</p>
      <p className="text-sm font-medium text-gray-800">{"missing data"}</p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800"># of Applications</p>
      <p className="text-sm font-medium text-gray-800">
        {customer.total_applications}
      </p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800"># of Approvals</p>
      <p className="text-sm font-medium text-gray-800">
        {customer.applications_approved}
      </p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800"># of Rejections</p>
      <p className="text-sm font-medium text-gray-800">
        {customer.applications_rejected}
      </p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800"># of Charge Offs</p>
      <p className="text-sm font-medium text-gray-800">{"missing data"}</p>
    </div>
  </>
);

export default AcccountSummaryRightSide;

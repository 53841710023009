import React from "react";

const PaymentFooter = ({ upcomingInvoice }) => {
  return (
    <div className="flex justify-center w-full px-4 py-2 text-gray-600 bg-gray-100 rounded-b-md">
      Payment scheduled for
      <span className="px-1 font-semibold">{upcomingInvoice?.due_date}</span>
    </div>
  );
};

export default PaymentFooter;

import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import authenticationApi from "apis/customers/authentication";
import { setAuthHeaders } from "apis/axios";
import SignIn from "./SignIn";
import MagicLink from "./MagicLink";
import Password from "./Password";
import SendResetPassword from "./SendResetPassword";
import { Toastr } from "neetoui";
import ResetPassword from "./ResetPassword";
import { useAuthDispatch } from "contexts/auth";
import { useUserDispatch } from "contexts/user";
import MagicLinkSuccess from "./MagicLinkSuccess";

const CustomerLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const authDispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const {
        data: { auth_token, customer, redirect_uri },
      } = await authenticationApi.login({
        customer: { email, password },
      });

      await authDispatch({
        type: "LOGIN",
        payload: { auth_token, email, role: "customer" },
      });
      await setAuthHeaders();
      await userDispatch({ type: "SET_USER", payload: { user: customer } });

      window.location.href = redirect_uri || "/";

      Toastr.success("Logged in successfully.");
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Switch>
      <Route
        render={() => <SignIn email={email} setEmail={setEmail} />}
        exact
        path="/login"
      />
      <Route
        render={() => <MagicLinkSuccess email={email} />}
        exact
        path="/login/magic-link/success"
      />
      <Route
        render={() => <MagicLink email={email} />}
        exact
        path="/login/magic-link/"
      />
      <Route
        render={() => (
          <Password
            handleSubmit={handleSubmit}
            password={password}
            setPassword={setPassword}
            email={email}
          />
        )}
        exact
        path="/login/password"
      />
      <Route
        render={() => <ResetPassword />}
        exact
        path="/login/reset_password"
      />
      <Route
        render={() => <SendResetPassword email={email} setEmail={setEmail} />}
        exact
        path="/login/reset"
      />
    </Switch>
  );
};

export default CustomerLogin;

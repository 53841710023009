import React from "react";
import { Radio } from "neetoui";
import { Field } from "formik";
import { Input as FormikInput } from "neetoui/formik";

const ReduceAmount = () => (
  <div>
    <FormikInput label="Amount to reduce" name="amount" className="py-2" />
    <Field>
      {({ field, form: { values, setFieldValue } }) => (
        <Radio label="Split amount equally?" stacked classname="py-2">
          <Radio.Item
            {...field}
            label="Yes"
            checked={values.split}
            onChange={split => {
              split && setFieldValue("split", true);
            }}
          />
          <Radio.Item
            {...field}
            label="No"
            checked={!values.split}
            onChange={split => {
              split && setFieldValue("split", false);
            }}
          />
        </Radio>
      )}
    </Field>
  </div>
);

export default ReduceAmount;

import React, { useEffect, useState } from "react";
import { Alert, PageLoader } from "@bigbinary/neetoui";

import leasesApi from "apis/customers/leases";
import LeaseList from "./List";

const OverdueAlert = ({ overDueAlertState, setOverDueAlertState }) => (
  <Alert
    isOpen={overDueAlertState}
    icon="ri-alarm-warning-fill text-red-500"
    title="Overdue Balance"
    message={
      <div className="inline-block">
        <span>
          You have an overdue balance of $100 for your lease #3430BH due to an
          autopayment failure on 3rd March 2021.
        </span>
      </div>
    }
    hideConfirmation
    onClose={() => setOverDueAlertState(false)}
    submitButtonProps={{
      style: "primary",
      onClick: () => {
        setOverDueAlertState(false);
      },
      label: "Pay Now",
    }}
    cancelButtonProps={{
      onClick: () => setOverDueAlertState(false),
    }}
  />
);

const Leases = () => {
  const [loading, setLoading] = useState(false);
  const [leases, setLeases] = useState(null);

  const [overDueAlertState, setOverDueAlertState] = useState(false);

  const fetchLeases = async () => {
    try {
      setLoading(true);
      const { data } = await leasesApi.leases();
      setLeases(data.leases);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setOverDueAlertState(false);
    !leases && fetchLeases();
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center justify-center w-screen h-screen">
          <PageLoader />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full mt-10 space-y-6">
          {leases &&
            leases.map(lease => <LeaseList lease={lease} key={lease.id} />)}

          <OverdueAlert
            overDueAlertState={overDueAlertState}
            setOverDueAlertState={setOverDueAlertState}
          />
        </div>
      )}
    </>
  );
};

export default Leases;

import React, { useState, useEffect, useRef } from "react";
import { usePopper } from "react-popper";

const PopOver = ({ children, ReferenceComponent }) => {
  const [visible, setVisibility] = useState(false);

  const referenceRef = useRef(null);
  const popperRef = useRef(null);

  const { styles, attributes } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: "top",
      modifiers: [
        {
          name: "offset",
          enabled: true,
          options: {
            offset: [0, 10],
          },
        },
      ],
    }
  );
  useEffect(() => {
    // listen for clicks and close dropdown on body
    // referenceRef.current.addEventListener("mouseenter", handleDocumentClick);
    // referenceRef.current.addEventListener("mouseleave", handleDocumentClick);
    // return () => {
    //   referenceRef.current.removeEventListener(
    //     "mouseenter",
    //     handleDocumentClick
    //   );
    //   referenceRef.current.removeEventListener(
    //     "mouseleave",
    //     handleDocumentClick
    //   );
    // };
  }, []);

  const onMouseLeaveFunction = () => {
    setVisibility(false);
  };

  const onMouseEnterFunction = () => {
    setVisibility(true);
  };

  return (
    <React.Fragment>
      <div ref={popperRef} style={styles.popper} {...attributes.popper}>
        <div style={{ display: visible ? "flex" : "none" }}>
          {children(onMouseLeaveFunction)}
        </div>
      </div>

      <div
        ref={referenceRef}
        //on={() => onMouseLeaveFunction()}
        onClick={() => onMouseEnterFunction()}
      >
        {ReferenceComponent}
      </div>
    </React.Fragment>
  );
};

export default PopOver;

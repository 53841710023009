import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { usePlacesWidget } from "react-google-autocomplete";
import { MaskField } from "react-mask-field";
import classNames from "classnames";
import { Form, Formik } from "formik";
import { Input as FormikInput, Select } from "neetoui/formik";
import { Button, PageLoader, Input } from "neetoui";

import { setAuthHeaders } from "apis/axios";
import { US_STATES } from "common/constants";
import { GOOGLE_MAPS_API_KEY } from "../constant";
import { getOptions } from "utils/objectFormatter";
import { maskSSN, formValuesFromPlaces } from "common/utils";
import { useSignupState, useSignupDispatch } from "contexts/signup";
import addressesApi from "apis/customers/addresses";
import formValidationSchemas from "../registerFormValidationSchemas";
import InputErrorIcon from "components/Common/InputErrorIconElement";

const AddressInfo = () => {
  const history = useHistory();
  const signupDetails = useSignupState();
  const signupDispatch = useSignupDispatch();
  const [maskedData, setMaskedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showApartment, setShowApartment] = useState(false);
  const [isExistingSsn, setSsnState] = useState(
    signupDetails?.personalInfo?.ssn
  );
  const [formValues, setFormValues] = useState(signupDetails.addressInfo);

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    onPlaceSelected: place => {
      setFormValues({
        ...formValues,
        ...formValuesFromPlaces(place.address_components),
      });
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
    },
  });

  useEffect(() => {
    const ssn = signupDetails?.personalInfo?.ssn;

    if (ssn) {
      setMaskedData({ unmaskedValue: ssn, maskedValue: maskSSN(ssn) });
    }
  }, [isExistingSsn]);

  const onAddressChange = (fieldName, value) => {
    setFormValues({ ...formValues, [fieldName]: value });
  };

  const sendGtmEvent = addressData => {
    if (window.dataLayer)
      window.dataLayer.push({
        event: "application.address_info",
        ...signupDetails.personalInfo,
        ...signupDetails.workInfo,
        ...addressData,
      });
  };

  const handleSubmit = async details => {
    try {
      setLoading(true);
      const payload = {
        address: { ...details, state: details.state.value },
      };
      const { data } = await callApi(payload);

      signupDispatch({
        type: "ADDRESS_INFO",
        payload: {
          ...payload.address,
          id: data.address.id,
        },
      });

      sendGtmEvent(payload.address);
      history.push("/flow/register/submit");
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const callApi = async payload => {
    setAuthHeaders();
    const addressId = signupDetails.addressInfo.id;
    if (addressId) {
      return await addressesApi.update(addressId, payload);
    } else {
      return await addressesApi.create(payload);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center justify-center becca-homepage">
          <PageLoader />
        </div>
      ) : (
        <>
          <div className="px-4 mt-4">
            <div className="mb-6">
              <p className="w-5/6 text-lg">What’s your home address? *</p>
              <p className="my-1 text-sm text-gray-500">
                This helps verify your identity.
              </p>
            </div>
            <Formik
              initialValues={formValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validateOnChange={true}
              validateOnBlur={true}
              validationSchema={formValidationSchemas.addressInfo}
            >
              {({ errors, setFieldValue, values }) => (
                <Form noValidate>
                  <div className="space-y-4">
                    <Input
                      placeholder="Street Address"
                      name="street_number"
                      type="text"
                      ref={ref}
                      value={values.street_number}
                      onChange={e =>
                        onAddressChange("street_number", e.target.value)
                      }
                      error={errors.street_number}
                    />
                    {!showApartment && (
                      <Button
                        className="underline"
                        style="link"
                        label="Add Apt #, Unit, Floor (optional)"
                        onClick={() => setShowApartment(true)}
                      />
                    )}
                    {showApartment && (
                      <FormikInput
                        placeholder="Apartment Number, Unit, Floor (optional)"
                        name="area"
                      />
                    )}
                    <Input
                      placeholder="City"
                      name="city"
                      type="text"
                      value={values.city}
                      onChange={e => onAddressChange("city", e.target.value)}
                      error={errors.city}
                    />
                    <Select
                      placeholder="State"
                      isDisabled={false}
                      name="state"
                      options={getOptions(US_STATES)}
                      error={errors.state}
                    />
                    <Input
                      placeholder="Zip code"
                      name="zip_code"
                      type="text"
                      value={values.zip_code}
                      onChange={e =>
                        onAddressChange("zip_code", e.target.value)
                      }
                      error={errors.zip_code}
                    />
                  </div>
                  <p className="my-4 text-sm text-gray-500">
                    P.O. boxes and temporary addresses are not supported.
                  </p>
                  <div className="space-y-4 ">
                    <p className="w-5/6 text-lg">
                      Now, please confirm your identity. *
                    </p>
                    {isExistingSsn ? (
                      <Input
                        name="fakessn"
                        onChange={() => setSsnState(false)}
                        value={`*** - ** - ${values?.ssn.substr(-4)}`}
                        onFocus={() => setSsnState(false)}
                      />
                    ) : (
                      <>
                        <div className="nui-input__wrapper mb-5">
                          <div
                            className={classNames("nui-input", {
                              "nui-input--error": errors.ssn,
                            })}
                          >
                            <MaskField
                              showMask={maskedData.unmaskedValue}
                              placeholder="Social Security / ITIN Number"
                              type="tel"
                              pattern="[\d]*"
                              inputMode="numeric"
                              value={maskedData.maskedValue}
                              mask="___-__-____"
                              replacement={{ _: /\d/ }}
                              onMasking={event => {
                                setMaskedData(event.detail);
                              }}
                              onBlur={() =>
                                setFieldValue("ssn", maskedData.unmaskedValue)
                              }
                            />
                            {errors.ssn && <InputErrorIcon />}
                          </div>
                          {errors.ssn && (
                            <p className="nui-input__error">{errors.ssn}</p>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <p className="my-4 text-sm text-gray-500 ">
                    Your social security or individual tax identification number
                    is used to confirm your identity. It’s also used to
                    recognize you if you need to come back.
                  </p>
                  <Button label="Next" type="submit" />
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default AddressInfo;

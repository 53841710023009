import React from "react";
import { Button } from "@bigbinary/neetoui";

import HeaderIcon from "./Header";
import { BANK_PAYMENT_OPTIONS } from "components/Payments/constants";
import { useHistory } from "react-router-dom";

const BankPayment = () => {
  const history = useHistory();
  const onCancel = () => {
    history.goBack();
  };

  return (
    <div className="p-6">
      <HeaderIcon />
      <div>
        <p className="text-lg">
          <span className="font-semibold text-purple-1000">Becca’s Home</span>
          &nbsp;uses Plaid to link your bank account.
        </p>
        <div>
          <div className="mt-4 space-y-6">
            {BANK_PAYMENT_OPTIONS.map((item, index) => {
              return (
                <div key={index}>
                  <div className="flex items-center justify-start space-x-4">
                    <span
                      className={`text-3xl leading-none text-gray-800 ${item.icon}`}
                    />
                    <p className="text-2xl font-semibold text-purple-1000">
                      {item.label}
                    </p>
                  </div>
                  <div>
                    <p className="mt-3 text-lg">{item.info}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex items-center justify-end mt-6 space-x-4">
            <Button
              label="Cancel"
              style="secondary"
              onClick={() => onCancel()}
            />
            <Button label="Continue" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankPayment;

import React from "react";
import { Button } from "neetoui";
import { Input as FormikInput } from "neetoui/formik";

const LeaseDetailsList = ({ isDollar = false, leaseList }) => {
  return (
    <>
      {leaseList?.map(item => {
        return (
          <div
            className="py-6 space-y-2 border-t border-gray-400 border-dashed"
            key={item?.name}
          >
            <div className="flex justify-between">
              <Button
                style="link"
                label={item?.name}
                className="font-semibold"
              />
              {isDollar && (
                <div className="w-20">
                  <FormikInput name="splitpercentage" size="small" />
                </div>
              )}
            </div>
            {item?.data_1 && (
              <div className="flex justify-between w-full">
                <p>{item?.data_1?.label}:</p>
                <p>{item?.data_1?.value}</p>
              </div>
            )}
            {item?.data_2 && (
              <div className="flex justify-between w-full">
                <p>{item?.data_2?.label}:</p>
                <p>{item?.data_2?.value}</p>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default LeaseDetailsList;

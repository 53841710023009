import React from "react";
import classnames from "classnames";

export const Body = ({ className = "", children }) => {
  return (
    <div className={classnames("p-4 flex flex-col space-y-2", [className])}>
      {children}
    </div>
  );
};

import React, { useState } from "react";
import { Button, Toastr } from "neetoui";
import { useHistory } from "react-router-dom";
import { resetAuthTokens } from "apis/axios";
import { useAuthDispatch } from "contexts/auth";
import BeccasHomeLogo from "common/images/beccas-home-logo.png";

import ContactUs from "./ContactUs";
import { MENU_ITEMS } from "../constants";

const MobileHeader = ({ credit_enabled, store_url }) => {
  const [menuState, setMenuState] = useState(false);
  const [contactUs, setContactus] = useState(false);
  const history = useHistory();
  const authDispatch = useAuthDispatch();
  const filteredMenuItems = MENU_ITEMS.filter(item => {
    return item.value == "creditApproval" && !credit_enabled ? false : true;
  });

  const menuStatusChange = () => {
    setMenuState(state => !state);
  };

  const logout = () => {
    try {
      authDispatch({
        type: "LOGOUT",
      });
      resetAuthTokens();
      window.location.href = "/logout";
      Toastr.success("Logged out successfully");
    } catch (error) {
      logger.error(error);
    }
  };

  const LogoClick = () => {
    history.push("/dashboard");
  };

  const onMenuItemClick = value => {
    switch (value) {
    case "lease": {
      history.push("/dashboard");
      setMenuState(state => !state);
      break;
    }
    case "payment": {
      history.push("/my-account/payment/new");
      setMenuState(state => !state);
      break;
    }
    case "creditApproval": {
      history.push("/my-account/credit-applications/new");
      setMenuState(state => !state);
      break;
    }
    case "listing": {
      history.push("/my-account/payment-methods");
      setMenuState(state => !state);
      break;
    }
    case "profile": {
      history.push("/my-account/my-profile");
      setMenuState(state => !state);
      break;
    }
    case "password": {
      history.push("/my-account/change-password");
      setMenuState(state => !state);
      break;
    }
    case "contact": {
      setContactus(true);
    }
    }
  };

  return (
    <div className="max-w-lg mx-auto md:mb-4">
      <div className="flex items-center justify-between w-full border-b border-gray-200 lg:h-16">
        <div className="flex items-center justify-between w-full p-2 lg:max-w-6xl lg:mx-auto">
          <img
            src={BeccasHomeLogo}
            width="72"
            height="72"
            onClick={() => LogoClick()}
            className="cursor-pointer"
          />
          <div className="flex space-x-4">
            <Button
              className="text-sm"
              style="link"
              label="Go to store"
              type="link"
              icon="ri-external-link-line"
              href={store_url}
            />
            <Button
              className="text-lg"
              style="text"
              icon="ri-question-line text-xl"
              onClick={() => setContactus(true)}
            />
            <Button
              className="text-2xl"
              style="text"
              icon="ri-menu-line"
              onClick={() => menuStatusChange()}
            />
          </div>
        </div>
        {menuState && (
          <div className="absolute top-0 left-0 z-40 w-full">
            <div className="w-full p-2 bg-white border border-gray-200 shadow-lg max-w-lg mx-auto">
              <div className="flex justify-between lg:justify-end">
                <div className="pt-1 lg:hidden">
                  <img src={BeccasHomeLogo} width="50px" height="50px" />
                </div>
                <Button
                  className="text-2xl"
                  style="text"
                  icon="ri-close-line"
                  onClick={() => menuStatusChange()}
                />
              </div>
              <div className="p-2">
                {filteredMenuItems.map(item => (
                  <div
                    key={item.name}
                    className="px-2 py-3 border-b border-gray-200 cursor-pointer"
                    onClick={() => onMenuItemClick(item.value)}
                  >
                    {item.name}
                  </div>
                ))}
                <div className="py-4">
                  <Button
                    style="secondary"
                    label="Log Out"
                    onClick={() => logout()}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ContactUs contactModal={contactUs} setcontactModal={setContactus} />
    </div>
  );
};

export default MobileHeader;

import React from "react";

import ProductOverviewMain from "./Main";
import Synopsis from "./Synopsis";

const ProductOverview = () => {
  return (
    <div className="flex bg-gray-100">
      <ProductOverviewMain />
      <Synopsis />
    </div>
  );
};

export default ProductOverview;

import React from "react";

import { Checkbox, TableLoader, Switch, Button } from "@bigbinary/neetoui";

const PaymentSettingsTableBody = ({ data }) => (
  <tbody>
    {data.map((item, index) => (
      <tr key={index}>
        <td>
          <div className="flex flex-row items-center justify-center">
            <Checkbox />
          </div>
        </td>
        <td>
          <div className="flex items-center">
            <Button style="link" label={item.title} />
          </div>
        </td>
        <td>{item.timing}</td>
        <td>{item.category}</td>
        <td className="text-center">{item.run_count}</td>
        <td className="text-center">{item.queue_count}</td>
        <td>
          <Switch checked={item.status} />
        </td>
        <td className="text-center">
          <div className="text-lg ri-inbox-archive-line"></div>
        </td>
      </tr>
    ))}
  </tbody>
);

const PaymentSettingsTableHeader = () => (
  <thead>
    <tr className="text-left">
      <th>
        <div className="flex flex-row items-center justify-center">
          <Checkbox disabled />
        </div>
      </th>
      <th>Title</th>
      <th>timing</th>
      <th>Category</th>
      <th className="text-center">run count</th>
      <th className="text-center">QUEUE count</th>
      <th>Status</th>
      <th className="text-center">Archive</th>
    </tr>
  </thead>
);

const PaymentSettingsTable = ({ loading, children }) => (
  <div className="flex justify-center w-full mt-6">
    <div className="w-11/12">
      <table className="nui-table nui-table--checkbox nui-table--avatar">
        {loading ? (
          <TableLoader rows={20} checkbox avatars actions />
        ) : (
          children
        )}
      </table>
    </div>
  </div>
);

PaymentSettingsTable.Body = PaymentSettingsTableBody;
PaymentSettingsTable.Header = PaymentSettingsTableHeader;

export default PaymentSettingsTable;

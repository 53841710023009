import React, { useEffect, useState } from "react";
import { Button, Toastr, PageLoader } from "@bigbinary/neetoui";
import { Input } from "neetoui/formik";
import SMSModal from "./common/SmsModal";
import { useUserState } from "contexts/user";
import customersProfileApi from "apis/customers/profile";
import customersPhoneVerificationApi from "apis/customers/phone-verification";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";

const Profile = () => {
  const { user } = useUserState();
  let history = useHistory();
  const [smsModal, setSmsModal] = useState(false);
  const [initialPhoneNumber, setInitialPhoneNumber] = useState("");
  const [phoneTobeVerified, setPhoneTobeVerified] = useState("");
  const [phoneId, setPhoneId] = useState("");
  const [loading, setLoading] = useState(false);

  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: {
      id: "",
      area: "",
      city: "",
      state: "",
      street_number: "",
    },
    zip_code: "",
    phone_number: "",
  });

  const getProfileData = async () => {
    try {
      setLoading(true);
      const { data } = await customersProfileApi.getProfile(user.id);
      setInitialPhoneNumber(data.customer.phone_number);
      setProfileData(data.customer);
      setPhoneId(data.customer?.phone?.id);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const goback = () => {
    history.goBack();
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const phoneNumberUpdate = async data => {
    try {
      await customersPhoneVerificationApi.registerPhone({
        phone: {
          country_code: "+1",
          phone_number: data.phone_number,
          phoneable_type: "Customer",
          phoneable_id: profileData.id,
        },
      });
      setPhoneTobeVerified(data.phone_number);
      setSmsModal(true);
    } catch (error) {
      logger.error(error);
      setSmsModal(false);
    }
  };

  const handleSubmit = async data => {
    try {
      if (data.phone_number !== initialPhoneNumber) {
        phoneNumberUpdate(data);
      } else {
        await customersProfileApi.updateProfile({
          customer: {
            first_name: data.first_name,
            last_name: data.last_name,
            addresses_attributes: {
              id: profileData.address.id,
              area: data.area,
              city: data.city,
              country: data.country,
              state: data.state,
              street_number: data.street_number,
              zip_code: data.zip_code,
            },
            phone_attributes: {
              id: phoneId,
              phone_number: data.phone_number,
            },
          },
        });
        Toastr.success("Profile details updated successfully");
      }
    } catch (error) {
      logger.error(error);
    }
  };

  const onModalClose = () => {
    setSmsModal(false);
  };

  let initialValues = { ...profileData, ...profileData.address };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center justify-center becca-homepage">
          <PageLoader />
        </div>
      ) : (
        <>
          <div className="mt-8">
            <div className="flex flex-col items-center justify-center">
              <div className="w-2/3 p-4 text-lg font-semibold border-b border-gray-200">
                My Details
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={Yup.object({
                  first_name: Yup.string()
                    .required("First name is required")
                    .nullable(),
                  last_name: Yup.string().required("Last name is required"),
                  email: Yup.string()
                    .email("Incorrect Email")
                    .required("Email id is required"),
                  area: Yup.string().required(" Area is required"),
                  zip_code: Yup.string()
                    .test(
                      "len",
                      "Please enter valid US zip code.",
                      val => val.length === 5
                    )
                    .required("Zip code is required"),
                  state: Yup.string().required("State is required"),
                  city: Yup.string().required("City is required"),
                  phone_number: Yup.string()
                    .matches(phoneRegExp, "Phone number is not valid")
                    .required("Phone number is required"),
                })}
              >
                {() => (
                  <Form>
                    <div className="w-full p-8 space-y-6">
                      <div className="flex justify-between">
                        <div className="w-1/3">
                          <Input label="First Name" name="first_name" />
                        </div>
                        <div className="w-1/3">
                          <Input label="Last Name" name="last_name" />
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="w-1/3">
                          <Input label="Email" name="email" />
                        </div>
                        <div className="w-1/3">
                          <Input label="Mobile Number" name="phone_number" />
                        </div>
                      </div>
                      <div>
                        <Input label="Home Address" name="area" />
                      </div>
                      <div>
                        <Input label="Apt/suite" name="street_number" />
                      </div>
                      <div className="flex justify-between">
                        <div className="w-1/5">
                          <Input label="City" name="city" />
                        </div>
                        <div className="w-1/5">
                          <Input label="State" name="state" />
                        </div>
                        <div className="w-1/5">
                          <Input label="Zip" name="zip_code" />
                        </div>
                      </div>
                      <div className="flex justify-end space-x-4">
                        <Button
                          label="Cancel"
                          style="secondary"
                          onClick={() => goback()}
                        />
                        <Button
                          label="Save Changes"
                          type="submit"
                          name="submit"
                          onSubmit={() => handleSubmit()}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <SMSModal
            smsModal={smsModal}
            onModalClose={onModalClose}
            phoneNumber={phoneTobeVerified}
            initialPhoneNumber={initialPhoneNumber}
            resentOtp={phoneNumberUpdate}
            setInitialPhoneNumber={setInitialPhoneNumber}
            setPhoneId={setPhoneId}
          />
        </>
      )}
    </>
  );
};

export default Profile;

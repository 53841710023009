import React, { useEffect, useRef, useState } from "react";
import { Button, Label, Toastr } from "neetoui";

import { useHistory } from "react-router-dom";
import { toTitleCase } from "utils/styleUtils";
import customersProfileApi from "apis/customers/profile";
import customersOtpApi from "apis/customers/otp";
import experianFraudApi from "apis/customers/experian-fraud";

import CreditSMSModal from "./CreditSmsModal";
import CreditConfirmModal from "./CreditConfirmModal";
import PersonalForm from "./PersonalDataForm";
import EmploymentForm from "./EmploymentForm";
import FraudOtpModal from "../common/FraudOtpModal";
import KiqQuestionsModal from "./KiqQuestionsModal";
import { creditApplicationSubscription } from "channels/creditApplicationChannel";
import Spinner from "components/SignUp/Registration/Spinner";
import { PageLoader } from "neetoui";

const CreditApproval = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPgeLoading, setIsPageLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isSmsModalOpen, setIsSmsModalOpen] = useState(false);
  const addressFormRef = useRef(null);
  const employmentFormRef = useRef(null);
  const history = useHistory();

  const [customer, setCustomer] = useState();
  const [changePersonal, setChangePersonal] = useState(true);
  const [changeEmployment, setChangeEmployment] = useState(true);
  const [isCreditConfirmOpen, setIsCreditConfirmOpen] = useState(false);
  const [fraudOtpModal, setFraudOtpModal] = useState(false);
  const [kiqModal, setKiqModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [creditInfo, setCreditInfo] = useState({ status: "", limit: 0 });

  const address = customer?.address;
  const phoneNumber = customer?.phone_number;
  const employmentDetail = customer?.employment_detail;

  const kbaQuestions = JSON.parse(localStorage.getItem("kbaQuestions")) || [];

  const onSmsModalClose = (value, page) => {
    setIsSmsModalOpen(false);
    nextStep(page, value);
  };

  const onFraudOtpModalClose = (value, page) => {
    setFraudOtpModal(false);
    nextStep(page, value);
  };

  const onKiqModalClose = (value, page) => {
    setKiqModal(false);
    nextStep(page, value);
  };

  const fetchCustomer = async (loading = true) => {
    loading && setIsLoading(true);
    try {
      const {
        data: { customer },
      } = await customersProfileApi.getProfile();
      setCustomer(customer);
    } catch (error) {
      logger.error(error);
    } finally {
      loading && setIsLoading(false);
      setIsPageLoading(false);
    }
  };

  const sendOtp = async () => {
    try {
      await customersOtpApi.create();
      setIsSmsModalOpen(true);
    } catch (error) {
      logger.error(error);
    }
  };

  const submitAndSendOtp = async () => {
    try {
      if (addressFormRef.current && employmentFormRef.current) {
        await addressFormRef.current.submitForm();
        await employmentFormRef.current.submitForm();

        if (
          addressFormRef.current.isValid &&
          employmentFormRef.current.isValid
        ) {
          await fetchCustomer();
          await customersOtpApi.create();
          setIsSmsModalOpen(true);
        }
      }
    } catch (error) {
      logger.error(error);
    }
  };

  const showNextModal = (data, setNextModal) => {
    const page = data.page;
    const limit = data.credit_limit;
    const status = data.credit_status;
    const kba_questions = data?.kba_questions;

    if (kba_questions && kba_questions?.length) {
      localStorage.setItem("kbaQuestions", JSON.stringify(kba_questions));
      setNextModal(true, page);
    } else if (limit) {
      setCreditInfo(state => ({ ...state, status, limit }));
      setNextModal(true, page);
    } else if (status) {
      setCreditInfo(state => ({ ...state, status }));
      setNextModal(true, page);
    } else {
      if (data.message) Toastr.success(data.message);
      setNextModal(true, page);
    }
  };

  const handleFraudOtpResponse = async data => {
    try {
      await fetchCustomer(false);
    } catch (error) {
      logger.error(error);
      onFraudOtpModalClose(false, null);
    } finally {
      setProcessing(false);
    }

    setRedirectUrl(data.redirect_uri);
    showNextModal(data, onFraudOtpModalClose);
  };

  const handleFraudOtpSubmit = async (event, otp) => {
    try {
      event.preventDefault();
      setProcessing(true);

      await experianFraudApi.processFraudOtp({
        otp: otp.join(""),
        reapply: true,
      });
    } catch (error) {
      logger.error(error);
      onFraudOtpModalClose(false, null);
    }
  };

  const handleKiqResponse = async data => {
    try {
      await fetchCustomer(false);
    } catch (error) {
      logger.error(error);
      onKiqModalClose(false, null);
    } finally {
      setProcessing(false);
    }

    setRedirectUrl(data.redirect_uri);
    showNextModal(data, onKiqModalClose);
  };

  const handleKiqSubmit = async details => {
    const answersObj = {};

    Object.keys(details).forEach(questionType => {
      const question = kbaQuestions.find(q => q.questionType == questionType);
      const index = kbaQuestions.indexOf(question);
      answersObj[`outWalletAnswer${index + 1}`] =
        question.questionSelect.questionChoice.indexOf(details[questionType]) +
        1;
    });

    try {
      setProcessing(true);
      const payload = { answers_obj: answersObj, reapply: true };

      await experianFraudApi.processFraudKiq(payload);
    } catch (error) {
      logger.error(error);
      onKiqModalClose(false, null);
    }
  };

  const nextStep = (page, value) => {
    switch (page) {
    case "Otp":
      setFraudOtpModal(value);
      creditApplicationSubscription({
        authToken: customer?.authentication_token,
        email: customer?.email,
        customerId: customer?.id,
        handleResponse: handleFraudOtpResponse,
      });

      break;
    case "Confirm":
      setIsCreditConfirmOpen(value);
      break;
    case "Kiq":
      setKiqModal(value);
      creditApplicationSubscription({
        authToken: customer?.authentication_token,
        email: customer?.email,
        customerId: customer?.id,
        handleResponse: handleKiqResponse,
      });

      break;
    }
  };

  useEffect(() => {
    setIsPageLoading(true);
    fetchCustomer();
  }, []);

  if (isLoading || isPgeLoading)
    return (
      <div className="flex flex-col items-center justify-center becca-homepage">
        {isPgeLoading && <PageLoader />}
        {!isPgeLoading && <Spinner />}
      </div>
    );
  return (
    <>
      <div className="px-4 overflow-y-auto w-full">
        <div className="py-4">
          <div className="text-lg font-semibold text-gray-600">
            <span className="text-purple-1000">Refresh</span> your limit
          </div>
          <p>{customer?.name}, please update your information.</p>
        </div>
        {!changePersonal && (
          <div>
            <div className="flex justify-between pb-1 border-b border-gray-300">
              <p className="text-base">Personal Information</p>
              <Button
                label="Change"
                style="link"
                className="underline"
                onClick={() => setChangePersonal(true)}
              />
            </div>
            <div className="flex justify-between px-2 py-2 ">
              <Label>Name</Label>
              <Label className="text-right">{customer?.name}</Label>
            </div>
            <div className="flex justify-between items-start px-2 py-2">
              <Label>{toTitleCase(address?.address_type, "_")}</Label>
              <Label className="text-right">
                {address?.street_number}, <br />
                {address?.area}, <br /> {address?.city}, {address?.state}{" "}
                {address?.zip_code}
              </Label>
            </div>
          </div>
        )}
        {changePersonal && (
          <PersonalForm
            address={address}
            setChangePersonal={setChangePersonal}
            ref={addressFormRef}
          />
        )}
        {!changeEmployment && (
          <div>
            <div className="flex justify-between py-4 pb-1 border-b border-gray-300">
              <p className="text-base">Employment Information</p>
              <Button
                label="Change"
                style="link"
                className="underline"
                onClick={() => setChangeEmployment(true)}
              />
            </div>
            <div className="flex justify-between px-2 py-2">
              <Label>Employment Status</Label>
              <Label className="text-right">
                {toTitleCase(employmentDetail?.current_employment_status, "_")}
              </Label>
            </div>
            <div className="flex justify-between px-2 py-2">
              <Label>Pay Frequency</Label>
              <Label className="text-right">
                {employmentDetail?.pay_cycle}
              </Label>
            </div>
            <div className="flex justify-between px-2 py-2">
              <Label>Monthly Income</Label>
              <Label className="text-right">
                {employmentDetail?.monthly_income}
              </Label>
            </div>
            <div className="flex justify-between px-2 py-2">
              <Label>Last Pay Day</Label>
              <Label className="text-right">
                {employmentDetail?.last_pay_day}
              </Label>
            </div>
            <div className="flex justify-between px-2 py-2">
              <Label>Next Pay Day</Label>
              <Label className="text-right">
                {employmentDetail?.next_pay_day}
              </Label>
            </div>
            <div className="flex justify-between px-2 py-2">
              <Label>Payment Method</Label>
              <Label className="text-right">
                {toTitleCase(employmentDetail?.mode_of_salary, "_")}
              </Label>
            </div>
          </div>
        )}
        {changeEmployment && (
          <EmploymentForm
            employmentDetail={employmentDetail}
            setChangeEmployment={setChangeEmployment}
            ref={employmentFormRef}
            setIsLoading={setIsLoading}
            showExisting={
              employmentFormRef.current && employmentFormRef.current.isValid
            }
          />
        )}
        {!(
          isSmsModalOpen ||
          isCreditConfirmOpen ||
          fraudOtpModal ||
          kiqModal
        ) && (
          <div className="space-y-2 my-6 flex flex-row">
            <Button
              label="Refresh Approval"
              fullWidth
              onClick={submitAndSendOtp}
            />
            <Button
              label="Cancel"
              style="text"
              className="text-sm underline ml-4"
              onClick={() => history.goBack()}
            />
          </div>
        )}
      </div>
      {isSmsModalOpen && (
        <CreditSMSModal
          phoneNumber={phoneNumber}
          resentOtp={sendOtp}
          customer={customer}
          fetchCustomer={fetchCustomer}
          showNextModal={showNextModal}
          onSmsModalClose={onSmsModalClose}
          setRedirectUrl={setRedirectUrl}
        />
      )}
      <CreditConfirmModal
        customer={customer}
        creditInfo={creditInfo}
        isCreditConfirmOpen={isCreditConfirmOpen}
        setIsCreditConfirmOpen={setIsCreditConfirmOpen}
        redirectUrl={redirectUrl}
      />
      <FraudOtpModal
        closeBtn={true}
        isOpen={fraudOtpModal}
        setIsOpen={setFraudOtpModal}
        phone={phoneNumber}
        handleSubmit={handleFraudOtpSubmit}
        processing={processing}
      />
      {kiqModal && (
        <KiqQuestionsModal
          kbaQuestions={kbaQuestions}
          handleKiqSubmit={handleKiqSubmit}
          setKiqModal={setKiqModal}
          processing={processing}
        />
      )}
    </>
  );
};

export default CreditApproval;

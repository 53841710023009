import React, { useState } from "react";

import { Label, Pane, Button } from "@bigbinary/neetoui";
import { Form, Formik } from "formik";

import { Input as FormikInput, Switch as FormikSwitch } from "neetoui/formik";

import paymentMethodsApi from "apis/payment-methods";
import { useCustomer, useCustomerState } from "contexts/customer";

import {
  NEW_CARD_FORM_INITIAL_VALUES,
  NEW_CARD_FORM_VALIDATION_SCHEMA,
  PaymentsStatus,
} from "../constants";

const CardForm = ({
  setAddCardPane,
  addCardPane,
  CardIcon,
  cardBeingEdited = "",
  setCardBeingEdited = "",
}) => {
  const { customer } = useCustomerState();
  const [, customerDispatch] = useCustomer();

  const [status, setStatus] = useState(PaymentsStatus.IDLE);

  const handleSubmit = async card => {
    try {
      const payload = {
        card: {
          number: card.number,
          cvc: card.cvc,
          is_default: card.is_default,
          exp_year: card.exp_year,
          exp_month: card.exp_month,
        },
      };
      setStatus(PaymentsStatus.LOADING);
      await paymentMethodsApi.create(customer.id, payload);
      setStatus(PaymentsStatus.SUCCESS);
      setAddCardPane(false);
      customerDispatch({ type: "SET_ADD_CARD" });
    } catch (error) {
      setStatus(PaymentsStatus.ERROR);
    }
  };

  const handlePaneClose = () => {
    setAddCardPane(false);
    cardBeingEdited && setCardBeingEdited(null);
  };

  const handleCancel = () => {
    customerDispatch({ type: "SET_ADD_CARD" });
    setAddCardPane(false);
  };

  return (
    <Pane
      title={cardBeingEdited ? "Edit Card" : "Add Card"}
      isOpen={addCardPane}
      onClose={handlePaneClose}
    >
      <Formik
        initialValues={NEW_CARD_FORM_INITIAL_VALUES}
        validationSchema={NEW_CARD_FORM_VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        <Form className="px-6 pb-20 space-y-6 overflow-auto">
          <FormikInput
            label="Card Number *"
            name="number"
            placeholder="4242424242424242"
            prefix={<img src={CardIcon} width="40px" height="40px" />}
          />

          <div className="flex space-x-4">
            <FormikInput
              className="w-1/3"
              label="Expiry Month *"
              name="exp_month"
              placeholder="7"
            />

            <FormikInput
              className="w-1/3"
              label="Expiry Year *"
              name="exp_year"
              placeholder="2022"
            />

            <FormikInput
              className="w-1/3"
              name="cvc"
              label="CVC *"
              placeholder="314"
            />
          </div>

          <div className="flex justify-between">
            <Label>Use card as default</Label>
            <FormikSwitch name="is_default" />
          </div>
          <div className="flex justify-end w-full space-x-2 neeto-ui-pane__footer neeto-ui-shadow-m">
            <Button
              style="primary"
              label="Submit"
              type="sumbit"
              loading={status == PaymentsStatus.LOADING}
            />
            <Button
              style="secondary"
              label="Cancel"
              onClick={() => handleCancel()}
            />
          </div>
        </Form>
      </Formik>
    </Pane>
  );
};

export default CardForm;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "neetoui";
import { Input } from "neetoui/formik";
import customersPasswordApi from "apis/customers/password";
import { Form, Formik } from "formik";

import {
  NEW_PASSWORD_VALIDATION_SCHEMA,
  NEW_PASSWORD_INITIAL_VALUES,
} from "./constants";
import customersProfileApi from "apis/customers/profile";
import { useUserState } from "contexts/user";

const NewPassword = () => {
  const history = useHistory();
  const { user } = useUserState();
  const [profileData, setProfileData] = useState();

  const handleSubmit = async (values, resetForm) => {
    try {
      await customersPasswordApi.changePassword({
        current_password: values.current_password,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
      });
      resetForm();
    } catch (error) {
      logger.error(error);
    }
  };

  const getProfileData = async () => {
    try {
      const { data } = await customersProfileApi.getProfile(user?.id);
      setProfileData(data?.customer);
    } catch (error) {
      logger.error(error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <div className="lg:max-w-lg lg:mx-auto">
      <div className="p-6 space-y-6">
        <Formik
          initialValues={NEW_PASSWORD_INITIAL_VALUES}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, resetForm);
          }}
          validationSchema={NEW_PASSWORD_VALIDATION_SCHEMA}
        >
          {() => (
            <Form noValidate>
              <div className="pb-6 pr-6">
                <div className="text-lg font-semibold text-gray-600">
                  Hey, {profileData?.first_name}
                </div>
                <div className="text-gray-400">
                  It is simple to update your password.
                </div>
              </div>
              <div className="w-full space-y-5 bg-white">
                <Input
                  label="Current Password"
                  name="current_password"
                  type="password"
                  required
                />
                <Input
                  label="New Password"
                  name="password"
                  type="password"
                  required
                />
                <Input
                  label="Confirm New Password"
                  name="passwordConfirmation"
                  type="password"
                  required
                />
                <div className="flex justify-end space-x-4">
                  <Button
                    label="Cancel"
                    style="secondary"
                    onClick={() => history.goBack()}
                  />
                  <Button name="submit" label="Reset Password" type="submit" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewPassword;

// Move this file to common after revamp
import React from "react";
import classnames from "classnames";
import { useUserState } from "contexts/user";

import Header from "./Header";
import Callout from "./Callout";
import Body from "./Body";
import Footer from "./Footer";
import { isPayableLease } from "common/utils";

const noop = () => {};

const LeaseItem = ({ onLeaseClick = noop, lease }) => {
  const { user } = useUserState();

  let defaultCard = user?.default_payment_method?.details.card?.last4;

  const generateDisplayText = () => {
    if (isPayableLease(lease?.lease_status))
      return `Eligible for ${epoOptionType} Payoff Option.`;
    else if (lease?.lease_status === "cancelled") {
      return "This lease is cancelled.";
    } else if (lease?.lease_status === "paid_off") {
      return "This lease is already paid off.";
    }
  };

  const fetchColorClass = () => {
    if (isPayableLease(lease?.lease_status)) {
      return "bg-purple-1000";
    } else if (lease?.lease_status === "paid_off") {
      return "bg-green-500";
    } else if (lease?.lease_status === "cancelled") {
      return "bg-red-600";
    }
  };

  const epoDetails = lease?.epo_details;
  const epoOptionType =
    epoDetails?.type === "retail_price" ? epoDetails?.period : "Early";

  return (
    <div
      className="relative w-full border border-gray-200 rounded-md cursor-pointer"
      onClick={() => onLeaseClick()}
    >
      <Callout
        isEligible={isPayableLease(lease?.lease_status)}
        displayText={generateDisplayText()}
        colorClass={fetchColorClass()}
      />
      <div
        className={classnames(
          ["px-4 pb-12 "],
          { "pt-12": lease.message !== "" },
          { "pt-4": lease.message === "" }
        )}
      >
        <Header
          slug={lease.slug}
          defaultCard={defaultCard}
          paymentAmount={lease.payment_amount}
          upcomingInvoice={lease.upcoming_invoice}
          taxOnPaymentAmount={lease.tax_on_payment_amount}
        />
        <Body lineItems={lease.line_items} payCycle={lease.pay_cycle} />
      </div>
      {lease?.upcoming_invoice && (
        <Footer upcomingInvoice={lease.upcoming_invoice} />
      )}
    </div>
  );
};

export default LeaseItem;

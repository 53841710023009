import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageLoader } from "@bigbinary/neetoui";

import creditApplicationsApi from "apis/credit-applications";

import ApplicationsList from "./List";
import useDocumentTitle from "hooks/useDocumentTitle";

const Applications = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);

  useDocumentTitle(`Applications for customer ${id}`);

  const fetchApplications = async () => {
    try {
      const {
        data: { credit_applications },
      } = await creditApplicationsApi.creditApplications(id);
      setApplications(credit_applications);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full h-full mt-10">
          <PageLoader />
        </div>
      ) : (
        <ApplicationsList applications={applications} />
      )}
    </>
  );
};

export default Applications;

import React from "react";
import { Dropdown, Input, Textarea, Button } from "@bigbinary/neetoui";

import Card from "../../../common/Card";
import ActivityTimeline from "./ActivityTimeline";

const Synopsis = () => {
  return (
    <div className="m-5 space-y-3 product_overview_synposis">
      <Card title="publish">
        <div className="py-2 space-y-2">
          <div className="flex justify-between">
            <p>Status</p>
            <Dropdown buttonStyle="text" label="Published">
              <li>Published</li>
              <li>Unpublished</li>
              <li>Draft</li>
            </Dropdown>
          </div>
          <div className="flex justify-between">
            <p>Visiblity</p>
            <Dropdown buttonStyle="text" label="Public">
              <li>Public</li>
              <li>Hidden</li>
            </Dropdown>
          </div>
          <div className="flex justify-between">
            <p>Publish On</p>
            <p>Jan-27-2021</p>
          </div>
        </div>
      </Card>
      <Card title="Settings">
        <div className="py-2 space-y-2">
          <Input
            label="Slug"
            suffix={
              <Button style="text" icon="text-gray-400 ri-external-link-line" />
            }
          />
          <Input label="SEO Keypharse" />
          <Input label="SEO Title" />
          <Textarea label="Description" rows={2} />
        </div>
      </Card>
      <Card title="Product Documnets">
        <div className="py-2 space-y-2">
          <div className="flex justify-between">
            <p>loveseat_assembly_guideline-2021.pdf</p>
            <Button style="text" icon="text-gray-400 ri-external-link-line" />
          </div>
          <div className="flex justify-between">
            <p>loveseat_assembly_guideline-2021.pdf</p>
            <Button style="text" icon="text-gray-400 ri-external-link-line" />
          </div>
        </div>
      </Card>
      <ActivityTimeline />
    </div>
  );
};

export default Synopsis;

import React from "react";
import { Select, Button } from "@bigbinary/neetoui";

import { CANCELLATION_CSV_STATUS, RETURN_CSV_STATUS } from "../../constants";
import FileUpload from "components/Common/FileUpload";

const CsvImport = ({ pageTitle }) => {
  let options = [];
  if (pageTitle == "cancellations") {
    options = CANCELLATION_CSV_STATUS.map(item => {
      return { label: item, value: item };
    });
  } else {
    options = RETURN_CSV_STATUS.map(item => {
      return { label: item, value: item };
    });
  }

  return (
    <div className="my-8 space-y-4">
      <FileUpload label="Order CSV" fileType="CSV" />
      {pageTitle !== "orders" && (
        <Select
          label="Updated Status After Import"
          options={options}
          value={options[0]}
        />
      )}
      <Button
        className="underline"
        style="link"
        label="Click here to download CSV import template "
      />
    </div>
  );
};

export default CsvImport;

const customerReducer = (state, { type, payload }) => {
  switch (type) {
  case "SET_CUSTOMER": {
    return {
      ...state,
      customer: payload?.customer,
    };
  }
  case "SET_ADD_CARD": {
    return {
      ...state,
      isCardAdded: !state.isCardAdded,
    };
  }
  default: {
    throw new Error(`Unhandled action type: ${type}`);
  }
  }
};

export default customerReducer;

import leasesApi from "apis/leases";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { PageLoader } from "@bigbinary/neetoui";

import LeaseDetails from "./LeaseDetails";
import LeaseList from "./LeaseList";

const Leases = ({ onLeaseClickFunction, leaseId }) => {
  const { id } = useParams();
  const [leaselist, setLeaseList] = useState([]);

  const [loader, setLoader] = useState(true);
  const [leaseStatusChange, setLeaseStatusChange] = useState(true);

  const fetchLeases = async () => {
    try {
      setLoader(true);
      const {
        data: { leases },
      } = await leasesApi.leases(id);
      setLeaseList(leases);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoader(false);
    }
  };

  const updateSelectedLeaseStatus = (leaseId, status) => {
    setLeaseList(
      leaselist.map(lease => {
        if (lease.id === leaseId) {
          lease.lease_status = status;
        }
        return lease;
      })
    );
  };

  useEffect(() => {
    fetchLeases();
  }, [leaseStatusChange]);

  const onLeaseItemClick = (leaseSlug, leaseId) => {
    onLeaseClickFunction(leaseSlug, leaseId);
  };

  const onLeaseStatusUpdate = () => {
    setLeaseStatusChange(state => !state);
  };

  const setLeaseSlugFunction = value => {
    onLeaseClickFunction(value, leaseId);
  };

  return (
    <div className="flex becca-main-container">
      {loader ? (
        <div className="flex items-center justify-center w-full h-full mt-10">
          <PageLoader />
        </div>
      ) : (
        <>
          {leaseId ? (
            <LeaseDetails
              leaseId={leaseId}
              setLeaseSlugFunction={setLeaseSlugFunction}
              onLeaseStatusUpdate={onLeaseStatusUpdate}
            />
          ) : (
            <LeaseList
              leaselist={leaselist}
              onLeaseItemClick={onLeaseItemClick}
              updateSelectedLeaseStatus={updateSelectedLeaseStatus}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Leases;

import axios from "axios";

const all = customer_id =>
  axios.get(`api/v1/customers/${customer_id}/payment_methods`);

const create = (customer_id, payload) =>
  axios.post(`api/v1/customers/${customer_id}/payment_methods`, payload);

const updateDefaultCard = (customer_id, payment_id) =>
  axios.put(`/api/v1/customers/${customer_id}/payment_methods/${payment_id}`, {
    card: {
      is_default: true,
    },
  });

const paymentMethodsApi = {
  create,
  all,
  updateDefaultCard,
};

export default paymentMethodsApi;

import React from "react";
import { Label, Pane, Button, Textarea, Avatar } from "neetoui";

export const AddNotePane = ({ notePane, setNotePane, data }) => (
  <Pane
    title="Add Notes"
    isOpen={notePane}
    onClose={() => setNotePane(false)}
    cancelButtonProps={{
      label: "Cancel",
      onClick: () => setNotePane(false),
    }}
    submitButtonProps={{
      label: "Add Note",
      onClick: () => setNotePane(false),
    }}
    showFooter
  >
    <div className="pb-20 mx-6 overflow-auto ">
      <Textarea label="Notes" rows="5">
        The spread of computers and layout programmes thus made dummy text
        better known. While in earlier times several lines of the Lorem Ipsum
        text were repeated in the creation of dummy texts, today the full text.
      </Textarea>
      <div className="mt-6">
        <Label className="mb-4">Previous Notes</Label>
        {data.map(item => (
          <div className="grid h-24 grid-cols-12" key={item.id}>
            <div className="flex flex-col items-center col-span-1">
              <div className="h-full border border-gray-200" />
              <Avatar size={36} contact={{ name: item.name }} />
              <div className="h-full border border-gray-200" />
            </div>
            <div className="col-span-11 ml-4">
              <p className="font-normal text-gray-600">{item.desc}</p>
              <p className="text-gray-600">{`${item.name} on ${item.date} at ${item.time}`}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <Button label="See all previous notes" style="link" />
      </div>
    </div>
  </Pane>
);

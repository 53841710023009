import React from "react";
import { Label } from "@bigbinary/neetoui";

const Retail = () => (
  <div className="w-1/3 p-4 space-y-4 border border-gray-200">
    <Label className="font-semibold text-gray-400 uppercase">Retail</Label>
    <div className="flex justify-between">
      <p>Wholesale ($): </p>
      <p>220.00</p>
    </div>
    <div className="flex justify-between">
      <p>Multiple: </p>
      <p>2</p>
    </div>
    <div className="flex justify-between">
      <p>Retail Price ($): </p>
      <p>220.00</p>
    </div>
  </div>
);

export default Retail;

import React, { useEffect, useState } from "react";
import { Select } from "neetoui";
import leasesApi from "apis/leases";
import { isPayableLease } from "common/utils";

const LeaseSelection = ({ id, onLeaseChange, initialLease }) => {
  const [leaseState, setLeaseState] = useState();
  const [leaseList, setLeaseList] = useState([]);

  useEffect(() => {
    setLeaseState(initialLease);
  }, [initialLease]);

  useEffect(() => {
    onLeaseChange(leaseState);
  }, [leaseState]);

  const onLeaseStateChange = value => {
    setLeaseState(value);
  };

  const fetchLeases = async () => {
    try {
      const {
        data: { leases },
      } = await leasesApi.leases(id);

      const filteredLeases = leases.filter(lease =>
        isPayableLease(lease?.lease_status)
      );

      const labeledOptions = filteredLeases?.map(lease => ({
        label: lease.slug,
        value: lease.id,
        ...lease,
      }));

      setLeaseList(labeledOptions);
      setLeaseState({
        label: filteredLeases?.[0]?.slug,
        value: filteredLeases?.[0]?.id,
        ...filteredLeases[0],
      });
    } catch (error) {
      logger.error(error);
    }
  };

  useEffect(() => {
    fetchLeases();
  }, []);

  return (
    <>
      <Select
        label="Pay to lease"
        value={leaseState}
        options={leaseList}
        onChange={lease => {
          onLeaseStateChange(lease);
        }}
      />
    </>
  );
};

export default LeaseSelection;

import React, { useState, useEffect } from "react";
import { formatCurrency } from "utils/styleUtils";

const Progress = ({ lease }) => {
  const [leaseStatus, setLeaseStatus] = useState({
    completed: false,
    progress: 0,
    remainingMonths: 17,
  });

  const paidAmountRatio = lease?.sum_of_payments / lease?.total_cost;
  const progressPercentage = paidAmountRatio * 100;
  const monthProgress = 17 - paidAmountRatio * 17;
  const remainingMonths =
    monthProgress > 1 ? Math.floor(monthProgress) : Math.ceil(monthProgress);

  useEffect(() => {
    if (lease?.lease_status === "paid_off") {
      setLeaseStatus({ completed: true, progress: 100, remainingMonths: 0 });
    } else {
      setLeaseStatus({
        completed: false,
        progress: progressPercentage,
        remainingMonths: remainingMonths,
      });
    }
  }, [lease]);

  if (leaseStatus.completed) {
    return (
      <div>
        <div className="flex justify-center mt-8">
          <p className="w-8/12 text-lg font-bold text-center text-gray-600">
            Yay! You have
            <span className="px-1 text-purple-1000">completed</span> all the
            lease payments.
          </p>
        </div>
        <div className="h-2 mx-6 my-4 rounded-md bg-purple-1000"></div>
      </div>
    );
  }
  return (
    <div className="px-6 space-y-4">
      <div className="flex justify-center">
        <p className="w-8/12 space-x-2 text-lg font-bold text-center text-gray-600">
          Only
          <span className="px-1 text-purple-1000">
            {`${leaseStatus.remainingMonths} months`}
          </span>
          until
          <br />
          you own the items!
        </p>
      </div>

      <div className="space-y-2">
        <div className="flex justify-between font-semibold">
          <p className="text-gray-600">Remaining Lease Balance</p>
          <p className="text-black">
            {formatCurrency(lease?.outstanding_amount)}
          </p>
        </div>
      </div>
      <div className="h-2 bg-gray-200 rounded-md">
        <div
          className="h-full rounded-md bg-purple-1000"
          style={{ width: `${leaseStatus.progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default Progress;

import React, { useEffect, useState } from "react";
import { components as SelectComponent } from "react-select";
import { Select } from "neetoui";
import CardIcon from "common/images/card_logo.svg";

const SingleValue = ({ children, ...props }) => {
  return (
    <SelectComponent.SingleValue {...props}>
      <div className="flex items-center justify-center space-x-2">
        <div>
          <img src={CardIcon} width="20px" height="20px" />
        </div>
        <div>{children}</div>
      </div>
    </SelectComponent.SingleValue>
  );
};

const DropDown = (month, year) => {
  const exp_month = month;
  const exp_year = year?.toString()?.slice(2);

  return (
    <p className="pr-2 text-gray-400">
      {exp_month}/{exp_year}
    </p>
  );
};

function PaymentSelect({
  initialPaymenteMethod,
  onPaymentMethodChange,
  onNewCardChange,
  paymentMethods,
  buttonStatus,
}) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [optionsState, setOptionsState] = useState(paymentMethods);

  useEffect(() => {
    let list = paymentMethods;
    list = list?.concat([{ label: "+ Add new payments method", value: "add" }]);
    setOptionsState(list);
  }, [paymentMethods]);

  useEffect(() => {
    let list = optionsState;
    if (buttonStatus?.applePay == true) {
      list.pop();
      list = list?.concat([{ label: "Pay using Apple Pay", value: "apay" }]);
      list = list?.concat([
        { label: "+ Add new payments method", value: "add" },
      ]);
      setOptionsState(list);
    }
    if (buttonStatus?.googlePay == true) {
      list.pop();
      list = list?.concat([{ label: "Pay using Google Pay", value: "gpay" }]);
      list = list?.concat([
        { label: "+ Add new payments method", value: "add" },
      ]);
      setOptionsState(list);
    }
  }, [buttonStatus]);

  const onChange = e => {
    if (e.value == "add") {
      onNewCardChange();
    } else {
      setSelectedPaymentMethod(e);
      onPaymentMethodChange(e);
    }
  };

  const Option = props => {
    const exp_month = props?.data?.details?.card?.exp_month;
    const exp_year = props?.data?.details?.card?.exp_year.toString()?.slice(2);
    const { innerProps } = props;
    const { onClick } = innerProps;
    return (
      <div
        {...props}
        className="flex justify-between p-2 cursor-pointer hover:text-white hover:bg-purple-1000"
        onClick={() => onClick()}
      >
        <p>{props.label}</p>
        {exp_month && exp_year && (
          <p className="pr-2 text-gray-400">
            {exp_month}/{exp_year}
          </p>
        )}
      </div>
    );
  };

  useEffect(() => {
    setSelectedPaymentMethod(initialPaymenteMethod);
  }, []);

  useEffect(() => {
    if (!paymentMethods) return;

    let labeledPaymentMethodList = paymentMethods?.map((item, index) => {
      return {
        label: "Debit Card ending " + item?.details?.card?.last4,
        value: item?.details?.card?.last4,
        index: index,
        ...item,
      };
    });
    labeledPaymentMethodList = labeledPaymentMethodList?.concat([
      { label: "+ Add new payment method", value: "add" },
    ]);

    setOptionsState(labeledPaymentMethodList);
    setSelectedPaymentMethod(labeledPaymentMethodList[0]);
  }, [paymentMethods]);

  return (
    <Select
      label="Payment Method"
      onChange={value => onChange(value)}
      value={selectedPaymentMethod}
      options={optionsState}
      components={{
        SingleValue: SingleValue,
        IndicatorSeparator: () => null,
        Option,
        DropdownIndicator: () =>
          DropDown(
            initialPaymenteMethod?.details?.card?.exp_month,
            initialPaymenteMethod?.details?.card?.exp_year
          ),
      }}
    />
  );
}

export default PaymentSelect;

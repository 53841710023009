import React, { useState } from "react";
import classNames from "classnames";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import { Badge, Button, Collapse } from "neetoui";

import {
  DEFAULT_PAGE_SIZE,
  SORT_BY_OPTIONS,
  TOTAL_TICKET_COUNT,
} from "./constants";
import { CREDIT_STATUSES, FRAUD_STATUSES } from "common/constants";
import { formatCurrency } from "utils/styleUtils";
import { creditStatusBadge } from "common/utils";
import Tabs from "./Tabs";

const ApplicationsTableHead = () => (
  <div className="grid gap-4 px-1 application-table">
    <div className="text-center">Id</div>
    <div className="text-center">Credit status</div>
    <div className="text-center">Fraud status</div>
    <div className="text-center">Credit limit</div>
    <div className="text-center">Applied On</div>
    <div className="text-center">Expires On</div>
  </div>
);

const ApplicationRow = ({ application }) => {
  const [isCollapseOpen, setCollapse] = useState(false);
  return (
    <>
      <div
        className={classNames(
          [
            "grid gap-2 px-1 py-3 application-table border-b border-gray-100 cursor-pointer",
          ],
          {
            "bg-gray-100": isCollapseOpen,
          }
        )}
        onClick={() => setCollapse(state => !state)}
      >
        <div className="text-center">
          <Button
            label={application.slug}
            style="link"
            className="underline break-normal"
          />
        </div>
        <div className="text-center">
          <div className="flex justify-center">
            <Badge
              color={creditStatusBadge(
                CREDIT_STATUSES[application.credit_status]
              )}
            >
              {CREDIT_STATUSES[application.credit_status]}
            </Badge>
          </div>
        </div>
        <div className="text-center">
          <div className="flex justify-center">
            <Badge
              color={creditStatusBadge(
                FRAUD_STATUSES[application.fraud_status]
              )}
            >
              {FRAUD_STATUSES[application.fraud_status]}
            </Badge>
          </div>
        </div>
        <div className="text-center">
          {formatCurrency(application.credit_limit)}
        </div>
        <div className="text-center">
          {application.last_experian_request_at}
        </div>
        <div className="text-center">
          <p className="inline-block">{application.expires_at}</p>

          <p className={classNames(["pl-1", "inline-block"])}>
            {isCollapseOpen ? (
              <i className="ri-arrow-up-s-line"></i>
            ) : (
              <i className="ri-arrow-down-s-line"></i>
            )}
          </p>
        </div>
      </div>

      <Collapse open={isCollapseOpen}>
        <Tabs application={application} />
      </Collapse>
    </>
  );
};

const ApplicationsTableBody = ({ applications }) => (
  <div>
    {applications.map((application, idx) => (
      <ApplicationRow key={idx} application={application} />
    ))}
  </div>
);

const ApplicationsList = ({ applications }) => {
  const [searchString, setSearchString] = useState("");
  const [pageIndex, setPageIndex] = useState(5);
  const sortFunction = {};

  return (
    <div className="flex becca-main-container">
      <div className="becca-details">
        <div>
          <SubHeader
            searchProps={{
              value: searchString,
              onChange: e => setSearchString(e.target.value),
              clear: () => setSearchString(" "),
            }}
            sortProps={{
              options: SORT_BY_OPTIONS,
              onClick: sortFunction,
            }}
            toggleFilter={() => {}}
            paginationProps={{
              count: TOTAL_TICKET_COUNT,
              pageNo: pageIndex,
              pageSize: DEFAULT_PAGE_SIZE,
              navigate: index => setPageIndex(index),
            }}
          />
        </div>
        <div className="px-8 my-4 ">
          <div className="flex flex-col justify-center">
            <ApplicationsTableHead />
            <ApplicationsTableBody applications={applications} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationsList;

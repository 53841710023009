import React from "react";
import { Button } from "@bigbinary/neetoui";

import { COLLECTION_TABLE_DATA } from "./constants";

const CollectionsTableRow = ({ data }) => (
  <tr className="text-center">
    <td>
      <div className="flex">
        <Button style="link" label={data.customer_name} />
      </div>
    </td>
    <td className="text-center ">{data.contact_no}</td>
    <td className="text-center ">{data.overdue_amount}</td>
    <td className="text-center ">{data.days_late}</td>
  </tr>
);

const CollectionsTableHead = () => (
  <thead>
    <tr className="text-left uppercase">
      <th>Customer name</th>
      <th className="text-center ">Contact no</th>
      <th className="text-center ">overdue amount</th>
      <th className="text-center ">days late</th>
    </tr>
  </thead>
);

const CollectionsTableBody = () => (
  <tbody>
    {COLLECTION_TABLE_DATA.map((data, idx) => {
      return <CollectionsTableRow data={data} key={idx} />;
    })}
  </tbody>
);

const CollectionsTable = () => {
  return (
    <div className="flex justify-center">
      <table className="w-11/12 nui-table">
        <CollectionsTableHead />
        <CollectionsTableBody />
      </table>
    </div>
  );
};

export default CollectionsTable;

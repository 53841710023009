import React, { useState, useEffect } from "react";

import { Badge } from "@bigbinary/neetoui";

import invoicesApi from "apis/leases/invoices";
import { formatCurrency } from "utils/styleUtils";
import { formatDateWithoutTimeZone } from "common/dateTimeFormatter";
import { generateInvoiceStatusBadge } from "common/utils";

const LeaseInvoiceTableItems = ({ invoice }) => {
  const { label, color } = generateInvoiceStatusBadge(invoice.status);
  return (
    <tr className="text-left">
      <td className="text-center">{invoice.slug}</td>
      <td className="text-center">
        {formatDateWithoutTimeZone(invoice.due_date)}
      </td>
      <td className="text-center">
        {formatCurrency(invoice.invoice_amount_with_tax)}
      </td>
      <td className="text-center">
        {formatCurrency(invoice.balance_amount_with_tax)}
      </td>
      <td className="text-center">
        <div>
          <Badge color={color}>{label}</Badge>
        </div>
      </td>
    </tr>
  );
};

const InvoiceTab = ({ leaseSlug }) => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState(null);

  const fetchInvoices = async () => {
    try {
      setLoading(true);
      const { data } = await invoicesApi.invoices(leaseSlug);
      setInvoices(data.invoices);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    !invoices && fetchInvoices();
  }, []);

  return (
    !loading && (
      <div className="px-4">
        {invoices?.length == 0 ? (
          <div className="flex justify-center w-full">
            <div className="m-4">No Invoices</div>
          </div>
        ) : (
          <table className="nui-table">
            <thead>
              <tr className="text-left">
                <th className="text-center uppercase ">Invoice</th>
                <th className="text-center uppercase ">Due Date</th>
                <th className="text-center uppercase">Invoice Amount</th>
                <th className="text-center uppercase">Balance Amount</th>
                <th className="text-center uppercase">Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invoices?.map(invoice => {
                return (
                  <LeaseInvoiceTableItems key={invoice.id} invoice={invoice} />
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    )
  );
};

export default InvoiceTab;

import React, { useState } from "react";
import { SubHeader, Header } from "@bigbinary/neetoui/layouts";
import { Button, Tab } from "@bigbinary/neetoui";

import {
  COLLECTION_TABS,
  DEFAULT_PAGE_SIZE,
  LOAD_SORT_PROPS,
  SORT_BY_OPTIONS,
  TOTAL_ROW_COUNT,
} from "./constants";
import CollectionsTable from "./CollectionsTable";
import ChargeOffs from "./ChargeOffTable";

const renderTabContents = currentTab => {
  switch (currentTab) {
  case "Collections":
    return <CollectionsTable />;
  case "Charge Offs":
    return <ChargeOffs />;
  }
};

const Collections = () => {
  const [searchString, setSearchString] = useState("");
  const [pageIndex, setPageIndex] = useState(5);
  const [currentTab, setCurrentTab] = useState(COLLECTION_TABS[0]);

  return (
    <>
      <Header
        title="Collections"
        actionBlock={<Button style="secondary" label="Export list" />}
      />
      <div className="w-full">
        <SubHeader
          searchProps={{
            value: searchString,
            onChange: e => setSearchString(e.target.value),
            clear: () => setSearchString(""),
          }}
          sortProps={{
            options: SORT_BY_OPTIONS,
            onClick: LOAD_SORT_PROPS,
          }}
          paginationProps={{
            count: TOTAL_ROW_COUNT,
            pageNo: pageIndex,
            pageSize: DEFAULT_PAGE_SIZE,
            navigate: index => setPageIndex(index),
          }}
        />
      </div>
      <Tab className="w-full px-4 border-b border-gray-200">
        {COLLECTION_TABS.map((tab, index) => {
          return (
            <Tab.Item
              key={index}
              active={currentTab === tab}
              onClick={() => setCurrentTab(tab)}
            >
              {tab}
            </Tab.Item>
          );
        })}
      </Tab>
      <div className="w-full px-4">{renderTabContents(currentTab)}</div>
    </>
  );
};

export default Collections;

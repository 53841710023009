import React from "react";
import { Label } from "@bigbinary/neetoui";

const Shipping = () => (
  <div className="w-1/3 p-4 space-y-4 border border-gray-200">
    <Label className="font-semibold text-gray-400 uppercase">Shipping</Label>
    <div className="flex justify-between">
      <p>Shipping Method</p>
      <p>Fedex</p>
    </div>
    <div className="flex justify-between">
      <p>Shipping Class</p>
      <p>Oversize</p>
    </div>
  </div>
);

export default Shipping;

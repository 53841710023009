export const DEFAULT_PAGE_SIZE = 20;
export const COLUMN_OPTIONS = [{ label: "Name" }, { label: "Client" }];
export const FILTER_BY_OPTIONS = [{ label: "Name" }, { label: "Client" }];
export const SORT_BY_OPTIONS = [{ label: "Name" }, { label: "Date" }];
export const LOAD_SORT_PROPS = {};

export const CANCELLATIONS_LIST = [
  {
    lease_id: "BH11232",
    customer: "Oliver Smith",
    product_sku: "AH12390",
    vendor: "Ashley’s Furniture",
    order_date: "03/24/21 8:30PM ET",
    item_status: "Ordered",
    date_request: "03/24/21 8:30PM ET",
    date_confirm: "03/24/21 8:30PM ET",
  },
  {
    lease_id: "BH11232",
    customer: "Oliver Smith",
    product_sku: "AH12390",
    vendor: "Ashley’s Furniture",
    order_date: "03/24/21 8:30PM ET",
    item_status: "Ordered",
    date_request: "03/24/21 8:30PM ET",
    date_confirm: "03/24/21 8:30PM ET",
  },
];

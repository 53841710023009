import * as yup from "yup";

const PHONE_NUMER_REG_EX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const PROFILE_DATA_VALIDATION_SCHEMA = yup.object({
  email: yup.string().email("Incorrect Email").required("Email is required"),
  street_number: yup.string().required("Street Address is required"),
  area: yup.string(),
  city: yup.string().required("City is required"),
  zip_code: yup
    .string()
    .test("len", "Please enter valid US zip code.", val => val.length === 5)
    .required("Zip code is required"),
  state: yup.string().required("State is required"),
  phone_number: yup
    .string()
    .matches(PHONE_NUMER_REG_EX, "Mobile number is not valid")
    .required("Phone number is required"),
});

export const NEW_PASSWORD_INITIAL_VALUES = {
  current_password: "",
  password: "",
  passwordConfirmation: "",
};
export const NEW_PASSWORD_VALIDATION_SCHEMA = yup.object({
  current_password: yup
    .string()
    .required("Please enter your current password."),
  password: yup
    .string()
    .min(6, "Too Short!")
    .required("Please enter your new password."),
  passwordConfirmation: yup
    .string()
    .test("passwords-match", "Passwords must match.", function (value) {
      return this.parent.password === value;
    })
    .required("Please confirm your new password."),
});

import React from "react";
import { Label } from "@bigbinary/neetoui";

const Wholesale = () => (
  <div className="w-1/3 p-4 space-y-4 border border-gray-200">
    <Label className="font-semibold text-gray-400 uppercase">Wholesale</Label>
    <div className="flex justify-between">
      <p>COGS ($): </p>
      <p>200.00</p>
    </div>
    <div className="flex justify-between">
      <p>Shipping ($): </p>
      <p>20.00</p>
    </div>
    <div className="flex justify-between">
      <p>Handling Fees ($): </p>
      <p>20.00</p>
    </div>
    <div className="flex justify-between">
      <p>Wholesale Price ($): </p>
      <p>220.00</p>
    </div>
  </div>
);

export default Wholesale;

import React, { useState, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import classnames from "classnames";
import { Button } from "@bigbinary/neetoui";
import classNames from "classnames";

const ImageUpload = ({ className = "" }) => {
  const imageRef = useRef(null);
  const [{ src, flag }, setImg] = useState({
    src: "",
    alt: "Upload an Image",
    flag: false,
  });

  const handleImg = e => {
    if (e.target.files[0]) {
      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        alt: e.target.files[0].name,
        flag: true,
      });
    }
  };

  const handleClick = () => {
    imageRef.current.click();
  };

  const onDrop = useCallback(files => {
    setImg({
      src: URL.createObjectURL(files[0]),
      alt: files[0].name,
      flag: true,
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      className={classNames({
        "py-6 transition duration-500 ease-in-out border border-dashed rounded-md cursor-pointer hover:bg-gray-50":
          !className,
      })}
    >
      {!src && (
        <div {...getRootProps()}>
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            id="photo"
            className="hidden"
            onChange={handleImg}
            ref={imageRef}
            {...getInputProps()}
          />
          <label
            htmlFor="photo"
            className={classnames(["flex justify-center mt-4"], {
              hidden: flag,
            })}
          >
            <div className="text-6xl text-gray-400 ri-image-add-line" />
          </label>
          {isDragActive ? (
            <p className={classnames(["flex justify-center"])}>
              Drop the file here
            </p>
          ) : (
            <div
              className={classnames(["flex justify-center"], {
                hidden: flag,
              })}
            >
              <p className="font-normal text-gray-600"> Drag and Drop or</p>
              <Button
                className="mx-1"
                style="link"
                label="browse"
                onClick={handleClick}
              />
              <p className="font-normal text-gray-600">.jpg, .png files</p>
            </div>
          )}
        </div>
      )}
      {src && (
        <div className="relative flex justify-center m-5 overflow-hidden ">
          <img src={src} className="block w-auto h-40 max-w-xs" />
          <div
            className="absolute top-0 right-0 flex items-center justify-center w-4 h-4 text-white bg-red-500 rounded-full cursor-pointer"
            onClick={() => {
              setImg({ src: "", alt: "Upload an Image", flag: false });
            }}
          >
            <i className="ri-subtract-line" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;

import React, { useState } from "react";
import { Dropdown, Tooltip } from "@bigbinary/neetoui";
import { Link, NavLink } from "react-router-dom";
import ContactUs from "components/MyAccount/common/ContactUs";
import BeccasHomeLogo from "common/images/beccas-home-logo.png";

const NavBar = ({ logout }) => {
  const [contactUs, setContactus] = useState(false);
  return (
    <div className="flex justify-between w-full px-4 py-2 border-b border-gray-200">
      <div className="flex items-center justify-between space-x-4">
        <img src={BeccasHomeLogo} width="50px" height="50px" />
        <Link
          to="/myaccount"
          className="font-semibold no-underline border-b border-purple-1000 hover:text-current"
        >
          My Leases
        </Link>
      </div>
      <div className="flex items-center justify-between space-x-4">
        <Tooltip content="Help" position="bottom">
          <i
            className="text-xl cursor-pointer ri-question-line"
            onClick={() => setContactus(true)}
          />
        </Tooltip>
        <Tooltip content="Profile" position="bottom">
          <Dropdown
            position="right-top"
            interactionKind="click"
            customTarget={() => (
              <i className="text-xl cursor-pointer ri-user-line" />
            )}
            closeOnSelect
            childClassName="p-3"
          >
            <NavLink
              to="/myaccount/myprofile"
              className="w-full p-2"
              activeClassName="active"
            >
              <p className="font-normal text-gray-600">My Details</p>
            </NavLink>
            <NavLink
              to="/myaccount/changepassword"
              className="w-full"
              activeClassName="active"
            >
              <p className="font-normal text-gray-600">Reset Password</p>
            </NavLink>
          </Dropdown>
        </Tooltip>
        <Tooltip content="Logout" position="bottom">
          <i
            className="text-xl cursor-pointer ri-logout-box-r-line"
            onClick={logout}
          />
        </Tooltip>
      </div>
      <ContactUs contactModal={contactUs} setcontactModal={setContactus} />
    </div>
  );
};

export default NavBar;

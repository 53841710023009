import axios from "axios";

const update = (customer_id, lease_id, payload) =>
  axios.put(
    `api/v1/customers/${customer_id}/payment_amounts/${lease_id}`,
    payload
  );

const details = (customer_id, lease_id, payload) =>
  axios.post(
    `api/v1/customers/${customer_id}/payment_amounts/${lease_id}/details`,
    payload
  );

const paymentAmountsApi = { update, details };

export default paymentAmountsApi;

import React from "react";

import Spinner from "components/SignUp/Registration/Spinner";

const REDIRECT_MAIN_MESSAGE =
  "We are adding the approval amount to your shopping cart.";
const REDIRECTING_MESSAGE = "Please wait, you are being redirected...";

const BeingRedirected = ({
  mainMessage = REDIRECT_MAIN_MESSAGE,
  redirectingMessage = REDIRECTING_MESSAGE,
}) => {
  return (
    <div className="flex flex-col items-center justify-center becca-homepage">
      <Spinner
        loadingHeading={mainMessage}
        loadingSubheading={redirectingMessage}
      />
    </div>
  );
};

export default BeingRedirected;

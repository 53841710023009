import React, { useState } from "react";
import { Collapse } from "neetoui";

const PaymentAccordion = ({ titleComponent, children }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  return (
    <div>
      <div
        onClick={e => {
          e.stopPropagation();
          setIsAccordionOpen(state => !state);
        }}
      >
        {titleComponent}
      </div>
      <Collapse open={isAccordionOpen}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default PaymentAccordion;

import React, { Component } from "react";
import { Button } from "neetoui";

class EditorToolbar extends Component {
  state = {
    id: this.props.id || "editorToolbar",
  };

  render = () => (
    <trix-toolbar id={this.state.id} style={this.props.toolbarStyles}>
      <div className="trix-button-row">
        <span
          className="trix-button-group trix-button-group--text-tools"
          data-trix-button-group="text-tools"
        >
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-bold"
            data-trix-attribute="bold"
            data-trix-key="b"
            title="Bold"
            tabIndex="-1"
          />
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-italic"
            data-trix-attribute="italic"
            data-trix-key="i"
            title="Italic"
            tabIndex="-1"
          >
            Italic
          </Button>
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-underline"
            data-trix-attribute="underline"
            data-trix-key="u"
            title="Underline"
            tabIndex="-1"
          />
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-strike"
            data-trix-attribute="strike"
            title="Strikethrough"
            tabIndex="-1"
          />
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-link"
            data-trix-attribute="href"
            data-trix-action="link"
            data-trix-key="k"
            title="Link"
            tabIndex="-1"
          />
        </span>
        <span
          className="trix-button-group trix-button-group--block-tools"
          data-trix-button-group="block-tools"
        >
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-heading-1"
            data-trix-attribute="x-heading"
            title="Heading"
            tabIndex="-1"
          />
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-quote"
            data-trix-attribute="quote"
            title="Blockquote"
            tabIndex="-1"
          />
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-code"
            data-trix-attribute="code"
            title="Codeblock"
            tabIndex="-1"
          />
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-bullet-list"
            data-trix-attribute="bullet"
            title="Bullets"
            tabIndex="-1"
          />
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-number-list"
            data-trix-attribute="number"
            title="Numbers"
            tabIndex="-1"
          />
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-decrease-nesting-level"
            data-trix-action="decreaseNestingLevel"
            title="Outdent"
            tabIndex="-1"
          />
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-increase-nesting-level"
            data-trix-action="increaseNestingLevel"
            title="Indent"
            tabIndex="-1"
          />
          <Button
            style="icon"
            className="trix-button trix-button--icon trix-button--icon-note"
            data-trix-attribute="section"
            title="Note"
            tabIndex="-1"
          />
        </span>
        <span
          className="ml-0 trix-button-group trix-button-group--upload-tools"
          data-trix-button-group="upload-tools"
        ></span>
        <span className="trix-button-group-spacer" />
        <></>
      </div>

      <div className="trix-dialogs" data-trix-dialogs>
        <div
          className="trix-dialog trix-dialog--link"
          data-trix-dialog="href"
          data-trix-dialog-attribute="href"
        >
          <div className="flex w-full px-0 mx-auto trix-dialog__link-fields">
            <div className="flex flex-grow mr-2">
              <input
                type="text"
                name="href"
                className="trix-input trix-input--dialog"
                placeholder="Enter URL"
                aria-label="${lang.url}"
                required
                data-trix-input
              />
            </div>
            <div className="flex">
              <Button
                type="button"
                className="mr-2 btn btn-outline--blue"
                label="Link"
                data-trix-method="setAttribute"
              />
              <Button
                type="button"
                style="secondary"
                className="btn btn-outline--gray"
                label="Unlink"
                data-trix-method="removeAttribute"
              />
            </div>
          </div>
        </div>

        <div
          className="trix-dialog trix-dialog--heading"
          data-trix-dialog="x-heading"
          data-trix-dialog-attribute="x-heading"
        >
          <div className="trix-dialog__link-fields">
            <div className="trix-button-group">
              <Button
                style="button"
                label="H1"
                className="trix-button trix-button--dialog"
                data-trix-attribute="heading1"
              />
              <Button
                style="button"
                label="H2"
                className="trix-button trix-button--dialog"
                data-trix-attribute="heading2"
              />
              <Button
                style="button"
                label="H3"
                className="trix-button trix-button--dialog"
                data-trix-attribute="heading3"
              />
              <Button
                style="button"
                label="H4"
                className="trix-button trix-button--dialog"
                data-trix-attribute="heading4"
              />
              <Button
                style="button"
                label="H5"
                className="trix-button trix-button--dialog"
                data-trix-attribute="heading5"
              />
              <Button
                style="button"
                label="H6"
                className="trix-button trix-button--dialog"
                data-trix-attribute="heading6"
              />
            </div>
          </div>
        </div>
      </div>
    </trix-toolbar>
  );
}

export default EditorToolbar;

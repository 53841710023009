import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Select, Input, DateInput } from "@bigbinary/neetoui";
import { DEBIT_CARD_LIST } from "components/MyAccount/constants";
import PaymentConfirmModal from "components/MyAccount/common/PaymentConfirmModal";

import HeaderIcon from "../Header";
import AmountSplitUp from "./AmountSplitUp";

const CardPayment = () => {
  const [amountSplitUp, setAmountSplitUp] = useState(false);

  const toggleAmountSplitUp = () => {
    setAmountSplitUp(state => !state);
  };

  const [paymentConfirm, setPaymentConfirm] = useState(false);
  const history = useHistory();

  const handleSubmit = () => {
    history.push("/payment/:customerId/payment/confirm?success=true");
  };
  const onCancel = () => {
    history.goBack();
  };
  return (
    <div className="p-4">
      <HeaderIcon />
      <div className="space-y-4">
        <p className="text-lg font-semibold text-gray-800">
          How do you wish to pay?
        </p>
        <Select
          label="Payment Method"
          options={DEBIT_CARD_LIST}
          value={DEBIT_CARD_LIST[0]}
        />
        <DateInput label="Payment Date" value={new Date()} />
        <Input label="Amount" value="$200" />
        {!amountSplitUp ? (
          <div className="flex justify-end my-2">
            <Button
              style="link"
              label="View amount splitup"
              onClick={() => toggleAmountSplitUp()}
            />
          </div>
        ) : (
          <AmountSplitUp
            toggleAmountSplitUp={toggleAmountSplitUp}
            amountSplitUp={amountSplitUp}
          />
        )}
        <div className="flex justify-end space-x-4">
          <Button label="Cancel" style="secondary" onClick={() => onCancel()} />
          <Button label="Next" onClick={() => setPaymentConfirm(true)} />
        </div>
      </div>
      <PaymentConfirmModal
        paymentConfirm={paymentConfirm}
        setPaymentConfirm={setPaymentConfirm}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default CardPayment;

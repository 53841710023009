import React, { useState } from "react";

import { Label, Button, Badge, Tooltip } from "neetoui";

import { useCustomerState } from "contexts/customer";

import Card from "../../Card";
import { AttachmentPaneComponent } from "../../PaneComponents/AttachmentPane";
import { ContactPaneComponent } from "../../PaneComponents/ContactPane";
import { IncomePaneComponent } from "../../PaneComponents/IncomePane";
import { UpdatePayCyclePane } from "../../PaneComponents/UpdatePayCyclePane";

import CreditInfoCard from "../CreditInfoCard";
import { formatDateWithoutTimeZone } from "common/dateTimeFormatter";

const GeneralInfoCard = () => {
  const { customer } = useCustomerState();

  return (
    <Card title={"GENERAL INFORMATION"}>
      <div className="flex justify-between mt-4">
        <Label>Full Name </Label>
        <p className="font-normal text-gray-600">{customer.name}</p>
      </div>
      <div className="flex justify-between mt-2">
        <Label>DOB </Label>
        <p className="font-normal text-gray-600">
          {formatDateWithoutTimeZone(customer.date_of_birth)}
        </p>
      </div>
      <div className="flex justify-between mt-2">
        <Label>SSN </Label>
        <p className="font-normal text-gray-600">{customer.ssn}</p>
      </div>
      <div className="flex justify-between mt-2">
        <Label>Store Profile </Label>
        <p className="font-normal text-gray-600">
          {customer.woo_commerce_profile_url && (
            <Button
              className="text-sm"
              style="link"
              label="Visit Profile"
              type="link"
              icon="ri-external-link-line"
              href={customer.woo_commerce_profile_url}
            />
          )}
        </p>
      </div>
      <div className="flex justify-between mt-2">
        <Label>Registration Step </Label>
        <p className="font-normal text-gray-600">
          {customer.registration_step}
        </p>
      </div>
    </Card>
  );
};

const ContactInfoCard = ({ setContactPane }) => {
  const { customer } = useCustomerState();

  return (
    <Card
      title={"CONTACT INFORMATION"}
      titleAction={
        <Button
          style="icon"
          icon="ri-pencil-line text-base"
          onClick={() => setContactPane(true)}
        />
      }
    >
      <div className="flex justify-between mt-4">
        <Label>Email </Label>
        <p className="font-normal text-gray-600">{customer.email}</p>
      </div>

      <div className="flex justify-between mt-3">
        <Label>Phone Number </Label>
        <div>
          <p className="font-normal text-gray-600">{customer.phone_number}</p>
        </div>
      </div>
      {customer?.address && (
        <div className="flex justify-between mt-2">
          <Label>Address</Label>
          <p className="w-1/2">
            {customer.address.street_number}, {customer.address.area}{" "}
            {customer.address.city}, {customer.address.state},
            {customer.address.zip_code}
          </p>
        </div>
      )}
    </Card>
  );
};

const AttachmentInfoCard = ({ setAttachmentPane }) => (
  <Card
    title={"ATTACHMENTS"}
    titleAction={
      <Button
        style="icon"
        icon="ri-pencil-line text-base"
        onClick={() => setAttachmentPane(true)}
      />
    }
  >
    <div className="flex justify-between my-4">
      <Badge color="green">Payslip</Badge>
      <p className="font-normal text-gray-600">__03/27/2021_</p>
      <Tooltip content={"Paysliy_Aug_2020.pdf"}>
        <p className="w-16 font-normal text-gray-600 truncate">
          __Paysliy_Aug_2020.pdf_
        </p>
      </Tooltip>
      <Button style="icon" icon="ri-download-2-line" className="text-sm" />
    </div>
  </Card>
);

const CustomerSynopsis = () => {
  const [contactPane, setContactPane] = useState(false);
  const [incomePane, setIncomePane] = useState(false);
  const [attachmentPane, setAttachmentPane] = useState(false);
  const [isUpdatePayCyclePaneOpen, setIsUpdatePayCyclePaneOpen] =
    useState(false);
  const { customer } = useCustomerState();
  return (
    <div>
      <div className="flex flex-col p-4 space-y-3 bg-gray-100 becca-fixed">
        <GeneralInfoCard />
        <ContactInfoCard setContactPane={setContactPane} />
        <CreditInfoCard
          setIsUpdatePayCyclePaneOpen={setIsUpdatePayCyclePaneOpen}
          customer={customer}
        />
        <AttachmentInfoCard setAttachmentPane={setAttachmentPane} />
      </div>

      <ContactPaneComponent
        contactPane={contactPane}
        setContactPane={setContactPane}
      />

      <IncomePaneComponent
        incomePane={incomePane}
        setIncomePane={setIncomePane}
      />
      <UpdatePayCyclePane
        isUpdatePayCyclePaneOpen={isUpdatePayCyclePaneOpen}
        setIsUpdatePayCyclePaneOpen={setIsUpdatePayCyclePaneOpen}
      />
      <AttachmentPaneComponent
        setAttachmentPane={setAttachmentPane}
        attachmentPane={attachmentPane}
      />
    </div>
  );
};

export default CustomerSynopsis;

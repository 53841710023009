import React, { useEffect, useState } from "react";

import { QUOTES } from "../constant";

const LoadingPage = () => {
  const [qoutes, setQoutes] = useState({ index: 0, value: QUOTES[0] });

  useEffect(() => {
    var index = qoutes.index + 1 == QUOTES.length ? 0 : qoutes.index + 1;
    setTimeout(() => setQoutes({ index: index, value: QUOTES[index] }), 10000);
  });

  return (
    <div className="flex items-center justify-center w-full h-4/6">
      <div>
        <p>loading</p>
        <p className="w-full text-center">{qoutes.value}</p>
      </div>
    </div>
  );
};

export default LoadingPage;

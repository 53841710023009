import { Formik } from "formik";
import React from "react";
import * as dayjs from "dayjs";
import * as yup from "yup";
import { DateInput, Label, Toastr } from "neetoui";
import { Input as FormikInput } from "neetoui/formik";

import { useCustomerState } from "contexts/customer";
import paymentPromisesApi from "apis/payment-promises";

const PaymentPromise = ({ formikRef, setModificationPane }) => {
  const INITIAL_VALUES = { amount: 1, promise_date: "" };
  const validationSchema = yup.object().shape({
    amount: yup.string().required("Please enter the amount."),
    promise_date: yup
      .date("Invalid date, please select properly.")
      .nullable()
      .required("Please enter promise date."),
  });

  const {
    customer: { id },
  } = useCustomerState();

  const handleSubmit = async values => {
    try {
      await paymentPromisesApi.create(id, {
        payment_promise: { ...values },
      });

      Toastr.success("Added payment promise successfully!");
    } catch (error) {
      logger.error(error);
    } finally {
      setModificationPane(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values, errors }) => (
        <div className="space-y-4">
          <FormikInput label="Payment amount" name="amount" className="py-2" />

          <div>
            <Label required>Promise Date</Label>
            <DateInput
              placeholder="Promise Date (MM/DD/YY)"
              value={
                values?.promise_date ? new Date(values.promise_date) : null
              }
              name="promise_date"
              format="MM/DD/YYYY"
              error={errors?.promise_date}
              maxDate={dayjs().add(5, "weeks").toDate()}
              onChange={date => {
                date && setFieldValue("promise_date", date.toDateString());
              }}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};

export default PaymentPromise;

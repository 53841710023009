import React, { useState } from "react";
import { Select, Label, Textarea, Button } from "@bigbinary/neetoui";
import RichTextInput from "components/Admin/ProductImport/common/RichTextInput";

const Actions = () => {
  const [simpleAction] = useState(false);

  return (
    <div className="w-full p-4 mt-6 space-y-2 border border-gray-200 rounded-md">
      <p className="font-semibold">Actions</p>
      {simpleAction ? (
        <div>
          <div className="flex justify-between mb-2">
            <Label>Sms Body</Label>
            <Button style="text" label="{ }" />
          </div>
          <Textarea />
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between w-1/2">
            <div className="w-56">
              <Select label="Email Recepeints" />
            </div>
            <div className="w-56">
              <Select label="Template Name" />
            </div>
          </div>
          <div className="w-4/6 mt-4">
            <div className="flex justify-between mb-2">
              <Label>Sms Body</Label>
              <Button style="text" label="{ }" />
            </div>
            <RichTextInput label={"Email Body"} />
          </div>
        </>
      )}
    </div>
  );
};

export default Actions;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Label, Badge } from "@bigbinary/neetoui";
import paymentPromisesAPI from "apis/payment-promises";

const PaymentPromiseTableRow = ({ paymentPromise, color }) => (
  <tr className="text-center">
    <td className="text-center">{paymentPromise.amount}</td>
    <td className="text-center">
      <Badge color={color}>{paymentPromise.status}</Badge>
    </td>
    <td className="text-center">{paymentPromise.promise_date}</td>
    <td className="text-center">{paymentPromise.created_at}</td>
  </tr>
);

const PaymentPromiseTableHead = () => (
  <thead>
    <tr className="text-center">
      <th className="text-center">Amount</th>
      <th className="text-center">Status</th>
      <th className="text-center">Promise Date</th>
      <th className="text-center">Promised On</th>
    </tr>
  </thead>
);

const PaymentPromiseTableBody = ({ paymentPromises }) => (
  <tbody>
    {paymentPromises.map(paymentPromise => (
      <PaymentPromiseTableRow
        paymentPromise={paymentPromise}
        color={paymentPromise.status === "broken" ? "red" : "green"}
        key={paymentPromise.id}
      />
    ))}
  </tbody>
);

const PaymentPromises = () => {
  const { id } = useParams();
  const [paymentPromises, setPaymentPromises] = useState([]);
  const fetchPaymentPromises = async () => {
    try {
      const {
        data: { payment_promises },
      } = await paymentPromisesAPI.index(id);
      setPaymentPromises(payment_promises);
    } catch (error) {
      logger.error(error);
    }
  };

  useEffect(() => {
    fetchPaymentPromises();
  }, []);

  return (
    <div className="p-4 my-8 border border-gray-200 rounded-md">
      <Label className="mb-2 font-bold uppercase">Payment Promises</Label>
      <table className="nui-table ">
        <PaymentPromiseTableHead />
        <PaymentPromiseTableBody paymentPromises={paymentPromises} />
      </table>
    </div>
  );
};

export default PaymentPromises;

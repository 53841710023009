import axios from "axios";

const creditApplications = customer_id =>
  axios.get(`/api/v1/admin/customers/${customer_id}/credit_applications`);
const creditApplication = (customer_id, id) =>
  axios.get(`/api/v1/admin/customers/${customer_id}/credit_applications/${id}`);
const updateAvailableCredit = (customer_id, payload) =>
  axios.put(`/api/v1/admin/customers/${customer_id}/available_credit`, payload);

const creditApplicationsApi = {
  creditApplications,
  creditApplication,
  updateAvailableCredit,
};

export default creditApplicationsApi;

import React from "react";

const LeaseHeader = ({ lease }) => {
  const { type: epo_type, period: epo_period } = lease.epo_details;

  return (
    <>
      {epo_type == "retail_price" && (
        <div className="flex justify-center w-full px-4 py-2 text-white rounded-t-md bg-purple-1000">
          Eligible for {epo_period} purchase option.
          <p className="px-1 underline">Buy Now!</p>
        </div>
      )}
      <div className="flex justify-start px-3 py-2 space-x-4 bg-gray-200">
        <div>
          <p className="text-gray-500 uppercase">Lease ID</p>
          <p className="font-semibold text-gray-500">{lease.slug}</p>
        </div>
        <div>
          <p className="text-gray-500 uppercase">Order Placed</p>
          <p className="font-semibold text-gray-500">{lease.start_date}</p>
        </div>
      </div>
    </>
  );
};

export default LeaseHeader;

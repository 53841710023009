import React from "react";
import { Button } from "neetoui";
import { useHistory } from "react-router";

import LeaseSubHeader from "./LeaseSubHeader";

const BankPayment = ({ paymentInfo, setPaymentInfo }) => {
  const history = useHistory();

  const goback = () => {
    history.goBack();
  };
  return (
    <>
      <LeaseSubHeader
        paymentInfo={paymentInfo}
        setPaymentInfo={setPaymentInfo}
      />
      <div className="px-4 py-8">
        <p className="text-lg">
          <span className="text-purple-1000 ">Becca’s Home</span> uses Plaid to
          link your bank account
        </p>
        <div className="py-4">
          <div className="flex items-center justify-start space-x-4">
            <div className="text-3xl leading-none text-gray-800 ri-secure-payment-line"></div>
            <p className="text-2xl font-semibold text-purple-1000">Secure</p>
          </div>
          <div>
            <p className="mt-3 text-lg">
              Transfer of your information is encrypted end to end
            </p>
          </div>
        </div>
        <div className="py-4">
          <div className="flex items-center justify-start space-x-4">
            <div className="text-3xl ri-git-repository-private-line"></div>
            <p className="text-2xl font-semibold text-purple-1000">Private</p>
          </div>
          <div>
            <p className="mt-3 text-lg">
              Your account information will never be made public.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-end mt-6 space-x-4">
          <Button label="Cancel" style="secondary" onClick={() => goback()} />
          <Button label="Continue" onClick={() => goback()} />
        </div>
      </div>
    </>
  );
};

export default BankPayment;

import React from "react";
import { Switch } from "@bigbinary/neetoui";

import CardIcon from "common/images/card_logo.svg";
import paymentMethodsApi from "apis/payment-methods";
import { formatDate } from "common/dateTimeFormatter";

const CardRow = ({ card, getCardDetails }) => {
  const onDefaultCardChange = async () => {
    try {
      await paymentMethodsApi.updateDefaultCard(card.customer_id, card.id);
      await getCardDetails();
    } catch (error) {
      logger.error(error);
    }
  };
  return (
    <tr className="text-left">
      <td>
        <div className="flex space-x-2">
          <img src={CardIcon} width="40px" height="40px" />
          <p className="font-normal text-gray-600">{card?.name}</p>
        </div>
      </td>
      <td className="text-center">
        {card.details.card.exp_month + "/" + card.details.card.exp_year}
      </td>
      <td className="text-center">
        {card.last_used ? formatDate(card.last_used) : "---"}
      </td>
      <td>
        <div className="flex justify-center">
          <Switch
            checked={card.is_default}
            onChange={() => onDefaultCardChange()}
          />
        </div>
      </td>
    </tr>
  );
};

const CardPaymentsTableHead = () => (
  <thead>
    <tr className="text-left">
      <th>CARD NUMBER</th>
      <th className="text-center uppercase">Expires Date</th>
      <th className="text-center uppercase">Last Used</th>
      <th className="text-center uppercase">Default Card</th>
    </tr>
  </thead>
);

const CardPaymentsTableBody = ({
  cards,
  setEditCardPane,
  setCardBeingEdited,
  getCardDetails,
}) => (
  <tbody>
    {cards?.map(card => (
      <CardRow
        key={card.id}
        card={card}
        getCardDetails={getCardDetails}
        setEditCardPane={setEditCardPane}
        setCardBeingEdited={setCardBeingEdited}
      />
    ))}
  </tbody>
);

const CardPayments = ({
  cards,
  setEditCardPane,
  setCardBeingEdited,
  getCardDetails,
}) => {
  return (
    <div>
      {cards.length == 0 ? (
        <div className="flex justify-center w-full">
          No cards payment method added
        </div>
      ) : (
        <table className="nui-table">
          <CardPaymentsTableHead />

          <CardPaymentsTableBody
            cards={cards}
            setEditCardPane={setEditCardPane}
            setCardBeingEdited={setCardBeingEdited}
            getCardDetails={getCardDetails}
          />
        </table>
      )}
    </div>
  );
};

export default CardPayments;

import React, { useEffect, useState } from "react";
import { Dropdown, Button } from "@bigbinary/neetoui";
import { Header, SubHeader } from "@bigbinary/neetoui/layouts";
import { DEFAULT_PAGE_SIZE, FILTER_BY_OPTIONS } from "./constants";
import CancellationsList from "./CancellationsList";
import { SORT_BY_OPTIONS, LOAD_SORT_PROPS } from "./constants";
import ExportOrdersForm from "../common/ExportOrdersForm";
import ImportOrdersForm from "../common/ImportOrdersForm";

const Cancellations = () => {
  const [searchString, setSearchString] = useState();
  const [totalCancellationsCount, setTotalCancellationsCount] = useState();
  const [pageIndex, setPageIndex] = useState(1);
  const [importPane, setImportPane] = useState(false);
  const [exportPane, setExportPane] = useState(false);

  useEffect(() => {
    setTotalCancellationsCount(50);
    if (importPane && exportPane) {
      setExportPane(false);
    }
  }, []);

  return (
    <div className="w-full">
      <Header
        title="Cancellations"
        actionBlock={
          <div className="flex space-x-2">
            <Button
              style="secondary"
              label="Import"
              onClick={() => {
                setImportPane(true);
              }}
            />
            <Button
              style="secondary"
              label="Export"
              onClick={() => {
                setExportPane(true);
              }}
            />
          </div>
        }
      />
      <SubHeader
        searchProps={{
          value: searchString,
          onChange: e => setSearchString(e.target.value),
          clear: () => setSearchString(""),
        }}
        paginationProps={{
          count: totalCancellationsCount,
          pageNo: pageIndex,
          pageSize: DEFAULT_PAGE_SIZE,
          navigate: index => setPageIndex(index),
        }}
        sortProps={{
          options: SORT_BY_OPTIONS,
          onClick: LOAD_SORT_PROPS,
        }}
        actionBlock={
          <div className="flex">
            <Dropdown
              buttonStyle="secondary"
              label="Filter By"
              position="bottom-right"
              data-cy="change-status-dropdown"
              className="mr-3"
            >
              {FILTER_BY_OPTIONS.map(option => {
                return <li key={option.label}>{option.label}</li>;
              })}
            </Dropdown>
          </div>
        }
      />
      <CancellationsList />
      <ImportOrdersForm
        importPane={importPane}
        setImportPane={setImportPane}
        pageTitle="cancellations"
      />
      <ExportOrdersForm
        exportPane={exportPane}
        setExportPane={setExportPane}
        pageTitle="cancellations"
      />
    </div>
  );
};

export default Cancellations;

import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Button } from "neetoui";
import { Form, Formik } from "formik";
import { Input as FormikInput } from "neetoui/formik";

import authenticationApi from "apis/authentication";
import { useAuthDispatch } from "contexts/auth";
import { useUserDispatch } from "contexts/user";
import { SIGN_UP_INITIAL_VALUE } from "./constant";
import BeccasHomeLogo from "common/images/beccas-home-logo.png";

const Signup = ({ history }) => {
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Incorrect Email address")
      .required("Email is required"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    password: Yup.string().required("Password is required"),
    password_confirmation: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const authDispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();

  const handleSubmit = async event => {
    try {
      setError("");
      setLoading(true);
      const {
        data: { user, auth_token },
      } = await authenticationApi.signup({
        user: {
          email: event.email,
          first_name: event.first_name,
          last_name: event.last_name,
          password: event.password,
          password_confirmation: event.password_confirmation,
        },
      });
      authDispatch({
        type: "LOGIN",
        payload: { auth_token, email: event.email, role: "staff" },
      });
      userDispatch({ type: "SET_USER", payload: { user } });
      history.push("/");
    } catch (error) {
      if (error.response.data.error) {
        setError(error.response.data.error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-row items-center justify-center w-screen h-screen p-6 overflow-x-hidden overflow-y-auto bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full h-full mx-auto sm:max-w-md">
        <div className="flex items-center justify-center rounded-md w-14 h-14">
          <img src={BeccasHomeLogo} width="100px" height="100px" />
        </div>
        <h2 className="mb-5 text-3xl font-extrabold text-center text-gray-800">
          Signup
        </h2>
        <Formik
          initialValues={SIGN_UP_INITIAL_VALUE}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="w-full p-8 space-y-6 bg-white border rounded-md shadow">
            <FormikInput
              name="email"
              type="email"
              label="Email *"
              placeholder="oliver@example.com"
            />
            <FormikInput
              name="first_name"
              type="text"
              label="First name *"
              placeholder="Sam"
            />
            <FormikInput
              name="last_name"
              type="text"
              placeholder="Smith"
              label="Last name *"
            />
            <FormikInput
              name="password"
              type="password"
              label="Password *"
              placeholder="******"
            />
            <FormikInput
              name="password_confirmation"
              type="password"
              label="Confirm password *"
              placeholder="******"
            />
            <p className="text-red-600">{error}</p>
            <Button
              name="submit"
              type="submit"
              loading={loading}
              label="Signup"
              fullWidth
            />
          </Form>
        </Formik>
        <div className="flex flex-row items-center justify-start mt-4 space-x-1">
          <p className="font-normal text-gray-600">Already have an account?</p>
          <Button label="Login" style="link" to="/staff/login" />
        </div>
      </div>
    </div>
  );
};

Signup.propTypes = {
  history: PropTypes.object,
};

export default Signup;

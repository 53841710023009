import React from "react";
// import { Badge } from "neetoui";
import { displayPayCycle } from "components/MyAccount/common/helper";
import { formatCurrency } from "utils/styleUtils";
// import { DELIVERY_STATUS } from "components/MyAccount/common/constants";

const Body = ({ lineItems, payCycle }) => {
  return (
    <div className="m-4">
      {lineItems?.map(
        (
          {
            woo_image_url,
            product_name,
            payment_amount,
            quantity,
            // shipment_status,
            tax_on_payment_amount,
          },
          index
        ) => {
          return (
            <div className="flex items-center space-x-6" key={index}>
              <img
                src={
                  woo_image_url ||
                  "https://via.placeholder.com/96.png?text=Image"
                }
                className="object-contain"
                width="96px"
                height="96px"
              />
              <div className="space-y-1">
                <div className="font-semibold">{product_name}</div>
                {/* <div className="flex space-x-1">
                  <Badge color={DELIVERY_STATUS[shipment_status].color}>
                    {DELIVERY_STATUS[shipment_status].label}
                  </Badge>
                </div> */}
                <div className="font-medium text-gray-600">{`${formatCurrency(
                  parseFloat(payment_amount) + parseFloat(tax_on_payment_amount)
                )}/${displayPayCycle(payCycle)}`}</div>
                <div className="font-medium text-gray-600">{`Qty - ${quantity}`}</div>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default Body;

import React from "react";
import { Button } from "@bigbinary/neetoui";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";

import Footer from "../Footer";
import HeaderIcon from "../Header";
import NewPaymentMethods from "./NewPaymentMethod";
import SavedPaymentMethods from "./SavedPaymentMethods";

const PaymentMethods = () => {
  const history = useHistory();

  const selectCard = () => {
    history.push("/payment/:customerId/payment/card");
  };

  const handleNewBankType = () => {
    history.push("/payment/:customerId/payment/bank/new");
  };

  const handleNewCardType = () => {
    history.push("/payment/:customerId/payment/card/new");
  };

  const onCancel = () => {
    history.goBack();
  };

  return (
    <>
      <div className="p-4">
        <HeaderIcon />
        <div className="space-y-4">
          <p className="text-lg font-bold text-gray-800">
            How do you wish to pay?
          </p>
          <SavedPaymentMethods selectCard={selectCard} />
          <NewPaymentMethods
            handleNewBankType={handleNewBankType}
            handleNewCardType={handleNewCardType}
          />
          {/* Replace with Google pay and apple pay buttons */}
          <div className="flex justify-between">
            <div className="flex justify-center w-2/5 text-2xl text-white bg-black ri-google-fill"></div>
            <div className="flex justify-center w-2/5 text-2xl text-white bg-black ri-apple-line"></div>
          </div>
          <div className="flex justify-start py-4">
            <Button
              label="Cancel"
              style="secondary"
              onClick={() => onCancel()}
            />
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="relative mt-6">
          <Footer />
        </div>
      )}
    </>
  );
};

export default PaymentMethods;

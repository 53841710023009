import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useParams } from "react-router-dom";
import { PageLoader } from "neetoui";

import NewCardPage from "./NewCard";
import BankPayment from "./BankPayment";
import CardPayment from "./CardPayment";
import PaymentConfirm from "./PaymentConfirm";

import classnames from "classnames";
import { isDesktop } from "react-device-detect";
import leasesApi from "apis/customers/leases";
import paymentMethodsApi from "apis/customers/payment-methods";
import PaymentSelection from "./Selection";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useDocumentTitle from "hooks/useDocumentTitle";

const Payment = () => {
  const { leaseId } = useParams();
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState({
    lease: null,
    leaseList: [],
    type: "invoice",
    method: null,
    date: new Date(),
    amount: 0,
    epoAmount: 0,
    upcomingInvoiceAmount: 0,
  });

  useDocumentTitle(`Payment for Lease ${leaseId}`);

  const [stripePromise] = useState(() =>
    loadStripe(
      "pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3"
    )
  );

  const fetchLeases = async () => {
    try {
      setLoading(true);
      const { data } = await leasesApi.leases();
      const UN_PAYABLE_LEASE_STATUSES = ["cancelled", "paid_off"];
      const leases = data?.leases?.filter(
        lease => !UN_PAYABLE_LEASE_STATUSES.includes(lease.lease_status)
      );
      const getCurrentLeaseList = leases.filter(
        lease => lease.slug === leaseId
      );
      const selectedLease =
        getCurrentLeaseList.length !== 0 ? getCurrentLeaseList[0] : leases[0];

      setPaymentInfo(state => ({
        ...state,
        leaseList: leases,
        lease: selectedLease,
      }));
    } catch (error) {
      logger.error(error);
    }
  };

  const fetchPaymentMethods = async () => {
    try {
      const {
        data: { payment_methods },
      } = await paymentMethodsApi.all();

      setPaymentMethods(payment_methods);
      setPaymentInfo(state => ({
        ...state,
        method: payment_methods.find(v => v.is_default),
      }));
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeases();
    fetchPaymentMethods();
  }, []);

  if (loading) {
    return (
      <div className="flex w-full h-screen">
        <PageLoader />
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <>
        <div className="flex items-center justify-center w-full ">
          <div
            className={classnames(
              {
                "w-96 justify-center h-full py-8": isDesktop,
              },
              { "w-full becca-page-maxheight": !isDesktop }
            )}
          >
            <div className="bg-white">
              <Switch>
                <Route
                  exact
                  path="/my-account/payment/:id/:cardnumber?"
                  render={() => (
                    <PaymentSelection
                      paymentInfo={paymentInfo}
                      setPaymentInfo={setPaymentInfo}
                      paymentMethods={paymentMethods}
                    />
                  )}
                />
                <Route
                  exact
                  path="/my-account/payment/bank/new"
                  render={() => (
                    <BankPayment
                      paymentInfo={paymentInfo}
                      setPaymentInfo={setPaymentInfo}
                    />
                  )}
                />
                <Route
                  exact
                  path="/my-account/payment/card/:index"
                  render={() => (
                    <CardPayment
                      paymentInfo={paymentInfo}
                      setPaymentInfo={setPaymentInfo}
                      paymentMethods={paymentMethods}
                    />
                  )}
                />
                <Route
                  exact
                  path="/my-account/payment/new/card/:id"
                  render={() => (
                    <NewCardPage fetchPaymentMethods={fetchPaymentMethods} />
                  )}
                />
                <Route
                  path="/my-account/payment/confirm"
                  component={PaymentConfirm}
                />
              </Switch>
            </div>
          </div>
        </div>
      </>
    </Elements>
  );
};

export default Payment;

export const PANE_TABS = ["CSV Import", "Manual Import"];

export const ORDER_EXPORT_STATUS = [
  "Ordered",
  "Awaiting Fulfillment",
  "Tracking Imported",
  "Tracking in progress",
  "Cancelled",
  "Carrier intercept",
  "Returned",
];

export const CANCELLATION_EXPORT_STATUS = [
  "Cancellation Requested",
  "Cancelled",
];

export const CANCELLATION_EXPORT_UPDATE_STATUS = [
  "Cancellation In Progress",
  "Cancelled",
];

export const RETURN_EXPORT_STATUS = ["Return Requested", "Returned"];

export const RETURN_EXPORT_UPDATE_STATUS = ["Return In Progress", "Returned"];

export const CANCELLATION_MANUAL_STATUS = [
  "Cancellation In Progress",
  "Cancelled",
];

export const RETURN_MANUAL_STATUS = ["Return In Progress", "Returned"];

export const CANCELLATION_CSV_STATUS = [
  "Cancellation In Progress",
  "Cancelled",
];

export const RETURN_CSV_STATUS = ["Return In Progress", "Returned"];

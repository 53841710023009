import React, { useState } from "react";
import { Tab } from "@bigbinary/neetoui";

import { CREDIT_TYPES, LEASE_INVOICE_DETAILS_TAB } from "../constants";
import InvoiceTab from "./InvoiceTab";
import PaymentTab from "./PaymentTab";
import { CreditPaymentPane } from "components/Admin/Customer/common/PaneComponents/CreditPayPane";
import PaymentModal from "components/Admin/Customer/Tabs/Leases/common/PaymentModal";

const renderTabContents = (currentTab, leaseSlug) => {
  switch (currentTab) {
    case "Invoices":
      return <InvoiceTab leaseSlug={leaseSlug} />;
    case "Payment Transaction":
      return <PaymentTab leaseSlug={leaseSlug} />;
  }
};

const Invoice = ({ leaseSlug }) => {
  const [currentTab, setCurrentTab] = useState("Invoices");
  const [creditPane, setCreditPane] = useState(false);
  const [creditType, setCreditType] = useState(CREDIT_TYPES[0]);
  const [paymentModal, setPaymentModal] = useState(false);

  const onCreditTypeChange = item => {
    setCreditType(item);
  };
  return (
    <div className="m-4 border border-gray-200 rounded-md">
      <div>
        <Tab className="w-full px-4">
          {LEASE_INVOICE_DETAILS_TAB.map((tab, index) => {
            return (
              <Tab.Item
                key={index}
                active={currentTab === tab}
                onClick={() => setCurrentTab(tab)}
              >
                {tab}
              </Tab.Item>
            );
          })}
        </Tab>
        {renderTabContents(currentTab, leaseSlug)}
      </div>
      <CreditPaymentPane
        creditPane={creditPane}
        setCreditPane={setCreditPane}
        creditType={creditType}
        onCreditTypeChange={onCreditTypeChange}
      />

      <PaymentModal
        paymentModal={paymentModal}
        setPaymentModal={setPaymentModal}
      />
    </div>
  );
};

export default Invoice;

import React from "react";
import { Button, Tooltip, Switch } from "@bigbinary/neetoui";

const BankPaymentsHead = () => (
  <thead>
    <tr className="text-left">
      <th>Bank account number</th>
      <th className="text-center uppercase">Bank Name</th>
      <th className="text-center uppercase">Last used</th>
      <th className="text-center uppercase">Default Payment option</th>
      <th></th>
    </tr>
  </thead>
);

const BankPaymentRow = ({ data }) => (
  <tr className="text-left">
    <td>{data.number}</td>
    <td className="text-center">{data.name}</td>
    <td className="text-center"> {data.last_used}</td>
    <td>
      <div className="flex justify-center">
        <Switch checked={data.default} />
      </div>
    </td>
    <td>
      <div className="flex space-x-4">
        <Tooltip content="Edit">
          <Button style="icon" icon="ri-pencil-line" onClick={() => {}} />
        </Tooltip>
      </div>
    </td>
  </tr>
);

const BankPaymentsBody = ({ data }) => (
  <tbody>
    {data.map((data, idx) => (
      <BankPaymentRow data={data} key={idx} />
    ))}
  </tbody>
);

const BankPayments = ({ bank }) => {
  return (
    <div className="my-4">
      {bank.length == 0 ? (
        <div className="flex justify-center w-full">
          No Bank payments methods added
        </div>
      ) : (
        <table className="nui-table">
          <BankPaymentsHead />
          <BankPaymentsBody data={bank} />
        </table>
      )}
    </div>
  );
};

export default BankPayments;

import React from "react";
import { Button, Badge } from "@bigbinary/neetoui";
import { CANCELLATIONS_LIST } from "./constants";
import { useHistory } from "react-router-dom";

const CancellationsTableRow = ({ order }) => {
  const history = useHistory();

  let itemStatusColor = "red";

  /*
  Order Statuses - 
  Ordered
  Awaiting Fulfillment
  Tracking Imported
  tracking in progress (actual status) -> In Transit, delivered
  cancelled -> Cancellation requested, Cancellation In Progress,  Cancelled
  Carrier intercept
  returned -> Return requested, Return In Progress,  Returned
  */

  if (order.item_status == "Ordered") {
    itemStatusColor = "blue";
  }
  const onLeaseButtonClick = () =>
    history.push("/customers/BH1002/leases/LID23992");

  const onCustomerButtonClick = () => history.push("/customers/BH1002");

  return (
    <tr className="text-left">
      <td>
        <div className="flex items-center">
          <Button
            className="underline"
            style="link"
            label={order.lease_id}
            onClick={() => onLeaseButtonClick()}
          />
        </div>
      </td>
      <td>
        <div className="flex items-center justify-center">
          <Button
            style="link"
            label={order.customer}
            onClick={() => onCustomerButtonClick()}
          />
        </div>
      </td>
      <td>
        <div className="flex items-center justify-center">
          <Button
            className="underline"
            style="link"
            label={order.product_sku}
            onClick={() => onLeaseButtonClick()}
          />
        </div>
      </td>
      <td className="text-center">{order.vendor}</td>
      <td className="text-center">{order.order_date}</td>
      <td>
        <div className="flex items-center justify-center">
          <Badge color={itemStatusColor}>{order.item_status}</Badge>
        </div>
      </td>
      <td className="text-center">{order.date_request}</td>
      <td className="text-center">{order.date_confirm}</td>
    </tr>
  );
};

const OrderTableHeader = () => (
  <thead>
    <tr>
      <th className="uppercase">LEASE ID</th>
      <th className="text-center uppercase">CUSTOMER</th>
      <th className="text-center uppercase">Product sku</th>
      <th className="text-center uppercase">vendor</th>
      <th className="text-center uppercase">Order date</th>
      <th className="text-center uppercase">ITEM STATUS</th>
      <th className="text-center uppercase">Date of request</th>
      <th className="text-center uppercase">Date of confirmation</th>
    </tr>
  </thead>
);

const CancellationsTableBody = () => (
  <tbody>
    {CANCELLATIONS_LIST.map((order, index) => (
      <CancellationsTableRow key={index} order={order} />
    ))}
  </tbody>
);

const OrderTable = () => (
  <div className="flex justify-center w-full mt-6">
    <div className="w-11/12">
      <table className="nui-table">
        <OrderTableHeader />
        <CancellationsTableBody />
      </table>
    </div>
  </div>
);

const CancellationsList = () => {
  return (
    <div>
      <OrderTable />
    </div>
  );
};
export default CancellationsList;

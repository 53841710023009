export const DEFAULT_PAGE_SIZE = 0;
export const TOTAL_ROW_COUNT = 0;
export const SORT_BY_OPTIONS = [{ label: "Name" }, { label: "Date" }];
export const LOAD_SORT_PROPS = {};
export const LeasesStatus = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
});

import React, { useRef, useState, useEffect } from "react";
import { Input, Button, Modal } from "@bigbinary/neetoui";
import customersPhoneVerificationApi from "apis/customers/phone-verification";

const SMSModal = ({
  onModalClose,
  smsModal,
  phoneNumber,
  resentOtp,
  initialPhoneNumber,
  setInitialPhoneNumber,
  setPhoneId,
}) => {
  const [otp, setOtp] = useState(["", "", "", ""]);

  const otpNumber1 = useRef();
  const otpNumber2 = useRef();
  const otpNumber3 = useRef();
  const otpNumber4 = useRef();
  const sumbitButton = useRef();

  useEffect(() => {
    otpNumber1.current.focus();
  }, []);

  const onOTPValueChange = (index, value) => {
    setOtp(state => {
      let updatedState = [...state];
      updatedState[index] = value;
      return updatedState;
    });
  };

  const numberOnChange = (otpCount, e, nextElement) => {
    if (e.target.value.length === 1) {
      nextElement.current.focus();
    }
    onOTPValueChange(otpCount, e.target.value);
  };

  const handleSubmit = async event => {
    try {
      event.preventDefault();
      const { data } = await customersPhoneVerificationApi.verifyOtp({
        phone_number: phoneNumber,
        otp: otp.join(""),
      });
      if (setPhoneId) setPhoneId(data.id);
      if (setInitialPhoneNumber) setInitialPhoneNumber(phoneNumber);
      onModalClose();
    } catch (error) {
      setOtp(["", "", "", ""]);
      if (setInitialPhoneNumber) setInitialPhoneNumber(initialPhoneNumber);
      logger.error(error);
    }
  };
  return (
    <Modal
      size="small"
      isOpen={smsModal}
      onClose={() => onModalClose()}
      autoHeight
    >
      <Modal.Title>Phone Number Verification</Modal.Title>
      <div className="p-2">
        <div className="flex flex-col items-center justify-center my-6 space-y-6">
          <p> Enter OTP sent to {phoneNumber}.</p>
          <div className="flex justify-between px-6 space-x-2">
            <Input
              maxlength={1}
              nakedInput
              autoFocus
              className="border-b border-gray-600"
              value={otp[0]}
              required
              onChange={e => {
                numberOnChange(0, e, otpNumber2);
              }}
              ref={otpNumber1}
            />
            <Input
              maxlength={1}
              nakedInput
              className="border-b border-gray-600"
              value={otp[1]}
              required
              onChange={e => {
                numberOnChange(1, e, otpNumber3);
              }}
              ref={otpNumber2}
            />
            <Input
              maxlength={1}
              nakedInput
              className="border-b border-gray-600"
              value={otp[2]}
              required
              onChange={e => {
                numberOnChange(2, e, otpNumber4);
              }}
              ref={otpNumber3}
            />
            <Input
              maxlength={1}
              nakedInput
              className="border-b border-gray-600"
              value={otp[3]}
              required
              onChange={e => {
                numberOnChange(3, e, sumbitButton);
              }}
              ref={otpNumber4}
            />
          </div>
          <div>
            Didn’t receive the OTP?
            <span
              className="mx-1 underline text-purple-1000"
              onClick={() => resentOtp(phoneNumber)}
            >
              Resend OTP.
            </span>
          </div>
        </div>
        <div className="flex justify-end space-x-4">
          <Button
            label="Cancel"
            style="secondary"
            onClick={() => onModalClose()}
          />
          <Button label="Verify" onClick={handleSubmit} ref={sumbitButton} />
        </div>
      </div>
    </Modal>
  );
};

export default SMSModal;

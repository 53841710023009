import React from "react";
import { isMobile } from "react-device-detect";

import { PAYMENT_METHODS } from "./constants";

const NewPaymentMethods = ({ handleNewCardType, handleNewBankType }) => {
  const paymentMethod = paymentType =>
    paymentType === "bank" ? handleNewBankType() : handleNewCardType();
  return (
    <div className="space-y-4 ">
      <p className="font-semibold text-gray-600 uppercase text-md">
        new payment methods
      </p>
      {PAYMENT_METHODS.map((method, idx) => (
        <div
          className="flex items-center justify-between p-2 border-b border-gray-200 cursor-pointer"
          onClick={() => paymentMethod(method.type)}
          key={idx}
        >
          <div className="flex items-center justify-center space-x-6">
            <i className={`text-3xl ${method.icon}`}></i>
            <p className="text-lg font-semibold text-purple-1000">
              {method.label}
            </p>
          </div>
          {!isMobile && (
            <i className="text-2xl text-gray-200 ri-arrow-right-s-line"></i>
          )}
        </div>
      ))}
    </div>
  );
};

export default NewPaymentMethods;

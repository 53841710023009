import React, { useEffect, useState } from "react";
import { Select } from "neetoui";

const LeaseSelectionComponent = ({ onLeaseChange, initialLease, leases }) => {
  const [leaseState, setLeaseState] = useState(null);
  const [leaseList, setLeaseList] = useState([]);

  useEffect(() => {
    setLeaseState({
      label: initialLease?.slug,
      value: initialLease?.id,
      ...initialLease,
    });
  }, [initialLease]);

  useEffect(() => {
    onLeaseChange(leaseState);
  }, [leaseState]);

  const onLeaseStateChange = value => {
    setLeaseState(value);
  };

  useEffect(() => {
    if (!leases?.length) return;

    const labeledOptions = leases?.map(lease => ({
      label: lease.slug,
      value: lease.id,
      ...lease,
    }));

    setLeaseList(labeledOptions);
    const lease = initialLease || leases?.[0];
    setLeaseState({
      label: lease?.slug,
      value: lease?.id,
      ...lease,
    });
  }, [leases]);

  return (
    <>
      {leaseList.length !== 0 && (
        <Select
          label="Pay to lease"
          value={leaseState}
          options={leaseList}
          onChange={lease => {
            onLeaseStateChange(lease);
          }}
        />
      )}
    </>
  );
};

export default LeaseSelectionComponent;

import React from "react";
import { Pane, Button, Select } from "neetoui";
import { NUMBER_OF_PAYMENTS } from "./constants";

const SkipPaymentPane = ({ skipPaymentPane, setSkipPaymentPane }) => {
  return (
    <Pane
      title="Skip Payments for Oliver Smith"
      isOpen={skipPaymentPane}
      onClose={() => setSkipPaymentPane(false)}
      cancelButtonProps={{
        label: "Cancel",
        onClick: () => setSkipPaymentPane(false),
      }}
      submitButtonProps={{
        label: "Skip Payments",
        onClick: () => setSkipPaymentPane(false),
      }}
      showFooter
    >
      <div className="w-full px-6 pb-20 space-y-6 overflow-auto">
        <Select
          requried
          name="state"
          label="Number of payments to be skipped"
          options={NUMBER_OF_PAYMENTS}
        />
        <div>
          <div className="py-6 space-y-2 border-t border-dashed">
            <Button
              style="link"
              label="BH16002"
              className="font-semibold underline"
            />
            <div className="flex justify-between w-full">
              <p>Next payment date:</p>
              <p>04/10/2022</p>
            </div>
            <div className="flex justify-between w-full">
              <p>New lease end date:</p>
              <p>04/10/2022</p>
            </div>
          </div>
          <div className="py-6 space-y-2 border-t border-dashed">
            <Button
              style="link"
              label="BH16002"
              className="font-semibold underline"
            />
            <div className="flex justify-between w-full">
              <p>Next payment date:</p>
              <p>04/10/2022</p>
            </div>
            <div className="flex justify-between w-full">
              <p>New lease end date:</p>
              <p>04/10/2022</p>
            </div>
          </div>
        </div>
      </div>
    </Pane>
  );
};

export default SkipPaymentPane;

import React, { useState } from "react";
import { Label, Button, Badge, Tooltip } from "@bigbinary/neetoui";
import { useCustomerState } from "contexts/customer";

import { AttachmentPaneComponent } from "components/Admin/Customer/common/PaneComponents/AttachmentPane";
import Card from "components/Admin/Customer/common/Card";
import { formatCurrency } from "utils/styleUtils";
import { UpdatePayCyclePane } from "components/Admin/Customer/common/PaneComponents/UpdatePayCyclePane";
import CreditInfoCard from "../CreditInfoCard";
import { formatDateWithoutTimeZone } from "common/dateTimeFormatter";
import { convertSnakeToTitle } from "utils/styleUtils";
import { isEmptyObject } from "common/utils";

const PersonalInfo = ({ customer }) => (
  <Card title={"PERSONAL INFORMATION"}>
    <div className="flex justify-between mt-4">
      <Label>Full Name </Label>
      <p className="font-normal text-gray-600">{customer.name}</p>
    </div>
    <div className="flex justify-between mt-2">
      <Label>Date of Birth </Label>
      <p className="font-normal text-gray-600">
        {formatDateWithoutTimeZone(customer.date_of_birth)}
      </p>
    </div>
    <div className="flex justify-between mt-2">
      <Label>SSN </Label>
      <p className="font-normal text-gray-600">{customer.ssn}</p>
    </div>
  </Card>
);

const LeaseInfo = ({ leaseDetailsData }) => {
  const calculateLeaseProgress = () => {
    if (leaseDetailsData?.lease_status === "paid_off") {
      return "17/17 months";
    } else {
      const paidMonths = (
        (leaseDetailsData?.sum_of_payments / leaseDetailsData?.total_cost) *
        17
      ).toFixed(1);

      return `${paidMonths}/17 months`;
    }
  };

  return (
    <Card title={"LEASE INFORMATION"}>
      <div className="flex justify-between mt-4">
        <Label>Outstanding Balance </Label>
        <p className="font-semibold text-red-600">
          {formatCurrency(leaseDetailsData?.outstanding_amount)}
        </p>
      </div>
      <div className="flex justify-between mt-4">
        <Label>Overdue Amount </Label>
        <p className="font-semibold text-red-600">
          {formatCurrency(leaseDetailsData?.due_amount_with_tax)}
        </p>
      </div>
      <div className="flex justify-between mt-4">
        <Label>Progress </Label>
        <p className="font-normal text-gray-600">{calculateLeaseProgress()}</p>
      </div>
      <div className="flex justify-between mt-3">
        <Label>Goodwill Recevied </Label>
        <p className="font-normal text-gray-600">
          {formatCurrency(leaseDetailsData?.goodwill_received)}
        </p>
      </div>
      <div className="flex justify-between mt-3">
        <Label>Sum Of Lease Payments </Label>
        <p className="font-normal text-gray-600">
          {formatCurrency(leaseDetailsData?.sum_of_payments)}
        </p>
      </div>
      <div className="flex justify-between mt-2">
        <Label>Total Lease Cost </Label>
        <p className="font-normal text-gray-600">
          {formatCurrency(leaseDetailsData?.total_cost)}
        </p>
      </div>
      <div className="flex justify-between mt-2">
        <Label>Lease Start Date </Label>
        <p className="font-normal text-gray-600">
          {formatDateWithoutTimeZone(leaseDetailsData?.start_date)}
        </p>
      </div>
      <div className="flex justify-between mt-2">
        <Label>Lease End Date </Label>
        <p className="font-normal text-gray-600">
          {formatDateWithoutTimeZone(leaseDetailsData?.end_date)}
        </p>
      </div>
    </Card>
  );
};

const PayoffInfo = ({ leaseDetailsData }) => {
  const payoffDetailsData = leaseDetailsData?.epo_details;
  const modified_type =
    payoffDetailsData?.type == "retail_price"
      ? payoffDetailsData?.period
      : "Early";

  const calculatePayoffWithTax = () => {
    if (payoffDetailsData === undefined) return 0;

    return (
      parseFloat(payoffDetailsData?.option_amount) +
      parseFloat(leaseDetailsData?.epo_estimated_tax) -
      parseFloat(payoffDetailsData?.balance)
    );
  };

  return (
    <Card title={"PAYOFF INFORMATION"}>
      <div className="flex justify-between mt-4">
        <Label>90 Days Payoff Expiration</Label>
        <p className="font-normal text-gray-600">
          {formatDateWithoutTimeZone(
            payoffDetailsData?.retail_price_payoff_expiry_date
          )}
        </p>
      </div>
      <div className="flex justify-between mt-4">
        <Label>EPO Type </Label>
        <p className="font-semibold text-purple-1000">
          {modified_type} Payoff Option
        </p>
      </div>
      <div className="flex justify-between mt-3">
        <Label>EPO Option </Label>
        <p className="font-semibold text-purple-1000">
          {formatCurrency(payoffDetailsData?.option_amount)}
        </p>
      </div>
      <div className="flex justify-between mt-3">
        <Label>EPO Wallet </Label>
        <p className="font-normal text-gray-600">
          {`- ${formatCurrency(payoffDetailsData?.balance)}`}
        </p>
      </div>
      <div className="flex justify-between mt-3">
        <Label>Estimated EPO Tax</Label>
        <p className="font-normal text-gray-600">
          {`+ ${formatCurrency(leaseDetailsData?.epo_estimated_tax)}`}
        </p>
      </div>
      <div className="flex justify-between mt-2">
        <Label>Payoff Balance</Label>
        <p className="font-normal text-gray-600">
          {formatCurrency(calculatePayoffWithTax())}
        </p>
      </div>
    </Card>
  );
};

const LeaseDocInfo = ({ setAttachmentPane, documents }) => (
  <Card
    title={"LEASE DOCUMENTS"}
    titleAction={
      <Button
        className="text-base"
        style="icon"
        icon="ri-add-line"
        onClick={() => setAttachmentPane(true)}
      />
    }
  >
    {isEmptyObject(documents) && (
      <div className="flex justify-between my-4">
        <p className="font-normal text-gray-600">No documents</p>
      </div>
    )}

    {documents &&
      Object.keys(documents).map((docName, index) => (
        <>
          <div className="flex justify-between my-4" key={index}>
            <Badge color="green">{convertSnakeToTitle(docName)}</Badge>
            <Tooltip
              content={`Download ${convertSnakeToTitle(docName)} PDF document`}
            >
              <Button
                style="icon"
                icon="ri-download-2-line"
                className="text-base"
                href={documents[docName]}
              />
            </Tooltip>
          </div>
        </>
      ))}
  </Card>
);
const LeaseSynopsis = ({ leaseDetailsData }) => {
  const [isUpdatePayCyclePaneOpen, setIsUpdatePayCyclePaneOpen] =
    useState(false);
  const [attachmentPane, setAttachmentPane] = useState(false);

  const { customer } = useCustomerState();

  return (
    <>
      <div className="flex flex-col p-4 space-y-3 bg-gray-100 becca-fixed">
        <PersonalInfo customer={customer} />
        <LeaseInfo leaseDetailsData={leaseDetailsData} />
        <PayoffInfo leaseDetailsData={leaseDetailsData} />
        <CreditInfoCard
          setIsUpdatePayCyclePaneOpen={setIsUpdatePayCyclePaneOpen}
          customer={customer}
        />
        <LeaseDocInfo
          setAttachmentPane={setAttachmentPane}
          documents={leaseDetailsData?.documents}
        />
      </div>

      <UpdatePayCyclePane
        isUpdatePayCyclePaneOpen={isUpdatePayCyclePaneOpen}
        setIsUpdatePayCyclePaneOpen={setIsUpdatePayCyclePaneOpen}
      />

      <AttachmentPaneComponent
        setAttachmentPane={setAttachmentPane}
        attachmentPane={attachmentPane}
      />
    </>
  );
};

export default LeaseSynopsis;

import axios from "axios";

const create = (lease_id, customer_id, payload) =>
  axios.post(
    `/api/v1/customers/${customer_id}/leases/${lease_id}/payment_distributions`,
    payload
  );

const paymentDistributionsApi = { create };

export default paymentDistributionsApi;

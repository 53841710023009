import { ROUTES } from "../constant";

export const PREVIOUS_PAGE_MAPPER = {
  [ROUTES.PERSONAL]: ROUTES.OTP,
  [ROUTES.WORK]: ROUTES.PERSONAL,
  [ROUTES.ADDRESS]: ROUTES.WORK,
  [ROUTES.SUMBIT]: ROUTES.ADDRESS,
};

export const CLASSNAME_MAPPER_FOR_PROGRESS_BAR = {
  [ROUTES.OTP]: "w-1/6 bg-purple-1000",
  [ROUTES.PERSONAL]: "w-2/6 bg-purple-1000",
  [ROUTES.WORK]: "w-3/6 bg-purple-1000",
  [ROUTES.ADDRESS]: "w-4/6 bg-purple-1000",
  [ROUTES.SUMBIT]: "w-5/6 bg-purple-1000",
  [ROUTES.APPROVE]: "w-full bg-green-500",
  [ROUTES.REJECTED]: "bg-gray-300",
  [ROUTES.FAILED]: "bg-gray-300",
  [ROUTES.CREDITLOCK]: "bg-gray-300",
  [ROUTES.ERROR]: "bg-gray-300",
};

import React from "react";

const LeaseItem = ({ item }) => {
  return (
    <div className="flex items-center space-x-4">
      <img
        src={item.woo_image_url}
        className="object-contain"
        width="56px"
        height="56px"
      />
      <div className="font-semibold">{item.product_name}</div>
    </div>
  );
};

export default LeaseItem;

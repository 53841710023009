import React, { useState } from "react";
import { Button, Input, Badge } from "@bigbinary/neetoui";

const AddBadge = ({ label }) => {
  const [addTagsState, setAddTagState] = useState(false);

  const [tagArray, setTagArray] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const onAddtagCheckbox = () => {
    setAddTagState(false);
    if (inputValue) {
      setTagArray(state => [...state, inputValue]);
    }
    setInputValue("");
  };

  const onAddTagClick = () => {
    setAddTagState(true);
  };

  const onDeleteTag = index => {
    setTagArray(state => {
      let updatedState = [...state];
      updatedState.splice(index, 1);
      return updatedState;
    });
  };

  const onInputValueChange = value => {
    setInputValue(value);
  };

  return (
    <>
      {addTagsState ? (
        <Input
          value={inputValue}
          onChange={e => {
            onInputValueChange(e.target.value);
          }}
          suffix={
            <div
              className="text-lg ri-check-line"
              onClick={() => onAddtagCheckbox()}
            />
          }
        />
      ) : (
        <div className="flex space-x-2">
          {tagArray.map((item, index) => {
            return (
              <Badge
                color="purple"
                key={index}
                onClose={() => onDeleteTag(index)}
              >
                {item}
              </Badge>
            );
          })}
          <Button label={label} style="link" onClick={() => onAddTagClick()} />
        </div>
      )}
    </>
  );
};

export default AddBadge;

import React from "react";
import { Button, Collapse } from "@bigbinary/neetoui";

const AmountSplitUp = ({ toggleAmountSplitUp, amountSplitUp }) => {
  return (
    <Collapse role="region" open={amountSplitUp} hidden={!amountSplitUp}>
      <div className="space-y-3 font-semibold">
        <div className="flex items-center justify-between text-gray-500">
          <p>Upcoming Lease Amount (April 2021)</p>
          <p>$100</p>
        </div>
        <div className="flex items-center justify-between text-gray-500">
          <p>Overdue Balance (March 2021)</p>
          <p>$100</p>
        </div>
        <div className="flex items-center justify-between pt-2 text-green-400 border-t border-gray-200">
          <p>Total Amount Due in April</p>
          <p>$100</p>
        </div>
        <div className="flex justify-end">
          <Button
            style="link"
            label="Hide amount splitup"
            onClick={() => toggleAmountSplitUp()}
          />
        </div>
      </div>
    </Collapse>
  );
};

export default AmountSplitUp;

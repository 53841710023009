import React, { useState } from "react";
import classnames from "classnames";
import { Modal } from "@bigbinary/neetoui";

import ConfirmPayment from "./ConfirmPayment";
import { PAYMENT_MODAL_DATA } from "./constants";
import MakePayment from "./MakePayment";

const PaymentModal = ({ paymentModal, setPaymentModal }) => {
  const [stepCount, setStepCount] = useState(0);
  const [paymentInformation, setPaymentInformation] = useState({
    paymentType: "invoice",
    amount: "",
    paymentMethod: "",
    lease: "",
    epoAmount: "",
    upcomingInvoiceAmount: "",
  });

  const modalClose = () => setPaymentModal(false);

  const changeStep = value => {
    setStepCount(state => state + value);
  };

  const renderPage = () => {
    switch (stepCount) {
    case 0:
      return (
        <MakePayment
          setPaymentInformation={setPaymentInformation}
          changeStep={changeStep}
          modalClose={modalClose}
        />
      );
    case 1:
      return (
        <ConfirmPayment
          paymentInformation={paymentInformation}
          modalClose={modalClose}
          changeStep={changeStep}
        />
      );
    }
  };

  const stepperBaseClass =
    "flex items-center justify-center w-12 h-12 mx-2 text-lg font-thin text-gray-300 border border-gray-300 rounded-full";

  return (
    <>
      <Modal
        isOpen={paymentModal}
        size="large"
        onClose={() => modalClose()}
        showCloseButton
        cancelButtonProps={{
          label: "Cancel",
          onClick: () => modalClose(),
        }}
        submitButtonProps={{
          label: "Save",
          onClick: () => modalClose(),
        }}
      >
        <div className="flex justify-center overflow-auto payment-modal-height">
          {renderPage()}
          <div className="fixed bottom-0 left-0 flex justify-center w-full border-t">
            {PAYMENT_MODAL_DATA.map((data, idx) => {
              const { count, stepTitle, stepDescription } = data;
              return (
                <>
                  <div className="flex items-center p-2">
                    <div
                      className={classnames(stepperBaseClass, {
                        "border-purple-1000 text-purple-1000":
                          stepCount === idx,
                        " text-white bg-purple-1000 border border-purple-1000":
                          stepCount > idx,
                      })}
                    >
                      {stepCount <= idx ? (
                        count
                      ) : (
                        <i className="text-2xl text-white ri-check-line"></i>
                      )}
                    </div>
                    <div>
                      <p className="font-medium">{stepTitle}</p>
                      <p className="text-xs text-gray-500">{stepDescription}</p>
                    </div>
                  </div>
                  {PAYMENT_MODAL_DATA.length - 1 != idx && (
                    <span className="flex items-center mx-5 text-gray-400">
                      - - -
                    </span>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PaymentModal;

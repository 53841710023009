import React, { useEffect, useState } from "react";
import { Collapse } from "neetoui";

import { SubHeader } from "@bigbinary/neetoui/layouts";
import { Button, TableLoader, Badge } from "neetoui";
import CardIcon from "common/images/card_logo.svg";

import paymentsApi from "apis/payments";

import { PaymentsStatus, SORT_BY_OPTIONS } from "./constants";
import { DEFAULT_PAGE_SIZE } from "../Leases/constants";
import { formatCurrency } from "utils/styleUtils";
import classNames from "classnames";
import {
  filterOutFailedManualPayments,
  generatePayableTypeLabel,
  liveMode,
} from "common/utils";
import { stripePaymentLink } from "components/Admin/Customer/Tabs/utils";
import { formatDate, formatTime } from "common/dateTimeFormatter";
import {
  generatePaymentStatusBadgeLabel,
  generatePaymentStatusBadgeColor,
  getInvoiceSlug,
} from "common/utils";
import { useParams } from "react-router-dom";

const PaymentHistoryTableHead = () => (
  <div className="grid gap-4 px-2 payment-table">
    <div>LEASE ID</div>
    <div className="text-center">Date</div>
    <div className="text-center">TIME</div>
    <div className="text-center">Amount</div>
    <div className="text-center">TRANSACTION ID</div>
    <div className="text-center">STATUS</div>
    <div className="text-center">Card no</div>
    <div></div>
  </div>
);

const PaymentHistoryRow = ({ payment, liveMode }) => {
  const [isCollapseOpen, setCollapse] = useState(false);
  return (
    <>
      <div
        className={classNames(["grid gap-2 px-2 py-3 payment-table"], {
          "bg-gray-100": isCollapseOpen,
        })}
      >
        <div>{payment?.lease?.slug}</div>
        <div className="text-center">{formatDate(payment?.paid_at)}</div>
        <div className="text-center whitespace-nowrap">
          {formatTime(payment?.created_at)}
        </div>
        <div className="text-center">{formatCurrency(payment?.amount)}</div>
        <div>
          <div className="flex justify-center">
            <Button
              className="underline"
              label={payment?.stripe_id}
              style="link"
              target="_blank"
              href={stripePaymentLink(payment?.stripe_id, liveMode)}
            />
          </div>
        </div>
        <div className="text-center">
          <Badge color={generatePaymentStatusBadgeColor(payment?.status)}>
            {generatePaymentStatusBadgeLabel(payment?.status)}
          </Badge>
        </div>
        <div>
          <div className="flex justify-center">
            <div className="flex space-x-2">
              <img src={CardIcon} width="40px" height="40px" />
              <p className="font-normal text-gray-600">
                {payment?.payment_method?.details?.card?.last4}
              </p>
            </div>
          </div>
        </div>
        <div className="text-center">
          <p
            className={classNames(["pl-1 cursor-pointer"])}
            onClick={() => setCollapse(state => !state)}
          >
            {isCollapseOpen ? (
              <i className="ri-arrow-up-s-line"></i>
            ) : (
              <i className="ri-arrow-down-s-line"></i>
            )}
          </p>
        </div>
      </div>
      <Collapse open={isCollapseOpen}>
        <div className="px-3 py-1 bg-gray-100 ">
          <table>
            <thead className="table-header-group">
              <th className="px-4">Type</th>
              <th className="px-4">Invoice</th>
              <th className="px-4">Amount</th>
              <th className="px-4">Tax</th>
              <th className="px-4">Payment ID</th>
            </thead>
            <tbody>
              {payment.payable_payments.map(payable_payment => (
                <tr key={payable_payment.id}>
                  <td className="px-4">
                    <p>
                      {generatePayableTypeLabel(payable_payment.payable_type)}
                    </p>
                  </td>
                  <td className="px-4">
                    <p>{getInvoiceSlug(payable_payment)}</p>
                  </td>
                  <td className="px-4">
                    <p>{formatCurrency(payable_payment.amount)}</p>
                  </td>
                  <td className="px-4">
                    <p>{formatCurrency(payable_payment.tax)}</p>
                  </td>
                  <td className="px-4">
                    <p>{payable_payment.slug}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Collapse>
    </>
  );
};

const PaymentHistoryTableBody = ({ payments, liveMode }) => (
  <>
    {payments &&
      filterOutFailedManualPayments(payments).map(payment => (
        <PaymentHistoryRow
          payment={payment}
          key={payment.id}
          liveMode={liveMode}
        />
      ))}
  </>
);

const PaymentHistory = () => {
  const { id } = useParams();
  const [status, setStatus] = useState(PaymentsStatus.IDLE);
  const [payments, setPayments] = useState(null);
  const [paymentsCount, setPaymentsCount] = useState(null);

  const [searchString, setSearchString] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const sortFunction = {};

  const fetchPayments = async () => {
    try {
      setStatus(PaymentsStatus.LOADING);
      const {
        data: { payments, payments_count },
      } = await paymentsApi.payments(id);

      setPayments(payments);
      setPaymentsCount(payments_count);
      setStatus(PaymentsStatus.SUCCESS);
    } catch (error) {
      setStatus(PaymentsStatus.ERROR);
    }
  };

  useEffect(() => {
    !payments && fetchPayments();
  }, []);

  return (
    <div>
      <SubHeader
        className="mt-10"
        searchProps={{
          value: searchString,
          onChange: e => setSearchString(e.target.value),
          clear: () => setSearchString(""),
        }}
        sortProps={{
          options: SORT_BY_OPTIONS,
          onClick: sortFunction,
        }}
        paginationProps={{
          count: paymentsCount,
          pageNo: pageIndex,
          pageSize: DEFAULT_PAGE_SIZE,
          navigate: index => setPageIndex(index),
        }}
      />
      <div className="my-4">
        <div>
          <PaymentHistoryTableHead />
          {status === PaymentsStatus.LOADING ? (
            <TableLoader rows={4} columns={8} />
          ) : (
            <PaymentHistoryTableBody
              payments={payments}
              liveMode={liveMode()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;

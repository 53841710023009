import React from "react";
import ErrorIcon from "common/images/error";
import { useLocation } from "react-router-dom";
import { DEFAULT_ERROR_MESSAGE } from "../constant";

const CreditApplicationError = () => {
  const error = new URLSearchParams(useLocation().search).get("error");

  return (
    <>
      <div className="px-4 mt-4">
        <p className="w-5/6 mb-6 text-lg">
          {`${error || DEFAULT_ERROR_MESSAGE}`}
        </p>
        <p className="mb-4 text-sm text-gray-500">
          Don’t worry — this did not affect your credit score.
        </p>
        <div className="flex justify-center w-full my-8">
          <img width="150px" height="150px" src={ErrorIcon} />
        </div>
        <p className="mt-4 text-sm text-gray-500">
          For more details, you can contact us.
        </p>
      </div>
    </>
  );
};

export default CreditApplicationError;

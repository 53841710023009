import React, { useState } from "react";
import { Toastr } from "neetoui";
import { Button } from "@bigbinary/neetoui";
import { useSignupState } from "contexts/signup";
import authenticationApi from "apis/customers/authentication";
import { formatCurrency } from "utils/styleUtils";
import PaymentSuccessIcon from "common/images/PaymentSuccess";
import BeingRedirected from "components/Common/BeingRedirected";

const CreditApproved = () => {
  const [redirectURI, setRedirectURI] = useState(null);
  const signupDetails = useSignupState();

  const onNextClick = async () => {
    const { auth_token: authToken, email } = signupDetails?.personalInfo;
    try {
      const {
        data: { redirect_uri },
      } = await authenticationApi.loginWithoutPassword({
        customer: { email, authToken },
      });

      setRedirectURI(redirect_uri);

      window.location.href = redirect_uri;
    } catch (error) {
      Toastr.error(error.response?.data?.error);
      logger.error(error);
    }
  };

  const calculatePerMonthCredit = () => {
    const creditLimit = signupDetails.personalInfo.credit_limit;

    if (creditLimit === undefined || creditLimit === null || creditLimit === 0)
      return 0;

    return (signupDetails.personalInfo.credit_limit * 2) / 17;
  };

  if (redirectURI) {
    return (
      <div className="h-screen">
        <BeingRedirected />
      </div>
    );
  }

  return (
    <>
      <div className="px-4 mt-4">
        <p className="w-5/6 mb-2 text-base text-gray-800">
          Congratulations{" "}
          {signupDetails ? signupDetails.personalInfo.first_name : "User"}!
        </p>
        <p className="mb-4 text-lg text-gray-800">
          You have been pre-approved for{" "}
          <span className="font-bold">
            {formatCurrency(signupDetails.personalInfo.credit_limit)}
          </span>{" "}
          or{" "}
          <span className="font-bold">
            {formatCurrency(calculatePerMonthCredit())}/month
          </span>
          .
        </p>
        <div className="flex justify-center w-full my-8">
          <img width="150px" height="150px" src={PaymentSuccessIcon} />
        </div>
        <Button
          label="Continue Shopping"
          fullWidth={true}
          onClick={() => onNextClick()}
        />
      </div>
    </>
  );
};

export default CreditApproved;

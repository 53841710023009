import React, { useState } from "react";
import { Radio, Button } from "neetoui";
import { Form, Formik } from "formik";

const KiqQuestions = ({ kbaQuestions, handleSubmit }) => {
  const [initialValues, setIntialValues] = useState({});

  const handleOptionChange = (evt, name) => {
    const values = { ...initialValues };
    values[name] = evt.target.value;
    setIntialValues(values);
  };

  return (
    <div className="px-4 mt-8">
      <div>
        <p className="w-5/6 text-lg">You are almost there.</p>
        <p className="w-5/6 text-lg">
          One last thing, please answer following questions.
        </p>

        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ errors }) => (
            <Form>
              {kbaQuestions.map((question, questionIndex) => {
                let name = question.questionType;
                return (
                  <div className="relative" key={questionIndex}>
                    <Radio
                      stacked
                      key={questionIndex}
                      label={question["questionText"]}
                      name={name}
                      error={errors.name}
                    >
                      {question["questionSelect"]["questionChoice"].map(
                        (item, index) => {
                          return (
                            <Radio.Item
                              key={index}
                              label={item}
                              value={item}
                              onChange={evt => handleOptionChange(evt, name)}
                              checked={initialValues[name] == item}
                              className="border-gray-200 border-b-1 border-t-1"
                            />
                          );
                        }
                      )}
                    </Radio>
                  </div>
                );
              })}
              <Button label="Next" type="submit" />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default KiqQuestions;

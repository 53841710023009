import React, { useEffect, useState } from "react";
import { Label, Callout } from "neetoui";
import CardIcon from "common/images/card_logo.svg";

import paymentMethodsApi from "apis/payment-methods";
import preferencesApi from "apis/preferences";

import PaymentHistory from "./PaymentHistory";
// import BankPayments from "./BankPayment";
import CardPayments from "./CardPayment";
import CardForm from "./CardForm";

import { PaymentsStatus } from "./constants";
import { useParams } from "react-router-dom";
import { useCustomer } from "contexts/customer";
import useDocumentTitle from "hooks/useDocumentTitle";

const PaymentMethodHeader = () => (
  <div className="flex justify-between mx-2 my-4">
    <Label className="font-bold uppercase">Payment method information</Label>
  </div>
);

const PaymentMethodInfo = () => {
  const { id } = useParams();
  const [, setStatus] = useState(PaymentsStatus.IDLE);
  const [cardPaymentMethods, setCardPaymentMethods] = useState([]);
  const [, setBankPaymentMethods] = useState([]);
  const [cardBeingEdited, setCardBeingEdited] = useState(null);

  const [addCardPane, setAddCardPane] = useState(false);

  const [{ isCardAdded }] = useCustomer();

  const fetchPaymentMethods = async () => {
    try {
      setStatus(PaymentsStatus.LOADING);
      const {
        data: { payment_methods },
      } = await paymentMethodsApi.all(id);
      setStatus(PaymentsStatus.SUCCESS);
      let bank_payments = payment_methods.filter(item => {
        return item.payment_method_type == "bank";
      });
      let card_payments = payment_methods.filter(item => {
        return item.payment_method_type == "card";
      });
      setBankPaymentMethods(bank_payments);
      setCardPaymentMethods(card_payments);
    } catch (error) {
      setStatus(PaymentsStatus.ERROR);
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, [isCardAdded]);

  return (
    <div className="p-2 border border-gray-200 rounded-md">
      <PaymentMethodHeader />
      <CardPayments
        cards={cardPaymentMethods?.filter(pm => pm.details?.card)}
        setEditCardPane={setAddCardPane}
        setCardBeingEdited={setCardBeingEdited}
        getCardDetails={fetchPaymentMethods}
      />
      {/* <BankPayments bank={bankPaymentMethods} /> */}
      <CardForm
        setAddCardPane={setAddCardPane}
        addCardPane={addCardPane}
        CardIcon={CardIcon}
        fetchPaymentMethods={fetchPaymentMethods}
        cardBeingEdited={cardBeingEdited}
        setCardBeingEdited={setCardBeingEdited}
      />
    </div>
  );
};

const Payments = ({ customer }) => {
  const { id } = useParams();
  const [isAutopayOn, setIsAutopayOn] = useState(false);

  useDocumentTitle(`Payments for customer ${id}`);

  useEffect(() => {
    customer && fetchPreferences();
  }, []);

  const fetchPreferences = async () => {
    try {
      const {
        data: { preferences },
      } = await preferencesApi.preferences(customer.id);
      setIsAutopayOn(preferences.autopay_enabled);
    } catch (error) {
      logger.error(error);
    }
  };

  const activateAutopay = async enabled => {
    try {
      const payload = { preference: { autopay_enabled: enabled } };
      await preferencesApi.update(customer.id, payload);
      await fetchPreferences();
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <div className="flex becca-main-container">
      <div className="px-10 pt-8 becca-details">
        {
          <Callout
            style={isAutopayOn ? "info" : "danger"}
            className="flex items-center py-2 mb-2 border"
            icon={<></>}
          >
            <div className="flex items-center">
              <span className="text-gray-700">
                {`Autopay has been ${
                  isAutopayOn ? "enabled" : "disabled"
                } for ${customer?.name}`}
                .
              </span>
              <span
                onClick={() => activateAutopay(!isAutopayOn)}
                className="pl-1 font-semibold underline cursor-pointer"
              >
                {`Click here to ${isAutopayOn ? "deactivate" : "activate"}`}.
              </span>
            </div>
          </Callout>
        }
        <PaymentMethodInfo />
        <PaymentHistory />
      </div>
    </div>
  );
};

export default Payments;

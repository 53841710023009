import axios from "axios";

const create = payload => axios.post(`api/v1/addresses`, payload);

const update = (addressId, payload) =>
  axios.put(`api/v1/addresses/${addressId}`, payload);

const addressesApi = {
  create,
  update,
};

export default addressesApi;

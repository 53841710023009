import React from "react";
import { isMobile } from "react-device-detect";
import CardIcon from "common/images/card_logo.svg";
import { DEBIT_CARD_LIST } from "components/MyAccount/constants";

const SavedPaymentMethods = ({ selectCard }) => (
  <div>
    <p className="font-semibold text-gray-600 uppercase text-md">
      saved payment methods
    </p>
    {DEBIT_CARD_LIST.map((cardData, index) => {
      return (
        <div
          key={index}
          className="flex justify-between p-2 pb-4 mt-4 space-x-4 border-b border-gray-200 cursor-pointer"
        >
          <div className="flex justify-center space-x-6">
            <img src={CardIcon} width="40px" height="40px" />
            <p
              className="text-lg font-semibold text-purple-1000"
              onClick={() => selectCard()}
            >
              {cardData.label}
            </p>
          </div>
          {!isMobile && (
            <div className="text-2xl text-gray-200 ri-arrow-right-s-line"></div>
          )}
        </div>
      );
    })}
  </div>
);

export default SavedPaymentMethods;

import * as Yup from "yup";

const PHONE_NUMER_REG_EX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const CUSTOMER_DATA_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().email("Incorrect Email").required("Email is required"),
  phone_number: Yup.string()
    .matches(PHONE_NUMER_REG_EX, "Mobile number is not valid")
    .required("Phone number is required"),
  street_number: Yup.string().required("Street Address is required"),
  area: Yup.string(),
  city: Yup.string().required("City is required"),
  state: Yup.object()
    .nullable()
    .shape({
      label: Yup.string().required("State is required"),
      value: Yup.string().required("State is required"),
    }),
  zip_code: Yup.string()
    .test("len", "Please enter valid US zip code.", val => val.length === 5)
    .required("Zip code is required"),
});

export const CUSTOMER_INITIAL_DATA = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  address: {
    id: "",
    area: "",
    city: "",
    state: "",
    street_number: "",
  },
  zip_code: "",
  phone: {
    id: "",
    phone_number: "",
  },
};

export const NUMBER_OF_PAYMENTS = [
  { label: "One", value: "One" },
  { label: "Two", value: "Two" },
  { label: "Three", value: "Three" },
];

export const MONTHLY_INCOME_INITIAL_VALUE = {
  monthly_income: "",
};

export const UPDATE_PAY_CYCLE_VALIDATION_SCHEMA = Yup.object({
  monthly_income: Yup.object()
    .shape({
      label: Yup.string().required("Income is required"),
      value: Yup.string().required("Income is required"),
    })
    .required("Income is required")
    .nullable(),
});

export const PAY_CYCLE = [
  {
    label: "Weekly",
    value: "Weekly",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
  {
    label: "Every Other Week",
    value: "Every Other Week",
  },
  {
    label: "Twice A Month",
    value: "Twice A Month",
  },
];

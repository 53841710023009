import AdditionalInfo from "components/SignUp/Registration/AdditionalInfo";
import AddressInfo from "components/SignUp/Registration/AddressInfo";
import Personal from "components/SignUp/Registration/Personal";
import Submit from "components/SignUp/Registration/Submit";
import WorkInfo from "components/SignUp/Registration/WorkInfo";
import FraudOtp from "components/SignUp/Registration/FraudOtp";
import CreditApproved from "components/SignUp/Results/CreditApproved";
import CreditRejected from "components/SignUp/Results/CreditRejected";
import ApplicationFailed from "components/SignUp/Results/ApplicationFailed";
import CreditApplicationError from "components/SignUp/Results/CreditApplicationError";
import CreditLock from "components/SignUp/Results/CreditLock";
import PasswordReset from "components/Authentication/ResetPassword";
import CustomerLogin from "components/Authentication/CustomerLogin";
import Login from "components/Authentication/Login";
import PaymentTest from "components/Payments";
import MyProfile from "components/MyAccount/MyProfile";
import Payment from "components/MyAccount/Payment";
import NewPassword from "components/MyAccount/MyProfile/NewPassword";
import PaymentListing from "components/MyAccount/PaymentListing";
import ExperianEmulation from "components/Admin/ExperianEmulation";
import Collections from "components/Admin/Collections";
import Agents from "components/Admin/Agents";
import Customers from "components/Admin/Customer";
import PasswordEdit from "components/Admin/Account/Passwords/Edit";
import Profile from "components/Admin/Account/Profile";

export const LOGIN_ROUTE = "/login";
export const CUSTOMERS_ROUTE = "/customers";

// TODO : Remove this when we clean up Admin pages
export const ORDERS_ROUTE = "/orders/orders";
export const CANCELLATION_ROUTE = "/orders/cancellations";
export const RETURN_ROUTE = "/orders/returns";
export const COMPONENT_ROUTE = "/components";
export const PRODUCT_IMPORT_ROUTE = "/product-import";
export const TAGS_ROUTE = "/inventory/tags";
export const PRODUCTS_ROUTE = "/inventory/products";
export const SETTINGS_ROUTE = "/settings";
export const CATEGORIES_ROUTE = "/inventory/categories";
export const ATTRIBUTES_ROUTE = "/inventory/attributes";
export const PRODUCT_DATA_ROUTE = "/inventory/product-data";

export const SIGNUP_ROUTES = [
  {
    component: Personal,
    path: "/flow/register/personal-info",
    titleKey: "personalInfo",
  },
  {
    component: WorkInfo,
    path: "/flow/register/work-info",
    titleKey: "workInfo",
  },
  {
    component: AddressInfo,
    path: "/flow/register/address-info",
    titleKey: "addressInfo",
  },
  {
    component: Submit,
    path: "/flow/register/submit",
    titleKey: "submit",
  },
  {
    component: FraudOtp,
    path: "/flow/register/otp-verification",
    titleKey: "fraudOtp",
  },
  {
    component: AdditionalInfo,
    path: "/flow/register/additional-info",
    titleKey: "additionalInfo",
  },
  {
    component: CreditApproved,
    path: "/flow/register/result/approved/:limit?",
    titleKey: "creditApproved",
  },
  {
    component: CreditRejected,
    path: "/flow/register/result/rejected",
    titleKey: "creditRejected",
  },
  {
    component: ApplicationFailed,
    path: "/flow/register/result/failed",
    titleKey: "applicationFailed",
  },
  {
    component: CreditApplicationError,
    path: "/flow/register/result/error",
    titleKey: "applicationError",
  },
  {
    component: CreditLock,
    path: "/flow/register/result/locked",
    titleKey: "creditProfileLocked",
  },
];

export const PUBLIC_ROUTES = [
  {
    component: PasswordReset,
    path: "/passwords/new",
    titleKey: "passwordReset",
    exact: true,
  },
  {
    component: Login,
    path: "/staff/login",
    titleKey: "staffLogin",
    exact: true,
  },
  {
    component: CustomerLogin,
    path: "/login",
    titleKey: "customerLogin",
    exact: false,
  },
];

export const CUSTOMER_ROUTES = [
  {
    component: MyProfile,
    path: "/my-account/my-profile",
    titleKey: "myProfile",
    exact: true,
  },
  {
    component: PaymentListing,
    path: "/my-account/payment-methods",
    titleKey: "paymentMethods",
    exact: true,
  },
  {
    component: Payment,
    path: "/my-account/payment/new",
    titleKey: "newPayment",
    exact: true,
  },
  {
    component: NewPassword,
    path: "/my-account/change-password",
    titleKey: "newPassword",
    exact: true,
  },
  {
    component: PaymentTest,
    path: "/payment",
    titleKey: "payment",
    exact: true,
  },
];

export const STAFF_ROUTES = [
  {
    component: Agents,
    path: "/Agents",
    titleKey: "agents",
    exact: true,
  },
  {
    component: Collections,
    path: "/collections",
    titleKey: "collections",
    exact: true,
  },
  {
    component: Customers,
    path: CUSTOMERS_ROUTE,
    titleKey: "customers",
    exact: false,
  },
  {
    component: PasswordEdit,
    path: "/my/password/edit",
    titleKey: "changePassword",
    exact: true,
  },
  {
    component: Profile,
    path: "/my/profile",
    titleKey: "profile",
    exact: true,
  },
  {
    component: ExperianEmulation,
    path: "/experian-emulation",
    titleKey: "experianEmulation",
    exact: true,
  },
];

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Label, Avatar, PageLoader } from "neetoui";
import AddNote from "./AddNote";
import customerNotesApi from "apis/notes";
import useDocumentTitle from "hooks/useDocumentTitle";

const Notes = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);

  useDocumentTitle(`Notes for customer ${id}`);

  const fetchNotes = async () => {
    try {
      const {
        data: { customer_notes },
      } = await customerNotesApi.list(id);
      setNotes(customer_notes);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  return (
    <div className="flex becca-main-container">
      <div className="becca-details">
        <div className="p-8">
          <AddNote setNotes={setNotes} notes={notes} />
          <div className="border-b border-gray-100" />
          <div className="mt-6">
            <Label className="mb-4">Previous Notes</Label>
            <>
              {loading ? (
                <div className="flex items-center justify-center w-full h-full mt-10">
                  <PageLoader />
                </div>
              ) : (
                notes.map((note, idx) => {
                  return (
                    <div className="grid h-24 grid-cols-12" key={idx}>
                      <div className="flex flex-col items-center col-span-1">
                        <Avatar size={36} contact={{ name: note.user_name }} />
                        {idx !== notes.length - 1 && (
                          <div className="h-full border border-gray-200" />
                        )}
                      </div>
                      <div className="col-span-11 ml-4">
                        <p className="font-normal text-gray-600">
                          {note.content}
                        </p>
                        <p className="text-gray-600">{`${note.user_name} on ${note.created_date} at ${note.created_time}`}</p>
                      </div>
                    </div>
                  );
                })
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notes;

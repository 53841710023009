import React from "react";
import { Input, Select, Pane } from "neetoui";
import { CREDIT_TYPES } from "../../Tabs/Leases/LeaseDetails/constants";

export const CreditPaymentPane = ({
  creditPane,
  setCreditPane,
  creditType,
  onCreditTypeChange,
}) => (
  <Pane
    isOpen={creditPane}
    title="Credit Account"
    showFooter
    onClose={() => setCreditPane(false)}
    cancelButtonProps={{
      label: "Cancel",
      onClick: () => {
        setCreditPane(false);
      },
    }}
    submitButtonProps={{
      label: "Submit",
      onClick: () => {
        setCreditPane(false);
      },
    }}
  >
    <div className="pb-20 mx-6 space-y-4 overflow-auto">
      <Select
        value={creditType}
        label="Credit Type"
        options={CREDIT_TYPES}
        onChange={value => onCreditTypeChange(value)}
      />
      {creditType == CREDIT_TYPES[0] ? (
        <Input value="__3_" label="Number of payments to be skipped" />
      ) : (
        <Input value="__$20_" label="Credit Amount" />
      )}
      <Input
        value="__Karthik’s new debit card is yet to be issued._"
        label="Reason"
      />
    </div>
  </Pane>
);

import React, { useState } from "react";
import { Button } from "@bigbinary/neetoui";
import paymentsApi from "apis/customers/payments";
import { useHistory } from "react-router-dom";
import { formatCurrency } from "utils/styleUtils";

const PaymentConfirmModal = ({
  paymentInfo,
  paymentConfirm,
  setPaymentConfirm,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        lease_id: paymentInfo.lease.id,
        payment_method_id: paymentInfo.method.id,
        payment_type: paymentInfo.type,
        amount: paymentInfo.amount,
        payment_date: paymentInfo.date,
        epo_amount: paymentInfo.epoAmount,
        upcoming_invoice_amount: paymentInfo.upcomingInvoiceAmount,
      };

      await paymentsApi.create(payload);
      history.push(
        `/my-account/payment/${paymentInfo.lease.slug}?success=true`
      );
    } catch (error) {
      logger.error(error);
      history.push(
        `/my-account/payment/${paymentInfo.lease.slug}?success=false`
      );
    } finally {
      setLoading(false);
      setPaymentConfirm(false);
    }
  };

  if (!paymentConfirm) {
    return null;
  }

  return (
    <div className="absolute bottom-0 z-40 bg-white border border-gray-200 shadow-lg w-96 rounded-t-2xl ">
      <div className="p-4 space-y-4">
        <div className="flex items-center justify-end">
          <Button
            className="text-2xl"
            style="text"
            icon="ri-close-line"
            onClick={() => setPaymentConfirm(false)}
          />
        </div>
        <p className="text-lg font-medium">
          Process payment of
          <span className="font-semibold text-purple-1000">
            &nbsp;{formatCurrency(paymentInfo.amount)}&nbsp;
          </span>
          for lease
          <span className="font-semibold text-purple-1000">
            &nbsp;{paymentInfo.lease?.slug}&nbsp;
          </span>
          using debit card ending 9876.
        </p>
        <Button
          fullWidth
          disabled={!paymentInfo.method}
          onClick={() => handleSubmit()}
          loading={loading}
          label="Submit"
        />
        <Button
          style="text"
          label="Cancel"
          onClick={() => setPaymentConfirm(false)}
        />
      </div>
    </div>
  );
};

export default PaymentConfirmModal;

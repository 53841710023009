import React from "react";
import { Button } from "@bigbinary/neetoui";
import { useHistory } from "react-router-dom";

import HeaderIcon from "./NewPayment/Header";

const PayNow = () => {
  const history = useHistory();

  const onPayNow = () => {
    history.push("/payment/:customerId/payment");
  };

  return (
    <div className="px-4 py-6 space-y-4 bg-white border border-gray-200 rounded-md shadow-md">
      <HeaderIcon />
      <p className="space-x-1 text-lg font-medium text-gray-800 ">
        You have an overdue balance of&nbsp;
        <span className="font-semibold">$100</span>
        &nbsp;for your lease
        <span className="font-semibold">#3430BH</span>
        &nbsp;due to an autopayment failure on 3rd March 2021.
      </p>
      <div className="flex justify-start py-2 space-x-4">
        <Button label="Pay Now" onClick={() => onPayNow()} />
      </div>
      <div className="text-xs text-gray-500 underline">View Past Payments</div>
    </div>
  );
};

export default PayNow;

import axios from "axios";

const fetchCreditStatus = () =>
  axios.get(`/api/v1/customers/recent_credit_application`);

const creditApplicationsApi = {
  fetchCreditStatus,
};

export default creditApplicationsApi;

import React, { useState } from "react";
import { Collapse } from "neetoui";
import { formatCurrency } from "utils/styleUtils";
import CardIcon from "common/images/card_logo.svg";
import classNames from "classnames";
import {
  generatePaymentStatusLabel,
  generatePayableTypeLabel,
  getInvoiceSlug,
} from "common/utils";
import { formatDate } from "common/dateTimeFormatter";

const PaymentItem = ({ payment }) => {
  const [isCollapseOpen, setCollapse] = useState(false);
  const isPaymentSuccessful = payment?.status == "successful" ? true : false;

  return (
    <div
      className={classNames(
        "p-4 my-4 space-y-2 border rounded-md lg:max-w-6xl lg:mx-auto",
        {
          "border-gray-200": isPaymentSuccessful,
          "border-red-600": !isPaymentSuccessful,
        }
      )}
    >
      <div className="space-y-2">
        <div className="flex justify-between">
          <p
            className={classNames({
              "text-red-600": !isPaymentSuccessful,
              "text-green-600": isPaymentSuccessful,
            })}
          >
            {generatePaymentStatusLabel(payment)}
          </p>

          <div>
            <p className="font-semibold text-gray-700">
              {formatCurrency(payment?.amount)}
            </p>
          </div>
        </div>
        <div>
          <div className="flex justify-between">
            <div>
              {formatDate(payment?.paid_at, {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })}
            </div>
            <div className="flex justify-start space-x-1">
              <img src={CardIcon} width="40px" height="40px" />
              <p>Card Ending {payment?.payment_method?.details.card?.last4}</p>
              <p
                className={classNames(["pl-1 cursor-pointer"], {
                  "border-gray-200": isPaymentSuccessful,
                  "border-red-600": !isPaymentSuccessful,
                })}
                onClick={() => setCollapse(state => !state)}
              >
                {isCollapseOpen ? (
                  <i className="ri-arrow-up-s-line" />
                ) : (
                  <i className="ri-arrow-down-s-line" />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Collapse open={isCollapseOpen}>
        <table>
          <thead className="table-header-group">
            <th className="px-4">Type</th>
            <th className="px-4">Invoice</th>
            <th className="px-4">Amount</th>
            <th className="px-4">Tax</th>
            <th className="px-4">Payment ID</th>
          </thead>
          <tbody>
            {payment.payable_payments.map(payable_payment => (
              <tr key={payable_payment.id}>
                <td className="px-2">
                  <p>
                    {generatePayableTypeLabel(payable_payment.payable_type)}
                  </p>
                </td>
                <td className="px-4">
                  <p>{getInvoiceSlug(payable_payment)}</p>
                </td>
                <td className="px-4">
                  <p>{`${formatCurrency(payable_payment.amount)}`}</p>
                </td>
                <td className="px-4">
                  <p>{` ${formatCurrency(payable_payment.tax)}`}</p>
                </td>
                <td className="px-4">
                  <p>{payable_payment.slug}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Collapse>
    </div>
  );
};

export default PaymentItem;

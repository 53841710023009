import React, { useState } from "react";
import { Modal, Button } from "@bigbinary/neetoui";

const ImageCarousel = ({ images }) => {
  const [imageArray] = useState(images);

  const [currentImage, setCurrentImage] = useState({
    index: 0,
    src: imageArray[0],
  });

  const [imageModal, setImageModal] = useState(false);

  const onImageChange = () => {
    setCurrentImage(state => {
      return state.index == imageArray.length - 1
        ? {
          index: 0,
          src: imageArray[0],
        }
        : {
          index: state.index + 1,
          src: imageArray[state.index + 1],
        };
    });
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <Button
          className="leading-none cursor-pointer"
          style="text"
          icon="text-3xl ri-arrow-left-s-line"
          onClick={() => onImageChange()}
        />
        <img
          className="cursor-pointer"
          src={currentImage.src}
          width="100px"
          height="100px"
          onClick={() => setImageModal(true)}
        />
        <Button
          className="leading-none cursor-pointer"
          style="text"
          icon="text-3xl ri-arrow-right-s-line"
          onClick={() => onImageChange()}
        />
      </div>
      <Modal
        isOpen={imageModal}
        size="small"
        onClose={() => setImageModal(false)}
        showCloseButton
        cancelButtonProps={{
          label: "Cancel",
          onClick: () => setImageModal(false),
        }}
      >
        <div className="flex items-center justify-center w-full h-full">
          <Button
            className="leading-none cursor-pointer"
            style="text"
            icon="text-3xl ri-arrow-left-s-line"
            onClick={() => onImageChange()}
          />
          <img
            className="object-cover"
            src={currentImage.src}
            height="400px"
            width="400px"
            onClick={() => setImageModal(true)}
          />
          <Button
            className="leading-none cursor-pointer"
            style="text"
            icon="text-3xl ri-arrow-right-s-line"
            onClick={() => onImageChange()}
          />
        </div>
      </Modal>
    </>
  );
};

export default ImageCarousel;

import React, { useState, useRef } from "react";
import { Label, Button } from "@bigbinary/neetoui";

import Image2 from "common/images/furniture/image-2.jpeg";
import Image1 from "common/images/furniture/image-1.jpeg";
import Image3 from "common/images/furniture/image-3.jpeg";
import Image4 from "common/images/furniture/image-4.jpeg";
import ImageUpload from "components/Common/ImageUpload";

const ImagesSection = () => {
  const productImageRef = useRef(null);
  const [, setProductImg] = useState({
    src: "",
    alt: "",
  });

  const onProductImageChange = e => {
    if (e.target.files[0]) {
      setProductImg({
        src: URL.createObjectURL(e.target.files[0]),
        alt: e.target.files[0].name,
      });
    }
  };

  const onProductImageUpload = () => {
    productImageRef.current.click();
  };

  return (
    <div className="flex py-4 mt-4 border border-gray-200 divide-x">
      <div className="w-1/2 px-4">
        <div className="flex justify-between">
          <Label className="font-semibold text-gray-400">Cover Image</Label>
        </div>
        <div className="flex items-center justify-center h-full">
          <ImageUpload className="items-center" />
        </div>
      </div>
      <div className="w-1/2 px-4 ">
        <div className="flex justify-between">
          <Label className="font-semibold text-gray-400">Product Media</Label>
          <input
            ref={productImageRef}
            type="file"
            onChange={() => onProductImageChange()}
            className="invisible"
          />
          <Button
            style="icon"
            icon="ri-add-line"
            onClick={() => onProductImageUpload()}
          />
        </div>
        <div className="flex flex-wrap">
          <div>
            <img
              src={Image1}
              className="object-contain"
              width="150px"
              height="150px"
            />
          </div>
          <div>
            <img
              src={Image2}
              className="object-contain"
              width="150px"
              height="150px"
            />
          </div>
          <div>
            <img
              src={Image3}
              className="object-contain"
              width="150px"
              height="150px"
            />
          </div>
          <div>
            <img
              src={Image4}
              className="object-contain"
              width="150px"
              height="150px"
            />
          </div>
          <div>
            <img
              src={Image1}
              className="object-contain"
              width="150px"
              height="150px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagesSection;

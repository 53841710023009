import React from "react";

import { useHistory } from "react-router";
import Lease from "./Lease";

const List = ({ paymentInfo }) => {
  const history = useHistory();

  const leaseClick = slug => {
    history.push(`/myaccount/payment/${slug}`);
  };

  return (
    <div className="p-4 overflow-y-scroll">
      <div>
        <p className="text-2xl text-gray-800">Make a payment</p>
        <p className="text-sm text-gray-600">
          {paymentInfo?.leaseList && paymentInfo?.leaseList.length > 0
            ? `Select a lease you would like to make payment for.`
            : `You don't have any leases make a payment.`}
        </p>
      </div>
      <div className="mt-2 space-y-3">
        {paymentInfo?.leaseList?.map(lease => (
          <Lease key={lease.id} lease={lease} leaseClick={leaseClick} />
        ))}
      </div>
    </div>
  );
};

export default List;

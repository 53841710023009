import React from "react";
import { Radio } from "neetoui";
import { Field } from "formik";

const NixComponent = ({ onChange, nix }) => (
  <Field>
    {({ field }) => (
      <Radio label="Nix out overdue balance as a whole?" stacked>
        <Radio.Item
          label="Yes"
          checked={nix}
          {...field}
          onChange={date => {
            date && onChange(true);
          }}
        />
        <Radio.Item
          label="No"
          checked={!nix}
          {...field}
          onChange={date => {
            date && onChange(false);
          }}
        />
      </Radio>
    )}
  </Field>
);

export default NixComponent;

import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import { PageLoader, Label, Toastr, Button, Checkbox } from "neetoui";
import { Header } from "neetoui/layouts";
import { Input as FormikInput, Select } from "neetoui/formik";
import formValidationSchema from "./formValidationSchema";
import { getOptions } from "utils/objectFormatter";
import qaServiceApi from "apis/qa-service";
import {
  EKATA_OPTIONS,
  PRECISE_ID_OPTIONS,
  CREDIT_APPROVAL_OPTIONS,
  FAILURE_OPTIONS,
} from "./constants";
import { liveMode } from "common/utils";
import classNames from "classnames";

const EmulatorForm = () => {
  const initialStates = {
    ekata: null,
    preciseId: null,
    credit: null,
    creditLimit: 0,
    emulationEnabled: false,
    failure: null,
  };
  const [loading, setLoading] = useState(false);
  const [creditLimit, showCreditLimit] = useState(false);
  const [initialValues, setInitialValues] = useState(initialStates);
  const validationSchema = formValidationSchema.qaForm;

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const { data } = await qaServiceApi.fetchSettings();
      if (Object.entries(data.settings).length > 0) {
        const settings = data.settings;
        setFormValues(settings);
      }
    } catch (error) {
      logger.error(error);
    }
  };

  const setFormValues = settings => {
    const payload = {
      ekata: {
        value: settings.ekata,
        label: EKATA_OPTIONS[settings.ekata],
      },
      preciseId: {
        value: settings.precise_id,
        label: PRECISE_ID_OPTIONS[settings.precise_id],
      },
      credit: {
        value: settings.credit,
        label: CREDIT_APPROVAL_OPTIONS[settings.credit],
      },
      creditLimit: settings.credit_limit,
      emulationEnabled: settings.emulation_enabled,
      failure: {
        value: settings.failure,
        label: FAILURE_OPTIONS[settings.failure],
      },
    };
    showCreditLimit(settings.credit === "mock_approve");
    setInitialValues(payload);
  };

  const handleSubmit = async details => {
    try {
      setLoading(true);
      const payload = {
        ekata: details.ekata.value,
        precise_id: details.preciseId.value,
        credit: details.credit.value,
        credit_limit: details.creditLimit,
        emulation_enabled: details.emulationEnabled,
        failure: details.failure.value,
      };
      await qaServiceApi.updateSettings(payload);
      setFormValues(payload);
      Toastr.success("Settings updated successfully");
    } catch (error) {
      Toastr.error(error.response?.data?.error);
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {};

  const onCreditApprovalChange = (obj, setFieldValue) => {
    setFieldValue("credit", obj);
    showCreditLimit(obj.value === "mock_approve");
  };

  const onCheckboxClick = (emulationEnabled, setFieldValue) => {
    setFieldValue("emulationEnabled", !emulationEnabled);
  };

  const setEkatavalue = (obj, setFieldValue) => {
    setFieldValue("ekata", obj);

    if (obj.value == "ekata_enabled")
      setFieldValue("preciseId", "precise_id_disabled");
  };

  const getFilteredOptions = ekataValue => {
    if (!ekataValue) return;
    if (ekataValue.value === "ekata_enabled")
      return [{ label: "No Experian call", value: "precise_id_disabled" }];
    return getOptions(PRECISE_ID_OPTIONS);
  };

  return (
    <>
      <Header title="QA Service" />
      {loading ? (
        <div className="flex flex-col items-center justify-center becca-homepage">
          <PageLoader />
        </div>
      ) : (
        <>
          <div
            className={classNames(["w-full px-4 mt-8"], {
              "bg-red-400": liveMode(),
            })}
          >
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={handleSubmit}
              onReset={handleReset}
            >
              {({ errors, values, setFieldValue }) => (
                <Form>
                  <div className="space-y-4">
                    <Checkbox
                      name="emulationEnabled"
                      label="Click on the checkbox to enable Emulation flow"
                      onChange={() =>
                        onCheckboxClick(values.emulationEnabled, setFieldValue)
                      }
                      checked={values.emulationEnabled}
                    />
                    <div className="flex p-4 my-8 space-x-6 border border-gray-200 rounded-md">
                      <Label>Ekata is</Label>
                      <Select
                        placeholder="Select one"
                        value={values.ekata}
                        isDisabled={false}
                        name="ekata"
                        options={getOptions(EKATA_OPTIONS)}
                        error={errors.ekata}
                        onChange={obj => setEkatavalue(obj, setFieldValue)}
                      />
                    </div>
                    <div className="flex p-4 my-8 space-x-6 border border-gray-200 rounded-md">
                      <Label>PreciseID is</Label>
                      <Select
                        placeholder="Select one"
                        value={values.preciseId}
                        isDisabled={false}
                        name="preciseId"
                        options={getFilteredOptions(values.ekata)}
                        error={errors.preciseId}
                      />
                    </div>
                    <div className="flex p-4 my-8 space-x-6 border border-gray-200 rounded-md">
                      <Label>Credit Approval is</Label>
                      <Select
                        placeholder="Select one"
                        value={values.credit}
                        isDisabled={false}
                        name="credit"
                        options={getOptions(CREDIT_APPROVAL_OPTIONS)}
                        onChange={obj =>
                          onCreditApprovalChange(obj, setFieldValue)
                        }
                        error={errors.credit}
                      />
                    </div>
                    {creditLimit && (
                      <div className="flex p-4 my-8 space-x-6 border border-gray-200 rounded-md">
                        <Label>Credit Limit is</Label>
                        <FormikInput
                          placeholder="Credit Limit"
                          value={values.creditLimit}
                          name="creditLimit"
                          error={errors.creditLimit}
                        />
                      </div>
                    )}
                    <hr className="h-px bg-gray-300 border-0" />
                    <div className="flex p-4 my-8 space-x-6 border border-gray-200 divide-y rounded-md">
                      <Label>Failure</Label>
                      <Select
                        placeholder="Select one"
                        value={values.failure}
                        isDisabled={false}
                        name="failure"
                        options={getOptions(FAILURE_OPTIONS)}
                        error={errors.failure}
                      />
                    </div>
                    <div className="flex space-x-2">
                      <Button style="secondary" label="Discard" type="reset" />
                      <Button
                        style="secondary"
                        label="Save Changes"
                        type="submit"
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default EmulatorForm;

import React from "react";
import { Radio, Label } from "neetoui";
import { Field } from "formik";
import { Select as FormikSelect, Input as FormikInput } from "neetoui/formik";

import { formatCurrency } from "utils/styleUtils";

const LeasesDetails = ({
  initialState,
  setInitialState,
  onPaymentAmountChange,
  values,
  setFieldValue,
}) => {
  const onLeaseChange = value => {
    setInitialState(state => ({
      ...state,
      selectedLease: value,
      modificationValues: { ...state.modificationValues, paymentAmount: "" },
      errors: { ...state.errors, paymentAmount: "" },
      endDate: null,
    }));
  };

  return (
    <>
      <FormikSelect
        name="lease"
        label="Lease to be modified"
        className="pb-6"
        placeholder="Select a lease"
        options={initialState.leaseList}
        value={initialState.selectedLease}
        onChange={value => onLeaseChange(value)}
      />
      <div className="flex justify-between">
        <Label>Original payment amount:</Label>
        <Label>
          {formatCurrency(initialState.selectedLease?.payment_amount)}
        </Label>
      </div>
      <FormikInput
        label="New payment amount"
        name="paymentAmount"
        className="py-2"
        error={initialState.errors.paymentAmount}
        value={initialState.modificationValues.paymentAmount}
        onChange={e => onPaymentAmountChange(e.target.value)}
      />
      <div className="flex justify-between">
        <Label>New Lease-End Date:</Label>
        <Label>{initialState.endDate || "_"}</Label>
      </div>
      <Field>
        {({ field }) => (
          <Radio label="Block customer credit limit?" stacked classname="py-2">
            <Radio.Item
              {...field}
              label="Yes"
              checked={values.block}
              onChange={block => {
                block && setFieldValue("block", true);
              }}
            />
            <Radio.Item
              {...field}
              label="No"
              checked={!values.block}
              onChange={block => {
                block && setFieldValue("block", false);
              }}
            />
          </Radio>
        )}
      </Field>
    </>
  );
};

export default LeasesDetails;

import React from "react";
import classnames from "classnames";
import { generateLeaseStatusBadge } from "common/utils";

const Header = ({ lease }) => {
  const { label, color } = generateLeaseStatusBadge(lease?.lease_status);
  const badgeColor = `text-${color}-600`;

  return (
    <div className="px-4 py-2 space-y-3 bg-purple-1000">
      <div className="lg:max-w-7xl lg:mx-auto">
        <div className="flex justify-between pt-4">
          <div className="font-semibold text-purple-300 uppercase ">
            Lease ID
          </div>
          <div className="flex items-center justify-between px-2 space-x-1 text-black bg-white rounded-xl">
            <div
              className={classnames([
                "text-tiny ri-checkbox-blank-circle-fill",
                badgeColor,
              ])}
            ></div>
            <p>{label}</p>
          </div>
        </div>
        <div className="text-4xl font-extrabold text-white">{lease?.slug}</div>
      </div>
    </div>
  );
};

export default Header;

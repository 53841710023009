import React from "react";
import classNames from "classnames";

import { formatDate, formatTime } from "common/dateTimeFormatter";
import CardIcon from "common/images/card_logo.svg";
import { formatCurrency } from "utils/styleUtils";

const History = ({ payment }) => {
  const isPaymentSuccessful = payment.status == "successful";

  return (
    <div
      className={classNames("p-4 my-4 space-y-2 border rounded-md", {
        "border-gray-200": isPaymentSuccessful,
        "border-red-600": !isPaymentSuccessful,
      })}
    >
      <div className="flex justify-between">
        <p className="w-6/12 uppercase truncate">{payment.category}</p>
        <p
          className={classNames({
            "text-red-600": !isPaymentSuccessful,
            "text-green-600": isPaymentSuccessful,
          })}
        >
          {isPaymentSuccessful ? "Payment Successful" : "Payment Failed"}
        </p>
      </div>
      <div>
        {formatDate(payment.paid_at)}
        &nbsp; at {formatTime(payment.paid_at)}
      </div>
      <div className="flex justify-between">
        <div className="flex justify-start space-x-1">
          <img src={CardIcon} width="40px" height="40px" />
          <p>
            Debit Card Ending {payment?.payment_method?.details.card?.last4}
          </p>
        </div>
        <div>
          <p className="font-semibold text-gray-700">
            {formatCurrency(payment.amount)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default History;

import axios from "axios";

const create = (lease_id, payload) =>
  axios.post(
    `/api/v1/leases/${lease_id}/payment_remaining_distributions`,
    payload
  );

const paymentRemainingDistributionsApi = { create };

export default paymentRemainingDistributionsApi;

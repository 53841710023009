import React, { useState, useEffect } from "react";
import { Button } from "neetoui";
import { formatCurrency } from "utils/styleUtils";

const CTA = ({ lease }) => {
  let [status, setStatus] = useState(false);

  const epoDetails = lease?.epo_details;
  const epoOptionType =
    epoDetails?.type == "retail_price" ? epoDetails?.period : "Early";

  useEffect(() => {
    setStatus(lease?.lease_status == "paid_off" ? true : false);
  }, [lease]);

  if (status) {
    return (
      <div className="flex flex-col items-center justify-center px-6 py-4 mx-4 mt-8 space-y-2 bg-gray-100 rounded-md lg:max-w-6xl lg:mx-auto">
        <p className="font-medium text-center ">
          {`Check out similar items to ${lease?.line_items[0]?.product_name} on our`}
          <span className="px-1 font-semibold text-purple-1000">
            storefront.
          </span>
        </p>
        <Button label="Buy now" />
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center justify-center px-6 py-4 mx-4 mt-8 space-y-2 bg-gray-100 rounded-md lg:max-w-6xl lg:mx-auto">
      <p className="mb-3 font-medium text-center ">
        Your lease is eligible for a
        <span className="px-1 font-semibold text-purple-1000">
          {`${epoOptionType} Payoff Option.`}
        </span>
        Pay off the lease for
        <span className="px-1 font-semibold text-purple-1000">{`${formatCurrency(
          lease?.epo_details?.payoff_amount
        )}`}</span>
        .
      </p>
      <Button label="Check Storefront" />
      <div className="flex justify-between w-full mt-4 space-x-4">
        <div className="w-full mt-3 border-t border-gray-800"></div>
        <div>or</div>
        <div className="w-full mt-3 border-t border-gray-800"></div>
      </div>
      <div className="flex flex-col items-center justify-center space-y-3">
        <p className="text-center">
          <span className="px-1 font-semibold text-purple-1000">
            Pay in installments
          </span>
          towards paying off the lease.
        </p>
        <Button label="Pay now" style="secondary" />
      </div>
    </div>
  );
};

export default CTA;

export const DELIVERY_STATUS = {
  pending: {
    label: "Pending Payment",
    color: "grey",
  },
  completed: {
    label: "Delivered",
    color: "blue",
  },
  cancelled: {
    label: "Cancelled",
    color: "grey",
  },
  processing: {
    label: "Processing",
    color: "green",
  },
  failed: {
    label: "Failed",
    color: "red",
  },
  refunded: {
    label: "Refunded",
    color: "grey",
  },
  on_hold: {
    label: "On Hold",
    color: "orange",
  },
};

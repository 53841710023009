import React from "react";
import { Label } from "@bigbinary/neetoui";

const Card = ({ title, titleAction = <></>, children }) => (
  <div className="p-4 bg-white border border-gray-200 rounded-md ">
    <div className="flex justify-between">
      <Label className="font-semibold text-gray-400 uppercase">{title}</Label>
      {titleAction}
    </div>

    {children}
  </div>
);

export default Card;

import React, { useState, useEffect } from "react";
import { Button, Select, Input, DateInput, Collapse } from "neetoui";

import LeaseSubHeader from "./LeaseSubHeader";
import PaymentConfirmModal from "../common/PaymentConfirmModal";

import { useHistory, useParams } from "react-router";
import { formatCurrency } from "utils/styleUtils";

const CardPayment = ({ paymentInfo, setPaymentInfo, paymentMethods }) => {
  const history = useHistory();
  const { index } = useParams();
  const [viewSplit, setViewSplit] = useState(false);

  const goback = () => {
    history.goBack();
  };

  useEffect(() => {
    if (index && paymentMethods) {
      let selected = paymentMethods
        ?.filter(pm => pm.id == index)
        .map(pm => ({
          label: `Debit Card Ending ${pm?.details?.card?.last4}`,
          value: pm.id,
          ...pm,
        }));
      setPaymentInfo(state => {
        return { ...state, method: selected[0] };
      });
    }
  }, [paymentMethods]);

  const changeSplitview = () => {
    setViewSplit(state => !state);
  };

  const [paymentConfirm, setPaymentConfirm] = useState(false);

  const onSubmit = () => {
    setPaymentConfirm(true);
  };

  const PaymentMethodChange = value => {
    setPaymentInfo(state => {
      return { ...state, method: value };
    });
  };

  return (
    <>
      <LeaseSubHeader
        paymentInfo={paymentInfo}
        setPaymentInfo={setPaymentInfo}
      />
      <div className="px-4 py-8 space-y-4">
        <p className="text-lg font-semibold">How do you wish to pay?</p>
        <Select
          label="Payment Method"
          options={paymentMethods?.map(pm => ({
            label: `Debit Card Ending ${pm?.details?.card?.last4}`,
            value: pm.id,
            ...pm,
          }))}
          defaultValue={paymentInfo.method}
          onChange={value => PaymentMethodChange(value)}
        />
        <DateInput
          onChange={date => setPaymentInfo({ ...paymentInfo, date: date })}
          label="Payment Date"
          value={paymentInfo.date}
          minDate={new Date()}
        />

        <Input
          label="Amount ($)"
          value={paymentInfo.amount}
          onChange={e =>
            setPaymentInfo({
              ...paymentInfo,
              amount: e.target.value,
            })
          }
        />

        {!viewSplit ? (
          <div className="flex justify-end my-2">
            <Button
              style="link"
              label="View amount splitup"
              onClick={() => changeSplitview()}
            />
          </div>
        ) : (
          <Collapse role="region" open={viewSplit} hidden={!viewSplit}>
            <div className="space-y-3 font-semibold">
              <div className="flex items-center justify-between text-gray-500">
                <p>Upcoming Lease Amount (April 2021)</p>
                <p>{formatCurrency(paymentInfo?.lease?.payment_amount)}</p>
              </div>
              <div className="flex items-center justify-between text-gray-500">
                <p>Overdue Balance (March 2021)</p>
                <p>{formatCurrency(paymentInfo?.lease?.overdue_amount)}</p>
              </div>
              <div className="flex items-center justify-between pt-2 text-green-400 border-t border-gray-200">
                <p>Total Amount Due in April</p>
                <p>
                  {formatCurrency(
                    paymentInfo?.lease?.payment_amount +
                      paymentInfo?.lease?.overdue_amount
                  )}
                </p>
              </div>
              <div className="flex justify-end">
                <Button
                  style="link"
                  label="Hide amount splitup"
                  onClick={() => changeSplitview()}
                />
              </div>
            </div>
          </Collapse>
        )}
        <div className="flex justify-end space-x-4">
          <Button label="Cancel" style="secondary" onClick={() => goback()} />
          <Button label="Next" onClick={() => onSubmit()} />
        </div>
      </div>
      <PaymentConfirmModal
        paymentInfo={paymentInfo}
        paymentConfirm={paymentConfirm}
        setPaymentConfirm={setPaymentConfirm}
      />
    </>
  );
};

export default CardPayment;

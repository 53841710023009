import React from "react";
import { Card } from "@bigbinary/neetoui";
import AccountSummary from "./AccountSummary";
import LatePaymentsChart from "./LatePaymentChart";
import PaymentPromises from "./PaymentPromises";
import { formatCurrency } from "utils/styleUtils";
import useDocumentTitle from "hooks/useDocumentTitle";
import {
  formatDate,
  formatDateWithoutTimeZone,
} from "common/dateTimeFormatter";

const CardList = ({
  overdueBalanceWithTax,
  totalOustandingBalance,
  lastPayment,
  nextPaymentDay,
}) => {
  return (
    <div className="flex space-x-8">
      <Card className="w-1/4">
        <p className="mb-2 text-4xl font-bold text-red-500">
          {overdueBalanceWithTax !== null
            ? formatCurrency(overdueBalanceWithTax)
            : "- -"}
        </p>
        <p className="text-sm font-medium tracking-wider text-gray-400 uppercase">
          Overdue Balance
        </p>
      </Card>
      <Card className="w-1/4">
        <p className="mb-2 text-4xl font-bold text-gray-700">
          {totalOustandingBalance
            ? formatCurrency(totalOustandingBalance)
            : "- -"}
        </p>
        <p className="text-sm font-medium tracking-wider text-gray-400 uppercase">
          Outstanding Balance
        </p>
      </Card>
      <Card className="w-1/4">
        <p className="mb-2 text-4xl font-bold text-gray-700">
          {lastPayment ? formatDate(lastPayment) : "- -"}
        </p>
        <p className="text-sm font-medium tracking-wider text-gray-400 uppercase">
          Last Payment Date
        </p>
      </Card>
      <Card className="w-1/4">
        <p className="mb-2 text-4xl font-bold text-gray-700">
          {nextPaymentDay ? formatDateWithoutTimeZone(nextPaymentDay) : "- -"}
        </p>
        <p className="text-sm font-medium tracking-wider text-gray-400 uppercase">
          Next Invoice Date
        </p>
      </Card>
    </div>
  );
};

const CustomerDetails = ({ customer }) => {
  useDocumentTitle(`Customer detail ${customer?.name}`);

  return (
    <div className="flex becca-main-container">
      <div className="px-10 pt-8 becca-details">
        <CardList
          overdueBalanceWithTax={customer?.overdue_balance_with_tax}
          totalOustandingBalance={customer?.total_outstanding_balance}
          lastPayment={customer?.last_payment}
          nextPaymentDay={customer?.next_invoice_payment_date}
        />
        <AccountSummary customer={customer} />
        <LatePaymentsChart />
        <PaymentPromises />
      </div>
    </div>
  );
};

export default CustomerDetails;

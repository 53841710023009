import axios from "axios";

const preferences = customerId =>
  axios.get(`/api/v1/admin/customers/${customerId}/preferences`);

const update = (customerId, payload) =>
  axios.put(`/api/v1/admin/customers/${customerId}/preferences`, payload);

const preferencesApi = {
  preferences,
  update,
};

export default preferencesApi;

import React from "react";
import { Button } from "@bigbinary/neetoui";
import { useHistory } from "react-router-dom";

import HeaderIcon from "./NewPayment/Header";

const PayEarly = ({
  amount = "$200",
  leaseId = "#1453BH",
  paymentMethod = "Debit Card ending 6784",
}) => {
  const history = useHistory();
  const handlePayEarly = () => {
    history.push("/payment/:customerId/payment");
  };
  const handleAutopaySettings = () => {
    history.push("/payment/:customerId/autopay-settings");
  };

  return (
    <div className="w-full px-4 py-6 bg-white border border-gray-200 rounded-md shadow-md">
      <HeaderIcon />
      <div className="mt-3 space-y-8">
        <p className="space-x-1 text-lg font-medium leading-8">
          Your monthly payment of&nbsp;
          <span className="font-semibold text-purple-1000">{amount}</span>
          &nbsp;towards lease
          <span className="font-semibold text-purple-1000">{leaseId}</span>
          &nbsp;from your
          <span className="font-semibold">{paymentMethod}</span>.
        </p>
        <div className="flex justify-start py-2 space-x-4">
          <Button label="Pay Early" onClick={() => handlePayEarly()} />
          <Button
            label="Autopay Settings"
            style="secondary"
            onClick={() => handleAutopaySettings()}
          />
        </div>
        <Button label="View Past Payments" style="link" />
      </div>
    </div>
  );
};

export default PayEarly;

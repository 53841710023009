import { useEffect, useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";

const useStripePay = (amount, clientSecret, label) => {
  const stripe = useStripe();
  const [buttonStatus, setButtonStatus] = useState({
    applePay: false,
    googlePay: false,
  });
  const [PaymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (stripe) {
      const stripePaymentRequest = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: label ? label : "Demo total",
          amount: amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      stripePaymentRequest.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(stripePaymentRequest);
        }
      });
    }
  }, [stripe, amount, clientSecret]);

  useEffect(() => {
    PaymentRequest?.canMakePayment().then(result => {
      if (result) {
        setButtonStatus(result);
      }
    });
  }, [PaymentRequest]);

  PaymentRequest?.on("paymentmethod", async ev => {
    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { paymentIntent, error: confirmError } =
      await stripe?.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      ev.complete("fail");
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      ev.complete("success");
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await stripe.confirmCardPayment(clientSecret);
        if (error) {
          // The payment failed -- ask your customer for a new payment method.
        } else {
          // The payment has succeeded.
        }
      } else {
        // The payment has succeeded.
      }
    }
  });

  return {
    stripe: stripe,
    paymentRequest: PaymentRequest,
    buttonStatus: buttonStatus,
  };
};

export default useStripePay;

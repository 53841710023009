import React, { useEffect, useState } from "react";
import { Label, Button } from "@bigbinary/neetoui";

import { LATE_CHART_DATA } from "./constants";
import leasesApi from "apis/leases";
import { useParams } from "react-router-dom";

const LatePaymentsTableRow = ({ data }) => (
  <tr className="text-center">
    <td>
      <Button
        style="link"
        label={data.slug}
        to={`/customers/${data.customer_id}/leases/${data.id}`}
      />
    </td>
    <td className="text-center">{LATE_CHART_DATA[0]?.day_3}</td>
    <td className="text-center"> {LATE_CHART_DATA[0]?.day_6}</td>
    <td className="text-center">{LATE_CHART_DATA[0]?.day_15}</td>
    <td className="text-center">{LATE_CHART_DATA[0]?.day_30}</td>
    <td className="text-center">{LATE_CHART_DATA[0]?.day_60}</td>
    <td className="text-center">{LATE_CHART_DATA[0]?.day_90}</td>
    <td className="text-center">{LATE_CHART_DATA[0]?.total}</td>
  </tr>
);

const LatePaymentsTableHead = () => (
  <thead>
    <tr className="text-center">
      <th>LEASE ID</th>
      <th className="text-center">1-3 DAYS</th>
      <th className="text-center">4-6 DAYS</th>
      <th className="text-center">7-15 DAYS</th>
      <th className="text-center">16-30 DAYS</th>
      <th className="text-center">31-60 DAYS</th>
      <th className="text-center">61-90 DAYS</th>
      <th className="text-center">Total</th>
    </tr>
  </thead>
);

const LatePaymentsTableBody = ({ leaseList }) => (
  <tbody>
    {leaseList.map(data => (
      <LatePaymentsTableRow data={data} key={data.id} />
    ))}
  </tbody>
);

const LatePaymentsChart = () => {
  const { id } = useParams();
  const [leaseList, setLeaseList] = useState([]);
  const fetchLeases = async () => {
    try {
      const {
        data: { leases },
      } = await leasesApi.leases(id);
      setLeaseList(leases);
    } catch (error) {
      logger.error(error);
    }
  };

  useEffect(() => {
    fetchLeases();
  }, []);

  return (
    <div className="p-4 my-8 border border-gray-200 rounded-md">
      <Label className="mb-2 font-bold uppercase">LATE PAYMENT CHART</Label>
      <table className="nui-table ">
        <LatePaymentsTableHead />
        <LatePaymentsTableBody leaseList={leaseList} />
      </table>
    </div>
  );
};

export default LatePaymentsChart;

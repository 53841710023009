export const toSnakeCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join("_");

export const formatCurrency = amount => {
  if (!amount) return "$0.00";

  const options = { style: "currency", currency: "USD" };
  const currencyFormatter = new Intl.NumberFormat("en-US", options);

  return currencyFormatter.format(amount);
};

export const convertToFloat = (number, decimalCount = 2) => {
  const finalNumber = typeof number == "number" ? number : +number;

  return number ? parseFloat(finalNumber?.toFixed(decimalCount)) : 0.0;
};

export const convertToDecimalString = (number, decimalCount = 2) => {
  const finalNumber = typeof number == "number" ? number : +number;

  return number ? finalNumber?.toFixed(decimalCount) : "0.00";
};

export const toTitleCase = (string = "", separator = " ") => {
  return string
    .split(separator)
    .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    .join(" ");
};

export const convertSnakeToTitle = (string = "") => {
  return toTitleCase(string.replace(/_/g, " "));
};

export const formatAddress = address =>
  `${address?.street_number} ${address?.area} ${address?.city}, ${address?.state} ${address?.zip_code}`;

export const toSentence = (string = "", separator = "_") => {
  return toTitleCase(string).split(separator).join(" ");
};

import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "neetoui";
import CreditLockedIcon from "common/images/credit-locked";

const CreditLock = () => {
  const history = useHistory();

  const onNextClick = () => {
    history.push(`/flow/register/submit`);
  };

  return (
    <div className="w-full">
      <div className="px-4 mt-8">
        <p className="w-5/6 mb-6 text-lg">
          Oops, your credit profile is locked!
        </p>
        <p className="mb-4 text-sm text-gray-500">
          Unlock your credit profile and apply again to get approved. Don’t
          worry — this process did not affect your credit score.
        </p>
        <div className="flex justify-center w-full my-8">
          <img width="200px" height="200px" src={CreditLockedIcon} />
        </div>

        <Button
          label="Unlocked? Apply now"
          fullWidth={true}
          onClick={() => onNextClick()}
        />
      </div>
    </div>
  );
};

export default CreditLock;

import React from "react";
import { Input, Select, Pane } from "neetoui";

export const IncomePaneComponent = ({ incomePane, setIncomePane }) => (
  <Pane
    title="Edit Income Information"
    isOpen={incomePane}
    onClose={() => setIncomePane(false)}
    cancelButtonProps={{
      label: "Cancel",
      onClick: () => setIncomePane(false),
    }}
    submitButtonProps={{
      label: "Save Changes",
      onClick: () => setIncomePane(false),
    }}
    showFooter
  >
    <div className="px-6 pb-20 space-y-6 overflow-auto">
      <Input label="Income" value="__$2200_" />
      <Input label="Credit Limit" value="__$2200_" />
      <div className="flex space-x-4">
        <Select
          className="w-1/2"
          label="Pay Cycle"
          options={[
            { label: "Weekly", value: "Weekly" },
            { label: "Monthly", value: "Monthly" },
          ]}
          value={{ label: "Weekly", value: "Weekly" }}
        />
        <Select
          className="w-1/2"
          label="Pay Day"
          options={[
            { label: "Monday", value: "Monday" },
            { label: "Tuesday", value: "Tuesday" },
          ]}
          value={{ label: "Monday", value: "Monday" }}
        />
      </div>
    </div>
  </Pane>
);

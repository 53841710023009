export const SETTINGS_TAB = ["Workflows", "Exclusions", "Logs", "Errors"];

export const PAYMENT_SETTINGS = [
  {
    title: "Payment Failure 3 - ....",
    timing: "Schedule for 5:45 pm after waiting 24 hours",
    category: "Payments",
    run_count: "203",
    queue_count: "203",
    status: false,
  },
  {
    title: "Payment Failure 3 - ....",
    timing: "Schedule for 5:45 pm after waiting 24 hours",
    category: "Payments",
    run_count: "203",
    queue_count: "203",
    status: false,
  },
];

import React from "react";
import { useHistory } from "react-router-dom";

import BeccasHomeLogo from "common/images/beccas-home-logo.png";

const IconHeader = () => {
  const history = useHistory();
  const showHeader =
    history.location.pathname != "/flow/dashboard" ? true : false;
  return (
    <>
      {showHeader && (
        <div className="px-4">
          <img src={BeccasHomeLogo} width="82px" height="82px" />
        </div>
      )}
    </>
  );
};

export default IconHeader;

import React from "react";
import { Button } from "@bigbinary/neetoui";
import { useHistory } from "react-router-dom";

import PaymentSuccessIcon from "common/images/PaymentSuccess";

const PaymentSuccess = ({
  paymentAmount = "$100",
  leaseId = "#1345BH",
  paymentDate = "05/25/2021",
  paymentMethod = "debit card ending 8495",
}) => {
  const isEarlyPayment = true;
  const history = useHistory();
  const handleAutoPayUpdate = () => {
    history.push("/payment/:customerId/autopay-settings");
  };
  return (
    <div>
      <div className="flex justify-center py-8">
        <img src={PaymentSuccessIcon} height="150em" width="150em"></img>
      </div>
      <p className="py-6 space-x-1 text-lg font-medium">
        <span>Payment worth</span>
        <span className="font-semibold text-purple-1000">{paymentAmount}</span>
        &nbsp;towards
        {isEarlyPayment && <span>an early pay off for</span>}
        <span>your lease</span>
        <span className="font-semibold text-purple-1000">{leaseId}</span>{" "}
        using&nbsp;
        {paymentMethod} completed successfully. Your next scheduled payment is
        on
        <span className="font-semibold text-purple-1000">{paymentDate}</span>.
      </p>
      <div className="flex justify-start py-2 space-x-4">
        <Button
          label="Autopay Settings"
          onClick={() => handleAutoPayUpdate()}
          style="secondary"
        />
        <Button label="Confirm" />
      </div>
    </div>
  );
};

export default PaymentSuccess;

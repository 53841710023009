import React, { useState } from "react";

import { SubHeader } from "@bigbinary/neetoui/layouts";

import {
  DEFAULT_PAGE_SIZE,
  LOAD_SORT_PROPS,
  SORT_BY_OPTIONS,
} from "./constants";

import LeaseItem from "./LeaseItem";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useParams } from "react-router-dom";

const LeaseList = ({
  onLeaseItemClick,
  leaselist,
  updateSelectedLeaseStatus,
}) => {
  const { id } = useParams();
  const [searchString, setSearchString] = useState("");
  const [pageIndex, setPageIndex] = useState(0);

  useDocumentTitle(`Leases for customer ${id}`);

  return (
    <div className="becca-details">
      <div>
        <SubHeader
          searchProps={{
            value: searchString,
            onChange: e => setSearchString(e.target.value),
            clear: () => setSearchString(""),
          }}
          sortProps={{
            options: SORT_BY_OPTIONS,
            onClick: LOAD_SORT_PROPS,
          }}
          paginationProps={{
            count: leaselist.length,
            pageNo: pageIndex,
            pageSize: DEFAULT_PAGE_SIZE,
            navigate: index => setPageIndex(index),
          }}
        />
      </div>
      <div className="grid grid-cols-2 gap-10 mx-6 mt-9">
        {leaselist.map((item, index) => {
          return (
            <div key={index}>
              <LeaseItem
                item={item}
                onLeaseItemClick={onLeaseItemClick}
                updateSelectedLeaseStatus={updateSelectedLeaseStatus}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LeaseList;

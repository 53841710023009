import React, { useEffect, useState } from "react";
import { Select, Input, Button } from "@bigbinary/neetoui";

import {
  CANCELLATION_MANUAL_STATUS,
  RETURN_MANUAL_STATUS,
} from "../../constants";

const ManualImport = ({ pageTitle }) => {
  const [purchaseBoxData, setPurchaseBoxData] = useState([]);

  let updateStatusOptions = [];

  if (pageTitle == "cancellations") {
    updateStatusOptions = CANCELLATION_MANUAL_STATUS.map(item => {
      return { label: item, value: item };
    });
  } else {
    updateStatusOptions = RETURN_MANUAL_STATUS.map(item => {
      return { label: item, value: item };
    });
  }

  useEffect(() => {
    if (pageTitle == "orders") {
      setPurchaseBoxData([
        {
          sku: { label: "AH39871", value: "AH39871" },
          DeliveryPartner: { label: "FedEx", value: "FedEx" },
          trackingID: "",
        },
      ]);
    } else if (pageTitle == "cancellations") {
      setPurchaseBoxData([
        {
          sku: { label: "AH39871", value: "AH39871" },
          status: {
            label: "Cancellation In Progress",
            value: "Cancellation In Progress",
          },
        },
      ]);
    } else {
      setPurchaseBoxData([
        {
          sku: { label: "AH39871", value: "AH39871" },
          status: {
            label: "Return In Progress",
            value: "Return In Progress",
          },
        },
      ]);
    }
  }, []);

  const addMoreBox = () => {
    setPurchaseBoxData(state => [...state, []]);
  };

  const deletePurchaseDateBox = () => {
    setPurchaseBoxData(state => state.splice(1, 1));
  };

  return (
    <div className="my-8 space-y-4">
      <Select
        label="Purchase Order"
        options={[{ label: "BH12322", value: "BH12322" }]}
        value={{ label: "BH12322", value: "BH12322" }}
      />
      {purchaseBoxData.map((item, index) => {
        return (
          <div
            key={index}
            className="relative p-4 border border-gray-200 border-dashed rounded-md"
          >
            <div className="flex space-x-4">
              <Select
                label="Product SKU"
                options={[{ label: "AH39871", value: "AH39871" }]}
                value={item.sku}
              />
              {pageTitle == "orders" ? (
                <Select
                  label=" Delivery Partner"
                  options={[{ label: "FedEx", value: "FedEx" }]}
                  value={item.DeliveryPartner}
                />
              ) : (
                <Select
                  label="Updated Status After Import"
                  options={updateStatusOptions}
                  value={item.status}
                />
              )}
            </div>
            {pageTitle == "orders" && (
              <Input label="Tracking ID" value={item.trackingID} />
            )}
            <div className="absolute top-0 right-0">
              <Button
                style="icon"
                icon="ri-close-line"
                onClick={() => deletePurchaseDateBox()}
              />
            </div>
          </div>
        );
      })}

      <div className="flex justify-end">
        <Button
          onClick={() => addMoreBox()}
          style="link"
          label={pageTitle == "orders" ? "Add More Boxes" : "Add More SKUs"}
          icon="ri-add-line"
          iconPosition="left"
        />
      </div>
    </div>
  );
};

export default ManualImport;

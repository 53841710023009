import React, { useState } from "react";
import { Label, Input, Button } from "@bigbinary/neetoui";

const Rename = ({ label, labelAction, className = "" }) => {
  const [labelState, setLabelState] = useState(label);
  const [labelEdit, setLabelEdit] = useState(false);

  const onInputValueChange = value => {
    setLabelState(value);
  };

  const onCheckboxClick = () => {
    setLabelEdit(false);
  };

  const onButtonClick = () => {
    setLabelEdit(true);
  };
  return (
    <div className="flex items-center space-x-2">
      {labelEdit ? (
        <div className="w-48">
          <Input
            className="p-0"
            value={labelState}
            onChange={e => {
              onInputValueChange(e.target.value);
            }}
            suffix={
              <div
                className="text-lg ri-check-line"
                onClick={() => onCheckboxClick()}
              ></div>
            }
          />
        </div>
      ) : (
        <Label className={className}>{labelState}</Label>
      )}
      {labelAction && !labelEdit && (
        <Button
          style="icon"
          icon="ri-pencil-line text-sm"
          onClick={() => onButtonClick()}
        />
      )}
    </div>
  );
};

export default Rename;

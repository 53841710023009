import axios from "axios";

const baseUrl = "api/v1";
const baseAdminUrl = `${baseUrl}/admin/customers`;

const update = (id, payload) =>
  axios.patch(`${baseAdminUrl}/${id}/employment_detail`, payload);
const getUpdateableDetails = (id, params) =>
  axios.post(`${baseAdminUrl}/${id}/employment_detail/fetch_details`, params);

const employmentDetailsApi = {
  update,
  getUpdateableDetails,
};

export default employmentDetailsApi;

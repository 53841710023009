import * as yup from "yup";

export const NEW_CARD_VALIDATION_SCHEMA = yup.object({
  name: yup.string(),
  card_number: yup
    .string()
    .required("Please enter a card number")
    .min(19, "Not a valid card number")
    .max(19, "Not a valid card number"),
  expiry_date: yup
    .string()
    .required("Please enter expiry date")
    .length(5, "Not a valid expiration date. Example: MM/YY")
    .matches(
      /([0][1-9]|[1][0-2])\/([22-99])/,
      "Not a valid expiration date. Example: MM/YY"
    ),
  cvv: yup
    .string()
    .required("Please enter CVV")
    .min(3, "Not a valid cvc")
    .max(3, "Not a valid cvc"),
});

export const MENU_ITEMS = [
  { name: "Dashboard", value: "lease" },
  { name: "Credit Approval", value: "creditApproval" },
  { name: "Make Payment", value: "payment" },
  { name: "Payment Methods", value: "listing" },
  { name: "My Profile", value: "profile" },
  { name: "Change Password", value: "password" },
];

export const DEBIT_CARD_LIST = [
  {
    label: "Debit Card ending __6787_",
    value: "Debit Card ending __6787_",
  },
  {
    label: "Debit Card ending __6786_",
    value: "Debit Card ending __6786_",
  },
];

import React from "react";
import AccountDropdown from "./AccountDropdown";
import NavItem from "./NavItem";
import { withRouter, useHistory } from "react-router-dom";
import { useAuthDispatch } from "contexts/auth";
import authenticationApi from "apis/authentication";
import { resetAuthTokens } from "apis/axios";
import { Toastr } from "neetoui";

import BeccasHomeLogo from "common/images/beccas-home-logo.png";

const NavBar = () => {
  const history = useHistory();
  const authDispatch = useAuthDispatch();
  const handleLogout = async () => {
    try {
      await authenticationApi.logout();
      authDispatch({ type: "LOGOUT" });
      resetAuthTokens();
      history.push("/staff/login");
    } catch (error) {
      Toastr.error(error);
    }
  };

  return (
    <div className="bg-gray-100 nh-sidebar" key="sidebar">
      <div className="nh-logo">
        <div className="flex items-center justify-center w-10 h-10 rounded-md">
          <img src={BeccasHomeLogo} width="100px" height="100px" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-between w-full h-full">
        <div className="flex flex-col items-center justify-center w-full h-full">
          <NavItem title="Customers" link="/customers" icon="ri-group-line" />
          <NavItem
            title="QA Service"
            link="/experian-emulation"
            icon="ri-hard-drive-2-line"
          />
          <NavItem title="Agents" link="/agents" icon="ri-user-2-line" />
        </div>
        <div className="mb-4">
          <AccountDropdown handleLogout={handleLogout} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(NavBar);

import { US_STATES } from "common/constants";
import { getOptions } from "./objectFormatter";
import { toTitleCase } from "./styleUtils";

export default class SignUpDataFormatter {
  constructor(data) {
    this.phoneNumber = data.phone_number;
    this.personalInfo = data.personal_info;
    this.addressInfo = data.address_info;
    this.workInfo = data.work_info;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  getPersonalInfo() {
    return { ...this.personalInfo, confirm_email: this.personalInfo?.email };
  }

  getAddressInfo() {
    const state = getOptions(US_STATES).find(
      item => item.value == this.addressInfo?.state
    );

    return { ...this.addressInfo, ssn: this.personalInfo?.ssn, state: state };
  }

  getWorkInfo() {
    const pay_day1 = this.workInfo?.pay_days[0] || 1;
    const pay_day2 = this.workInfo?.pay_days[1] || 15;

    return {
      ...this.workInfo,
      monthly_income: {
        value: this.workInfo?.monthly_income,
        label: this.workInfo?.monthly_income,
      },
      mode_of_salary: {
        value: this.workInfo?.mode_of_salary,
        label: toTitleCase(this.workInfo?.mode_of_salary, "_"),
      },
      pay_day1: {
        value: pay_day1,
        label: pay_day1,
      },
      pay_day2: {
        value: pay_day2,
        label: pay_day2,
      },
    };
  }
}

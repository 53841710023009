import React, { useState } from "react";
import { Button, Label, Modal } from "@bigbinary/neetoui";
import { isMobile } from "react-device-detect";
import paymentsApi from "apis/customers/payments";
import { formatCurrency } from "utils/styleUtils";
import classNames from "classnames";
import { formatDate } from "common/dateTimeFormatter";
import useStripePay from "../Payment/StripepayHook";

const PaymentConfirmModal = ({
  paymentInfo,
  paymentConfirm,
  setPaymentConfirm,
  setPaymentResultModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  let USAmount = parseFloat(paymentInfo.amount) * 100;

  const callPaymentIntent = async () => {
    const { paymentRequest: stripRequest } = useStripePay(
      USAmount,
      clientSecret,
      "Becca " + paymentInfo?.lease?.slug
    );

    const { data } = await paymentsApi.paymentIntent(paymentInfo.lease.id, {
      amount: paymentInfo.amount,
    });

    setClientSecret(data.payment_intent.client_secret);
    stripRequest?.show();
  };

  const handleSubmit = async () => {
    if (
      paymentInfo.method.value == "gpay" ||
      paymentInfo.method.value == "apay"
    ) {
      callPaymentIntent();
    } else {
      try {
        setLoading(true);
        const payload = {
          lease_id: paymentInfo.lease.id,
          payment_method_id: paymentInfo.method.id,
          payment_type: paymentInfo.type,
          amount: paymentInfo.amount,
          payment_date: paymentInfo.date,
          epo_amount: paymentInfo.epoAmount,
          upcoming_invoice_amount: paymentInfo.upcomingInvoiceAmount,
        };

        await paymentsApi.create(payload);
        setPaymentResultModal({ status: true });
      } catch (error) {
        logger.error(error);
        setPaymentResultModal({ status: false });
      } finally {
        setLoading(false);
        setPaymentConfirm(false);
      }
    }
  };

  return (
    <Modal
      size={isMobile ? "large" : "small"}
      className={classNames(["px-4 py-1 mx-3 rounded-md"], {
        "max-h-96": !isMobile,
      })}
      isOpen={paymentConfirm}
      onClose={() => setPaymentConfirm(false)}
      autoHeight
    >
      <div>
        <p className="py-3 text-lg font-semibold text-gray-800 border-b border-gray-200">
          Payment Confirmation
        </p>
        <div className="flex justify-between py-3 text-gray-800 border-b border-gray-200">
          <Label>Lease ID</Label>
          <p>{paymentInfo?.lease?.slug}</p>
        </div>
        <div className="flex justify-between py-3 text-gray-800 uppercase border-b border-gray-200">
          <Label>Payment Towards</Label>
          <p>{paymentInfo?.type}</p>
        </div>
        <div className="flex justify-between py-3 text-gray-800 border-b border-gray-200">
          <Label>Payment Method</Label>
          <p>
            {paymentInfo?.method?.details?.card
              ? `Debit Card ${paymentInfo?.method?.details?.card?.last4}`
              : paymentInfo?.method?.value == "gpay" && "Pay using Google pay"}
          </p>
        </div>
        <div className="flex justify-between py-3 border-b border-gray-200">
          <Label>Payment Date</Label>
          <p>{formatDate(paymentInfo?.date)}</p>
        </div>
        <div className="flex justify-between py-3 border-b border-gray-200">
          <Label>Payment Amount</Label>
          <p>{formatCurrency(paymentInfo?.amount)}</p>
        </div>
        <div className="flex justify-end py-3 space-x-4">
          <Button
            label="Cancel"
            style="secondary"
            onClick={() => setPaymentConfirm(false)}
          />
          <Button
            label="Confirm Payment"
            loading={loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PaymentConfirmModal;

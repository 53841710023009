import React, { useState, useEffect } from "react";
import { Button, PageLoader, Callout } from "neetoui";

import CardIcon from "common/images/card_logo.svg";
import { useHistory } from "react-router-dom";
import paymentMethodsApi from "apis/customers/payment-methods";
import preferencesApi from "apis/customers/preferences";

const PaymentListing = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isAutopayOn, setIsAutopayOn] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    fetchPreferences();
    fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = async () => {
    try {
      setLoading(true);
      const {
        data: { payment_methods },
      } = await paymentMethodsApi.all();

      setPaymentMethods(payment_methods);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPreferences = async () => {
    try {
      const {
        data: { preferences },
      } = await preferencesApi.preferences();
      setIsAutopayOn(preferences.autopay_enabled);
    } catch (error) {
      logger.error(error);
    }
  };

  const deletePaymentMethods = async id => {
    try {
      await paymentMethodsApi.destroy(id);

      await fetchPaymentMethods();
    } catch (error) {
      logger.error(error);
    }
  };

  const onDefaultCardChange = async id => {
    try {
      await paymentMethodsApi.updateDefaultCard(id);

      await fetchPaymentMethods();
    } catch (error) {
      logger.error(error);
    }
  };

  const onNewCard = () => {
    history.push("/my-account/payment/new/card/default");
  };

  const activateAutopay = async () => {
    try {
      const payload = { preference: { autopay_enabled: true } };
      await preferencesApi.update(payload);
      await fetchPreferences();
    } catch (error) {
      logger.error(error);
    }
  };

  if (loading)
    return (
      <div className="flex flex-col items-center justify-center w-screen becca-homepage">
        <PageLoader />
      </div>
    );

  return (
    <div className="justify-center w-screen px-4 overflow-y-auto lg:max-w-lg">
      <div className="py-6">
        <p className="text-2xl text-gray-800">Payment methods</p>
        <p className="text-sm text-gray-600">
          {paymentMethods.length > 0
            ? "Update or delete your saved payment methods."
            : "It seems that you don't have any payment methods added."}
        </p>
      </div>
      {!isAutopayOn && (
        <Callout
          style="danger"
          className="flex items-center py-2 mb-2 border border-red-500"
          icon={<></>}
        >
          <div className="flex items-center">
            <span className="text-gray-700">
              Your autopay has been disabled.
            </span>
            <span
              onClick={activateAutopay}
              className="pl-1 font-semibold underline cursor-pointer"
            >
              Click here to reactivate.
            </span>
          </div>
        </Callout>
      )}
      <div className="py-4">
        <Button
          label="+ Add new payment method"
          style="link"
          onClick={() => {
            onNewCard();
          }}
        />
      </div>
      <div className="space-y-2">
        {paymentMethods &&
          paymentMethods.map(item => (
            <div
              key={item.id}
              className="flex items-center justify-between p-2 border border-gray-200 rounded-md"
            >
              <div className="flex space-x-2">
                <img src={CardIcon} width="20px" height="20px" />
                <p>Card Ending {item.number_ending_with}</p>
                <p className="text-gray-400">
                  {item.details.card.exp_month +
                    "/" +
                    item.details.card.exp_year}
                </p>
              </div>
              {item.is_default ? (
                <Button label="Default Card" style="link" />
              ) : (
                <div className="flex text-xs text-gray-400 underline cursor-pointer">
                  <p
                    className="pr-1 border-r"
                    onClick={() => onDefaultCardChange(item.id)}
                  >
                    Make Default
                  </p>
                  <p
                    className="pl-1"
                    onClick={() => deletePaymentMethods(item.id)}
                  >
                    Delete
                  </p>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default PaymentListing;

import React, { useEffect, useState } from "react";

const Status = ({ lease }) => {
  const [leaseStatus, setLeaseStatus] = useState({
    completed: false,
    percentange: 50,
    months: 10,
  });

  useEffect(() => {
    if (lease?.lease_status == "paid_off") {
      setLeaseStatus(state => ({ ...state, completed: true }));
    }
    let end_date = new Date(lease?.end_date);
    let start_date = new Date();
    let total_months =
      (end_date.getFullYear() - start_date.getFullYear()) * 12 +
      (end_date.getMonth() - start_date.getMonth());
    setLeaseStatus(state => ({ ...state, months: total_months }));
  }, [lease]);

  if (leaseStatus.completed) {
    return (
      <div>
        <div className="flex justify-center mt-8">
          <p className="text-2xl font-semibold text-gray-600">
            Yay! You have
            <span className="mx-1 text-purple-1000">completed</span> all the
            lease payments.
          </p>
        </div>
        <div className="h-2 mx-6 my-4 rounded-md bg-purple-1000"></div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="flex justify-center mt-8">
          <p className="w-8/12 space-x-2 text-lg font-bold text-center text-gray-600 ">
            Only
            <span className="px-1 text-purple-1000">
              {`${leaseStatus.months} months`}
            </span>
            until you own the lease!
          </p>
        </div>

        <div className="h-2 mx-6 my-4 bg-gray-200 rounded-md ">
          <div className="w-2/3 h-full rounded-md bg-purple-1000 "></div>
        </div>
      </div>
    );
  }
};

export default Status;

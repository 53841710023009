import React from "react";

const Callout = ({
  isEligible,
  displayText,
  colorClass = "bg-purple-1000",
}) => {
  return (
    <div
      className={`absolute top-0 flex justify-center w-full px-4 py-2 text-white rounded-t-md ${colorClass}`}
    >
      {displayText}
      {isEligible && <p className="px-1 underline">Buy Now!</p>}
    </div>
  );
};

export default Callout;

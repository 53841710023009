import React, { useState, useEffect, useRef } from "react";
import { Button, Toastr } from "neetoui";
import { useHistory } from "react-router-dom";
import Spinner from "./Spinner";

import {
  SUBMIT_PAGE_TEXT,
  DEFAULT_ERROR_MESSAGE,
  TIMEOUT_ERROR_MESSAGE,
  REQUEST_TIMEOUT_IN_MS,
} from "../constant";
import experianFraudApi from "apis/customers/experian-fraud";
import { setAuthHeaders } from "apis/axios";
import { useSignupDispatch, useSignupState } from "contexts/signup";
import routeMapper from "utils/routeMapper";
import { creditApplicationSubscription } from "channels/creditApplicationChannel";

const SubmitPage = () => {
  const history = useHistory();
  const signupDetails = useSignupState();
  const signupDispatch = useSignupDispatch();
  const [loading, setLoading] = useState(false);

  const timerRef = useRef(null);

  const sendGtmEvent = (event, data = {}) => {
    if (window.dataLayer)
      window.dataLayer.push({
        event,
        ...signupDetails.personalInfo,
        ...signupDetails.workInfo,
        ...signupDetails.addressInfo,
        ...data,
      });
  };

  const handleResponse = data => {
    setLoading(false);
    if (data.status === 200) {
      const appStatus = data.credit_status || data.fraud_status;

      signupDispatch({
        type: "PERSONAL_INFO",
        payload: {
          ...signupDetails.personalInfo,
          credit_limit: data.credit_limit,
        },
      });

      if (data.message) Toastr.error({ error: data.message });

      if (appStatus) {
        history.push(`${routeMapper[data.page]}/${appStatus}`);
        sendGtmEvent(`application.${appStatus}`, {
          credit_limit: data.credit_limit,
          page: data.page,
          status: appStatus,
        });
      } else if (data.page) {
        if (data.page === "Error") {
          history.push(`${routeMapper["Error"]}?error=${data?.error}`);
          sendGtmEvent("application.error", { error: data?.error });
        } else {
          history.push(routeMapper[data.page]);
          sendGtmEvent(`application.${appStatus}`, { page: data.page });
        }
      }
    } else {
      if (data.status === 503 || data.status === 504) {
        history.push(`${routeMapper["Error"]}?error=${TIMEOUT_ERROR_MESSAGE}`);
        sendGtmEvent("application.error", { error: TIMEOUT_ERROR_MESSAGE });
      } else {
        const errorMessage = data?.error;
        history.push(`${routeMapper["Error"]}?error=${errorMessage}`);
        sendGtmEvent("application.error", { error: errorMessage });
      }
    }
  };

  useEffect(() => {
    creditApplicationSubscription({
      authToken: signupDetails?.personalInfo?.authentication_token,
      email: signupDetails?.personalInfo?.email,
      customerId: signupDetails?.personalInfo.id,
      handleResponse,
    });
  }, []);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const onNextClick = async () => {
    try {
      setLoading(true);
      setAuthHeaders();

      timerRef.current = setTimeout(
        () =>
          history.push(
            `${routeMapper["Error"]}?error=${TIMEOUT_ERROR_MESSAGE}`
          ),
        REQUEST_TIMEOUT_IN_MS
      );

      const { data } = await experianFraudApi.processFraudCheck();

      sendGtmEvent("application.submit");

      const appStatus = data.credit_status || data.fraud_status;

      if (data.message) Toastr.error({ error: data.message });

      if (appStatus) {
        history.push(`${routeMapper[data.page]}/${appStatus}`);
        sendGtmEvent(`application.${appStatus}`, {
          page: data.page,
          status: appStatus,
        });
      } else if (data.page) {
        if (data.page === "Error") {
          history.push(`${routeMapper["Error"]}?error=${data?.error}`);
          sendGtmEvent("application.error", { error: data?.error });
        } else {
          history.push(routeMapper[data.page]);
          sendGtmEvent(`application.${appStatus}`, { page: data.page });
        }
      }
    } catch (error) {
      const errorCode = error.response?.status;
      if (errorCode === 503 || errorCode === 504) {
        history.push(`${routeMapper["Error"]}?error=${TIMEOUT_ERROR_MESSAGE}`);
      } else {
        const errorMessage =
          error?.response?.data?.error || DEFAULT_ERROR_MESSAGE;
        history.push(`${routeMapper["Error"]}?error=${errorMessage}`);
      }
      logger.error(error);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center justify-center becca-homepage">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="px-4 mt-4">
            <div>
              <p className="w-5/6 text-lg">You’re all set!</p>
              <p className="my-2 text-sm text-gray-500">
                Get your approval with no impact to your credit score!
              </p>
            </div>
            <div className="flex justify-center w-full my-4">
              <span className="ri-checkbox-circle-line text-green-700 text-12xl"></span>
            </div>
            <div className="my-4">
              <p className="text-xs text-gray-600 leading-5">
                {SUBMIT_PAGE_TEXT}
              </p>
            </div>
            <Button
              label="Submit application"
              fullWidth={true}
              onClick={() => onNextClick()}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SubmitPage;

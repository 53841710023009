import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button } from "neetoui";

import { useHistory } from "react-router-dom";
import FloatingInput from "components/Common/FloatingInput";
import customersApi from "apis/customer";
import { setAuthHeaders } from "apis/axios";
import { fetchQueryParams } from "utils/pathUtils";
import BeccasHomeLogo from "common/images/beccas-home-logo.png";
import useDocumentTitle from "hooks/useDocumentTitle";
import { getPageTitle } from "common/utils";

const SendResetPassword = ({ email, setEmail }) => {
  const [loading] = useState(false);
  const historyRoute = useHistory();
  const [tempEmail, setTempEmail] = useState(email || fetchQueryParams.email);

  useDocumentTitle(getPageTitle("login", "sendPassword"));

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      setEmail(tempEmail);
      setAuthHeaders();
      await customersApi.resetPassword({
        customer: {
          email: tempEmail,
        },
      });
    } catch (error) {
      logger.error(error);
    }
  };

  const handleMagicLink = () => {
    historyRoute.push("/login/magic-link");
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen p-6 overflow-x-hidden overflow-y-auto bg-white">
      <div className="flex items-center justify-center rounded-md w-14 h-14">
        <img src={BeccasHomeLogo} width="100px" height="100px" />
      </div>
      <div className="flex flex-col items-start justify-center w-full h-full mx-auto sm:max-w-md">
        <div className="flex flex-col justify-start">
          <p className="text-3xl ri-lock-password-line text-purple-1000"></p>
          <h2 className="mt-4 text-3xl font-bold text-gray-800">
            Reset Password
          </h2>
          <p className="mt-3 font-normal text-gray-600">Reset your password.</p>
        </div>
        <form className="w-full mt-6 space-y-5 bg-white">
          <FloatingInput
            id="email"
            value={tempEmail}
            label="Email"
            onChange={e => setTempEmail(e.target.value)}
            required
          />

          <Button
            type="submit"
            loading={loading}
            fullWidth
            label="Send Reset password link"
            onClick={e => handleSubmit(e)}
          />
        </form>
        <div className="flex flex-col items-center justify-center w-full mt-6 ">
          <div className="flex items-center w-full px-8 space-x-2">
            <div className="w-full h-0.5 border border-gray-200" />
            <p>or</p>
            <div className="w-full h-0.5 border border-gray-200" />
          </div>
          <div className="container max-w-xs">
            <Button
              label="Sign in using magic link"
              style="secondary"
              className="flex justify-center w-full p-4 mt-5 text-center"
              onClick={() => handleMagicLink()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SendResetPassword.propTypes = {
  history: PropTypes.object,
};

export default SendResetPassword;

import axios from "axios";

const updateProfile = payload =>
  axios.patch(`api/v1/customers/my/profile/update`, payload);

const getProfile = () => axios.get(`/api/v1/customers/my/profile`);

const customersProfileApi = {
  updateProfile,
  getProfile,
};

export default customersProfileApi;

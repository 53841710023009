export const CUSTOMER_LIST_DATA = [
  {
    customer_name: "Charlie Smith",
    contact_info: "(555) 555-1234",
    email: "charlie@beccashome.com",
    ssn: "420-49-8832",
    status: false,
    credit_status: true,
  },
  {
    customer_name: "Charlie Smith",
    contact_info: "(555) 555-1234",
    email: "charlie@beccashome.com",
    ssn: "420-49-8832",
    status: true,
    credit_status: false,
  },
];

export const CUSTOMER_DETAILS_TAB = [
  "Customer Detail",
  "Leases",
  "Payments",
  "Applications",
  "Notes",
  "_Credit Reporting_",
];

export const ADDNOTE = [
  {
    name: "Ajay Ghosh",
    desc: "_Charlie Smith lost his job and will not be able to pay lease amount for the next 2 months._",
    date: "02/13/2020",
    time: "1:00PM ET",
  },
];

export const ADDLEASENOTE = [
  {
    name: "Ajay Ghosh",
    desc: "Charlie Smith lost his job and will not be able to pay lease amount for the next 2 months.",
    date: "02/13/2020",
    time: "1:00PM ET",
  },
  {
    name: "Ajay Ghosh",
    desc: "Charlie Smith lost his job and will not be able to pay lease amount for the next 2 months.",
    date: "02/13/2020",
    time: "1:00PM ET",
  },
  {
    name: "Ajay Ghosh",
    desc: "Charlie Smith lost his job and will not be able to pay lease amount for the next 2 months.",
    date: "02/13/2020",
    time: "1:00PM ET",
  },
  {
    name: "Ajay Ghosh",
    desc: "Charlie Smith lost his job and will not be able to pay lease amount for the next 2 months.",
    date: "02/13/2020",
    time: "1:00PM ET",
  },
];

export const ACCOUNT_ACTIONS = [
  "Active",
  "Reposession",
  "Delinquent 0 days",
  "Charge Off",
  "Rejected",
];

export const LEASES_ACTIONS = [
  "Ordered",
  "Not yet shipped",
  "In Transit",
  "Delivered",
  "Cancellation Requested",
  "Return Requested",
];

export const ROLE_OPTIONS = [
  { label: "Administrator", value: "Administrator" },
  { label: "Customers", value: "Agennts" },
  { label: "Administraor", value: "Administrato" },
];

export const CustomerStatus = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
});

export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_BLANK_OPTION = { label: "", value: "", color: "" };
export const DEFAULT_RANGE = [null, null];

export const COLUMN_OPTIONS = [{ label: "Name" }, { label: "Account Status" }];

export const FILTER_BY_OPTIONS = [
  { label: "Name" },
  { label: "Account Status" },
];

export const FILTER_DATA = {
  account_status_eq: {
    name: "Account Status",
    type: "select",
    dependentOn: null,
  },
  with_recent_credit_status: {
    name: "Credit Status",
    type: "select",
    dependentOn: null,
  },
  registration_step_eq: {
    name: "Registration Step",
    type: "select",
    dependentOn: null,
  },
  created_at_date_start: {
    name: "Signup Date",
    type: "dateRange",
    dependentOn: null,
    dependentKey: "created_at_date_end_of_day",
  },
  created_at_date_end_of_day: {
    name: "Signup Date",
    type: "dateRange",
    dependentOn: "created_at_date_start",
  },
  with_overdue_amount: {
    name: "Overdue Amount",
    type: "range",
    dependentOn: null,
  },
  with_recent_available_credit: {
    name: "Available Credit",
    type: "range",
    dependentOn: null,
  },
};

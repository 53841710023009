import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { MaskField } from "react-mask-field";
import { Input, Button } from "neetoui";

import customersOtpApi from "apis/customers/otp";
import customersPhoneVerificationApi from "apis/customers/phone-verification";
import { useSignupDispatch, useSignupState } from "contexts/signup";
import formValidationSchemas from "../registerFormValidationSchemas";
import { REGISTRATION_STEPS } from "../constant";
import { maskPhoneNumber } from "common/utils";

const SMSModal = ({
  onModalClose,
  onNextClick,
  initialPhoneNumber,
  resendOtp,
}) => {
  const otpRef0 = React.createRef();
  const otpRef1 = React.createRef();
  const otpRef2 = React.createRef();
  const otpRef3 = React.createRef();

  const [otp, setOtp] = useState(["", "", "", ""]);

  useEffect(() => {
    otpRef0.current.focus();
  }, []);

  const onOTPValueChange = (index, value) => {
    setOtp(state => {
      let updatedState = [...state];
      updatedState[index] = value;

      if (index == 0) otpRef1.current.focus();
      if (index == 1) otpRef2.current.focus();
      if (index == 2) otpRef3.current.focus();
      if (index == 3) otpRef0.current.focus();

      return updatedState;
    });
  };

  const sendGtmEvent = () => {
    if (window.dataLayer)
      window.dataLayer.push({
        event: "application.new",
        phone_number: initialPhoneNumber,
      });
  };

  const handleSubmit = async event => {
    try {
      event.preventDefault();
      const {
        data: { registration_step },
      } = await customersPhoneVerificationApi.verifyOtp({
        phone_number: initialPhoneNumber,
        otp: otp.join(""),
      });

      sendGtmEvent();
      onNextClick(initialPhoneNumber, registration_step);
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <div>
      <div className="fixed top-0 bottom-0 left-0 ">
        <div className="w-screen h-screen bg-gray-300 bg-opacity-75 backdrop-blur-3xl"></div>
        <div className="absolute bottom-0 z-40 flex justify-center w-screen border border-gray-200 shadow-lg">
          <div className="bg-white md:justify-center rounded-t-2xl">
            <div className="p-2">
              <div className="flex items-center justify-end">
                <Button
                  className="text-2xl"
                  style="text"
                  icon="ri-close-line"
                  onClick={() => onModalClose()}
                />
              </div>
              <div className="flex flex-col items-center justify-center mt-6 space-y-4">
                <p className="w-5/6 text-lg">
                  {`The verification code was sent to ${initialPhoneNumber}. Enter it below.`}
                </p>
                <div className="flex justify-between px-6 space-x-2">
                  <Input
                    ref={otpRef0}
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[0]}
                    required
                    maxlength="1"
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    onChange={e => onOTPValueChange(0, e.target.value)}
                  />
                  <Input
                    ref={otpRef1}
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[1]}
                    required
                    maxlength="1"
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    onChange={e => onOTPValueChange(1, e.target.value)}
                  />
                  <Input
                    ref={otpRef2}
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[2]}
                    required
                    maxlength="1"
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    onChange={e => onOTPValueChange(2, e.target.value)}
                  />
                  <Input
                    ref={otpRef3}
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[3]}
                    required
                    maxlength="1"
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    onChange={e => onOTPValueChange(3, e.target.value)}
                  />
                </div>
                <Button
                  label="Next"
                  fullWidth={true}
                  onClick={evt => handleSubmit(evt)}
                />
                <div className="self-start">
                  Didn’t work?
                  <Button
                    style="link"
                    className="inline ml-2"
                    label="Resend OTP"
                    onClick={() => resendOtp(initialPhoneNumber)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OtpPage = () => {
  const signupDispatch = useSignupDispatch();
  const signupDetails = useSignupState();
  const [smsModal, setSmsModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [initialPhoneNumber, setInitialPhoneNumber] = useState(
    signupDetails?.phone_number || ""
  );
  const [maskedData, setMaskedData] = useState({});

  useEffect(() => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("authEmail");
    localStorage.removeItem("role");
  }, []);

  useEffect(() => {
    if (signupDetails.phone_number) {
      setInitialPhoneNumber(signupDetails.phone_number);
      setMaskedData({
        maskedValue: maskPhoneNumber(signupDetails.phone_number),
      });
    }
  }, [signupDetails]);

  const onNextClick = (phone, nextPage) => {
    onModalClose();
    signupDispatch({ type: "OTP", payload: phone });
    window.location.href =
      REGISTRATION_STEPS[nextPage] || REGISTRATION_STEPS["personal_info"];
  };

  const onModalClose = () => {
    setSmsModal(false);
  };

  const resendOtp = async () => {
    try {
      await customersOtpApi.create({ phone_number: initialPhoneNumber });
    } catch (error) {
      logger.error(error);
    }
  };

  const verifyNumber = async ({ phone_number }) => {
    setInitialPhoneNumber(phone_number);
    try {
      setLoading(true);
      const response = await customersPhoneVerificationApi.registerPhone({
        phone: {
          country_code: "+1",
          phone_number: phone_number,
        },
      });
      if (response.status === 200) {
        setSmsModal(true);
      }
    } catch (apiError) {
      logger.error(apiError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="px-4 mt-4">
        <p className="w-5/6 mb-6 text-lg">
          Get approved for New Furniture In 2 minutes with no impact to your
          credit score.
        </p>
        <p className="mb-4 text-base font-semibold">
          Verify your number to get started.
        </p>
        <p className="mb-4 text-sm text-gray-500">
          By continuing, you agree to the &nbsp;
          <Button
            className="text-sm"
            style="link inline font-light"
            label="Privacy Policy"
            type="link"
            href="https://beccashome.com/privacy-policy"
          />
          ,&nbsp;
          <Button
            className="text-sm"
            style="link inline font-light"
            label="Terms of Use"
            type="link"
            href="https://beccashome.com/terms-and-conditions/"
          />
          , and &nbsp;
          <Button
            className="text-sm"
            style="link inline font-light"
            label="Esign Disclosure"
            type="link"
            href="https://beccashome.com/esign-consent/"
          />
          &nbsp; and to be contacted at the number you enter below for
          notifications and marketing. Message and data rates may apply.
        </p>

        <Formik
          initialValues={{ phone_number: initialPhoneNumber || "" }}
          enableReinitialize={true}
          onSubmit={verifyNumber}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={formValidationSchemas.phoneInfo}
        >
          {({ setFieldValue, errors }) => (
            <Form noValidate>
              <div className="nui-input__wrapper mb-5">
                <div
                  className={`nui-input ${
                    errors.phone_number && "nui-input--error"
                  }`}
                >
                  <MaskField
                    placeholder="(555) 555-1234"
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    value={maskedData?.maskedValue}
                    mask="+1 (___) ___-____"
                    replacement={{ _: /\d/ }}
                    onMasking={event => {
                      setMaskedData(event.detail);
                    }}
                    onChange={() =>
                      setFieldValue("phone_number", maskedData?.unmaskedValue)
                    }
                  />
                </div>
                {errors.phone_number && (
                  <p className="nui-input__error">{errors.phone_number}</p>
                )}
              </div>
              <Button
                label="Text me my code"
                fullWidth={true}
                disabled={loading}
                type="submit"
              />
            </Form>
          )}
        </Formik>
        <div className="flex w-full mt-4 space-x-4">
          <span className="ri-lock-line text-2xl text-gray-500"></span>
          <p className="text-xs text-gray-500">
            Your phone number is used to verify your identity and protect your
            info. It can also be used to take you to where you left off.
          </p>
        </div>
        <div className="my-4">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-white px-2 text-gray-500">
                Or already have an account?
              </span>
            </div>
          </div>
        </div>
        <Button label="Login now!" fullWidth to="/login" style="secondary" />
      </div>
      {smsModal && (
        <SMSModal
          onModalClose={onModalClose}
          onNextClick={onNextClick}
          initialPhoneNumber={initialPhoneNumber}
          resendOtp={resendOtp}
        />
      )}
    </>
  );
};

export default OtpPage;

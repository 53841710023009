import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Formik } from "formik";
import { Input as FormikInput } from "neetoui/formik";
import { MaskField } from "react-mask-field";
import { Button, Label, Input } from "neetoui";

import authenticationApi from "apis/customers/authentication";
import customersProfileApi from "apis/customers/profile";
import formValidationSchemas from "../registerFormValidationSchemas";
import { setAuthHeaders } from "apis/axios";
import { useSignupDispatch, useSignupState } from "contexts/signup";
import { isString } from "common/utils";

const Personal = () => {
  const history = useHistory();
  const signupDispatch = useSignupDispatch();
  const signupDetails = useSignupState();
  const [loading, setLoading] = useState(false);
  const [initialValues] = useState(signupDetails.personalInfo);
  const [maskedData, setMaskedData] = useState({});
  const [isExistingDob, setIsExistingDob] = useState(
    signupDetails?.personalInfo?.date_of_birth
  );

  useEffect(() => {
    const dob = signupDetails?.personalInfo?.date_of_birth;

    if (dob) {
      setMaskedData({
        unmaskedValue: dob,
        maskedValue: new Date(dob).toLocaleDateString(),
      });
    }
  }, [isExistingDob]);

  const sendGtmEvent = customer => {
    if (window.dataLayer)
      window.dataLayer.push({
        event: "application.personal_info",
        ...customer,
      });
  };

  const handleSubmit = async details => {
    try {
      setLoading(true);
      const payload = {
        customer: { ...details, phone_number: signupDetails.phone_number },
      };
      const { data } = await callApi(payload);

      signupDispatch({
        type: "PERSONAL_INFO",
        payload: {
          ...details,
          ...data.customer,
        },
      });
      sendGtmEvent(payload.customer);
      history.push("/flow/register/work-info");
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDOBYearFromValues = values => {
    if (isString(values?.date_of_birth))
      return values?.date_of_birth?.substring(0, 4);
    else if (values?.date_of_birth instanceof Date)
      return values?.date_of_birth.getFullYear();
    else return "";
  };

  const callApi = async payload => {
    if (signupDetails.id) {
      setAuthHeaders();
      return await customersProfileApi.updateProfile(payload);
    } else {
      return await authenticationApi.signup(payload);
    }
  };

  return (
    <>
      <div className="px-4 mt-4">
        <p className="w-5/6 mb-6 text-lg">
          Thanks! Enter your info to continue.
        </p>
        <p className="mb-4 text-sm text-gray-500">
          It’s legally required to collect your personal information for your
          application.
        </p>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={formValidationSchemas.personalInfo}
        >
          {({ setFieldValue, errors, values }) => (
            <Form noValidate>
              <div className="space-y-4">
                <FormikInput label="Email" name="email" required />
                <FormikInput
                  label="Confirm Email"
                  name="confirm_email"
                  required
                />
                <FormikInput label="First Name" name="first_name" required />
                <FormikInput label="Last Name" name="last_name" required />
                <div className="flex space-x-2">
                  <FormikInput label="Middle Name" name="middle_name" />
                  <FormikInput label="Suffix" name="suffix" />
                </div>

                <div>
                  <Label required>Date of Birth (MM/DD/YYYY)</Label>
                  {isExistingDob ? (
                    <Input
                      name="fakessn"
                      onClick={() => setIsExistingDob(false)}
                      value={`**/**/${fetchDOBYearFromValues(values)}`}
                    />
                  ) : (
                    <div className="nui-input__wrapper mb-5">
                      <div
                        className={`nui-input ${
                          errors.date_of_birth && "nui-input--error"
                        }`}
                      >
                        <MaskField
                          showMask={maskedData?.maskedValue}
                          placeholder="MM/DD/YYYY"
                          type="tel"
                          pattern="[\d]*"
                          inputMode="numeric"
                          value={maskedData?.maskedValue}
                          mask="__/__/____"
                          replacement={{ _: /\d/ }}
                          onMasking={event => {
                            setMaskedData(event.detail);
                          }}
                          onBlur={() =>
                            setFieldValue(
                              "date_of_birth",
                              maskedData?.maskedValue &&
                                new Date(maskedData?.maskedValue)
                            )
                          }
                        />
                      </div>

                      {errors.date_of_birth && (
                        <p className="nui-input__error">
                          {errors.date_of_birth}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <Button label="Next" type="submit" loading={loading} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Personal;

import React, { useState, useEffect, useRef } from "react";
import routeMapper from "utils/routeMapper";
import { useHistory } from "react-router-dom";

import { Toastr } from "neetoui";
import Spinner from "./Spinner";
import { setAuthHeaders } from "apis/axios";
import experianFraudApi from "apis/customers/experian-fraud";
import { useSignupState, useSignupDispatch } from "contexts/signup";
import KiqQuestions from "components/MyAccount/common/KiqQuestions";
import {
  DEFAULT_ERROR_MESSAGE,
  TIMEOUT_ERROR_MESSAGE,
  REQUEST_TIMEOUT_IN_MS,
} from "../constant";
import { creditApplicationSubscription } from "channels/creditApplicationChannel";

const AdditionalInfo = () => {
  const history = useHistory();
  const signupDetails = useSignupState();
  const signupDispatch = useSignupDispatch();
  const [loading, setLoading] = useState(false);
  const timerRef = useRef(null);

  const kbaQuestions = JSON.parse(localStorage.getItem("kbaQuestions")) || [];

  const handleResponse = data => {
    setLoading(false);

    if (data.status === 200) {
      const appStatus = data.credit_status || data.fraud_status;
      const creditLimit = data.credit_limit;
      if (creditLimit) {
        signupDispatch({
          type: "PERSONAL_INFO",
          payload: {
            ...signupDetails.personalInfo,
            credit_limit: creditLimit,
          },
        });

        history.push(
          `${routeMapper[data.page]}/${appStatus}?limit=${creditLimit}`
        );
      } else if (appStatus) {
        history.push(`${routeMapper[data.page]}/${appStatus}`);
      } else {
        if (data.message) Toastr.success(data.message);
        history.push(routeMapper[data.page]);
      }
    } else {
      if (data.status === 503 || data.status === 504) {
        history.push(`${routeMapper["Error"]}?error=${TIMEOUT_ERROR_MESSAGE}`);
      } else {
        const errorMessage = data?.error;
        history.push(`${routeMapper["Error"]}?error=${errorMessage}`);
      }
    }
  };

  useEffect(() => {
    creditApplicationSubscription({
      authToken: signupDetails?.personalInfo?.authentication_token,
      email: signupDetails?.personalInfo?.email,
      customerId: signupDetails?.personalInfo.id,
      handleResponse,
    });
  }, []);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const handleSubmit = async details => {
    const answersObj = {};
    Object.keys(details).forEach(questionType => {
      const question = kbaQuestions.find(q => q.questionType == questionType);
      const index = kbaQuestions.indexOf(question);
      answersObj[`outWalletAnswer${index + 1}`] =
        question.questionSelect.questionChoice.indexOf(details[questionType]) +
        1;
    });

    try {
      setLoading(true);
      setAuthHeaders();
      const payload = { answers_obj: answersObj };

      timerRef.current = setTimeout(
        () =>
          history.push(
            `${routeMapper["Error"]}?error=${TIMEOUT_ERROR_MESSAGE}`
          ),
        REQUEST_TIMEOUT_IN_MS
      );

      await experianFraudApi.processFraudKiq(payload);
    } catch (error) {
      const errorCode = error.response?.status;

      if (errorCode === 503 || errorCode === 504) {
        history.push(`${routeMapper["Error"]}?error=${TIMEOUT_ERROR_MESSAGE}`);
      } else {
        const errorMessage =
          error?.response?.data?.error || DEFAULT_ERROR_MESSAGE;
        history.push(`${routeMapper["Error"]}?error=${errorMessage}`);
      }

      logger.error(error);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center justify-center becca-homepage">
          <Spinner />
        </div>
      ) : (
        <>
          <KiqQuestions
            handleSubmit={handleSubmit}
            kbaQuestions={kbaQuestions}
          />
        </>
      )}
    </>
  );
};

export default AdditionalInfo;

import React from "react";
import { Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import classNames from "classnames";

import NewCardPayment from "./NewCardPayment";
import BankPayment from "./BankPayment";
import CardPayment from "./CardPayment";
import PaymentStatus from "./PaymentStatus";
import PaymentMethods from "./PaymentMethods";
import Footer from "./Footer";

const PaymentHome = () => {
  const history = useHistory();
  const showFooter =
    history.location.pathname == "/payment/:customerId/payment/card/new" ||
    history.location.pathname == "/payment/:customerId/payment/confirm"
      ? true
      : false;
  return (
    <div
      className={classNames(["justify-center bg-gray-100"], {
        "w-full": isMobile,
      })}
    >
      <div className="flex items-center justify-center w-full overflow-y-scroll">
        <div className="w-full becca-page-maxheight">
          <div className="bg-white border border-gray-200 rounded-md shadow-md">
            <Switch>
              <Route
                exact
                path="/payment/:customerId/payment"
                component={PaymentMethods}
              />
              <Route
                exact
                path="/payment/:customerId/payment/bank/new"
                component={BankPayment}
              />
              <Route
                exact
                path="/payment/:customerId/payment/card"
                component={CardPayment}
              />
              <Route
                exact
                path="/payment/:customerId/payment/card/new"
                component={NewCardPayment}
              />
              <Route
                path="/payment/:customerId/payment/confirm"
                component={PaymentStatus}
              />
            </Switch>
          </div>
        </div>
      </div>
      {!showFooter && isMobile && <Footer />}
    </div>
  );
};

export default PaymentHome;

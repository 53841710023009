import axios from "axios";

const list = customer_id =>
  axios.get(`/api/v1/admin/customers/${customer_id}/customer_notes`);
const create = (customer_id, payload) =>
  axios.post(`/api/v1/admin/customers/${customer_id}/customer_notes`, payload);

const customerNotesApi = {
  list,
  create,
};

export default customerNotesApi;

import React, { useEffect, useState } from "react";
import { PageLoader } from "neetoui";

import { JsonFormatter } from "react-json-formatter";
import { useParams } from "react-router-dom";
import creditApplicationsApi from "apis/credit-applications";
import { toTitleCase } from "utils/styleUtils";
import useDocumentTitle from "hooks/useDocumentTitle";

const ApplicationJsonViewer = () => {
  const { id, applicationId, logType } = useParams();
  const JsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useDocumentTitle(`Application ${applicationId} ${logType}`);

  const fetchLogs = async () => {
    setLoading(true);
    try {
      const {
        data: { credit_application },
      } = await creditApplicationsApi.creditApplication(id, applicationId);
      setLogs(
        credit_application.experian_logs.filter(log =>
          log.log_type.includes(logType.toLowerCase())
        )
      );
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <div className="p-24">
          {logs.map(item => (
            <div key={item.id} className="my-4">
              <h5 className="text-lg font-semibold">
                {toTitleCase(item.log_type, "_")}:
              </h5>
              <JsonFormatter
                json={JSON.stringify(item?.response)}
                tabWith="4"
                JsonStyle={JsonStyle}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ApplicationJsonViewer;

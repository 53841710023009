import React from "react";
import classnames from "classnames";

export const Header = ({ className = "", children }) => {
  return (
    <div
      className={classnames("bg-purple-1000 w-full px-4 py-2 rounded-t-md", [
        className,
      ])}
    >
      {children}
    </div>
  );
};

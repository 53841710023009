import axios from "axios";

const updateSettings = payload =>
  axios.post("api/v1/admin/experian_settings", payload);

const fetchSettings = () => axios.get("api/v1/admin/experian_settings");

const qaServiceApi = {
  updateSettings,
  fetchSettings,
};

export default qaServiceApi;

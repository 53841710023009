import React, { useState, useEffect } from "react";
import { Input, Collapse, Checkbox } from "neetoui";
import classNames from "classnames";
import { useDebouncedCallback } from "use-debounce";

const AmountBox = ({
  children,
  title,
  amount,
  isValid = false,
  overdue = false,
  isDisable = false,
  isChecked = false,
  onCheckboxChange = () => {},
  onAmountChange = () => {},
  formatAmounts = () => {},
}) => {
  const [isCollapseOpen, setCollapse] = useState(false);

  const [value, setValue] = useState(amount);

  useEffect(() => {
    setValue(amount);
  }, [amount]);

  useEffect(() => {
    !isChecked && setCollapse(false);
  }, [isChecked]);

  const valueChange = useDebouncedCallback(e => {
    onAmountChange(e.target.value);
  }, 500);

  return (
    isValid && (
      <div
        className={classNames(
          ["border rounded-md"],
          {
            "border-red-400": overdue,
            "border-purple-1000": !overdue && isChecked,
            "border-gray-300": !overdue,
          },
          {
            "bg-gray-100": isDisable,
          }
        )}
      >
        <div className="flex justify-between p-2">
          <div className="flex space-x-2">
            <Checkbox
              className={classNames(
                ["pt-1"],
                { "pointer-events-none": isDisable },
                { "pointer-events-none": overdue }
              )}
              checked={isChecked}
              onChange={() => {
                !isDisable && !overdue && onCheckboxChange();
                formatAmounts();
              }}
              disabled={isDisable || overdue}
            />
            <div>
              <p
                className={classNames({
                  "text-purple-1000": !overdue && isChecked,
                  "text-gray-600": !overdue,
                  "text-red-600": overdue,
                })}
              >
                {title}
              </p>
              <p
                className={classNames(["pl-1 underline cursor-pointer"], {
                  "text-purple-1000": !overdue && isChecked,
                  "text-gray-600": !overdue,
                  "text-red-600": overdue,
                })}
                onClick={() => setCollapse(state => !state)}
              >
                {isCollapseOpen ? "Hide details" : "View details"}
              </p>
            </div>
          </div>
          <div className="w-20">
            <Input
              value={value}
              prefix={"$"}
              onChange={e => {
                !isDisable && setValue(e.target.value);
                valueChange(e);
              }}
              onBlur={formatAmounts}
              disabled={isDisable || overdue || !isChecked}
            />
          </div>
        </div>
        <Collapse open={isCollapseOpen}>
          <div className="pl-8 pr-2 text-gray-400">{children}</div>
        </Collapse>
      </div>
    )
  );
};

export default AmountBox;

import React, { useState } from "react";
import { Tab } from "@bigbinary/neetoui";
import { APPLICATION_TABS } from "./constants";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { toTitleCase } from "utils/styleUtils";

const PrettyPrintJson = ({ data }) => (
  <div className="overflow-scroll">
    <pre>{JSON.stringify(data, null, 2)}</pre>
  </div>
);

const FraudData = ({ data }) => {
  const [isCollapseOpen, setCollapse] = useState(false);
  return (
    <>
      {data.map(item => (
        <div
          key={item.id}
          className="p-8 m-6 overflow-scroll bg-gray-100 application-accordion"
        >
          <div className="flex space-x-1">
            {toTitleCase(item.log_type, "_")}
            <p
              className={classNames(["pl-1 cursor-pointer"])}
              onClick={() => setCollapse(state => !state)}
            >
              {isCollapseOpen ? (
                <i className="ri-arrow-up-s-line"></i>
              ) : (
                <i className="ri-arrow-down-s-line"></i>
              )}
            </p>
          </div>

          {isCollapseOpen && (
            <div>
              <p className="mt-5 mb-5 bg-white rounded-md shadow p-2 text-center">
                REQUEST
              </p>
              <PrettyPrintJson data={item.request} />
              <p className="mt-5 mb-5 bg-white rounded-md shadow p-2 text-center">
                RESPONSE
              </p>
              <PrettyPrintJson data={item.response} />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

const CreditData = ({ data }) => {
  const [isCollapseOpen, setCollapse] = useState(false);

  return (
    <>
      {data.map(item => (
        <div
          key={item}
          className="p-8 m-6 overflow-scroll bg-gray-100 application-accordion"
        >
          <div className="flex space-x-1">
            {toTitleCase(item.log_type, "_")}
            <p
              className={classNames(["pl-1 cursor-pointer"])}
              onClick={() => setCollapse(state => !state)}
            >
              {isCollapseOpen ? (
                <i className="ri-arrow-up-s-line"></i>
              ) : (
                <i className="ri-arrow-down-s-line"></i>
              )}
            </p>
          </div>
          {isCollapseOpen && (
            <div>
              <p className="mt-5 mb-5 bg-white rounded-md shadow p-2 text-center">
                REQUEST
              </p>
              <PrettyPrintJson data={item.request} />
              <p className="mt-5 mb-5 bg-white rounded-md shadow p-2 text-center">
                RESPONSE
              </p>
              <PrettyPrintJson data={item.response} />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

const ManualCreditDetails = ({ application }) => {
  const details = application.manual_credit_details;
  return (
    <>
      <div className="grid gap-4 px-1 application-table">
        <div className="text-center">Top Up</div>
        <div className="text-center">Added On</div>
        <div className="text-center">Expires On</div>
      </div>
      {details.map(detail => (
        <div key={detail.manual_expires_at} className="py-8 mt-6 bg-gray-100">
          <div className="grid gap-4 px-1 application-table">
            <div className="text-center">{detail.manual_credit}</div>
            <div className="text-center">{detail.added_on}</div>
            <div className="text-center">
              {detail.manual_expires_at || application.expires_at}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const renderTab = (currentTab, application) => {
  const experianLogs = application.experian_logs;
  const fraudData = experianLogs.filter(log => log.log_type.includes("fraud"));
  const creditData = experianLogs.filter(log =>
    log.log_type.includes("credit")
  );

  switch (currentTab) {
  case APPLICATION_TABS[0]:
    return <FraudData data={fraudData} />;

  case APPLICATION_TABS[1]:
    return <CreditData data={creditData} />;

  case APPLICATION_TABS[2]:
    return <ManualCreditDetails application={application} />;
  }
};

const Tabs = ({ application }) => {
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState(APPLICATION_TABS[0]);

  return (
    <div className="h-full px-4">
      <div className="grid justify-between w-full grid-cols-3 px-6 mb-6 ">
        <Tab className="w-full px-4 border-b border-gray-100">
          {APPLICATION_TABS.map((tab, index) => {
            return (
              <Tab.Item
                key={index}
                active={currentTab === tab}
                onClick={() => setCurrentTab(tab)}
              >
                <div className="flex space-x-4">
                  <p>{tab}</p>
                  <i
                    className="ri-external-link-line"
                    onClick={() =>
                      window.open(
                        `/customers/${id}/applications/${
                          application.id
                        }/${currentTab.toLowerCase()}`,
                        "_blank"
                      )
                    }
                  ></i>
                </div>
              </Tab.Item>
            );
          })}
        </Tab>
      </div>
      {application.experian_logs && renderTab(currentTab, application)}
    </div>
  );
};
export default Tabs;

import React from "react";
import CardIcon from "common/images/card_logo.svg";
import classNames from "classnames";
import { isDesktop } from "react-device-detect";

const NewCardRepresentation = ({ cardValues }) => {
  return (
    <div
      className={classNames(["h-48 p-6 card-background"], {
        "w-80": isDesktop,
      })}
    >
      <div className="flex justify-end my-4">
        <img src={CardIcon} width="40px" height="40px" />
      </div>
      <div className="my-4 font-light text-white"></div>
      <div className="my-4 font-light text-white">{cardValues.card_number}</div>
      <div className="flex justify-between">
        <div className="space-y-2">
          <p className="font-semibold text-gray-300 uppercase">card Holder</p>
          <p className="text-white"></p>
          <p className="text-white uppercase ">{cardValues.name}</p>
        </div>
        <div className="space-y-2">
          <p className="font-semibold text-gray-300 uppercase">expiry date</p>
          <p className="text-white"></p>
          <p className="text-white">{cardValues.expiry_date}</p>
        </div>
      </div>
    </div>
  );
};

export default NewCardRepresentation;

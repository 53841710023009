import axios from "axios";

const payments = customer_id =>
  axios.get(`api/v1/customers/${customer_id}/payments`);

const create = (customer_id, payload) =>
  axios.post(`api/v1/customers/${customer_id}/payments`, payload);

const paymentsApi = {
  payments,
  create,
};

export default paymentsApi;

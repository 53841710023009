import React from "react";
import { Route, Switch } from "react-router";

import IconHeader from "components/SignUp/Common/IconHeader";
import PageProgressbar from "components/SignUp/Common/PageProgressbar";
import Otp from "components/SignUp/Registration/Otp";
import { useSignupState } from "contexts/signup";
import PrivateRoute from "components/Common/PrivateRoute";
import { SIGNUP_ROUTES } from "../constant";
import { getPageTitle } from "common/utils";
import withTitle from "components/Common/withTitle";

const SignupRoutes = () => {
  const signupDetails = useSignupState();
  const registrationStep = signupDetails?.personalInfo?.registration_step;
  const isRegistering =
    signupDetails?.phone_number && registrationStep !== "completed";

  const OTPWithTitle = withTitle({
    Component: Otp,
    title: getPageTitle("register", "otp"),
  });

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen md:max-w-lg md:mx-auto">
      <PageProgressbar />
      <div className="w-full application_flow_container">
        <IconHeader />

        <Switch>
          <Route exact path="/flow/register/otp" component={OTPWithTitle} />

          {isRegistering ? (
            <>
              {SIGNUP_ROUTES.map(({ titleKey, component, path }) => {
                const title = getPageTitle("register", titleKey);
                const Component = withTitle({ Component: component, title });

                return (
                  <Route component={Component} exact key={path} path={path} />
                );
              })}
            </>
          ) : (
            <>
              <PrivateRoute
                path="*"
                redirectRoute="/flow/register/otp"
                condition={isRegistering}
                component={Otp}
              />
            </>
          )}
        </Switch>
      </div>
    </div>
  );
};

export default SignupRoutes;

import axios from "axios";

const emailVerify = payload =>
  axios.post(`api/v1/customers/email_verifications/`, payload);

const login = payload => axios.post("api/v1/customers/session", payload);

const loginWithoutPassword = payload =>
  axios.post("api/v1/customers/registration_completions", payload);

const logout = () => axios.delete("api/v1/customers/logout");

const signup = payload => axios.post("api/v1/customers/sign_up", payload);

const sendMagicLink = payload => axios.post("api/v1/customers/login", payload);

const authenticationApi = {
  login,
  logout,
  signup,
  emailVerify,
  sendMagicLink,
  loginWithoutPassword,
};

export default authenticationApi;

import React, { useState, useEffect } from "react";

import { Header, SubHeader } from "@bigbinary/neetoui/layouts";
import { Button, Dropdown } from "@bigbinary/neetoui";

import agentsApi from "apis/agents";
import NoDataImage from "common/images/no-data.svg";
import EmptyState from "components/Common/EmptyState";

import AgentForm from "./AgentForm";
import AgentsTable from "./AgentsTable";

import {
  DEFAULT_PAGE_SIZE,
  COLUMN_OPTIONS,
  FILTER_BY_OPTIONS,
  AgentsStatus,
} from "./constants";

const Agents = () => {
  const [status, setStatus] = useState(AgentsStatus.IDLE);
  const [agents, setAgents] = useState(null);
  const [agentBeingEdited, setAgentBeingEdited] = useState(null);
  const [totalAgentsCount, setTotalAgentsCount] = useState(null);

  const [showPane, setShowPane] = useState(false);
  const [searchString, setSearchString] = useState();
  const [pageIndex, setPageIndex] = useState(1);

  const closePane = () => {
    setShowPane(false);
    agentBeingEdited && setAgentBeingEdited(null);
  };

  useEffect(() => {
    agentBeingEdited && setShowPane(true);
  }, [agentBeingEdited]);

  const fetchAgents = async () => {
    try {
      setStatus(AgentsStatus.LOADING);
      const {
        data: { agents, agents_count },
      } = await agentsApi.agents();

      setAgents(agents);
      setTotalAgentsCount(agents_count);
      setStatus(AgentsStatus.SUCCESS);
    } catch (error) {
      setStatus(AgentsStatus.ERROR);
    }
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  return (
    <>
      <Header
        title="Agents"
        actionBlock={
          <Button
            label="New Agent"
            icon="ri-add-line"
            onClick={() => setShowPane(true)}
          />
        }
      />
      <SubHeader
        searchProps={{
          value: searchString,
          onChange: e => setSearchString(e.target.value),
          clear: () => setSearchString(""),
        }}
        paginationProps={{
          count: totalAgentsCount,
          pageNo: pageIndex,
          pageSize: DEFAULT_PAGE_SIZE,
          navigate: index => setPageIndex(index),
        }}
        actionBlock={
          <div className="flex">
            <Dropdown
              buttonStyle="secondary"
              label="Filter By"
              position="bottom-right"
              data-cy="change-status-dropdown"
              className="mr-3"
            >
              {FILTER_BY_OPTIONS.map(option => {
                return <li key={option.label}>{option.label}</li>;
              })}
            </Dropdown>
            <Dropdown
              buttonStyle="secondary"
              label="Columns"
              position="bottom-right"
              data-cy="change-status-dropdown"
            >
              {COLUMN_OPTIONS.map(option => {
                return <li key={option.label}>{option.label}</li>;
              })}
            </Dropdown>
          </div>
        }
      />

      {totalAgentsCount === 0 ? (
        <EmptyState
          image={NoDataImage}
          title="No Agents"
          subtitle="There are no agents available to show."
          primaryAction={() => setShowPane(true)}
          primaryActionLabel="New Agent"
        />
      ) : (
        <AgentsTable loading={status === AgentsStatus.LOADING}>
          <AgentsTable.Header />
          <AgentsTable.Body
            agents={agents}
            fetchAgents={fetchAgents}
            setAgentBeingEdited={setAgentBeingEdited}
          />
        </AgentsTable>
      )}

      <AgentForm
        refetchAgents={fetchAgents}
        showPane={showPane}
        agentBeingEdited={agentBeingEdited}
        closePane={closePane}
      />
    </>
  );
};

export default Agents;

import React from "react";
import classNames from "classnames";

const FloatingInput = ({ label, error = "", ...props }) => {
  return (
    <div>
      <div className="floating-input-label">
        <input
          className={classNames("rounded-lg floating-input", {
            "border border-red-500 rounded-lg": error,
          })}
          {...props}
          placeholder=" "
        />
        <span className="highlight"></span>
        <label className="floating-label">{label}</label>
      </div>
      {error && <p className="pt-1 text-xs text-red-500">{error}</p>}
    </div>
  );
};

export default FloatingInput;

import React, { useState } from "react";
import { SubHeader } from "neetoui/layouts";
import PaymentItem from "./PaymentItem";
import { filterOutFailedManualPayments } from "common/utils";

const PaymentHistory = ({ payments }) => {
  const [pageIndex, setPageIndex] = useState(1);

  return (
    <div className="px-4 lg:max-w-6xl lg:mx-auto">
      {payments?.length > 0 && (
        <>
          <div className="py-2 font-bold">Payment Activity</div>
          <div>
            <SubHeader
              paginationProps={{
                count: payments?.length,
                pageNo: pageIndex,
                pageSize: 100,
                navigate: index => setPageIndex(index),
              }}
            />
          </div>
        </>
      )}
      <div>
        {payments &&
          filterOutFailedManualPayments(payments).map(payment => (
            <PaymentItem key={payment.id} payment={payment} />
          ))}
      </div>
    </div>
  );
};

export default PaymentHistory;

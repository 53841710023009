import React, { useState, useEffect } from "react";
import { Pane, Select, DateRangeInput, Input, Label } from "neetoui";

import {
  ACCOUNT_STATUS_OPTIONS,
  CREDIT_STATUS_OPTIONS,
  REGISTRATION_STEP_OPTIONS,
} from "common/constants";

export const FilterPane = ({
  isFilterOpen,
  toggleFilter,
  accountStatus,
  setAccountStatus,
  creditStatus,
  setCreditStatus,
  registrationStep,
  setRegistrationStep,
  createdAt,
  setCreatedAt,
  withOverdueAmount,
  setWithOverdueAmount,
  availableCredit,
  setAvailableCredit,
  filters,
  setFilters,
  setPageIndex,
}) => {
  const [tempFilters, setTempFilters] = useState(filters);

  useEffect(() => {
    setTempFilters(filters);
  }, [filters]);

  return (
    <Pane
      title="Filter Customers"
      isOpen={isFilterOpen}
      onClose={() => toggleFilter(false)}
      cancelButtonProps={{
        label: "Cancel",
        onClick: () => toggleFilter(false),
      }}
      submitButtonProps={{
        label: "Apply Filters",
        onClick: () => {
          toggleFilter(false);
          setFilters(tempFilters);
          setPageIndex(1);
        },
      }}
      showFooter
    >
      <div className="pb-20 mx-6 overflow-auto ">
        <div className="form-group mb-5">
          <Select
            name="account_status_eq"
            label="Account Status"
            placeholder="Select"
            size="small"
            options={ACCOUNT_STATUS_OPTIONS}
            value={accountStatus}
            onChange={selectedOption => {
              setAccountStatus(selectedOption);
              setTempFilters({
                ...tempFilters,
                account_status_eq: selectedOption.value,
              });
            }}
          />
        </div>
        <div className="form-group mb-5">
          <Select
            name="with_recent_credit_status"
            label="Credit Status"
            placeholder="Select"
            size="small"
            options={CREDIT_STATUS_OPTIONS}
            value={creditStatus}
            onChange={selectedOption => {
              setCreditStatus(selectedOption);
              setTempFilters({
                ...tempFilters,
                with_recent_credit_status: selectedOption.value,
              });
            }}
          />
        </div>
        <div className="form-group mb-5">
          <Select
            name="registration_step_eq"
            label="Registration Step"
            placeholder="Select"
            size="small"
            options={REGISTRATION_STEP_OPTIONS}
            value={registrationStep}
            onChange={selectedOption => {
              setRegistrationStep(selectedOption);
              setTempFilters({
                ...tempFilters,
                registration_step_eq: selectedOption.value,
              });
            }}
          />
        </div>
        <div className="form-group mb-5">
          <DateRangeInput
            placeholder="Signup Date"
            name="created_at"
            format="MM/DD/YYYY"
            label="Signup Date"
            value={createdAt}
            onChange={dates => {
              setCreatedAt(dates);
              setTempFilters({
                ...tempFilters,
                created_at_date_start: dates[0]
                  ? dates[0].toLocaleDateString("en-US")
                  : null,
                created_at_date_end_of_day: dates[1]
                  ? dates[1].toLocaleDateString("en-US")
                  : null,
              });
            }}
          />
        </div>
        <div className="form-group mb-5 flex">
          <Label>Overdue Amount</Label>
        </div>
        <div className="form-group mb-5 flex">
          <Input
            placeholder="Enter min value"
            className="inline"
            value={withOverdueAmount[0]}
            onChange={e => {
              setWithOverdueAmount([e.target.value, withOverdueAmount[1]]);
              setTempFilters({
                ...tempFilters,
                with_overdue_amount: [
                  parseFloat(e.target.value).toFixed(1),
                  parseFloat(withOverdueAmount[1]).toFixed(1),
                ],
              });
            }}
          />
          <Input
            placeholder="Enter max value"
            className="ml-4 inline"
            value={withOverdueAmount[1]}
            onChange={e => {
              setWithOverdueAmount([withOverdueAmount[0], e.target.value]);
              setTempFilters({
                ...tempFilters,
                with_overdue_amount: [
                  parseFloat(withOverdueAmount[0]).toFixed(1),
                  parseFloat(e.target.value).toFixed(1),
                ],
              });
            }}
          />
        </div>

        <div className="form-group mb-5 flex">
          <Label>Available Credit</Label>
        </div>
        <div className="form-group mb-5 flex">
          <Input
            placeholder="Enter min value"
            className="inline"
            value={availableCredit[0]}
            onChange={e => {
              setAvailableCredit([e.target.value, availableCredit[1]]);
              setTempFilters({
                ...tempFilters,
                with_recent_available_credit: [
                  parseFloat(e.target.value).toFixed(1),
                  parseFloat(availableCredit[1]).toFixed(1),
                ],
              });
            }}
          />
          <Input
            placeholder="Enter max value"
            className="ml-4 inline"
            value={availableCredit[1]}
            onChange={e => {
              setAvailableCredit([availableCredit[0], e.target.value]);
              setTempFilters({
                ...tempFilters,
                with_recent_available_credit: [
                  parseFloat(availableCredit[0]).toFixed(1),
                  parseFloat(e.target.value).toFixed(1),
                ],
              });
            }}
          />
        </div>
      </div>
    </Pane>
  );
};

import React, { useState, useEffect } from "react";
import { Button, Input, Toastr } from "neetoui";
import { useRef } from "react";

import { creditApplicationSubscription } from "channels/creditApplicationChannel";
import customersPhoneVerificationApi from "apis/customers/phone-verification";
import experianFraudApi from "apis/customers/experian-fraud";
import Spinner from "components/SignUp/Registration/Spinner";

const CreditSMSModal = ({
  onSmsModalClose,
  phoneNumber,
  resentOtp,
  showNextModal,
  customer,
  fetchCustomer,
  setRedirectUrl,
}) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [loading, setLoading] = useState(false);

  const otpNumber1 = useRef();
  const otpNumber2 = useRef();
  const otpNumber3 = useRef();
  const otpNumber4 = useRef();
  const sumbitButton = useRef();

  const handleResponse = async data => {
    try {
      await fetchCustomer(false);
      setLoading(false);
    } catch (error) {
      logger.error(error);
      onSmsModalClose(true, null);
    }

    setRedirectUrl(data.redirect_uri);
    showNextModal(data, onSmsModalClose);
  };

  useEffect(() => {
    otpNumber1.current.focus();

    creditApplicationSubscription({
      authToken: customer?.authentication_token,
      email: customer?.email,
      customerId: customer?.id,
      handleResponse,
    });
  }, []);

  const onOTPValueChange = (index, value) => {
    setOtp(state => {
      let updatedState = [...state];
      updatedState[index] = value;
      return updatedState;
    });
  };

  const onProcessFraud = async () => {
    setLoading(true);
    try {
      const { data } = await experianFraudApi.processFraudCheck({
        reapply: true,
      });

      if (data.message) handleResponse(data);

      if (data.error) Toastr.error({ error: data.error });
    } catch (error) {
      const errorMessage = error?.response?.data?.error;

      if (errorMessage) Toastr.error({ error: errorMessage });
      logger.error(error);
      onSmsModalClose(true, null);
    }
  };

  const handleSubmit = async event => {
    try {
      event.preventDefault();
      await customersPhoneVerificationApi.verifyOtp({
        phone_number: phoneNumber,
        otp: otp.join(""),
      });
      onProcessFraud();
    } catch (error) {
      logger.error(error);
    }
  };

  const numberOnChange = (otpCount, e, nextElement) => {
    if (e.target.value.length === 1) {
      nextElement.current.focus();
    }
    onOTPValueChange(otpCount, e.target.value);
  };

  return (
    <>
      {loading ? (
        <div className="absolute bottom-0 z-40 w-full max-w-lg bg-white border border-gray-200 shadow-lg rounded-t-xl">
          <div className="p-2">
            <div className="flex items-center justify-between">
              <Spinner />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="absolute bottom-0 z-40 w-full max-w-lg bg-white border border-gray-200 shadow-lg rounded-t-2xl">
            <div className="p-2">
              <div className="flex items-center justify-between">
                <Button
                  className="text-2xl"
                  style="text"
                  icon="ri-close-line"
                  onClick={() => onSmsModalClose(false, null)}
                />
              </div>
              <div className="flex flex-col items-center justify-center my-6 space-y-6">
                <p>
                  A verification code was sent to {phoneNumber}. Enter it below.
                </p>
                <div className="flex justify-between px-6 space-x-2">
                  <Input
                    maxlength="1"
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    autoFocus
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[0]}
                    required
                    onChange={e => {
                      numberOnChange(0, e, otpNumber2);
                    }}
                    ref={otpNumber1}
                  />
                  <Input
                    maxlength="1"
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[1]}
                    required
                    onChange={e => {
                      numberOnChange(1, e, otpNumber3);
                    }}
                    ref={otpNumber2}
                  />
                  <Input
                    maxlength="1"
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[2]}
                    required
                    onChange={e => {
                      numberOnChange(2, e, otpNumber4);
                    }}
                    ref={otpNumber3}
                  />
                  <Input
                    maxlength="1"
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[3]}
                    required
                    onChange={e => {
                      numberOnChange(3, e, sumbitButton);
                    }}
                    ref={otpNumber4}
                  />
                </div>
                <Button
                  label="Verify & Proceed"
                  onClick={handleSubmit}
                  ref={sumbitButton}
                />
                <div>
                  Didn’t receive the OTP?
                  <span
                    className="mx-1 underline text-purple-1000 cursor-pointer"
                    onClick={resentOtp}
                  >
                    Resend OTP.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CreditSMSModal;

import axios from "axios";

const preferences = () => axios.get(`/api/v1/preferences`);

const update = payload => axios.put(`/api/v1/preferences`, payload);

const preferencesApi = {
  preferences,
  update,
};

export default preferencesApi;

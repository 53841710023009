import React from "react";

import { convertToDecimalString, formatCurrency } from "utils/styleUtils";
import AmountBox from "components/Admin/Customer/common/Amountbox";

const OvedueInvoices = ({ initialOverdueValue }) => {
  const amount = convertToDecimalString(initialOverdueValue?.amount);
  const isOverDueChecked = amount ? true : false;
  const details = initialOverdueValue?.details?.details;

  return (
    <AmountBox
      title={"Overdue Balance"}
      overdue={true}
      isChecked={isOverDueChecked}
      amount={amount}
      isValid={initialOverdueValue.isValid}
      isDisable={true}
    >
      {details?.map((invoice, index) => (
        <div className="flex justify-between" key={index}>
          <p>
            {invoice?.slug} ({invoice?.date})
          </p>
          <p>{formatCurrency(invoice.total)}</p>
        </div>
      ))}
    </AmountBox>
  );
};

export default OvedueInvoices;

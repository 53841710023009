import React from "react";
import { Button, Dropdown, Label } from "neetoui";
import classnames from "classnames";
import { useHistory, useLocation } from "react-router-dom";

import SubMenu from "./SubMenu";

export default function NavItem({
  icon = "",
  link = "",
  title = "",
  subLinks = [],
  className = "",
}) {
  const history = useHistory();
  const location = useLocation();
  let isActive = false;
  if (link) {
    isActive = location.pathname.includes(link);
  } else if (subLinks.length !== 0) {
    let filteredLink = subLinks.filter(item => {
      return location.pathname.includes(item.link);
    });
    if (filteredLink.length != 0) {
      isActive = location.pathname.includes(filteredLink[0].link);
    }
  }

  const NavButton = ({ haveSubLink = false, title }) => (
    <div className="flex flex-col items-center justify-center">
      <Button
        style="icon"
        icon={icon}
        onClick={() => !haveSubLink && history.push(link)}
        className={classnames(
          [
            "w-12 h-12 rounded-md text-2xl flex items-center justify-center opacity-100",
            className,
          ],
          {
            "text-white bg-purple-1000": isActive,
            "text-cool-gray-600 bg-gray-100 hover:bg-gray-200": !isActive,
          }
        )}
      />
      <Label
        className={classnames(["text-xs mt-2"], {
          "text-purple-1000 font-semibold": isActive,
          "text-cool-gray-600 bg-gray-100 ": !isActive,
        })}
      >
        {title}
      </Label>
    </div>
  );

  if (subLinks.length !== 0) {
    return (
      <div className="mb-6">
        <Dropdown
          position="right-top"
          interactionKind="hover"
          customTarget={() => <NavButton haveSubLink={true} title={title} />}
          closeOnSelect
        >
          <SubMenu subLinks={subLinks} title={title} />
        </Dropdown>
      </div>
    );
  } else {
    return (
      <div className="mb-6">
        <NavButton title={title} />
      </div>
    );
  }
}

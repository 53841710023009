import React, { useState } from "react";
import {
  Label,
  Checkbox,
  Badge,
  Collapse,
  Tooltip,
  Button,
} from "@bigbinary/neetoui";
import ImageCarousel from "./imageCarousel";

const TableColumnHeader = ({ children }) => (
  <Tooltip content={children}>
    <Label className="text-gray-400 uppercase truncate">{children}</Label>
  </Tooltip>
);

const CollapsibleDetails = ({ isCollapseOpen }) => (
  <Collapse open={isCollapseOpen}>
    <div className="space-y-5">
      <div className=" vendor_item_grid">
        <div className="flex-col col-start-2">
          <TableColumnHeader>Assembled width</TableColumnHeader>
          <div>60</div>
        </div>
        <div className="flex-col">
          <TableColumnHeader>Assembled Depth</TableColumnHeader>
          <div>60</div>
        </div>
        <div className="flex-col">
          <TableColumnHeader>Assembled Height</TableColumnHeader>
          <div>60</div>
        </div>
        <div className="flex-col">
          <TableColumnHeader>Country of Origin</TableColumnHeader>
          <div>60</div>
        </div>
        <div className="flex-col">
          <TableColumnHeader>Style statement</TableColumnHeader>
          <div>60</div>
        </div>
      </div>
      <div className="vendor_item_grid">
        <div className="col-start-2 col-end-11 col-span-full">
          <div>
            <div className="flex justify-start">
              <TableColumnHeader>Short product description</TableColumnHeader>
            </div>
            <p>
              Get to work on your next project with a home office furniture set
              that shows off your sense of style. The Bush Furniture Architect
              60W Writing Desk and Chair Set features contrasting materials that
              reflect a modern industrial aesthetic. The large,
              scratch-resistant work surface provides plenty of room to spread
              out with all the materials you need. A full-extension file drawer
              organizes legal and letter-size documents, and the box drawer is
              perfect for keeping supplies within arms reach. Ribbed bonded
              leather upholstery gives the Mid Back Office Chair a distinct look
              that perfectly complements the home office desk. Foam cushioning
              gives the seat a medium-firm feel while the desk chairs height and
              tilt tension can be adjusted to meet your own preferences. This
              computer desk with chair is tested to meet ANSI/SOHO standards for
              safety and performance and is backed by a 1 Year Warranty.
            </p>
          </div>
        </div>
      </div>
      <div className=" vendor_item_grid">
        <div className="flex-col col-start-2">
          <TableColumnHeader>Keywords</TableColumnHeader>
          <div>60</div>
        </div>
        <div className="flex-col">
          <TableColumnHeader>Feature 1</TableColumnHeader>
          <div>60</div>
        </div>
        <div className="flex-col">
          <TableColumnHeader>Feature 2</TableColumnHeader>
          <div>60</div>
        </div>
        <div className="flex-col">
          <TableColumnHeader>Feature 3</TableColumnHeader>
          <div>60</div>
        </div>
        <div className="flex-col">
          <TableColumnHeader>Feature 4</TableColumnHeader>
          <div>60</div>
        </div>
      </div>

      <div className=" vendor_item_grid">
        <div className="flex-col col-start-2">
          <TableColumnHeader>Assembled width</TableColumnHeader>
          <div>60</div>
        </div>
        <div className="flex-col">
          <TableColumnHeader>Assembled Depth</TableColumnHeader>
          <div>60</div>
        </div>
        <div className="flex-col">
          <TableColumnHeader>Assembled Height</TableColumnHeader>
          <div>60</div>
        </div>
        <div className="flex-col">
          <TableColumnHeader>Country of Origin</TableColumnHeader>
          <div>USA</div>
        </div>
        <div className="flex-col">
          <TableColumnHeader>Style statement</TableColumnHeader>
          <div>Living room</div>
        </div>
      </div>
    </div>
  </Collapse>
);

const VendorItem = ({ data }) => {
  const [isCollapseOpen, setCollapseOpen] = useState(false);

  return (
    <div>
      <div className="vendor_item_grid">
        <div className="flex justify-center">
          <Checkbox />
        </div>
        <ImageCarousel images={data.images} />
        <div className="flex justify-center">{data.category}</div>
        <div className="flex justify-center">{data.monthly}</div>
        <div className="flex justify-center">
          <Badge color="green"> Available</Badge>
        </div>
        <div className="flex justify-center">{data.brand}</div>
        <div className="flex justify-center">{data.name}</div>
        <div className="flex justify-center">{data.wholesale}</div>
        <div className="flex justify-center">{data.shipping}</div>
        <div className="flex justify-center">{data.first_seen}</div>
        {isCollapseOpen ? (
          <p
            className="text-2xl ri-arrow-up-s-line"
            onClick={() => setCollapseOpen(false)}
          />
        ) : (
          <Button
            className="cursor-pointer"
            style="text"
            icon="text-2xl ri-arrow-down-s-line"
            onClick={() => setCollapseOpen(true)}
          />
        )}
      </div>
      <CollapsibleDetails isCollapseOpen={isCollapseOpen} />
    </div>
  );
};

export default VendorItem;

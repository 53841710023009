import React from "react";
import { Label, Input, DateInput } from "@bigbinary/neetoui";

const SaleScheduling = () => (
  <div className="w-1/3 p-4 space-y-4 border border-gray-200">
    <Label className="font-semibold text-gray-400 uppercase">
      Sale Scheduling
    </Label>
    <div className="flex justify-center space-x-2">
      <div className="w-1/2">
        <DateInput label="Sale Start" />
      </div>
      <div className="w-1/2">
        <DateInput label="Sale End" />
      </div>
    </div>
    <div>
      <Input label="Retail Sale Price ($)" />
    </div>
  </div>
);
export default SaleScheduling;

import React from "react";
import { useHistory } from "react-router-dom";
// import { Badge } from "neetoui";

import PaymentFooter from "./Footer";
import LeaseHeader from "./Header";

// import { DELIVERY_STATUS } from "components/MyAccount/common/constants";
import { displayPayCycle } from "components/MyAccount/common/helper";
import { formatCurrency } from "utils/styleUtils";

const LeaseItem = ({ item, payCycle }) => (
  <div className="flex">
    <div className="p-2">
      <img
        src={
          item?.woo_image_url
            ? item.woo_image_url
            : "https://via.placeholder.com/96.png?text=Image"
        }
        className="object-contain"
        width="96px"
        height="96px"
      />
    </div>
    <div className="space-y-2">
      <div className="font-semibold">{item.product_name}</div>
      <div className="font-medium text-gray-600">{`${formatCurrency(
        item.payment_amount
      )}/${displayPayCycle(payCycle)}`}</div>
      <div className="font-medium text-gray-600">{`Qty - ${item.quantity}`}</div>
      {/* <div className="font-medium text-gray-600">{lease.delivery_message}</div> */}
      {/* {item?.shipment_status && (
        <Badge color={DELIVERY_STATUS[item.shipment_status].color}>
          <span className="capitalize">
            {DELIVERY_STATUS[item.shipment_status].label}
          </span>
        </Badge>
      )} */}
    </div>
  </div>
);

const LeaseList = ({ lease }) => {
  const history = useHistory();

  const onLeaseClick = () => {
    history.push(`/myaccount/leases/${lease.id}`);
  };

  return (
    <div
      className="w-3/5 border border-gray-200 rounded-md cursor-pointer"
      onClick={onLeaseClick}
    >
      <LeaseHeader lease={lease} />
      <div className="flex w-full justify-between px-16 p-4 space-x-6">
        {lease?.line_items?.map(item => (
          <LeaseItem key={item.id} item={item} payCycle={lease.pay_cycle} />
        ))}
      </div>

      <div>
        <PaymentFooter lease={lease} />
      </div>
    </div>
  );
};

export default LeaseList;

import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Button } from "neetoui";

import { ROUTES } from "../constant";
import {
  PREVIOUS_PAGE_MAPPER,
  CLASSNAME_MAPPER_FOR_PROGRESS_BAR,
} from "./constants";

const PageProgressbar = () => {
  const location = useLocation();
  const history = useHistory();

  const shouldNotShowBackButton = () =>
    location.pathname === ROUTES.FAILED ||
    location.pathname === ROUTES.REJECTED ||
    location.pathname === ROUTES.APPROVE;

  const handleBack = () => {
    history.push(PREVIOUS_PAGE_MAPPER[location.pathname] ?? "/");
  };

  return (
    <div className="relative top-0 flex items-center w-full h-10 px-2 py-4 space-x-2">
      {!shouldNotShowBackButton() && (
        <Button
          style="icon"
          icon="ri-arrow-left-s-line"
          className="text-gray-400"
          onClick={() => handleBack()}
        />
      )}
      <div className="relative w-full h-3 bg-gray-200 rounded-md">
        <div
          className={classNames("h-full rounded-md", [
            CLASSNAME_MAPPER_FOR_PROGRESS_BAR[location.pathname] ?? "w-0",
          ])}
        ></div>
      </div>
      <Button
        style="icon"
        icon="ri-checkbox-circle-line"
        className="text-gray-400"
      />
    </div>
  );
};

export default PageProgressbar;

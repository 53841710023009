import React from "react";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { Button, Input, Switch, Label } from "@bigbinary/neetoui";

const NewCardForm = ({ paymentDetails, setPaymentDetails }) => {
  const history = useHistory();
  const onCancel = () => {
    history.goBack();
  };
  return (
    <div className="space-y-4">
      <Input
        label="Name on card"
        value={paymentDetails.cardName}
        onChange={e =>
          setPaymentDetails(paymentDetails => ({
            ...paymentDetails,
            cardName: e.target.value,
          }))
        }
      />
      <Input
        label="Card Number"
        value={paymentDetails.cardNumber}
        onChange={e =>
          setPaymentDetails(paymentDetails => ({
            ...paymentDetails,
            cardNumber: e.target.value,
          }))
        }
      />
      <div
        className={classNames(
          {
            "grid grid-flow-row grid-cols-2 gap-10": !isMobile,
          },
          { "space-y-4": isMobile }
        )}
      >
        <Input
          label="Expiration Date"
          value={paymentDetails.expiryDate}
          onChange={e =>
            setPaymentDetails(paymentDetails => ({
              ...paymentDetails,
              expiryDate: e.target.value,
            }))
          }
        />
        <Input label="CVV" value="943" />
      </div>
      <Input label="Zipcode" value="489912" />
      <div className="flex justify-between space-x-4">
        <Label>Use card as default card</Label>
        <Switch />
      </div>
      <div className="flex justify-between space-x-4">
        <Label>Remember Card Information</Label>
        <Switch />
      </div>
      <div className="flex justify-end space-x-4">
        <Button label="Cancel" style="secondary" onClick={() => onCancel()} />
        <Button label="Continue" />
      </div>
    </div>
  );
};

export default NewCardForm;

export const EKATA_OPTIONS = {
  ekata_disabled: "No Experian call",
  ekata_enabled: "Make Experian call",
};

export const PRECISE_ID_OPTIONS = {
  precise_id_disabled: "No Experian call",
  precise_id_enabled: "Make Experian call",
};

export const CREDIT_APPROVAL_OPTIONS = {
  credit_enabled: "Make Experian call",
  mock_approve: "Mock Approve",
  mock_decline: "Mock Decline",
};

export const FAILURE_OPTIONS = {
  mock: "Mock Failure",
  no_mock: "Don't mock failure",
};

import React, { useState } from "react";
import { Pane, Button, Toastr } from "neetoui";
import { Form, Formik } from "formik";
import { Select as FormikSelect } from "neetoui/formik";

import { useCustomer } from "contexts/customer";

import employmentDetailsApi from "apis/employment-details";

import { MONTHLY_INCOME } from "common/constants";
import { generateEmploymentDetailsPayload } from "../utils";

import {
  MONTHLY_INCOME_INITIAL_VALUE,
  UPDATE_PAY_CYCLE_VALIDATION_SCHEMA,
} from "../constants";

export const UpdatePayCyclePane = ({
  isUpdatePayCyclePaneOpen,
  setIsUpdatePayCyclePaneOpen,
}) => {
  const [{ customer }, customerDispatch] = useCustomer();
  const [customerData, setCustomerData] = useState(
    customer || MONTHLY_INCOME_INITIAL_VALUE
  );

  let initialValues = {
    ...customerData,
    ...customerData.employment_detail,
  };

  initialValues.monthly_income = MONTHLY_INCOME.find(
    item => item.value === initialValues.monthly_income
  );

  const handleSubmit = async values => {
    try {
      const {
        data: { customer },
      } = await employmentDetailsApi.update(
        customerData.id,
        generateEmploymentDetailsPayload(values)
      );
      setCustomerData(customer);
      customerDispatch({
        type: "SET_CUSTOMER",
        payload: {
          customer: {
            ...customer,
          },
        },
      });
      Toastr.success("Customer updated successfully");
      setIsUpdatePayCyclePaneOpen(false);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Pane
      isOpen={isUpdatePayCyclePaneOpen}
      title="Update Monthly Income"
      onClose={() => setIsUpdatePayCyclePaneOpen(false)}
    >
      <div className="pb-20">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={UPDATE_PAY_CYCLE_VALIDATION_SCHEMA}
          onSubmit={values => handleSubmit(values)}
        >
          {({ isSubmitting, dirty }) => (
            <Form className="mx-6 space-y-4" noValidate>
              <FormikSelect
                label="Monthly Income"
                placeholder="Select an option"
                name="monthly_income"
                options={MONTHLY_INCOME}
              />
              <div className="flex justify-end w-full space-x-2 nui-pane__footer nui-pane__footer--absolute neeto-ui-shadow-m ">
                <Button
                  style="primary"
                  label="Submit"
                  type="submit"
                  disabled={!dirty}
                  loading={isSubmitting}
                />
                <Button
                  style="secondary"
                  label="Cancel"
                  disabled={isSubmitting}
                  onClick={() => setIsUpdatePayCyclePaneOpen(false)}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Pane>
  );
};

import React from "react";

const PaymentFooter = ({ lease }) => {
  if (lease?.has_overdue_invoice) {
    return (
      <div className="flex justify-center w-full px-4 py-2 text-white bg-red-600 rounded-b-md">
        Payment failed on
        <span className="px-1 font-semibold">
          {lease.last_failed_payment_date}
        </span>
      </div>
    );
  } else {
    lease?.upcoming_invoice && (
      <div className="flex justify-center w-full px-4 py-2 text-gray-600 bg-gray-100 rounded-b-md">
        Payment scheduled for
        <span className="px-1 font-semibold">
          {lease?.upcoming_invoice?.due_date}
        </span>
      </div>
    );
  }
};

export default PaymentFooter;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Button } from "neetoui";

import { useHistory, useLocation } from "react-router-dom";
import FloatingInput from "components/Common/FloatingInput";
import { setAuthHeaders } from "apis/axios";
import customersApi from "apis/customer";
import BeccasHomeLogo from "common/images/beccas-home-logo.png";
import useDocumentTitle from "hooks/useDocumentTitle";
import { getPageTitle } from "common/utils";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const historyRoute = useHistory();
  const location = useLocation();
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState({ error1: "", error2: "" });

  useDocumentTitle(getPageTitle("login", "enterPassword"));

  useEffect(() => {
    const token = new URLSearchParams(location.search).get(
      "reset_password_token"
    );
    setToken(token);
  }, []);

  const handleSubmit = async event => {
    if (password !== passwordConfirmation) {
      setError(state => ({ ...state, error2: "Password mismatch" }));
    } else if (password == "") {
      setError(state => ({ ...state, error1: "Enter a password" }));
    } else if (passwordConfirmation == "") {
      setError(state => ({ ...state, error2: "Enter matching password" }));
    } else {
      setError({ error1: "", error2: "" });
      try {
        event.preventDefault();
        setLoading(true);
        setAuthHeaders();
        await customersApi.updatePassword({
          customer: {
            reset_password_token: token,
            password: password,
            password_confirmation: passwordConfirmation,
          },
        });
        historyRoute.push("/login");
      } catch (error) {
        logger.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleMagicLink = () => {
    historyRoute.push("/login/magic-link");
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen p-6 overflow-x-hidden overflow-y-auto bg-white">
      <div className="flex items-center justify-center rounded-md w-14 h-14">
        <img src={BeccasHomeLogo} width="100px" height="100px" />
      </div>
      <div className="flex flex-col items-start justify-center w-full h-full mx-auto sm:max-w-md">
        <div className="flex flex-col justify-start">
          <p className="text-3xl ri-lock-password-line text-purple-1000"></p>
          <h2 className="mt-4 text-3xl font-bold text-gray-800">
            Reset Password
          </h2>
          <p className="mt-3 font-normal text-gray-600">Reset your password.</p>
        </div>
        <form className="w-full mt-6 space-y-5 bg-white">
          <FloatingInput
            id="customer_password"
            value={password}
            type="password"
            label="New Password"
            onChange={e => setPassword(e.target.value)}
            required
          />
          <p className="text-red-600">{error.error1}</p>
          <div className="p-4 space-y-2 bg-gray-100 rounded-md">
            <div className="flex space-x-1">
              <p className="ri-check-line"></p>
              <p>Must be at least 6 characters.</p>
            </div>
            <div className="flex space-x-1">
              <p className="ri-check-line"></p>
              <p>Must contain numbers.</p>
            </div>
          </div>
          <FloatingInput
            id="customer_password"
            value={passwordConfirmation}
            type="password"
            label="Confirm New Password"
            onChange={e => setPasswordConfirmation(e.target.value)}
            required
          />
          <p className="text-red-600">{error.error2}</p>
          <Button
            type="submit"
            loading={loading}
            fullWidth
            label="Reset my password"
            onClick={e => handleSubmit(e)}
          />
        </form>
        <div className="flex flex-col items-center justify-center w-full mt-6 ">
          <div className="flex items-center w-full px-8 space-x-2">
            <div className="w-full h-0.5 border border-gray-200" />
            <p>or</p>
            <div className="w-full h-0.5 border border-gray-200" />
          </div>
          <div className="container max-w-xs">
            <Button
              label="Sign in using magic link"
              style="secondary"
              className="flex justify-center w-full p-4 mt-5 text-center"
              onClick={() => handleMagicLink()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object,
};

export default ResetPassword;

import React from "react";
import { Label, Input, Switch, Pane } from "neetoui";

export const CreditCardPaneComponent = ({
  setCreditPane,
  creditPane,
  CardIcon,
}) => (
  <Pane
    title="Edit Credit/Debit Info"
    isOpen={creditPane}
    onClose={() => setCreditPane(false)}
    cancelButtonProps={{
      label: "Cancel",
      onClick: () => setCreditPane(false),
    }}
    submitButtonProps={{
      label: "Save Changes",
      onClick: () => setCreditPane(false),
    }}
    showFooter
  >
    <div className="px-6 pb-20 space-y-6 overflow-auto">
      <Input label="Card Holder Name" value="__Charlie Smith_" />
      <Input
        label="Card Number"
        value="__9920-2931-1992-0093_"
        prefix={<img src={CardIcon} width="40px" height="40px" />}
      />
      <div className="flex space-x-4">
        <Input className="w-1/3" label="Expiry Date" value="__02/23_" />
        <Input className="w-1/3" label="CVV" value="__944_" />
        <Input className="w-1/3" label="Zipcode" value="__90255_" />
      </div>
      <div className="flex justify-between">
        <Label>Use card as default</Label>
        <Switch checked />
      </div>
    </div>
  </Pane>
);

import React, { useState } from "react";
import { Button, Pane, Toastr } from "neetoui";
import { useCustomer } from "contexts/customer";
import customersApi from "apis/customer";
import { US_STATES } from "common/constants";
import { getOptions } from "utils/objectFormatter";
import { generatePayload } from "../utils";
import { Form, Formik } from "formik";
import { Input as FormikInput, Select as FormikSelect } from "neetoui/formik";
import {
  CUSTOMER_DATA_VALIDATION_SCHEMA,
  CUSTOMER_INITIAL_DATA,
} from "../constants";

export const ContactPaneComponent = ({ contactPane, setContactPane }) => {
  const [{ customer }, customerDispatch] = useCustomer();
  const [customerData, setCustomerData] = useState(
    customer || CUSTOMER_INITIAL_DATA
  );

  let initialValues = {
    ...customerData,
    ...customerData.address,
    ...customerData.phone,
  };

  const handleSubmit = async values => {
    try {
      const {
        data: { customer },
      } = await customersApi.updateCustomer(
        customerData.id,
        generatePayload(values, customerData.address.id, customerData.phone.id)
      );
      setCustomerData(customer);
      customerDispatch({
        type: "SET_CUSTOMER",
        payload: {
          customer: {
            ...customer,
          },
        },
      });
      Toastr.success("Customer updated successfully");
      setContactPane(false);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Pane
      title="Edit Contact Information"
      isOpen={contactPane}
      onClose={() => setContactPane(false)}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={CUSTOMER_DATA_VALIDATION_SCHEMA}
        onSubmit={values => handleSubmit(values)}
      >
        {({ isSubmitting, dirty }) => (
          <Form className="px-6 pb-20 space-y-6 overflow-auto" noValidate>
            <FormikInput name="email" label="Email address" required />
            <FormikInput name="phone_number" label="Phone Number" required />
            <FormikInput name="street_number" label="Street Address" required />
            <FormikInput name="area" label="Apartment Number" />
            <FormikInput name="city" label="City" />
            <div className="flex space-x-4">
              <FormikSelect
                requried
                name="state"
                className="w-1/2"
                label="State/Province/Region"
                options={getOptions(US_STATES)}
              />
            </div>
            <div className="flex space-x-4">
              <FormikInput
                className="w-1/2"
                label="Zipcode"
                name="zip_code"
                requried
              />
            </div>
            <div className="flex justify-end w-full space-x-2 nui-pane__footer nui-pane__footer--absolute neeto-ui-shadow-m ">
              <Button
                style="primary"
                label="Submit"
                type="submit"
                disabled={!dirty}
                loading={isSubmitting}
              />
              <Button
                style="secondary"
                label="Cancel"
                disabled={isSubmitting}
                onClick={() => setContactPane(false)}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Pane>
  );
};

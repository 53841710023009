import React from "react";
import { Button } from "@bigbinary/neetoui";

import DashboardCard from "../Common/DashboardCard";
import FlowDashBoardHeader from "../Common/DashBoardHeader";
import Example from "./Chart";

const FlowDashBoard = () => {
  return (
    <div className="w-full h-full">
      <div className="absolute top-0 z-10 w-full bg-white">
        <FlowDashBoardHeader logout={() => {}} />
      </div>
      <div className="justify-center w-screen mb-2 becca-homepage">
        <div className="px-4 pt-4 overflow-auto">
          <p className="pb-2 mb-4 font-extrabold border-b ">Dashboard</p>
          <div className="space-y-6">
            <DashboardCard>
              <div className="space-y-4">
                <div className="flex justify-between ">
                  <p>Credit Status: </p>
                  <p className="text-green-400">Approved</p>
                </div>
                <div className="flex justify-between ">
                  <p>Credit Amount: </p>
                  <p className="font-semibold">$3000</p>
                </div>
                <div className="flex justify-end">
                  <p className="text-sm">Last refreshed on 05/20/2021</p>
                </div>
              </div>
            </DashboardCard>
            <DashboardCard>
              <div className="flex items-center mt-2 space-x-4">
                <img width="70px" height="70px" />
                <div className="space-y-2">
                  <p className="text-base font-semibold text-purple-1000">
                    Congratulations Oliver Smith!
                  </p>
                  <p>Your credit for $3000 has been approved.</p>
                </div>
              </div>
            </DashboardCard>

            <DashboardCard>
              <div className="flex items-center mt-2 space-x-4">
                <img width="70px" height="70px" />
                <div className="space-y-2">
                  <p className="text-base font-semibold text-purple-1000">
                    Upcoming Payment on 23rd
                  </p>
                  <p>Your lease BH12300 has an upcoming payment</p>
                  <Button label="Pay Now" />
                </div>
              </div>
            </DashboardCard>

            <DashboardCard>
              <div className="flex items-center justify-center w-full">
                <Example />
              </div>
            </DashboardCard>
            <DashboardCard>
              <div className="space-y-2">
                <p className="text-lg font-semibold">Customers also bought</p>
                <img width="100px" height="100px" />
                <p className="text-xl font-semibold">Santini Sleeper Sofa</p>
                <p>$57/mo</p>
                <p>Delivers to FL 33127 by March 22</p>
                <div className="flex space-x-4">
                  <Button label="Add item" />
                  <div className="flex items-center justify-center w-8 border border-gray-200 rounded-full ri-heart-line"></div>
                </div>
              </div>
            </DashboardCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowDashBoard;

import React from "react";
import { Pane, Select } from "@bigbinary/neetoui";
import FileUpload from "components/Common/FileUpload";
import { IMPORT_PANE_TYPE } from "../constants";

const ImportPane = ({ importPane, setImportPane }) => {
  return (
    <Pane
      title="Import Vendor Data"
      isOpen={importPane}
      onClose={() => setImportPane(false)}
    >
      <div className="px-6 pb-20 space-y-6 overflow-auto">
        <Select
          label="Vendor"
          options={[
            { label: "Modway’s Furniture", value: "Modway’s Furniture" },
            { label: "Ash’s Furniture", value: "Ash’s Furniture" },
          ]}
          value={{ label: "Modway’s Furniture", value: "Modway’s Furniture" }}
        />
        <Select
          label="Import Type"
          options={IMPORT_PANE_TYPE}
          value={IMPORT_PANE_TYPE[0]}
        />
        <FileUpload label="Vendor CSV" fileType="CSV" />
      </div>
    </Pane>
  );
};

export default ImportPane;

import React from "react";
import { Button, Badge, Switch } from "@bigbinary/neetoui";
import { useHistory } from "react-router-dom";

import { PRODUCT_IMPORT_DATA } from "../constants";

const ProductImportsTableRow = ({ data }) => {
  const history = useHistory();

  const onVendorClick = () =>
    history.push("/product-import/vendor-list/:vendor-id/");

  return (
    <tr className="text-left">
      <td>
        <div className="flex items-center">
          <Button
            className="underline"
            style="link"
            label={data.vendor_name}
            onClick={() => onVendorClick()}
          />
        </div>
      </td>
      <td className="text-center">{data.public}</td>
      <td className="text-center">{data.hidden}</td>
      <td className="text-center">{data.last_inventory_update}</td>
      <td className="text-center">{data.last_product_update}</td>
      <td className="text-center">{data.last_pricing_update}</td>
      <td className="text-center">
        <div className="flex items-center justify-center">
          <Badge
            color={
              data.import_status == "completed"
                ? "green"
                : data.import_status == "awaiting"
                  ? "red"
                  : "yellow"
            }
          >
            {data.import_status == "completed"
              ? "Completed"
              : data.import_status == "awaiting"
                ? "Awaiting formatting"
                : "Awaiting QA"}
          </Badge>
        </div>
      </td>
      <td className="text-center">
        <div className="flex items-center justify-center">
          <Switch checked={data.visibility} />
        </div>
      </td>
    </tr>
  );
};

const ProductImportTableHeader = () => (
  <thead>
    <tr>
      <th className="uppercase">vendor name</th>
      <th className="text-center uppercase">public </th>
      <th className="text-center uppercase">Hidden</th>
      <th className="text-center uppercase">last inventory update</th>
      <th className="text-center uppercase">last product update</th>
      <th className="text-center uppercase">last pricing update</th>
      <th className="text-center uppercase">import status</th>
      <th className="text-center uppercase">visibility</th>
    </tr>
  </thead>
);

const ProductImportsTableBody = () => (
  <tbody>
    {PRODUCT_IMPORT_DATA.map((eachImportData, index) => (
      <ProductImportsTableRow key={index} data={eachImportData} />
    ))}
  </tbody>
);

const ProductImportTable = () => (
  <div className="flex justify-center w-full mt-6">
    <div className="w-11/12">
      <table className="nui-table">
        <ProductImportTableHeader />
        <ProductImportsTableBody />
      </table>
    </div>
  </div>
);

const ProductImportsList = () => {
  return (
    <div>
      <ProductImportTable />
    </div>
  );
};
export default ProductImportsList;

import React from "react";

import { Checkbox, TableLoader } from "@bigbinary/neetoui";

import AgentsTableRow from "./AgentsTableRow";

const AgentsTableBody = ({ agents, setAgentBeingEdited, fetchAgents }) =>
  agents && (
    <tbody>
      {agents.map(agent => (
        <AgentsTableRow
          key={agent.email}
          agent={agent}
          fetchAgents={fetchAgents}
          setAgentBeingEdited={() => setAgentBeingEdited(agent)}
        />
      ))}
    </tbody>
  );

const AgentsTableHeader = () => (
  <thead>
    <tr className="text-left">
      <th>
        <div className="flex flex-row items-center justify-center">
          <Checkbox disabled />
        </div>
      </th>
      <th>Name</th>
      <th>Role</th>
      <th>email</th>
      <th>last logged in</th>
      <th>Status</th>
      <th></th>
    </tr>
  </thead>
);

const AgentsTable = ({ loading, children }) => (
  <div className="flex justify-center w-full mt-6">
    <div className="w-11/12">
      <table className="nui-table nui-table--checkbox nui-table--avatar">
        {loading ? (
          <TableLoader rows={20} checkbox avatars actions />
        ) : (
          children
        )}
      </table>
    </div>
  </div>
);

AgentsTable.Body = AgentsTableBody;
AgentsTable.Header = AgentsTableHeader;

export default AgentsTable;

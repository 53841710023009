import axios from "axios";
import { getTimeZoneOffset } from "common/utils";
import { Toastr } from "neetoui";

axios.defaults.baseURL = "/";

export const setAuthHeaders = (setLoading = () => null) => {
  axios.defaults.headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-TOKEN": document
      .querySelector('[name="csrf-token"]')
      ?.getAttribute("content"),
  };

  const token = localStorage.getItem("authToken");
  const email = localStorage.getItem("authEmail");
  if (token && email) {
    axios.defaults.headers["X-Auth-Email"] = email;
    axios.defaults.headers["X-Auth-Token"] = token;
    axios.defaults.headers["TZ-Offset"] = getTimeZoneOffset();
  }
  setLoading(false);
};

export const resetAuthTokens = () => {
  delete axios.defaults.headers["X-Auth-Email"];
  delete axios.defaults.headers["X-Auth-Token"];
  localStorage.removeItem("authToken");
  localStorage.removeItem("authEmail");
};

const handleSuccessResponse = response => {
  if (response) {
    response.success = response.status === 200;
    if (response.data.notice && !isPaymentCreationRequest(response)) {
      Toastr.success(response.data.notice);
    }
  }
  return response;
};

const handleErrorResponse = (error, authDispatch) => {
  const status = error.response?.status;
  if (status === 401) {
    authDispatch({ type: "LOGOUT" });
    Toastr.error({ error: error.response?.data?.error });
    if (!window.location.pathname.includes("login"))
      setTimeout(() => {
        window.location.href = "/login";
      }, 3000);
  } else if (status >= 400 || status <= 522) {
    if (isExperianRequest(error)) {
      logger.error("Error - exp timeout.");
    } else if (!isPaymentCreationRequest(error)) {
      Toastr.error({ error: error.response?.data?.error || error.message });
    }
  }
  return Promise.reject(error);
};

export const registerIntercepts = authDispatch => {
  axios.interceptors.response.use(handleSuccessResponse, error =>
    handleErrorResponse(error, authDispatch)
  );
};

const isPaymentCreationRequest = responseObject =>
  responseObject.config.url.endsWith("/payments") &&
  responseObject.config.method === "post";

const isExperianRequest = responseObject =>
  responseObject.config.url.includes("/fraud_investigations");

import React, { useState } from "react";
import { Pane, Tab } from "@bigbinary/neetoui";

import { PANE_TABS } from "../../constants";
import ManualImport from "./ManualImport";
import CsvImport from "./CsvImport";

const ImportPane = ({ importPane, setImportPane, pageTitle }) => {
  const [currentTab, setCurrentTab] = useState(PANE_TABS[0]);

  const renderTabContents = currentTab => {
    switch (currentTab) {
    case PANE_TABS[0]:
      return <CsvImport pageTitle={pageTitle} />;
    case PANE_TABS[1]:
      return <ManualImport pageTitle={pageTitle} />;
    }
  };

  const title = () => {
    if (pageTitle == "orders") return "Import Orders Details";
    else if (pageTitle == "cancellations")
      return "Import Cancellations Details";
    else if (pageTitle == "returns") return "Import Returns Details";
  };

  return (
    <Pane
      title={title()}
      isOpen={importPane}
      onClose={() => setImportPane(false)}
      cancelButtonProps={{
        label: "Cancel",
        onClick: () => setImportPane(false),
      }}
      submitButtonProps={{
        label: "Import",
        onClick: () => setImportPane(false),
      }}
      showFooter
    >
      <div>
        <Tab className="w-full px-4 pb-20 overflow-auto border-b border-gray-200">
          {PANE_TABS.map((tab, index) => {
            return (
              <Tab.Item
                key={index}
                active={currentTab === tab}
                onClick={() => setCurrentTab(tab)}
              >
                {tab}
              </Tab.Item>
            );
          })}
        </Tab>
        <div className="px-6 space-y-6">{renderTabContents(currentTab)}</div>
      </div>
    </Pane>
  );
};

export default ImportPane;

import React, { useState } from "react";
import { Label, Button, Input, Select, Pane } from "@bigbinary/neetoui";

import { SKU_SELECT_OPTIONS } from "components/Admin/ProductImport/constants";

const CheckInverntoryPane = ({
  checkInventoryState,
  setCheckInventoryState,
}) => {
  return (
    <Pane
      title="Check Inverntory"
      isOpen={checkInventoryState}
      onClose={() => setCheckInventoryState(false)}
    ></Pane>
  );
};

const VendorInformation = () => {
  const [checkInventoryState, setCheckInventoryState] = useState(false);

  return (
    <>
      <div className="w-1/3 p-4 space-y-3 border border-gray-200">
        <Label className="font-semibold text-gray-400">
          Vendor Information
        </Label>
        <div className="flex justify-between font-semibold">
          <p>Vendor:</p>
          <p>Ashleys Furniture</p>
        </div>
        <div>
          <Select
            label="SKU"
            isMulti
            options={SKU_SELECT_OPTIONS}
            defaultValue={SKU_SELECT_OPTIONS[0]}
          />
        </div>
        <div>
          <Input label="Minimum Quantity" value="1" />
        </div>
        <div className="flex justify-end">
          <Button
            style="link"
            className="underline"
            label="Check Inventory Levels"
            onClick={() => setCheckInventoryState(true)}
          />
        </div>
      </div>
      <CheckInverntoryPane
        checkInventoryState={checkInventoryState}
        setCheckInventoryState={setCheckInventoryState}
      />
    </>
  );
};
export default VendorInformation;

export const CANT_INCREASE_LIMIT_MESSAGE =
  "Unfortunately, your account isn’t eligible for a limit increase right now.";

export const REFRESH_AGAIN_MESSAGE = "You may refresh again after 30 days.";

export const CANT_REFRESH_LIMIT_MESSAGE =
  "We could not refresh your credit limit.";

export const CONTACT_OWNER_MESSAGE =
  "Please contact application owner for more details.";

export const DEFAULT_EMPLOYMENT_FORM_VALUES = {
  current_employment_status: "",
  monthly_income: {
    value: "",
    label: "",
  },
  mode_of_salary: {
    value: "",
    label: "",
  },
  pay_cycle: "",
  pay_day1: "",
  pay_day2: "",
};

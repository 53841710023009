import React, { useEffect, useState } from "react";
import { Label, Button, Badge, PageLoader, Dropdown } from "neetoui";

// import DeliveryPopper from "../common/DeliveryPopper";
import Invoice from "./Invoice";
import leasesApi from "apis/leases";
import { useParams } from "react-router";
import LeaseSynopsis from "components/Admin/Customer/common/Synopsis/Lease";

// import { DELIVERY_STATUS } from "components/MyAccount/common/constants";
import { LEASE_STATUSES } from "common/constants";
import { formatCurrency } from "utils/styleUtils";
import { displayPayCycle } from "components/MyAccount/common/helper";
import { generateLeaseStatusBadge } from "common/utils";
import { formatDate } from "common/dateTimeFormatter";
import useDocumentTitle from "hooks/useDocumentTitle";

const LeaseTableHead = () => (
  <thead>
    <tr>
      <th className="text-center uppercase">ITEMS</th>
      <th className="text-center uppercase">MULTIPLE</th>
      <th className="text-center uppercase">COST($)</th>
      <th className="text-center uppercase">TAX($)</th>
      <th className="text-center uppercase ">TOTAL($)</th>
      <th className="text-center uppercase">ITEM STATUS</th>
      {/* <th className="text-center uppercase">DELIVERY STATUS</th> */}
    </tr>
  </thead>
);

const LeaseTableItems = ({ eachItem }) => {
  //this is for showing different type of delivery status at the end row of the table
  // let deliveryStatus1 = "Request Cancellation";
  // let deliveryStatus = eachItem.deliveryStatus;
  // let statusColor = "blue";
  // if (eachItem.status == "In Transit") {
  //   deliveryStatus = "Reaches by ".concat(deliveryStatus);
  // } else {
  //   statusColor = "green";
  //   deliveryStatus = "Delivered on ".concat(deliveryStatus);
  // }

  return (
    <tr className="text-left">
      <td className="w-1/5">
        <div className="flex flex-col items-center justify-center">
          <img
            src={eachItem.woo_image_url}
            className="object-contain"
            width="96px"
            height="96px"
          />
          <div className="space-y-2 ">
            <p className="text-sm font-bold">{eachItem.product_name}</p>
            <div className="flex space-x-1">
              <Label className="text-xs font-bold uppercase "> SKU: </Label>
              <p className="font-normal text-gray-600">{eachItem.sku}</p>
            </div>
            <div className="flex space-x-1">
              <Label className="text-xs font-bold uppercase ">QTYs:</Label>
              <p className="font-normal text-gray-600">{eachItem.quantity}</p>
            </div>
          </div>
        </div>
      </td>
      <td className="text-center">
        <Badge color={"blue"}>_</Badge>
      </td>
      <td className="text-center">{formatCurrency(eachItem.payment_amount)}</td>
      <td className="text-center">
        {formatCurrency(eachItem.tax_on_payment_amount)}
      </td>
      <td className="text-center">
        {formatCurrency(
          parseFloat(eachItem.payment_amount) +
            parseFloat(eachItem.tax_on_payment_amount)
        )}
      </td>
      {/* <td className="text-center">
        <Badge color={DELIVERY_STATUS[eachItem.shipment_status].color}>
          {DELIVERY_STATUS[eachItem.shipment_status].label}
        </Badge>
      </td> */}
      {/* Commented delivery status since we currently do not have data in DB */}
      {/* <td>
        <div className="flex items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="space-y-3">
              <Button
                className="underline"
                label={deliveryStatus1}
                style="link"
              />
              <DeliveryPopper deliveryStatus={deliveryStatus} />
            </div>
          </div>
        </div>
      </td> */}
    </tr>
  );
};

const LeaseTableBody = ({ ItemList }) => (
  <tbody>
    <>
      {ItemList.map(eachItem => (
        <LeaseTableItems eachItem={eachItem} key={eachItem.id} />
      ))}
    </>
  </tbody>
);

const LeaseHeader = ({ data, updateLeaseStatus }) => {
  const { label, color } = generateLeaseStatusBadge(data?.lease_status);
  return (
    <div className="flex justify-between w-full px-4 py-2 bg-gray-100">
      <div className="space-y-1">
        <Label className="text-xs font-bold uppercase ">Lease ID</Label>
        <Button label={data?.slug} style="link" className="text-lg" />
      </div>
      <div className="flex items-center justify-between space-x-8">
        <div className="space-y-1">
          <Label className="text-xs font-bold uppercase ">Start date</Label>
          <p className="font-semibold text-gray-600">
            {formatDate(data?.start_date)}
          </p>
        </div>
        <div className="space-y-1">
          <Dropdown
            buttonStyle="secondary"
            buttonProps={{
              className: `text-${color}-600`,
            }}
            label={label}
            position="bottom-right"
            data-cy="change-status-dropdown"
            className="mr-3"
          >
            {LEASE_STATUSES.map(option => (
              <li
                key={option.label}
                onClick={() => updateLeaseStatus(option.value)}
              >
                {option.label}
              </li>
            ))}
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const LeaseFooter = ({ data }) => (
  <div className="flex justify-end p-4">
    <div className="space-y-4">
      <div className="flex justify-between space-x-12">
        <Label>Item Subtotal</Label>
        <p className="font-semibold text-purple-1000">
          {formatCurrency(data.payment_amount)}
        </p>
      </div>
      <div className="flex justify-between space-x-12">
        <Label>Tax </Label>
        <p className="font-semibold text-purple-1000">
          {formatCurrency(data.tax_on_payment_amount)}
        </p>
      </div>
      <div className="flex justify-between pt-2 space-x-12 border-t border-gray-200">
        <Label>Total</Label>
        <p className="font-semibold text-purple-1000">{`${formatCurrency(
          parseFloat(data.payment_amount) +
            parseFloat(data.tax_on_payment_amount)
        )}/${displayPayCycle(data?.pay_cycle)}`}</p>
      </div>
    </div>
  </div>
);

const Lease = ({ data, updateLeaseStatus }) => {
  const ItemList = data.line_items ? data.line_items : [];

  return (
    <div className="m-4 border border-gray-200 rounded-md">
      <LeaseHeader data={data} updateLeaseStatus={updateLeaseStatus} />
      {ItemList == 0 ? (
        <div className="flex justify-center w-full">
          <div className="m-4">No items</div>
        </div>
      ) : (
        <div>
          <table className="nui-table nui-table--checkbox nui-table--avatar">
            <LeaseTableHead />
            <LeaseTableBody ItemList={ItemList} />
          </table>
          <LeaseFooter data={data} />
        </div>
      )}
    </div>
  );
};

export const LeaseDetails = ({
  leaseId,
  setLeaseSlugFunction,
  onLeaseStatusUpdate,
}) => {
  const { id } = useParams();
  const [LeaseDetailsData, setLeaseDetailsData] = useState();
  const [loading, setLoading] = useState(true);

  useDocumentTitle(`Lease detail ${id}`);

  const fetchLeaseDetails = async () => {
    try {
      setLoading(true);
      const {
        data: { lease },
      } = await leasesApi.lease(id, leaseId);
      setLeaseDetailsData(lease);
      setLeaseSlugFunction(lease.slug);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateLeaseStatus = async status => {
    try {
      setLoading(true);
      await leasesApi.update(id, leaseId, {
        lease_status: status,
      });
      fetchLeaseDetails();
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
      onLeaseStatusUpdate();
    }
  };

  useEffect(() => {
    leaseId && fetchLeaseDetails();
  }, [leaseId]);

  return (
    <>
      <div className="becca-details">
        {loading ? (
          <div className="flex items-center justify-center w-full h-full mt-10">
            <PageLoader />
          </div>
        ) : (
          <>
            {LeaseDetailsData && (
              <>
                <Lease
                  data={LeaseDetailsData}
                  updateLeaseStatus={updateLeaseStatus}
                />
                <Invoice leaseSlug={LeaseDetailsData.slug} />
              </>
            )}
          </>
        )}
      </div>
      <LeaseSynopsis leaseDetailsData={LeaseDetailsData} />
    </>
  );
};

export default LeaseDetails;

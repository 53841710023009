import axios from "axios";

const leases = () => axios.get("api/v1/leases");

const lease = lease_id => axios.get(`api/v1/leases/${lease_id}`);

const autoPayDetails = () => axios.get(`/api/v1/payment_methods`);

const updatePaymentDetails = paymentId =>
  axios.put("/api/v1/autopay_preferences", {
    payment_method_id: paymentId,
  });

const leasesApi = {
  leases,
  lease,
  autoPayDetails,
  updatePaymentDetails,
};

export default leasesApi;

import React, { useState } from "react";
import { Button } from "@bigbinary/neetoui";
import { DragDropContext } from "react-beautiful-dnd";

import FeatureTable from "./DataTable";
import Rename from "../../common/Rename";

const ProductData = () => {
  const [addMoreSpecifications, setAddMoreSpecifications] = useState([
    { name: "one", deletable: false },
  ]);
  const [addMoreDimensions, setAddMoreDimensions] = useState([
    { name: "one", deletable: false },
  ]);

  const addMoreSpecificationsClick = () => {
    setAddMoreSpecifications(state => [
      ...state,
      { name: "random", deletable: true },
    ]);
  };

  const addMoreDimensionsClick = () => {
    setAddMoreDimensions(state => [
      ...state,
      { name: "random", deletable: true },
    ]);
  };

  const onDeleteSpecificationSection = index => {
    setAddMoreSpecifications(state => {
      let updatedState = [...state];
      updatedState.splice(index, 1);
      return updatedState;
    });
  };

  const onDeleteDimensionsSection = index => {
    setAddMoreDimensions(state => {
      let updatedState = [...state];
      updatedState.splice(index, 1);
      return updatedState;
    });
  };

  const onDragEnd = () => {
    // console.debug("values", value);
  };

  const UnMapped = () => (
    <div>
      <div className="py-4 text-xl font-semibold border-b border-gray-200">
        <Rename
          label={"Unmapped Fields"}
          labelAction={false}
          className="text-xl font-semibold"
        />
      </div>
      <div className="flex justify-between mb-8 space-x-2">
        <div className="w-1/2">
          <FeatureTable tableDetails={"unmapped1"} />
        </div>
        <div className="w-1/2">
          <FeatureTable tableDetails={"unmapped2"} />
        </div>
      </div>
    </div>
  );

  const Specfications = () => {
    return (
      <div>
        <div className="py-4 mt-4 text-2xl font-semibold border-b border-gray-200">
          Specifications
        </div>
        <div>
          <div className="py-2 border-b border-gray-200">
            <Rename
              label={"Features"}
              labelAction={true}
              className="text-xl font-semibold"
            />
          </div>
          <div className="flex justify-between mt-4 space-x-2">
            <div className="w-1/2">
              <FeatureTable tableDetails={"feature1"} />
            </div>
            <div className="w-1/2">
              <FeatureTable tableDetails={"feature2"} />
            </div>
          </div>
        </div>
        {addMoreSpecifications.map((item, index) => {
          return (
            <div key={index}>
              <div className="flex items-center justify-start py-2 border-b border-gray-200 ">
                <Rename
                  label={"Specifications"}
                  labelAction={true}
                  className="text-xl font-semibold"
                />
                {item.deletable && (
                  <Button
                    style="text"
                    icon="ri-close-line"
                    onClick={() => onDeleteSpecificationSection(index)}
                  />
                )}
              </div>
              <div className="flex justify-between mt-4 space-x-2">
                <div className="w-1/2">
                  <FeatureTable tableDetails={"spec"} />
                </div>
              </div>
            </div>
          );
        })}
        <div className="flex justify-center my-6">
          <Button
            label=" + ADD MORE CATEGORIES"
            style="link"
            onClick={() => addMoreSpecificationsClick()}
          />
        </div>
      </div>
    );
  };

  const Dimensions = () => (
    <div>
      <div className="py-4 text-xl font-semibold border-b border-gray-200">
        Weight & Dimensions
      </div>
      {addMoreDimensions.map((item, index) => {
        return (
          <div key={index}>
            <div className="flex justify-start py-2 space-x-2 border-b border-gray-200">
              <Rename
                label={"Weight"}
                labelAction={true}
                className="text-xl font-semibold"
              />
              {item.deletable && (
                <Button
                  style="text"
                  icon="ri-close-line"
                  onClick={() => onDeleteDimensionsSection(index)}
                />
              )}
            </div>
            <div className="flex justify-between space-x-2">
              <div className="w-1/2">
                <FeatureTable tableDetails={"weight1"} />
              </div>
              <div className="w-1/2">
                <FeatureTable tableDetails={"weight2"} />
              </div>
            </div>
          </div>
        );
      })}
      <div className="flex justify-center my-6">
        <Button
          label=" + ADD MORE CATEGORIES"
          style="link"
          onClick={() => addMoreDimensionsClick()}
        />
      </div>
    </div>
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="px-6 bg-white">
        <Specfications />
        <Dimensions />
        <UnMapped />
      </div>
    </DragDropContext>
  );
};

export default ProductData;

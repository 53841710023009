export const MODIFICATION_ACTION = [
  {
    label: "Skip payments for the customer",
    value: "skip",
  },
  {
    label: "Nix out overdue balance",
    value: "reduce",
  },
  {
    label: "Modify recurring payment amount",
    value: "modify",
  },
  {
    label: "Update pay cycle",
    value: "update",
  },
  {
    label: "Change available credit limit",
    value: "credit",
  },
  {
    label: "Add payment promise",
    value: "promise",
  },
];

export const SKIP_COUNT = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
];

export const LEASEDATAMODIFY = [
  {
    data_1: {
      label: "New payment amount",
      value: "2 usd",
    },
    data_2: {
      label: "New end date",
      value: "21-2-2002",
    },
    name: "BH2001",
  },
  {
    data_1: {
      label: "New payment amount",
      value: "2 usd",
    },
    data_2: {
      label: "New end date",
      value: "21-2-2002",
    },
    name: "BH2001",
  },
];

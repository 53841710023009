import axios from "axios";

const create = (customerId, payload) =>
  axios.post(`api/v1/admin/customers/${customerId}/payment_promises/`, payload);

const index = customerId =>
  axios.get(`api/v1/admin/customers/${customerId}/payment_promises`);

const paymentPromisesApi = { create, index };

export default paymentPromisesApi;

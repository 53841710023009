import axios from "axios";

const all = () => axios.get("api/v1/payment_methods");

const create = payload => axios.post("api/v1/payment_methods", payload);

const updateDefaultCard = id =>
  axios.put(`api/v1/payment_methods/${id}`, {
    card: {
      is_default: true,
    },
  });

const destroy = id => axios.delete(`api/v1/payment_methods/${id}`);

const paymentMethodsApi = {
  all,
  create,
  destroy,
  updateDefaultCard,
};

export default paymentMethodsApi;

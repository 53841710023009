import React from "react";
import { Label, Input, Pane } from "neetoui";

import ImageUpload from "components/Common/ImageUpload";

export const AttachmentPaneComponent = ({
  attachmentPane,
  setAttachmentPane,
}) => (
  <Pane
    title="Upload Lease Documents"
    isOpen={attachmentPane}
    onClose={() => setAttachmentPane(false)}
    cancelButtonProps={{
      label: "Cancel",
      onClick: () => setAttachmentPane(false),
    }}
    submitButtonProps={{
      label: "_Save Changes_",
      onClick: () => setAttachmentPane(false),
    }}
    showFooter
  >
    <div className="px-6 pb-20 space-y-6 overflow-auto">
      <Input label="Document" />
      <Input label="Date" disabled value="03/21/2021" />
      <div className="space-y-1">
        <Label>Lease Documents</Label>
        <ImageUpload />
      </div>
    </div>
  </Pane>
);

import React from "react";
// import { Badge } from "neetoui";
// import { DELIVERY_STATUS } from "components/MyAccount/common/constants";
import { displayPayCycle } from "components/MyAccount/common/helper";
import { formatCurrency } from "utils/styleUtils";

const LeaseItem = ({ item, payCycle }) => (
  <div className="flex">
    <div className="p-2">
      <img
        src={
          item?.woo_image_url
            ? item.woo_image_url
            : "https://via.placeholder.com/96.png?text=Image"
        }
        className="object-contain"
        width="96px"
        height="96px"
      />
    </div>
    <div className="space-y-2">
      <div className="font-semibold">{item.product_name}</div>
      <div className="font-medium text-gray-600">{`${formatCurrency(
        item.payment_amount
      )}/${displayPayCycle(payCycle)}`}</div>
      <div className="font-medium text-gray-600">{`Qty - ${item.quantity}`}</div>
      {/* <div className="font-medium text-gray-600">
        __Delivers By 27 March 2021_
      </div> */}
      {/* {item?.shipment_status && (
        <Badge color={DELIVERY_STATUS[item?.shipment_status].color}>
          {DELIVERY_STATUS[item.shipment_status].label}
        </Badge>
      )} */}
    </div>
  </div>
);

export default LeaseItem;

import React from "react";
import { Button, Label } from "neetoui";

import { formatCurrency } from "utils/styleUtils";
import {
  CANT_INCREASE_LIMIT_MESSAGE,
  CANT_REFRESH_LIMIT_MESSAGE,
  CONTACT_OWNER_MESSAGE,
  REFRESH_AGAIN_MESSAGE,
} from "./constants";

const CreditConfirmModal = ({
  isCreditConfirmOpen,
  setIsCreditConfirmOpen,
  creditInfo,
  customer,
  redirectUrl,
}) => {
  if (!isCreditConfirmOpen) {
    return null;
  }

  return (
    <div className="absolute w-full bottom-0 z-40 px-4 overflow-y-auto lg:max-w-lg bg-white border border-gray-200 shadow-lg rounded-t-2xl">
      <div className="p-4 space-y-4">
        <div className="flex items-center justify-end">
          <Button
            className="text-2xl"
            style="text"
            icon="ri-close-line"
            onClick={() => setIsCreditConfirmOpen(false)}
          />
        </div>
        {creditInfo.status === "approved" && (
          <>
            <p className="text-lg font-medium">
              Your credit has been refreshed.
            </p>
            <div className="flex justify-between">
              <Label>Available Limit</Label>
              <Label className="text-right">
                {formatCurrency(customer?.available_credit)}
              </Label>
            </div>
            <div className="flex justify-between">
              <Label>Expiration Date</Label>
              <Label className="text-right">
                {customer?.credit_valid_till}
              </Label>
            </div>
          </>
        )}
        {creditInfo.status === "rejected" && (
          <div className="text-center">
            <p className="text-lg font-medium">{CANT_INCREASE_LIMIT_MESSAGE}</p>
            <p className="text-lg font-medium pt-3">{REFRESH_AGAIN_MESSAGE}</p>
          </div>
        )}
        {creditInfo.status !== "rejected" && creditInfo.status !== "approved" && (
          <div className="text-center">
            <p className="text-lg font-medium">{CANT_REFRESH_LIMIT_MESSAGE}</p>
            <p className="text-lg font-medium pt-3">{CONTACT_OWNER_MESSAGE}</p>
          </div>
        )}
        <Button
          fullWidth
          label="Continue"
          onClick={() => {
            setIsCreditConfirmOpen(false);
            window.location.href = redirectUrl ? redirectUrl : "/";
          }}
        />
      </div>
    </div>
  );
};

export default CreditConfirmModal;

import axios from "axios";

const update = (customer_id, installment_count, payload) =>
  axios.put(
    `/api/v1/customers/${customer_id}/skip_installments/${installment_count}`,
    payload
  );

const details = (customer_id, installment_count, payload) =>
  axios.post(
    `/api/v1/customers/${customer_id}/skip_installments/${installment_count}/details`,
    payload
  );

const skipInstallmentsApi = { update, details };

export default skipInstallmentsApi;

export const OVERDUE_BALANCE = [
  { desc: "Overdue Autopay (March 2021)", amount: "$5" },
  { desc: "Overdue Autopay (Feb 2021)", amount: "$5" },
];

export const LEASE_PAYMENT = [
  { desc: "Upcoming Autopay: Fri, Nov 12", amount: "$5" },
  { desc: "Payments Made", amount: "$5" },
  { desc: "Total Upcoming Payment", amount: "$5" },
];

export const EARLY_PAYMENT = [
  { desc: "Payoff Installments", amount: "$5" },
  { desc: "Total Purchase Balance", amount: "$5" },
];

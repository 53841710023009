import React, { useEffect, useState } from "react";
import { DateInput, Toastr } from "neetoui";
import { Field, FieldArray, Formik } from "formik";
import { Select as FormikSelect } from "neetoui/formik";
import { PAY_CYCLE } from "../../constants";
import { convertArrayToHash } from "utils/objectFormatter";
import {
  FIRST_PAY_AVAILABLE_DAYS,
  SECOND_PAY_AVAILABLE_DAYS,
} from "common/constants";
import employmentDetailsApi from "apis/employment-details";
import { useCustomer } from "contexts/customer";
import LeaseDetailsList from "./Common/LeaseDetailsList";
import { generatePayCycleUpdatePayload } from "../../utils";
import { formatCurrency } from "utils/styleUtils";

const UpdatePayCycle = ({ setModificationPane, formikRef, setLoading }) => {
  const [{ customer }, customerDispatch] = useCustomer();

  const [leaseList, setLeaseList] = useState([]);
  const [suggestedNextInvoiceDate, setSuggestedNextInvoiceDate] =
    useState(null);
  const [suggestInvoiceDate, setSuggestInvoiceDate] = useState(false);

  const initialValues = {
    pay_cycle: PAY_CYCLE.find(
      item => item.value === customer?.employment_detail?.pay_cycle
    ) || {
      label: "Weekly",
      value: "Weekly",
    },
    pay_day: new Date(customer?.employment_detail?.next_pay_day) || new Date(),
    pay_days: !customer?.employment_detail?.pay_days.length
      ? [FIRST_PAY_AVAILABLE_DAYS[0], SECOND_PAY_AVAILABLE_DAYS[0]]
      : customer?.employment_detail?.pay_days,
  };

  const isTwiceAMonthPayCycle = payCycle => payCycle.value === "Twice A Month";

  const handleSubmit = async data => {
    setLoading(true);
    const suggestedNextInvoiceDateString =
      String(suggestedNextInvoiceDate) === suggestedNextInvoiceDate
        ? suggestedNextInvoiceDate
        : suggestedNextInvoiceDate.toDateString();

    try {
      const response = await employmentDetailsApi.update(customer.id, {
        ...generatePayCycleUpdatePayload(data),
        next_invoice_date: suggestedNextInvoiceDateString,
      });

      customerDispatch({
        type: "SET_CUSTOMER",
        payload: {
          customer: {
            ...response.data.customer,
          },
        },
      });
      setModificationPane(false);
      Toastr.success("Successfully updated customer's pay cycle");
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDetails = async values => {
    setLoading(true);

    try {
      const response = await employmentDetailsApi.getUpdateableDetails(
        customer.id,
        {
          ...generatePayCycleUpdatePayload(values),
          suggest_invoice_date: suggestInvoiceDate,
        }
      );

      let leaseDataList = response.data.leases;
      leaseDataList = leaseDataList.map(item => {
        return {
          data_1: {
            label: "New payment amount",
            value: formatCurrency(item.new_payment_amount),
          },
          data_2: {
            label: "New end date",
            value: item.new_end_date,
          },
          name: item.slug,
        };
      });
      setLeaseList(leaseDataList);
      setSuggestedNextInvoiceDate(response.data.suggested_next_invoice_date);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails(initialValues);
    setSuggestInvoiceDate(true);
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => handleSubmit(values)}
      innerRef={formikRef}
    >
      {({ values, setFieldValue, errors }) => (
        <div>
          <FormikSelect
            name="pay_cycle"
            label="Pay cycle"
            onChange={payCycle => {
              setFieldValue("pay_cycle", payCycle);
              fetchDetails({ ...values, pay_cycle: payCycle });
            }}
            options={PAY_CYCLE}
          />
          <div className="py-6 space-y-2">
            {!isTwiceAMonthPayCycle(values.pay_cycle) && (
              <>
                <Field name="pay_day">
                  {({ field }) => (
                    <DateInput
                      label="Pay Day"
                      {...field}
                      minDate={new Date()}
                      value={values?.pay_day ? new Date(values.pay_day) : null}
                      popoverProps={{ usePortal: false }}
                      onChange={date => {
                        date && setFieldValue("pay_day", date.toDateString());
                        fetchDetails({ ...values, pay_day: date });
                      }}
                    />
                  )}
                </Field>
              </>
            )}
            {isTwiceAMonthPayCycle(values.pay_cycle) && (
              <>
                <FieldArray
                  name="pay_days"
                  render={() => (
                    <>
                      {values.pay_days &&
                        values.pay_days.map((pay_day, index) => (
                          <div key={index}>
                            <FormikSelect
                              name={`pay_days[${index}]`}
                              label={`Pay day ${index + 1}`}
                              options={
                                index === 0
                                  ? convertArrayToHash(FIRST_PAY_AVAILABLE_DAYS)
                                  : convertArrayToHash(
                                    SECOND_PAY_AVAILABLE_DAYS
                                  )
                              }
                              onChange={option => {
                                setFieldValue(
                                  `pay_days[${index}]`,
                                  option.value
                                );
                                let newPayDays = [...values.pay_days];
                                newPayDays[index] = option.value;
                                fetchDetails({
                                  ...values,
                                  pay_days: newPayDays,
                                });
                              }}
                              error=""
                            />
                          </div>
                        ))}
                    </>
                  )}
                />
                <p className="nui-input__error">{errors.pay_days}</p>
              </>
            )}
          </div>
          {leaseList && suggestedNextInvoiceDate && (
            <div className="mb-4">
              <DateInput
                label="Next invoice date"
                minDate={new Date()}
                value={new Date(suggestedNextInvoiceDate)}
                popoverProps={{ usePortal: false }}
                onChange={date => {
                  date && setSuggestedNextInvoiceDate(date);
                }}
              />
            </div>
          )}
          <LeaseDetailsList leaseList={leaseList} />
        </div>
      )}
    </Formik>
  );
};

export default UpdatePayCycle;

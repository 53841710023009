import React from "react";
import { Label } from "neetoui";

import AcccountSummaryLeftSide from "./AcccountSummaryLeftSide";
import AcccountSummaryRightSide from "./AcccountSummaryRightSide";

const AccountSummary = ({ customer }) => {
  return (
    <div className="p-4 my-8 border border-gray-200 rounded-md">
      <Label className="font-bold uppercase">ACCOUNT SUMMARY</Label>
      <div className="flex">
        <div className="w-1/2 p-4 space-y-2 border-r border-gray-200 ">
          <AcccountSummaryLeftSide customer={customer} />
        </div>
        <div className="w-1/2 p-4 space-y-2">
          <AcccountSummaryRightSide customer={customer} />
        </div>
      </div>
    </div>
  );
};

export default AccountSummary;

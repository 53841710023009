import React from "react";
import { Button, Badge, TableLoader } from "neetoui";
import { generateAccountStatusBadge } from "common/utils";

const CustomersTableRow = ({ data }) => {
  const formatPhoneNumber = phoneNumber =>
    `(${phoneNumber.slice(0, 3)})  ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6)}`;

  const { label, color } = generateAccountStatusBadge(data?.account_status);

  const generateWooCommerceUserLink = wooUserId => {
    if (wooUserId) {
      return (
        <a
          href={`https://beccashome.com/wp-admin/user-edit.php?user_id=${wooUserId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Profile
        </a>
      );
    }
  };

  return (
    <tr className="text-left">
      <td>
        <Button style="link" label={data.name} to={`/customers/${data.id}`} />
      </td>
      <td>{formatPhoneNumber(data.phone_number)}</td>
      <td>{data.email}</td>
      <td className="text-center">
        <Badge color={color}>{label}</Badge>
      </td>
      <td>{data.overdue_amount || 0}</td>
      <td>{data.credit_status}</td>
      <td>{data.available_credit}</td>
      <td>{data.created_at}</td>
      <td>{data.last_applied_at}</td>
      <td>{data.registration_step}</td>
      <td>{generateWooCommerceUserLink(data.woo_user_id)}</td>
    </tr>
  );
};

const CustomersTableHead = () => (
  <thead>
    <tr className="text-left">
      <th>Customer Name</th>
      <th>Phone Number</th>
      <th>Email</th>
      <th className="text-center">Account status</th>
      <th>Overdue Balance</th>
      <th>Credit Status</th>
      <th>Available Credit</th>
      <th>Signup Date</th>
      <th>Last Application</th>
      <th>Registration Step</th>
      <th>Store URL</th>
    </tr>
  </thead>
);

const CustomersTableBody = ({ customers }) => (
  <tbody>
    {customers &&
      customers.map((data, idx) => <CustomersTableRow key={idx} data={data} />)}
  </tbody>
);

const CustomersTable = props => (
  <div className="flex justify-center w-full mt-8 ">
    <div className="w-full mx-6">
      <table className="nui-table nui-table--avatar">
        {props.loading ? (
          <tbody>
            <TableLoader rows={20} checkbox avatars actions />
          </tbody>
        ) : (
          <>
            <CustomersTableHead />
            <CustomersTableBody customers={props.customers} />
          </>
        )}
      </table>
    </div>
  </div>
);

export default CustomersTable;

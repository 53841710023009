import React from "react";
import { Button } from "neetoui";

import { useHistory } from "react-router-dom";
import { formatCurrency } from "utils/styleUtils";

const PaymentResultModal = ({
  paymentInfo,
  paymentResultModal,
  setPaymentResultModal,
}) => {
  const history = useHistory();

  const onContinue = () => {
    history.push(`/my-account/leases/${paymentInfo.lease?.id}`);
  };
  const onClose = () => {
    setPaymentResultModal(null);
    history.push(`/my-account/payment/${paymentInfo.lease?.slug}`);
  };

  const SuccessMessageComponent = () => (
    <>
      <p className="text-lg font-medium">
        <span>Payment of&nbsp;</span>
        <span className="font-semibold text-purple-1000">
          {formatCurrency(paymentInfo.amount)}&nbsp;
        </span>
        towards your lease
        <span className="font-semibold text-purple-1000">
          &nbsp;{paymentInfo.lease?.slug}&nbsp;
        </span>
        was completed successfully.
      </p>
      <Button fullWidth label="Continue" onClick={() => onContinue()} />
    </>
  );

  const FailedMessageComponent = () => (
    <>
      <p className="text-lg font-medium">
        <span>Payment of&nbsp;</span>
        <span className="font-semibold text-purple-1000">
          {formatCurrency(paymentInfo.amount)}&nbsp;
        </span>
        towards your lease
        <span className="font-semibold text-purple-1000">
          &nbsp;{paymentInfo.lease?.slug}&nbsp;
        </span>
        was failed.
      </p>
      <Button fullWidth label="Retry" onClick={() => onClose()} />
    </>
  );

  if (!paymentResultModal) {
    return null;
  }

  return (
    <div className="absolute bottom-0 z-40 w-full bg-white border border-gray-200 shadow-lg md:w-96 rounded-t-2xl ">
      <div className="p-4 space-y-4">
        <div className="flex items-center justify-end">
          <Button
            className="text-2xl"
            style="text"
            icon="ri-close-line"
            onClick={() => onClose()}
          />
        </div>
        {paymentResultModal.status == true ? (
          <SuccessMessageComponent />
        ) : (
          <FailedMessageComponent />
        )}
        <Button style="text" label="Cancel" onClick={() => onClose()} />
      </div>
    </div>
  );
};

export default PaymentResultModal;

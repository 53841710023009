import React from "react";

import { Checkbox, TableLoader, Button } from "@bigbinary/neetoui";

const ExclusionTableBody = ({ data }) => (
  <tbody>
    {data.map((item, index) => (
      <tr key={index}>
        <td>
          <div className="flex flex-row items-center justify-center">
            <Checkbox />
          </div>
        </td>
        <td>
          <div className="flex items-center">
            <Button style="link" className="underline" label={item.name} />
          </div>
        </td>
        <td className="text-center">{item.email}</td>
        <td className="text-center">{item.date}</td>
        <td className="text-center">
          <div className="text-lg ri-inbox-archive-line"></div>
        </td>
      </tr>
    ))}
  </tbody>
);

const ExclusionTableHeader = () => (
  <thead>
    <tr className="text-left">
      <th>
        <div className="flex flex-row items-center justify-center">
          <Checkbox disabled />
        </div>
      </th>
      <th>Name</th>
      <th className="text-center">Email</th>
      <th className="text-center">Date</th>
      <th className="text-center">Revert</th>
    </tr>
  </thead>
);

const ExclusionTable = ({ loading, children }) => (
  <div className="flex justify-center w-full mt-6">
    <div className="w-11/12">
      <table className="nui-table nui-table--checkbox nui-table--avatar">
        {loading ? (
          <TableLoader rows={20} checkbox avatars actions />
        ) : (
          children
        )}
      </table>
    </div>
  </div>
);

ExclusionTable.Body = ExclusionTableBody;
ExclusionTable.Header = ExclusionTableHeader;

export default ExclusionTable;

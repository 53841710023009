import axios from "axios";

const create = payload => axios.post(`api/v1/employment_details`, payload);

const update = (employmentId, payload) =>
  axios.put(`api/v1/employment_details/${employmentId}`, payload);

const employmentDetailsApi = { create, update };

export default employmentDetailsApi;

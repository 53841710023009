import { createConsumer } from "@rails/actioncable";
import { getWebsocketURL } from "./consumer";

export const creditApplicationSubscription = ({
  authToken,
  email,
  customerId,
  handleResponse,
}) => {
  if (authToken == undefined) return null;

  const subscription = createConsumer(
    getWebsocketURL(authToken, email)
  ).subscriptions.create(
    {
      channel: "CreditApplicationChannel",
      customer_id: customerId,
    },
    {
      connected() {},
      disconnected() {},

      received(data) {
        handleResponse(data);
        subscription.unsubscribe();
      },
    }
  );

  return subscription;
};

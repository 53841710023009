import React, { useState, useEffect, useRef } from "react";
import { Toastr } from "neetoui";
import Spinner from "./Spinner";
import { setAuthHeaders } from "apis/axios";
import {
  DEFAULT_ERROR_MESSAGE,
  TIMEOUT_ERROR_MESSAGE,
  REQUEST_TIMEOUT_IN_MS,
} from "../constant";
import { useSignupState, useSignupDispatch } from "contexts/signup";
import experianFraudApi from "apis/customers/experian-fraud";
import routeMapper from "utils/routeMapper";
import { useHistory } from "react-router-dom";
import FraudOtpModal from "components/MyAccount/common/FraudOtpModal";
import { creditApplicationSubscription } from "channels/creditApplicationChannel";

const FraudOtpPage = () => {
  const history = useHistory();
  const signupDetails = useSignupState();
  const signupDispatch = useSignupDispatch();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const timerRef = useRef(null);

  useEffect(() => setPhone(signupDetails.phone_number));

  const handleResponse = data => {
    setLoading(false);

    if (data.status === 200) {
      const appStatus = data.credit_status || data.fraud_status;
      const creditLimit = data.credit_limit;

      if (data.kba_questions && data.kba_questions.length > 0) {
        localStorage.setItem(
          "kbaQuestions",
          JSON.stringify(data.kba_questions)
        );
        history.push("/flow/register/additional-info");
      } else if (creditLimit) {
        signupDispatch({
          type: "PERSONAL_INFO",
          payload: {
            ...signupDetails.personalInfo,
            credit_limit: creditLimit,
          },
        });

        history.push(
          `${routeMapper[data.page]}/${appStatus}?limit=${creditLimit}`
        );
      } else if (appStatus) {
        history.push(`${routeMapper[data.page]}/${appStatus}`);
      } else {
        if (data.message) Toastr.success(data.message);
        history.push(routeMapper[data.page]);
      }
    } else {
      if (data.status === 503 || data.status === 504) {
        history.push(`${routeMapper["Error"]}?error=${TIMEOUT_ERROR_MESSAGE}`);
      } else {
        const errorMessage = data?.error;
        history.push(`${routeMapper["Error"]}?error=${errorMessage}`);
      }
    }
  };

  useEffect(() => {
    creditApplicationSubscription({
      authToken: signupDetails?.personalInfo?.authentication_token,
      email: signupDetails?.personalInfo?.email,
      customerId: signupDetails?.personalInfo.id,
      handleResponse,
    });
  }, []);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const handleSubmit = async (event, otp) => {
    try {
      setLoading(true);
      event.preventDefault();
      setAuthHeaders();

      timerRef.current = setTimeout(
        () =>
          history.push(
            `${routeMapper["Error"]}?error=${TIMEOUT_ERROR_MESSAGE}`
          ),
        REQUEST_TIMEOUT_IN_MS
      );

      await experianFraudApi.processFraudOtp({ otp: otp.join("") });
    } catch (error) {
      const errorCode = error.response?.status;

      if (errorCode === 503 || errorCode === 504) {
        history.push(`${routeMapper["Error"]}?error=${TIMEOUT_ERROR_MESSAGE}`);
      } else {
        const errorMessage =
          error?.response?.data?.error || DEFAULT_ERROR_MESSAGE;
        history.push(`${routeMapper["Error"]}?error=${errorMessage}`);
      }

      logger.error(error);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center justify-center becca-homepage">
          <Spinner />
        </div>
      ) : (
        <div className="py-8">
          <FraudOtpModal phone={phone} handleSubmit={handleSubmit} />
        </div>
      )}
    </>
  );
};

export default FraudOtpPage;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Label, Modal } from "neetoui";

import paymentsApi from "apis/payments";
import { useCustomerState } from "contexts/customer";
import { formatCurrency } from "utils/styleUtils";
import { formatDate } from "common/dateTimeFormatter";

const ConfirmPayment = ({ paymentInformation, changeStep, modalClose }) => {
  const { customer } = useCustomerState();
  const [loading, setLoading] = useState(null);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState("");

  const history = useHistory();

  const onConfirmPayment = value => {
    setPaymentModalOpen(value);
  };

  const onContinue = () => {
    setPaymentModalOpen(false);
    modalClose(false);
    history.go(0);
  };

  const handlePayment = async () => {
    try {
      setLoading(true);
      const payload = {
        lease_id: paymentInformation.lease.id,
        payment_method_id: paymentInformation.paymentMethod.id,
        payment_type: paymentInformation.type,
        amount: paymentInformation.amount,
        payment_date: paymentInformation.date,
        epo_amount: paymentInformation.epoAmount,
        upcoming_invoice_amount: paymentInformation.upcomingInvoiceAmount,
      };

      await paymentsApi.create(customer.id, payload);
      onConfirmPayment("success");
    } catch (error) {
      onConfirmPayment("failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="relative w-2/5 mt-32 space-y-4">
        <div className="flex justify-center text-lg">
          Confirm Payment Details
        </div>
        <div className="flex justify-between my-2">
          <Label>Payment Date:</Label>
          <div className="font-medium text-gray-800">{formatDate()}</div>
        </div>
        <div className="flex justify-between my-2">
          <Label>Lease ID:</Label>
          <div className="font-semibold underline text-purple-1000">
            #{paymentInformation?.lease?.slug}
          </div>
        </div>
        <div className="flex justify-between my-2">
          <Label>Payment To:</Label>
          <div className="font-medium text-gray-800">Becca’s Home</div>
        </div>
        <div className="flex justify-between my-2">
          <Label>Customer Name:</Label>
          <div className="font-medium text-gray-800">{customer?.name}</div>
        </div>
        <div className="flex justify-between my-2">
          <Label>Payment Details:</Label>
          <div className="font-medium text-gray-800">
            {paymentInformation?.paymentMethod?.name}
          </div>
        </div>
        <div className="flex justify-between my-2">
          <Label>Payment Amount:</Label>
          <div className="font-medium text-gray-800">
            {formatCurrency(paymentInformation?.amount)}
          </div>
        </div>
        <div className="flex justify-end my-2 space-x-4">
          <Button
            style="secondary"
            label="Back"
            onClick={() => {
              changeStep(-1);
            }}
          />
          <Button
            loading={loading}
            style="primary"
            label="Submit Payment"
            onClick={() => handlePayment()}
          />
        </div>
      </div>
      <Modal
        isOpen={isPaymentModalOpen}
        size="small"
        className="min-h-0"
        onClose={() => setPaymentModalOpen(false)}
      >
        <div className="flex flex-col justify-center ">
          <div className="text-2xl font-bold">Payment Confirmation Modal</div>
          <p className="py-2 text-lg">
            Payment of &nbsp;
            <span className="font-semibold text-purple-1000">
              {formatCurrency(paymentInformation?.amount)}
            </span>
            &nbsp; towards your lease &nbsp;
            <span className="font-semibold text-purple-1000">
              {paymentInformation?.lease?.slug}
            </span>{" "}
            &nbsp;
            {isPaymentModalOpen == "success" && "was completed successfully."}
            {isPaymentModalOpen == "failed" && "failed."}
          </p>
          <div>
            <Button
              label="Continue"
              fullWidth={true}
              onClick={() => onContinue()}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmPayment;

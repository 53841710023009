import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, PageLoader, Toastr } from "neetoui";
import { Input } from "neetoui/formik";
import customersProfileApi from "apis/customers/profile";
import customersPhoneVerificationApi from "apis/customers/phone-verification";
import { Form, Formik } from "formik";
import { useUserState } from "contexts/user";

import SMSModal from "./SmsModal";
import { PROFILE_DATA_VALIDATION_SCHEMA } from "./constants";

const MyProfile = () => {
  const { user } = useUserState();
  const [isSmsModalOpen, setIsSmsModalOpen] = useState(false);
  const [initialPhoneNumber, setInitialPhoneNumber] = useState("");
  const [phoneToBeVerified, setPhoneToBeVerified] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address: {
      area: "",
      city: "",
      state: "",
      street_number: "",
    },
    zip_code: "",
    phone_number: "",
  });
  const history = useHistory();

  const getProfileData = async () => {
    setIsLoading(true);
    try {
      const { data } = await customersProfileApi.getProfile(user.id);
      setInitialPhoneNumber(data.customer.phone_number);
      setProfileData(data.customer);
    } catch (error) {
      logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const updatePhoneNumber = async phoneNumber => {
    try {
      await customersPhoneVerificationApi.registerPhone({
        phone: {
          country_code: "+1",
          phone_number: phoneNumber,
          phoneable_type: "Customer",
          phoneable_id: profileData.id,
        },
      });
      setPhoneToBeVerified(phoneNumber);
      setIsSmsModalOpen(true);
    } catch (error) {
      logger.error(error);
      setIsSmsModalOpen(false);
    }
  };

  const handleSubmit = async ({
    first_name,
    last_name,
    email,
    phone_number,
    area,
    city,
    state,
    zip_code,
    street_number,
  }) => {
    try {
      setIsSubmitting(true);
      if (phone_number !== initialPhoneNumber) {
        // updatePhoneNumber(phone_number);
      } else {
        const payload = {
          customer: {
            first_name,
            last_name,
            email,
            addresses_attributes: {
              id: profileData.address.id,
              area,
              city,
              state,
              street_number,
              zip_code,
            },
          },
          phone: {
            phone_number,
          },
        };

        logger.log(payload);
        // await customersProfileApi.updateProfile(payload);

        Toastr.success("Profile details updated successfully.");
      }
    } catch (error) {
      logger.error(error);
    }
    setIsSubmitting(false);
  };

  const onModalClose = () => {
    setIsSmsModalOpen(false);
  };

  let initialValues = { ...profileData, ...profileData.address };

  if (isLoading)
    return (
      <div className="flex flex-col items-center justify-center w-screen becca-homepage">
        <PageLoader />
      </div>
    );
  return (
    <>
      <div className="overflow-y-auto lg:max-w-lg">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
          validationSchema={PROFILE_DATA_VALIDATION_SCHEMA}
        >
          {() => (
            <Form noValidate>
              <div className="p-6">
                <div className="text-lg font-semibold text-gray-600">
                  Hey, {initialValues.first_name}
                </div>
                <div className="text-red-400">
                  If your details are outdated, Contact support to modify.
                </div>
              </div>

              <div className="pb-6 pl-6 pr-6 space-y-6">
                <Input label="Email" name="email" required readOnly />
                <Input
                  label="Mobile Number"
                  name="phone_number"
                  required
                  readOnly
                />
                <Input
                  label="Street Address"
                  name="street_number"
                  required
                  readOnly
                />
                <Input label="Apartment Number" name="area" readOnly />
                <Input label="City" name="city" required readOnly />
                <Input label="State" name="state" required readOnly />
                <Input label="Zip" name="zip_code" required readOnly />
                <div className="flex justify-end space-x-4">
                  <Button
                    label="Go back"
                    style="secondary"
                    onClick={() => history.goBack()}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {isSmsModalOpen && (
        <SMSModal
          isSmsModalOpen={isSmsModalOpen}
          onModalClose={onModalClose}
          phoneNumber={phoneToBeVerified}
          initialPhoneNumber={initialPhoneNumber}
          resentOtp={updatePhoneNumber}
          setInitialPhoneNumber={setInitialPhoneNumber}
        />
      )}
    </>
  );
};

export default MyProfile;

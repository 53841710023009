import React from "react";
import RejectedIcon from "common/images/rejected";

const ApplicationFailed = () => {
  return (
    <>
      <div className="px-4 mt-4">
        <p className="w-5/6 mb-6 text-lg">
          Sorry, It seems that your credit request was not approved.
        </p>
        <p className="mb-4 text-sm text-gray-500">
          Don’t worry — this did not affect your credit score.
        </p>
        <div className="flex justify-center w-full my-4">
          <img width="150px" height="150px" src={RejectedIcon} />
        </div>
        <p className="mt-4 text-sm text-gray-500">
          For more details, you can contact us.
        </p>
      </div>
    </>
  );
};

export default ApplicationFailed;

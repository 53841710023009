import React, { useState } from "react";
import { Label, Checkbox, Button } from "@bigbinary/neetoui";
import { Header, SubHeader } from "@bigbinary/neetoui/layouts";

import VendorItem from "./VendorItem";
import {
  DEFAULT_PAGE_SIZE,
  LOAD_SORT_PROPS,
  SORT_BY_OPTIONS_VENDOR,
  VENDOR_DETAILS_TABLE,
} from "../constants";

const TableColumnHeader = ({ children }) => (
  <div className="flex justify-center">
    <Label className="text-gray-400 uppercase" infoTextPosition="center">
      {children}
    </Label>
  </div>
);

const VendorTableHeader = () => (
  <div className="vendor_item_grid">
    <div className="flex justify-center">
      <Checkbox />
    </div>
    <TableColumnHeader>Product Images</TableColumnHeader>
    <TableColumnHeader>Product Category </TableColumnHeader>
    <TableColumnHeader>Monthly</TableColumnHeader>
    <TableColumnHeader>Inventory</TableColumnHeader>
    <TableColumnHeader>Brand</TableColumnHeader>
    <TableColumnHeader>Name</TableColumnHeader>
    <TableColumnHeader>Wholesale</TableColumnHeader>
    <TableColumnHeader>Shipping</TableColumnHeader>
    <TableColumnHeader>First seen on</TableColumnHeader>
    <div></div>
  </div>
);

const titleNameFunction = () => {
  return "Modway’s Furniture";
};

const breadcrumbsFunction = () => {
  return [{ text: "Product Import", link: "/productimport/vendorlist" }];
};

const VendorItemList = () => {
  const [searchString, setSearchString] = useState();
  const [totalOrderCount] = useState();
  const [pageIndex, setPageIndex] = useState(1);

  return (
    <>
      <div className="w-full">
        <Header
          title={titleNameFunction()}
          breadcrumbs={breadcrumbsFunction()}
          actionBlock={
            <div className="flex space-x-2">
              <Button style="secondary" label="Discard" onClick={() => {}} />
              <Button label="+ Import" onClick={() => {}} />
            </div>
          }
        />
        <SubHeader
          searchProps={{
            value: searchString,
            onChange: e => setSearchString(e.target.value),
            clear: () => setSearchString(""),
          }}
          paginationProps={{
            count: totalOrderCount,
            pageNo: pageIndex,
            pageSize: DEFAULT_PAGE_SIZE,
            navigate: index => setPageIndex(index),
          }}
          sortProps={{
            options: SORT_BY_OPTIONS_VENDOR,
            onClick: LOAD_SORT_PROPS,
          }}
        />
        <div className="grid mx-auto mt-10">
          <VendorTableHeader />
          {VENDOR_DETAILS_TABLE.map((item, index) => {
            return <VendorItem key={index} data={item} />;
          })}
        </div>
      </div>
    </>
  );
};

export default VendorItemList;

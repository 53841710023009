import React from "react";
import { Label, Checkbox } from "@bigbinary/neetoui";

const PaymentInfo = () => (
  <>
    <div className="w-full mt-10 ml-20">
      <p className="pb-3 text-xl font-bold border-b">Payment Information</p>
      <div className="flex w-3/5 space-x-6">
        <div className="w-full pt-3 space-y-1 ">
          <div className="flex ">
            <Label className="w-1/2 font-semibold">Last Payment:</Label>
            <p>_September 21,2021</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">First delinquency:</Label>
            <p>_August 12,2021</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">Account status:</Label>
            <p>_11</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">Highest credit:</Label>
            <p>_$2344</p>
          </div>
        </div>
        <div className="w-full pt-3 space-y-1 ">
          <div className="flex ">
            <Label className="w-1/2 font-semibold">Current balance:</Label>
            <p>_$12331</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">Payment Frequency:</Label>
            <p>_Weekly</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">Scheduled payment:</Label>
            <p>_$100</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">Actual payment:</Label>
            <p>_$100</p>
          </div>
        </div>
      </div>
    </div>
    <div className="mt-10 ml-20 border-t">
      <div className="w-3/5 mb-10 ml-36">
        <table className="nui-table nui-table--checkbox nui-table--avatar">
          <thead>
            <tr className="text-center">
              <th></th>
              <th className="text-center">Jan</th>
              <th className="text-center">Feb</th>
              <th className="text-center">Mar</th>
              <th className="text-center">Apr</th>
              <th className="text-center">May</th>
              <th className="text-center">Jun</th>
              <th className="text-center">Jul</th>
              <th className="text-center">Aug</th>
              <th className="text-center">Sep</th>
              <th className="text-center">Oct</th>
              <th className="text-center">Nov</th>
              <th className="text-center">Dec</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border">
              <td>2021</td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">_L</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">_L</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">_G</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">_G</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">_G</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">_2</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">_1</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
            </tr>
            <tr className="border">
              <td>2020</td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">L</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">L</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">G</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">G</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">G</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">2</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">1</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
            </tr>
            <tr className="border">
              <td>2019</td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">L</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">L</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">G</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">G</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">G</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">2</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-xl font-semibold">1</p>
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
              <td>
                <div className="flex flex-row items-center justify-center">
                  <Checkbox disabled />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="my-10 space-y-1">
        <p>0 = 0 payments past due (current account) </p>
        <p>1 = 30 - 59 days past due date </p>
        <p>2 = 60 - 89 days past due date </p>
        <p>
          B = No payment history available prior to this time – either because
          the account was not open or because the payment history cannot be
          furnished. A “B” may not be embedded within other values.
        </p>
        <p>
          D = No payment history reported/available this month. “D” may be
          embedded in the payment history profile.
        </p>
        <p> G = Collection</p>
        <p> K = Repossession</p>
        <p> L = Charge-off</p>
      </div>
    </div>
  </>
);

export default PaymentInfo;

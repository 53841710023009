import React from "react";
import BeccasHomeLogo from "common/images/beccas-home-logo.png";
import { fetchQueryParams } from "utils/pathUtils";
import { Link } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import { getPageTitle } from "common/utils";

const MagicLinkSuccess = ({ email }) => {
  const { email: paramEmail } = fetchQueryParams;

  const getEmail = () => email || paramEmail;

  useDocumentTitle(getPageTitle("login", "magicLinkSuccess"));

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen p-6 overflow-x-hidden overflow-y-auto bg-white">
      <div className="flex items-center justify-center rounded-md w-24 h-24">
        <img src={BeccasHomeLogo} width="150px" height="150px" />
      </div>
      <div className="flex flex-col items-start justify-center w-full h-full mx-auto sm:max-w-md">
        <div className="flex flex-col justify-start">
          <p className="text-3xl ri-lock-password-line text-purple-1000"></p>
          <h2 className="mt-4 text-2xl font-bold text-gray-800">
            Magic link sent to {getEmail()}.
          </h2>
          <p className="mt-3 font-normal text-gray-600">
            Check your inbox and click on the link to sign in instantly!
          </p>
        </div>
        <div className="w-full text-left mt-4">
          <p className="text-gray-500">
            Didn&apos;t receive the email or having trouble signing in? Try
            resetting your password{" "}
            <Link
              to={`/login/reset?email=${getEmail()}`}
              className="text-purple-700 underline"
            >
              here
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default MagicLinkSuccess;

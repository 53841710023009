import React from "react";
import { Route, Switch } from "react-router-dom";
import { isDesktop, isMobile } from "react-device-detect";
import classnames from "classnames";

import PayNow from "./PayNow";
import PayEarly from "./PayEarly";
import NewPayment from "./NewPayment";
import AutoPaySettings from "./AutoPaySettings";

const PaymentsMobile = () => {
  return (
    <div className="justify-center w-screen h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div
          className={classnames({
            "bh-desktop--container": isDesktop,
            "w-full": isMobile,
          })}
        >
          <Switch>
            <Route
              exact
              component={PayNow}
              path="/payment/:customerId/paynow"
            />
            <Route
              exact
              component={PayEarly}
              path="/payment/:customerId/payearly"
            />
            <Route
              exact
              component={AutoPaySettings}
              path={"/payment/:customerId/autopay-settings"}
            />
            <Route component={NewPayment} path="/payment/:customerId/payment" />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default PaymentsMobile;

import React from "react";
import CardIcon from "common/images/card_logo.svg";

const CardVector = ({
  paymentDetails: { cardNumber, cardName, expiryDate },
}) => (
  <div className="p-6 card-background">
    <div className="flex justify-end my-4">
      <img src={CardIcon} width="40px" height="40px" />
    </div>
    <div className="my-4 text-lg font-light text-white">{cardNumber}</div>
    <div className="flex justify-between">
      <div className="space-y-2">
        <p className="font-semibold text-gray-300 uppercase">Card Holder</p>
        <p className="text-base font-semibold text-white">{cardName}</p>
      </div>
      <div className="space-y-2">
        <p className="font-semibold text-gray-300 uppercase">Expiry date</p>
        <p className="text-base text-white">{expiryDate}</p>
      </div>
    </div>
  </div>
);

export default CardVector;

import React from "react";
import { Button } from "neetoui";

import { Form, Formik } from "formik";
import { Input as FormikInput } from "neetoui/formik";
import {
  RESET_PASSWORD_INITIAL_VALUE,
  RESET_VALIDATION_SCHEMA,
} from "./constant";
import BeccasHomeLogo from "common/images/beccas-home-logo.png";

const ResetPassword = () => {
  const handleSubmit = () => {};

  const validationSchema = RESET_VALIDATION_SCHEMA;

  return (
    <div className="flex flex-row items-center justify-center w-screen h-screen p-6 overflow-x-hidden overflow-y-auto bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full h-full mx-auto sm:max-w-md">
        <div className="flex items-center justify-center rounded-md w-14 h-14">
          <img src={BeccasHomeLogo} width="100px" height="100px" />
        </div>
        <h2 className="mb-5 text-3xl font-extrabold text-center text-gray-800">
          Forgot your password?
        </h2>
        <div className="w-2/3 mb-5 -mt-4 text-center text-gray-700">
          Enter your email address below and we&apos;ll send you a link to reset
          your password.
        </div>
        <Formik
          initialValues={RESET_PASSWORD_INITIAL_VALUE}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="w-full p-8 space-y-6 bg-white border rounded-md shadow">
            <FormikInput
              name="email"
              type="email"
              label="Email *"
              placeholder="oliver@example.com"
            />
            <div className="flex flex-col items-center justify-center space-y-2">
              <Button
                type="submit"
                label="Send reset password email"
                data-disable-with="Send reset password email"
                fullWidth
              />
              <Button label="Back" style="link" to="/staff/login" />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;

const authReducer = (state, { type, payload }) => {
  switch (type) {
  case "LOGIN": {
    localStorage.setItem("authToken", payload.auth_token);
    localStorage.setItem("authEmail", payload.email);
    localStorage.setItem("role", payload.role);

    return {
      isLoggedIn: true,
      authToken: payload.auth_token,
      authEmail: payload.email,
      role: payload.role,
    };
  }
  case "LOGOUT": {
    localStorage.setItem("authToken", "null");
    localStorage.setItem("authEmail", "null");
    localStorage.setItem("role", "null");

    return {
      isLoggedIn: false,
      authToken: null,
      authEmail: null,
      role: null,
    };
  }
  default: {
    throw new Error(`Unhandled action type: ${type}`);
  }
  }
};

export default authReducer;

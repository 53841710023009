import React from "react";
import { Accordion } from "@bigbinary/neetoui";
import PaymentSettingsTable from "./PaymentTable";
import { PAYMENT_SETTINGS } from "../../constants";

const WorkflowSettings = () => {
  return (
    <div className="flex justify-center mt-10">
      <Accordion className="w-5/6">
        <Accordion.Item
          title={
            <div className="flex items-center justify-center text-lg font-semibold text-center">
              Payment Workflows
            </div>
          }
        >
          <PaymentSettingsTable loading={false}>
            <PaymentSettingsTable.Header />
            <PaymentSettingsTable.Body data={PAYMENT_SETTINGS} />
          </PaymentSettingsTable>
        </Accordion.Item>
        <Accordion.Item
          title={
            <div className="flex items-center justify-center text-lg font-semibold text-center">
              Orders Workflows
            </div>
          }
        >
          <PaymentSettingsTable loading={false}>
            <PaymentSettingsTable.Header />
            <PaymentSettingsTable.Body data={PAYMENT_SETTINGS} />
          </PaymentSettingsTable>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default WorkflowSettings;

import React from "react";
import { Route } from "react-router";

import CustomerDetails from "./Tabs";
import CustomersListing from "./CustomerListing";
import { CustomerProvider } from "contexts/customer";
import ApplicationJsonViewer from "./Tabs/Applications/ApplicationJsonViewer";

const Customer = () => {
  return (
    <CustomerProvider>
      <Route exact path={"/customers"} component={CustomersListing} />
      <Route
        exact
        path={"/customers/:id/applications/:applicationId/:logType"}
        component={ApplicationJsonViewer}
      />
      <Route
        exact
        path={"/customers/:id/:tab?/:leaseId?"}
        component={CustomerDetails}
      />
    </CustomerProvider>
  );
};

export default Customer;

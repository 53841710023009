import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";

import { Button, Toastr } from "neetoui";

import { useHistory } from "react-router-dom";
import FloatingInput from "components/Common/FloatingInput";
import BeccasHomeLogo from "common/images/beccas-home-logo.png";
import authenticationApi from "apis/customers/authentication";

const SignIn = ({ email, setEmail }) => {
  const [loading, setLoading] = useState(false);
  const [tempEmail, setTempEmail] = useState(email);
  const [error, setError] = useState("");

  const historyRoute = useHistory();

  const validEmail = e => {
    // const filter =
    //   /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
    // return String(e).search(filter) != -1;
    let schema = Yup.string().email();
    let result = schema.isValidSync(e);
    return result;
  };

  const handleSubmit = async () => {
    setEmail(tempEmail);
    try {
      if (validEmail(tempEmail)) {
        setLoading(true);
        await authenticationApi.emailVerify({ email: tempEmail });
        historyRoute.push(`/login/magic-link?email=${tempEmail}`);
      } else {
        setError("Please enter a  proper email address");
      }
    } catch (error) {
      if (error.response.status == 404) {
        Toastr.error({ error: error.response.data.error });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen py-4 px-6 overflow-x-hidden overflow-y-auto bg-white">
      <div className="flex items-center justify-center rounded-md w-24 h-24">
        <img src={BeccasHomeLogo} width="150px" height="150px" />
      </div>
      <div className="flex flex-col items-start justify-center w-full h-full mx-auto sm:max-w-md">
        <div className="flex flex-col justify-start">
          <p className="text-4xl ri-account-circle-line text-purple-1000"></p>
          <h2 className="mt-4 text-3xl font-bold text-gray-800 ">Sign in</h2>
          <p className="mt-3 font-normal text-gray-600">
            Sign in to your account using your email.
          </p>
        </div>
        <form className="w-full mt-6 space-y-6 bg-white">
          <FloatingInput
            error={error}
            label="Email *"
            id="customer_email"
            value={tempEmail}
            onChange={e => {
              e.preventDefault();
              setTempEmail(e.target.value);
            }}
          />
          <Button
            type="submit"
            loading={loading}
            fullWidth
            label="Continue"
            onClick={e => {
              e.preventDefault();
              handleSubmit();
            }}
          />
        </form>
        <div className="flex items-center justify-center w-full mt-8">
          <div className="flex items-center justify-center w-full h-px bg-gray-300">
            <div className="absolute flex items-center justify-center px-4 text-sm text-gray-600 bg-white">
              Don&rsquo;t have an account?
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full mt-8">
          <Button
            label="Get approved now!"
            style="secondary"
            onClick={() => historyRoute.push("/flow/register/otp")}
          />
        </div>
      </div>
      <div className="flex items-end justify-end w-full text-gray-500">
        Terms | Privacy
      </div>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object,
};

export default SignIn;

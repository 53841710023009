import React, { useState, useEffect } from "react";
import { Input, Button } from "neetoui";
import Spinner from "components/SignUp/Registration/Spinner";

const FraudOtpModal = ({
  isOpen = true,
  closeBtn = false,
  setIsOpen = () => {},
  phone,
  handleSubmit,
  processing = false,
}) => {
  if (!isOpen) return null;

  const otpRef0 = React.createRef();
  const otpRef1 = React.createRef();
  const otpRef2 = React.createRef();
  const otpRef3 = React.createRef();
  const otpRef4 = React.createRef();
  const otpRef5 = React.createRef();

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  useEffect(() => {
    otpRef0.current.focus();
  }, []);

  const onOTPValueChange = (index, value) => {
    setOtp(state => {
      let updatedState = [...state];
      updatedState[index] = value;

      if (index == 0) otpRef1.current.focus();
      if (index == 1) otpRef2.current.focus();
      if (index == 2) otpRef3.current.focus();
      if (index == 3) otpRef4.current.focus();
      if (index == 4) otpRef5.current.focus();
      if (index == 5) otpRef0.current.focus();

      return updatedState;
    });
  };

  return (
    <>
      {processing ? (
        <div className="absolute bottom-0 z-40 w-full max-w-lg bg-white border border-gray-200 shadow-lg rounded-t-xl">
          <div className="p-2">
            <div className="flex items-center justify-between">
              <Spinner />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="absolute w-full bottom-0 z-40 px-4 overflow-y-auto lg:max-w-lg bg-white border border-gray-200 shadow-lg rounded-t-2xl">
            <div className="p-2">
              {closeBtn && (
                <div className="flex items-center justify-end">
                  <Button
                    className="text-2xl"
                    style="text"
                    icon="ri-close-line"
                    onClick={() => setIsOpen(false)}
                  />
                </div>
              )}
              <div className="flex flex-col items-center justify-center mt-6 space-y-4">
                <p className="w-5/6 text-lg">
                  {`The verification code was sent to ${phone}. Enter it below.`}
                </p>
                <div className="flex justify-between px-6 space-x-2">
                  <Input
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    ref={otpRef0}
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[0]}
                    required
                    onChange={e => onOTPValueChange(0, e.target.value)}
                  />
                  <Input
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    ref={otpRef1}
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[1]}
                    required
                    onChange={e => onOTPValueChange(1, e.target.value)}
                  />
                  <Input
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    ref={otpRef2}
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[2]}
                    required
                    onChange={e => onOTPValueChange(2, e.target.value)}
                  />
                  <Input
                    type="tel"
                    pattern="[\d]*"
                    inputMode="numeric"
                    ref={otpRef3}
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[3]}
                    required
                    onChange={e => onOTPValueChange(3, e.target.value)}
                  />
                  <Input
                    ref={otpRef4}
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[4]}
                    required
                    onChange={e => onOTPValueChange(4, e.target.value)}
                  />
                  <Input
                    ref={otpRef5}
                    nakedInput
                    className="border-b border-gray-600"
                    value={otp[5]}
                    required
                    onChange={e => onOTPValueChange(5, e.target.value)}
                  />
                </div>
                <Button
                  label="Next"
                  fullWidth={true}
                  onClick={evt => handleSubmit(evt, otp)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FraudOtpModal;

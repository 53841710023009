const signupReducer = (state, { type, payload }) => {
  switch (type) {
  case "OTP": {
    return {
      ...state,
      phone_number: payload,
    };
  }
  case "PERSONAL_INFO": {
    return {
      ...state,
      personalInfo: payload,
      id: payload.id,
    };
  }
  case "WORK_INFO": {
    return {
      ...state,
      workInfo: payload,
    };
  }
  case "ADDRESS_INFO": {
    return {
      ...state,
      addressInfo: payload,
    };
  }
  default: {
    throw new Error(`Unhandled action type: ${type}`);
  }
  }
};

export default signupReducer;

import React from "react";
import { Button } from "@bigbinary/neetoui";
import { Input } from "neetoui/formik";
import customersPasswordApi from "apis/customers/password";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router";

const PasswordChange = () => {
  const history = useHistory();

  const handleSubmit = async data => {
    try {
      await customersPasswordApi.changePassword({
        current_password: data.current_password,
        password: data.password,
        password_confirmation: data.password_confirmation,
      });
    } catch (error) {
      logger.error(error);
    }
  };

  const goback = () => {
    history.goBack();
  };

  return (
    <div className="mt-8">
      <div className="flex flex-col items-center justify-center">
        <div className="w-full p-4 text-lg font-semibold border-b border-gray-200">
          Reset Password
        </div>
        <Formik
          initialValues={{
            current_password: "",
            password: "",
            password_confirmation: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={yup.object({
            current_password: yup
              .string()
              .required("Current password is required."),
            password: yup
              .string()
              .min(6, "Password is too short!")
              .required("New password is required."),
            password_confirmation: yup
              .string()
              .required("Confirm password is required.")
              .test(
                "passwords-match",
                "Passwords must match",
                function (value) {
                  return this.parent.password === value;
                }
              ),
          })}
        >
          {() => (
            <Form>
              <div className="flex flex-col justify-center pt-8 space-y-6 w-80">
                <Input
                  label="Current Password"
                  name="current_password"
                  type="password"
                />
                <Input label="New Password" name="password" type="password" />
                <Input
                  label="Confirm New Password"
                  name="password_confirmation"
                  type="password"
                />
                <div className="flex justify-end space-x-4">
                  <Button
                    label="Cancel"
                    style="secondary"
                    onClick={() => goback()}
                  />
                  <Button label="Save Changes" type="submit" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PasswordChange;

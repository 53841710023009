import React, { useEffect, useState } from "react";
import { components } from "react-select";
import { Select } from "@bigbinary/neetoui";
import { countries } from "countries-list";

const CountrySelect = ({ onCountryChange, country = "US" }) => {
  const [countryState, setCountryState] = useState({
    label: "",
    phone: "",
    emoji: "",
    value: "",
  });

  useEffect(() => {
    if (countries[country]) {
      setCountryState({
        label: countries[country].name,
        phone: countries[country].phone,
        emoji: countries[country].emoji,
        value: countries[country].name,
      });
    }
  }, [country]);

  const CustomOption = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        {props.data.emoji} {children}
      </components.Option>
    );
  };

  const SingleValue = ({ ...props }) => {
    return (
      <components.SingleValue {...props}>
        {props.data.emoji}
      </components.SingleValue>
    );
  };

  let CountryLabel = Object.keys(countries).map(item => {
    return {
      label: countries[item].name,
      phone: countries[item].phone,
      emoji: countries[item].emoji,
      value: countries[item].name,
    };
  });

  return (
    <Select
      components={{
        Option: CustomOption,
        SingleValue: SingleValue,
        IndicatorSeparator: () => null,
      }}
      onChange={value => {
        setCountryState(value);
        onCountryChange(value);
      }}
      value={countryState}
      options={CountryLabel}
      maxMenuHeight="300px"
      styles={{
        singleValue: base => ({
          ...base,
          fontSize: "24px",
          marginRight: "20px",
          color: "black",
          display: "flex",
        }),
        container: base => ({
          ...base,
          minWidth: "80px",
          maxWidth: "80px",
        }),
        menu: base => ({
          ...base,
          minWidth: "200px",
        }),
      }}
    />
  );
};

export default CountrySelect;

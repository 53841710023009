import React, { useEffect, useState } from "react";
import { components as SelectComponent } from "react-select";
import { Select } from "neetoui";
import CardIcon from "common/images/card_logo.svg";

import paymentMethodsApi from "apis/payment-methods";

const SingleValue = ({ children, ...props }) => {
  return (
    <SelectComponent.SingleValue {...props}>
      <div className="flex items-center justify-center space-x-2">
        <div>
          <img src={CardIcon} width="20px" height="20px" />
        </div>
        <div>{children}</div>
      </div>
    </SelectComponent.SingleValue>
  );
};

const Option = props => {
  const exp_month = props?.data?.details?.card?.exp_month;
  const exp_year = props?.data?.details?.card?.exp_year.toString()?.slice(2);
  const { innerProps } = props;
  const { onClick } = innerProps;
  return (
    <div
      {...props}
      className="flex justify-between p-2 cursor-pointer hover:text-white hover:bg-purple-1000"
      onClick={() => onClick()}
    >
      <p>{props.label}</p>
      {exp_month && exp_year && (
        <p className="pr-2 text-gray-400">
          {exp_month}/{exp_year}
        </p>
      )}
    </div>
  );
};

const DropDown = (month, year) => {
  const exp_month = month;
  const exp_year = year?.toString()?.slice(2);

  return (
    <p className="pr-2 text-gray-400">
      {exp_month}/{exp_year}
    </p>
  );
};

function PaymentSelect({
  initialPaymenteMethod,
  id,
  newCardPane,
  onPaymentMethodChange,
  onNewCardChange,
}) {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [paymentMethodList, setPaymentMethodList] = useState([]);

  const onChange = value => {
    if (value.value == "add") {
      onNewCardChange();
    } else {
      setSelectedPaymentMethod(value);
      onPaymentMethodChange(value);
    }
  };

  useEffect(() => {
    setSelectedPaymentMethod(initialPaymenteMethod);
  }, []);

  useEffect(() => {
    fetchPaymentMethods();
  }, [newCardPane]);

  const fetchPaymentMethods = async () => {
    try {
      const {
        data: { payment_methods },
      } = await paymentMethodsApi.all(id);

      let labeledPaymentMethodList = payment_methods?.map((item, index) => {
        return {
          label: "Debit Card ending " + item?.number_ending_with,
          value: item?.number_ending_with,
          index: index,
          ...item,
        };
      });
      let defaultCardIndex = payment_methods.findIndex(item => item.is_default);
      let selectedCardIndex = defaultCardIndex ? defaultCardIndex : 0;

      labeledPaymentMethodList = labeledPaymentMethodList?.concat([
        { label: "+ Add new payment method", value: "add" },
      ]);

      setPaymentMethodList(labeledPaymentMethodList);
      setSelectedPaymentMethod(labeledPaymentMethodList[selectedCardIndex]);
      onPaymentMethodChange(labeledPaymentMethodList[selectedCardIndex]);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Select
      label="Payment Method"
      onChange={value => onChange(value)}
      value={selectedPaymentMethod}
      options={paymentMethodList}
      components={{
        SingleValue: SingleValue,
        IndicatorSeparator: () => null,
        Option,
        DropdownIndicator: () =>
          DropDown(
            initialPaymenteMethod?.details?.card?.exp_month,
            initialPaymenteMethod?.details?.card?.exp_year
          ),
      }}
    />
  );
}

export default PaymentSelect;

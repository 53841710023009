import React from "react";
import { NavLink } from "react-router-dom";

export default function SubMenu({ subLinks, title }) {
  return (
    <>
      <li className="font-semibold text-gray-800 pointer-events-none">
        {title}
      </li>
      {subLinks.map(subLink => (
        <div key={subLink.title}>
          <NavLink
            to={subLink.link}
            className="w-full text-purple-1000 nui-dropdown--item "
            activeClassName="active"
            onClick={e => e.target.parentElement.click()}
          >
            <p className="font-normal text-gray-600">{subLink.title}</p>
          </NavLink>
        </div>
      ))}
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce/lib";
import { Header, SubHeader } from "@bigbinary/neetoui/layouts";
import { Tooltip, Button, Badge } from "@bigbinary/neetoui";
import customersApi from "apis/customer";
import NoDataImage from "common/images/no-data.svg";
import CustomerTable from "./CustomerTable";
import EmptyState from "components/Common/EmptyState";
import { objectToQueryString, omitKey } from "common/utils";
import {
  CustomerStatus,
  DEFAULT_PAGE_SIZE,
  DEFAULT_BLANK_OPTION,
  FILTER_DATA,
  DEFAULT_RANGE,
} from "./constants";
import { FilterPane } from "./FilterPane";

const ActionBlockComponent = ({
  toggleFilter,
  setAccountStatus,
  setCreditStatus,
  setRegistrationStep,
  setCreatedAt,
  setWithOverdueAmount,
  setAvailableCredit,
  filters,
  setFilters,
}) => {
  const renderTag = ({ onClose, label }) => (
    <Badge color="gray" onClose={onClose} key={label}>
      {label}
    </Badge>
  );

  const getDefaultValue = filterName => {
    const filterData = FILTER_DATA[filterName];

    if (filterData.type === "select") return DEFAULT_BLANK_OPTION;
    else return DEFAULT_RANGE;
  };

  const generateLabel = filterName => {
    const filterData = FILTER_DATA[filterName];

    const rawValue = filters[filterName];
    if (filterData.type === "select") {
      return `${filterData.name}: ${rawValue}`;
    } else if (filterData.type === "dateRange") {
      const dependentValue = filters[filterData.dependentKey];
      const dateRange = [rawValue, dependentValue].join(" - ");

      return `${filterData.name}: ${dateRange}`;
    } else {
      return `${filterData.name}: ${rawValue.join(" - ")}`;
    }
  };

  const fetchSetValueFunction = filterName => {
    switch (filterName) {
    case "account_status_eq":
      return setAccountStatus;
    case "with_recent_credit_status":
      return setCreditStatus;
    case "registration_step_eq":
      return setRegistrationStep;
    case "created_at_date_start":
      return setCreatedAt;
    case "created_at_date_end_of_day":
      return setCreatedAt;
    case "with_overdue_amount":
      return setWithOverdueAmount;
    case "with_recent_available_credit":
      return setAvailableCredit;
    }
  };

  const onTagClose = (filterName, setValue) => {
    return () => {
      setValue(getDefaultValue(filterName));
      let updatedFilters = omitKey(filters, filterName);
      const filterData = FILTER_DATA[filterName];

      if (filterData.dependentKey) {
        updatedFilters = omitKey(updatedFilters, filterData.dependentKey);
      }
      if (filterData.dependentOn) {
        updatedFilters = omitKey(updatedFilters, filterData.dependentOn);
      }

      setFilters(updatedFilters);
    };
  };

  const getFilterKeys = () => {
    return Object.keys(filters).filter(
      filterName => FILTER_DATA[filterName].dependentOn === null
    );
  };

  return (
    <>
      <div className="flex items-center">
        <div className="flex flex-wrap justify-start">
          {getFilterKeys().map(filterName => {
            return renderTag({
              onClose: onTagClose(
                filterName,
                fetchSetValueFunction(filterName)
              ),
              label: generateLabel(filterName),
            });
          })}
        </div>
      </div>
      <div className="flex space-x-3">
        <Tooltip content="Filter" position="bottom">
          <Button
            style="icon"
            icon="ri-filter-2-line"
            onClick={toggleFilter}
            label="Filter"
          />
        </Tooltip>
      </div>
    </>
  );
};

const CustomersListing = () => {
  const [status, setStatus] = useState(CustomerStatus.IDLE);
  const [customers, setCustomers] = useState([]);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);

  const [searchString, setSearchString] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [debouncedSearch] = useDebounce(searchString, 500);
  const [isFilterOpen, toggleFilter] = useState(false);
  const [accountStatus, setAccountStatus] = useState(DEFAULT_BLANK_OPTION);
  const [creditStatus, setCreditStatus] = useState(DEFAULT_BLANK_OPTION);
  const [filters, setFilters] = useState({});
  const [registrationStep, setRegistrationStep] =
    useState(DEFAULT_BLANK_OPTION);
  const [createdAt, setCreatedAt] = useState([null, null]);
  const [withOverdueAmount, setWithOverdueAmount] = useState([null, null]);
  const [availableCredit, setAvailableCredit] = useState([null, null]);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const fetchSearchCriteria = () => {
    const trimmedSearchString = searchString.trim();
    if (/^[\d()-]/.test(trimmedSearchString)) {
      return {
        email_or_phone_phone_number_cont: trimmedSearchString.replace(
          /\D/g,
          ""
        ),
      };
    } else if (trimmedSearchString.split(" ").length == 2) {
      const name = trimmedSearchString.split(" ");
      return { first_name_cont: name[0], last_name_cont: name[1] };
    }
    return {
      email_or_first_name_or_last_name_or_phone_phone_number_cont:
        trimmedSearchString,
    };
  };

  const fetchCustomers = async () => {
    const searchParams = {
      q: {
        ...fetchSearchCriteria(),
        ...filters,
        s: `${sortBy} ${sortDirection}`,
      },
      page: pageIndex,
      per_page: DEFAULT_PAGE_SIZE,
    };

    try {
      setStatus(CustomerStatus.LOADING);
      const {
        data: { customers, customers_count },
      } = await customersApi.customers(objectToQueryString(searchParams));
      setCustomers(customers);
      setTotalCustomerCount(customers_count);
      setStatus(CustomerStatus.SUCCESS);
    } catch (error) {
      setStatus(CustomerStatus.ERROR);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [pageIndex, debouncedSearch, filters, sortBy, sortDirection]);

  return (
    <>
      <Header title={"Customers"} />
      <SubHeader
        searchProps={{
          value: searchString,
          onChange: e => setSearchString(e.target.value),
          clear: () => setSearchString(""),
        }}
        paginationProps={{
          count: totalCustomerCount,
          pageNo: pageIndex,
          pageSize: DEFAULT_PAGE_SIZE,
          navigate: index => setPageIndex(index),
        }}
        sortProps={{
          options: [
            { label: "Signup Date", value: "created_at" },
            { label: "Name", value: "full_name" },
            { label: "Email", value: "email" },
            { label: "Overdue amount", value: "overdue_amount" },
            {
              label: "Available Credit",
              value: "available_credit",
            },
          ],
          direction: sortDirection,
          onClick: ({ column, direction }) => {
            setSortBy(column);
            setSortDirection(direction);
            setPageIndex(1);
          },
        }}
        actionBlock={
          <ActionBlockComponent
            toggleFilter={toggleFilter}
            setAccountStatus={setAccountStatus}
            setCreditStatus={setCreditStatus}
            setRegistrationStep={setRegistrationStep}
            setCreatedAt={setCreatedAt}
            setWithOverdueAmount={setWithOverdueAmount}
            setAvailableCredit={setAvailableCredit}
            filters={filters}
            setFilters={setFilters}
          />
        }
      />

      {totalCustomerCount == 0 ? (
        <EmptyState
          image={NoDataImage}
          title="No Customers"
          subtitle="There are no customers to show, try changing filters."
        />
      ) : (
        <CustomerTable
          customers={customers}
          loading={status == CustomerStatus.LOADING}
        />
      )}

      <FilterPane
        isFilterOpen={isFilterOpen}
        toggleFilter={toggleFilter}
        setAccountStatus={setAccountStatus}
        accountStatus={accountStatus}
        creditStatus={creditStatus}
        setCreditStatus={setCreditStatus}
        registrationStep={registrationStep}
        setRegistrationStep={setRegistrationStep}
        createdAt={createdAt}
        setCreatedAt={setCreatedAt}
        withOverdueAmount={withOverdueAmount}
        setWithOverdueAmount={setWithOverdueAmount}
        availableCredit={availableCredit}
        setAvailableCredit={setAvailableCredit}
        filters={filters}
        setFilters={setFilters}
        setPageIndex={setPageIndex}
      />
    </>
  );
};

export default CustomersListing;

import React from "react";
import { Radio } from "neetoui";
import { Field } from "formik";

const NixComponent = () => (
  <div className="pt-2 ">
    <Field>
      {({ field, form: { values, setFieldValue } }) => (
        <Radio label="Nix out overdue balance as a whole?" stacked>
          <Radio.Item
            label="Yes"
            checked={values.nix}
            {...field}
            onChange={date => {
              date && setFieldValue("nix", true);
            }}
          />
          <Radio.Item
            label="No"
            checked={!values.nix}
            {...field}
            onChange={date => {
              date && setFieldValue("nix", false);
            }}
          />
        </Radio>
      )}
    </Field>
  </div>
);

export default NixComponent;

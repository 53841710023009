import React from "react";

import { Button } from "@bigbinary/neetoui";
import ContactInfo from "./ContactInfo";
import PaymentInfo from "./PaymentInfo";
import ReportInfo from "./ReportInfo";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useParams } from "react-router-dom";

const CreditReport = () => {
  const { id } = useParams();

  useDocumentTitle(`Credit reporting for customer ${id}`);

  return (
    <div className="w-full">
      <div className="flex mt-5 ml-20">
        <ReportInfo />
        <ContactInfo />
      </div>
      <PaymentInfo />
      <div className="flex justify-end m-10 space-x-3">
        <Button style="secondary" label="Discard" />
        <Button label="Save Changes" />
        <Button label="Push to Bureau" />
      </div>
    </div>
  );
};

export default CreditReport;

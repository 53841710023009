import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Label, Checkbox } from "neetoui";
import { Input as FormikInput } from "neetoui/formik";

import { formatCurrency } from "utils/styleUtils";
import { useCustomerState } from "contexts/customer";
import { add } from "utils/mathOperation";
import creditApplicationsApi from "apis/credit-applications";
import { useParams } from "react-router-dom";

const AvailableCredit = ({
  modificationPane,
  setModificationPane,
  formikRef,
  fetchCustomer,
}) => {
  const { id } = useParams();
  const { customer } = useCustomerState();
  const initialValues = { amount: 0, credit: false };
  const [topUpAmount, setTopUpAmount] = useState(initialValues.amount);
  const [blockCredit, setBlockCredit] = useState(initialValues.credit);

  const onTopUpAmountChange = value => {
    setTopUpAmount(value);
  };

  const onCreditCheckChange = () => {
    setTopUpAmount(0);
    setBlockCredit(state => !state);
  };

  const newCreditValue = () => {
    if (blockCredit) return 0;
    else {
      return add(
        customer.available_credit === 0
          ? customer.restorable_credit
          : customer.available_credit,
        topUpAmount
      );
    }
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        top_up_amount: topUpAmount,
        block_credit: blockCredit,
      };

      await creditApplicationsApi.updateAvailableCredit(customer.id, payload);
      await fetchCustomer(id);
      setModificationPane(false);
    } catch (error) {
      logger.error(error);
    }
  };

  useEffect(() => {
    setBlockCredit(!customer.preferences.credit_apply_enabled);
  }, [modificationPane]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => handleSubmit()}
      innerRef={formikRef}
    >
      {() => (
        <>
          <FormikInput
            disabled={blockCredit}
            value={topUpAmount}
            onChange={e => onTopUpAmountChange(e.target.value)}
            prefix="$"
            label="Enter top up amount"
            name="amount"
            className="py-2"
          />
          <div className="py-6 space-y-2 border-t border-b border-dashed">
            <div className="flex justify-between">
              <Label>Internal Credit Status</Label>
              <Label>
                {customer.credit_apply_enabled ? "Not blocked" : "Blocked"}
              </Label>
            </div>
            <div className="flex justify-between">
              <Label>Credit Expiry</Label>
              <Label>{customer.credit_valid_till}</Label>
            </div>
            <div className="flex justify-between">
              <Label>Current available credit:</Label>
              <Label>{formatCurrency(customer.available_credit)}</Label>
            </div>
            <div className="flex justify-between">
              <Label>New available credit:</Label>
              <Label>{formatCurrency(newCreditValue())}</Label>
            </div>
          </div>
          <Checkbox
            name="credit"
            label="Set $0 available credit & block customer from purchasing"
            checked={blockCredit}
            onChange={() => onCreditCheckChange()}
          />
        </>
      )}
    </Formik>
  );
};

export default AvailableCredit;

import React, { useState } from "react";
import { Button, Input } from "neetoui";
import customersPhoneVerificationApi from "apis/customers/phone-verification";
import { useRef } from "react";

const SMSModal = ({
  onModalClose,
  phoneNumber,
  resentOtp,
  initialPhoneNumber,
  setInitialPhoneNumber,
}) => {
  const [otp, setOtp] = useState(["", "", "", ""]);

  const otpNumber1 = useRef();
  const otpNumber2 = useRef();
  const otpNumber3 = useRef();
  const otpNumber4 = useRef();
  const sumbitButton = useRef();

  const onOTPValueChange = (index, value) => {
    setOtp(state => {
      let updatedState = [...state];
      updatedState[index] = value;
      return updatedState;
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const payload = {
        phone_number: phoneNumber,
        otp: otp.join(""),
      };

      await customersPhoneVerificationApi.verifyOtp(payload);

      if (setInitialPhoneNumber) setInitialPhoneNumber(phoneNumber);

      onModalClose();
    } catch (error) {
      setOtp(["", "", "", ""]);
      if (setInitialPhoneNumber) setInitialPhoneNumber(initialPhoneNumber);

      logger.error(error);
    }
  };

  const numberOnChange = (otpCount, e, nextElement) => {
    if (e.target.value.length === 1) {
      nextElement.current.focus();
    }
    onOTPValueChange(otpCount, e.target.value);
  };

  return (
    <div className="absolute bottom-0 z-40 w-full max-w-lg bg-white border border-gray-200 shadow-lg rounded-t-2xl ">
      <div className="p-2">
        <div className="flex items-center justify-between">
          <div className="font-semibold">Phone Number Verification</div>
          <Button
            className="text-2xl"
            style="text"
            icon="ri-close-line"
            onClick={() => onModalClose()}
          />
        </div>
        <div className="flex flex-col items-center justify-center my-6 space-y-6">
          <p> Enter code sent to +1 {phoneNumber}.</p>
          <div className="flex justify-between px-6 space-x-2">
            <Input
              maxlength={1}
              autoFocus
              nakedInput
              className="border-b border-gray-200"
              value={otp[0]}
              required
              type="tel"
              pattern="[\d]*"
              inputMode="numeric"
              onChange={e => {
                numberOnChange(0, e, otpNumber2);
              }}
              ref={otpNumber1}
            />
            <Input
              maxlength={1}
              nakedInput
              className="border-b border-gray-200"
              value={otp[1]}
              required
              type="tel"
              pattern="[\d]*"
              inputMode="numeric"
              onChange={e => {
                numberOnChange(1, e, otpNumber3);
              }}
              ref={otpNumber2}
            />
            <Input
              maxlength={1}
              nakedInput
              className="border-b border-gray-200"
              value={otp[2]}
              required
              type="tel"
              pattern="[\d]*"
              inputMode="numeric"
              onChange={e => {
                numberOnChange(2, e, otpNumber4);
              }}
              ref={otpNumber3}
            />
            <Input
              maxlength={1}
              nakedInput
              className="border-b border-gray-200"
              value={otp[3]}
              required
              type="tel"
              pattern="[\d]*"
              inputMode="numeric"
              onChange={e => {
                numberOnChange(3, e, sumbitButton);
              }}
              ref={otpNumber4}
            />
          </div>
          <Button
            label="Verify & Proceed"
            onClick={handleSubmit}
            ref={sumbitButton}
          />
          <div>
            Didn’t receive the OTP?
            <span
              className="mx-1 underline text-purple-1000"
              onClick={() => resentOtp(phoneNumber)}
            >
              Resend OTP.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSModal;

import Image1 from "common/images/furniture/image-1.jpeg";
import Image2 from "common/images/furniture/image-2.jpeg";
import Image3 from "common/images/furniture/image-3.jpeg";
import Image4 from "common/images/furniture/image-4.jpeg";
export const DEFAULT_PAGE_SIZE = 20;
export const TOTAL_TICKET_COUNT = 20;
export const SORT_BY_OPTIONS = [{ label: "Name" }, { label: "Date" }];

export const LEASE_DATA = [
  {
    id: "BH00005",
    overdue: "__$10_",
    outstanding: "__$443.3_",
    status: "__Active_",
    itemList: [
      {
        name: "__Ana Sofia Wall Clock_",
        id: "__M12300_",
        image: [Image1],
        vendor: "__Ashley’s Furniture_",
        multiple: "__3.25_",
        cost: "__11.88_",
        va_tax: "__0.71_",
        total: "__12.89_",
        qty: "__1",
        status: "__In Transit_",
        deliveryStatus: "__05/13/2021_",
      },
      {
        name: "__Ana Sofia Wall Clock_",
        image: [Image2],
        id: "__M12300_",
        vendor: "__Ashley’s Furniture_",
        multiple: "__3.25_",
        qty: "__1_",
        cost: "__11.88_",
        va_tax: "__0.71_",
        total: "__12.89_",
        status: "__Delivered_",
        deliveryStatus: "__05/13/2021_",
      },
    ],
    payment: "__$33/week_",
    last_payment: "__$33/week_",
    start_date: "__03/14/2020_",
  },
  {
    id: "__LID23992_",
    overdue: "__$10_",
    outstanding: "__$443.3_",
    status: "__Active_",
    itemList: [
      {
        name: "__Ana Sofia Wall Clock_",
        id: "__M12300",
        image: [Image3],
        vendor: "__Ashley’s Furniture_",
      },
      {
        name: "__Ana Sofia Wall Clock_",
        id: "__M12300_",
        image: [Image4],
        vendor: "__Ashley’s Furniture_",
      },
    ],
    payment: "__$33/week_",
    last_payment: "__$33/week_",
    start_date: "__03/14/2020_",
  },
];

export const LEASE_ITEM_LIST_DATA = [
  {
    name: "__Ana Sofia Wall Clock_",
    id: "__M12300_",
    image: [Image1],
    vendor: "__Ashley’s Furniture_",
    multiple: "__3.25_",
    cost: "__11.88_",
    va_tax: "__0.71_",
    total: "__12.89_",
    qty: "__1",
    status: "__In Transit_",
    deliveryStatus: "__05/13/2021_",
  },
  {
    name: "__Ana Sofia Wall Clock_",
    image: [Image2],
    id: "__M12300_",
    vendor: "__Ashley’s Furniture_",
    multiple: "__3.25_",
    qty: "__1_",
    cost: "__11.88_",
    va_tax: "__0.71_",
    total: "__12.89_",
    status: "__Delivered_",
    deliveryStatus: "__05/13/2021_",
  },
];

import classNames from "classnames";
import React from "react";
import { isMobile } from "react-device-detect";
import BeccasHomeLogo from "common/images/beccas-home-logo.png";

const HeaderIcon = () => {
  return (
    <div
      className={classNames(
        ["flex  pb-4"],
        { "justify-between": isMobile },
        { "justify-center": !isMobile }
      )}
    >
      <img src={BeccasHomeLogo} width="100px" height="100px" />
    </div>
  );
};

export default HeaderIcon;

import React, { useState } from "react";
import classNames from "classnames";
import { isMobile } from "react-device-detect";

import HeaderIcon from "../Header";
import CardVector from "./CardVector";
import NewCardForm from "./NewCardForm";

const NewCardPayment = () => {
  const [paymentDetails, setPaymentDetails] = useState({
    cardNumber: "9123-1231-9823-1002",
    cardName: "Jane Done",
    expiryDate: "03/23",
  });

  return (
    <div className="p-4">
      <HeaderIcon />
      <div className={classNames({ "px-6": !isMobile })}>
        <CardVector paymentDetails={paymentDetails} />
      </div>
      <div className="px-4 py-8 space-y-4">
        <p className="text-lg font-semibold">Enter Debit Card Details</p>
        <NewCardForm
          paymentDetails={paymentDetails}
          setPaymentDetails={setPaymentDetails}
        />
      </div>
    </div>
  );
};

export default NewCardPayment;

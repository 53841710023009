import React from "react";
import classnames from "classnames";

import { Item } from "./Item";
import { Body } from "./Body";
import { Header } from "./Header";

const Card = ({ className = "", bottomText = null, children }) => {
  return (
    <div
      className={classnames("bg-white shadow-md rounded-md max-w-lg w-full", [
        className,
      ])}
    >
      {children}
      {bottomText && (
        <div className="flex flex-row justify-end px-2 pb-4">
          <p className="text-sm text-gray-600 font-medium">{bottomText}</p>
        </div>
      )}
    </div>
  );
};

export default Card;

Card.Header = Header;
Card.Body = Body;
Card.Item = Item;

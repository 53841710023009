import * as yup from "yup";

import {
  LEASE_UNAVAILABLE_IN_STATES_MESSAGE,
  NON_LEASE_STATES,
} from "common/constants";

export default {
  address: yup.object().shape({
    street_number: yup.string().required("Please enter your address."),
    area: yup.string(),
    city: yup.string().required("Please enter a city."),
    state: yup
      .object()
      .nullable()
      .shape({
        label: yup.string().required("Please select a state."),
        value: yup.string().required("Please select a state."),
      })
      .required("Please select a state.")
      .test(
        "is-one-of",
        LEASE_UNAVAILABLE_IN_STATES_MESSAGE,
        state => !NON_LEASE_STATES.includes(state?.value)
      ),
    zip_code: yup
      .string()
      .test("len", "Please enter valid US zip code.", val => val?.length === 5)
      .required("Please enter zip code."),
  }),
};

import React, { useState } from "react";
import { Badge, Button, ActionDropdown, Tooltip } from "@bigbinary/neetoui";
import { Header } from "@bigbinary/neetoui/layouts";
import classnames from "classnames";

import { ACTION_VALUES } from "./constants";

const titleNameFunction = () => {
  return "Arcola Loveseat";
};

const breadcrumbsFunction = () => {
  return [
    { text: "Product Import", link: "/lease" },
    { text: "Modway’s Furniture", link: "/customers" },
  ];
};

const ProductDataHeader = () => {
  const [currentActionState, setCurrentActionState] = useState(
    ACTION_VALUES[0]
  );

  return (
    <Header
      title={titleNameFunction()}
      breadcrumbs={breadcrumbsFunction()}
      actionBlock={
        <div className="flex items-center space-x-3">
          <Badge color="yellow"> Formatting</Badge>
          <Tooltip content="Preview">
            <Button style="secondary" icon="ri-eye-line" />
          </Tooltip>
          <ActionDropdown buttonProps={{ label: currentActionState }}>
            {ACTION_VALUES.map((item, index) => {
              return (
                <li
                  className={classnames({
                    "text-white bg-red-500": item == ACTION_VALUES[3],
                  })}
                  onClick={() => setCurrentActionState(item)}
                  key={index}
                >
                  {item}
                </li>
              );
            })}
          </ActionDropdown>
        </div>
      }
    />
  );
};

export default ProductDataHeader;

export const AGENTS_LIST_DATA = [
  {
    name: "Charlie Smith",
    role: "Agent",
    email: "charlie@beccashome.com",
    last_login: "2 Hours Ago",
    status: false,
  },
  {
    name: "Maria Gracia",
    role: "Administrator",
    email: "maria@beccashome.com",
    last_login: "Unregistered",
    status: true,
  },
];

export const AGENT_ROLES = [
  { label: "Administrator", value: "admin" },
  { label: "Agent", value: "agent" },
];

export const DEFAULT_PAGE_SIZE = 20;
export const COLUMN_OPTIONS = [{ label: "Name" }, { label: "Client" }];
export const FILTER_BY_OPTIONS = [{ label: "Name" }, { label: "Client" }];

export const AgentsStatus = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
});

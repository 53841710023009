import React from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Button, Modal, Label } from "neetoui";
import { formatDateWithoutTimeZone } from "common/dateTimeFormatter";

const AutoPayModal = ({
  autoPaymentModalState,
  setAutoPaymentModalState,
  nextPaymentDate = "23 March 2021",
  payFrequency = "Friday/Weekly",
  paymentMethod = "Debit Card 6787",
  paymentAmount = "$100",
}) => {
  const history = useHistory();

  const handleAutopayConfirmation = () => {
    history.push("/payment/:customerId/payearly");
  };

  return (
    <Modal
      size={isMobile ? "large" : "small"}
      className="px-4 py-1 mx-3 rounded-md"
      isOpen={autoPaymentModalState}
      onClose={() => setAutoPaymentModalState(false)}
      autoHeight
    >
      <div>
        <div className="py-3 text-lg font-semibold border-b border-gray-200">
          Autopay Details Confirmation
        </div>
        <div className="flex justify-between py-3 border-b border-gray-200">
          <Label>Next Payment Date</Label>
          <p className="text-gray-800">
            {formatDateWithoutTimeZone(nextPaymentDate)}
          </p>
        </div>
        <div className="flex justify-between py-3 border-b border-gray-200">
          <Label>Pays Every</Label>
          <p className="text-gray-800">{payFrequency}</p>
        </div>
        <div className="flex justify-between py-3 border-b border-gray-200">
          <Label>Payment Method</Label>
          <p className="text-gray-800">{paymentMethod}</p>
        </div>
        <div className="flex justify-between py-3 border-b border-gray-200">
          <Label>Payment Amount</Label>
          <p className="text-gray-800">{paymentAmount}</p>
        </div>
        <div className="flex justify-end py-3 space-x-4">
          <Button
            label="Cancel"
            style="secondary"
            onClick={() => setAutoPaymentModalState(false)}
          />
          <Button
            label="Proceed"
            onClick={() => {
              handleAutopayConfirmation();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AutoPayModal;

export const PAYMENT_HISTORY_DATA = [
  {
    name: "__EARLY PAYOFF INSTALLMENT_",
    time: "__03/23/21 at 1:30 PM_",
    cardDetails: "__Debit Card Ending 8894_",
    status: false,
    amount: "__30 USD_",
  },
  {
    name: "__EARLY PAYOFF INSTALLMENT_",
    time: "__03/23/21 at 1:30 PM_",
    cardDetails: "__Debit Card Ending 8894_",
    status: true,
    amount: "__30 USD",
  },
];

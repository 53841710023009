import React from "react";
import CardIcon from "common/images/card_logo.svg";
import { formatCurrency } from "utils/styleUtils";

const Header = ({
  slug,
  defaultCard,
  paymentAmount,
  taxOnPaymentAmount,
  upcomingInvoice,
}) => {
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="font-semibold text-gray-500">{slug}</div>
        <div className="flex items-center space-x-3">
          <div className="p-1 border border-gray-200 rounded-md">
            <img src={CardIcon} width="28px" height="28px" />
          </div>
          <div className="text-gray-500">**** {defaultCard}</div>
        </div>
      </div>
      <div className="flex items-center justify-between mt-2">
        <div className="text-lg font-bold text-purple-1000">
          {formatCurrency(
            parseFloat(paymentAmount) + parseFloat(taxOnPaymentAmount)
          )}
        </div>
        <div className="text-gray-500"> {upcomingInvoice?.due_date}</div>
      </div>
    </>
  );
};

export default Header;

import * as yup from "yup";

const NAME_REGEX = /^[a-zA-Z\s]+$/;
const ADDRESS_REGEX = /^[a-zA-Z0-9#\-'/.\s]+$/;
import {
  LEASE_UNAVAILABLE_IN_STATES_MESSAGE,
  NON_LEASE_STATES,
} from "common/constants";

export default {
  phoneInfo: yup.object().shape({
    phone_number: yup
      .string()
      .required("Please enter a valid phone number.")
      .min(10, "Phone number is too short.")
      .max(10, "Phone number is too long.")
      .matches(/^[2-9]\d{9}$/, {
        message: "Please enter a valid phone number.",
        excludeEmptyString: false,
      }),
  }),
  personalInfo: yup.object().shape({
    first_name: yup
      .string()
      .required("Please enter first name.")
      .matches(NAME_REGEX, {
        message: "Please enter a valid first name.",
        excludeEmptyString: false,
      }),
    last_name: yup
      .string()
      .required("Please enter last name.")
      .matches(NAME_REGEX, {
        message: "Please enter a valid last name.",
        excludeEmptyString: false,
      }),
    email: yup
      .string()
      .email("Incorrect Email")
      .required("Please enter your email."),
    confirm_email: yup
      .string()
      .oneOf([yup.ref("email")], "Email must match.")
      .required("Please confirm your email."),
    date_of_birth: yup
      .date()
      .nullable()
      .test(
        "dob",
        "Sorry, only age between 18 and 90 is allowed.",
        function (value) {
          if (!value) return true;

          const age = new Number(
            (new Date().getTime() - value.getTime()) / 31536000000
          )?.toFixed(0);

          return age >= 18 && age <= 90;
        }
      )
      .required("Please enter date of birth."),
  }),
  workInfo: yup.object().shape({
    current_employment_status: yup
      .string()
      .required("Employment status is required."),
    pay_cycle: yup.string().required("Please select your pay frequency."),
    monthly_income: yup
      .object()
      .nullable()
      .shape({
        label: yup.string().required("Please select your monthly income."),
        value: yup.string().required("Please select your monthly income."),
      })
      .required("Please select your monthly income."),
    pay_day: yup.string().when("pay_cycle", {
      is: value => value !== "twice_a_month",
      then: yup.string().required("Please select your last pay day."),
      otherwise: yup.string().nullable(),
    }),
    mode_of_salary: yup
      .object()
      .nullable()
      .shape({
        label: yup.string().required("Please select your salary mode."),
        value: yup.string().required("Please select your salary mode."),
      })
      .required("Please select your salary mode."),
    pay_day1: yup
      .object()
      .nullable()
      .when("pay_cycle", {
        is: "twice_a_month",
        then: yup.object().required("Please select your first pay day."),
        otherwise: yup.object(),
      }),
    pay_day2: yup
      .object()
      .nullable()
      .when("pay_cycle", {
        is: "twice_a_month",
        then: yup.object().required("Please select your second pay day."),
        otherwise: yup.object(),
      }),
  }),
  addressInfo: yup.object().shape({
    street_number: yup
      .string()
      .required("Please enter address.")
      .matches(ADDRESS_REGEX, {
        message: "Please enter a valid address.",
        excludeEmptyString: false,
      }),
    area: yup.string().matches(ADDRESS_REGEX, {
      message: "Please enter a valid address.",
      excludeEmptyString: true,
    }),
    city: yup.string().required("City is required").matches(ADDRESS_REGEX, {
      message: "Please enter a valid city name.",
      excludeEmptyString: false,
    }),
    state: yup
      .object()
      .nullable()
      .shape({
        label: yup.string().required("State is required"),
        value: yup.string().required("State is required"),
      })
      .required("State is required")
      .test(
        "is-one-of",
        LEASE_UNAVAILABLE_IN_STATES_MESSAGE,
        state => !NON_LEASE_STATES.includes(state?.value)
      ),
    zip_code: yup
      .string()
      .test("len", "Please enter valid US zip code.", val => val?.length === 5)
      .required("Zip code is required."),
    ssn: yup
      .string()
      .nullable()
      .required("SSN is required.")
      .matches("^\\d{3}-?\\d{2}-?\\d{4}$", "Please enter a valid SSN."),
  }),
};

import React from "react";

const LOADING_HEADING =
  "We are securely processing your application. This will only take a few moments.";
const LOADING_SUBHEADING =
  "DO NOT click back button or refresh. Sit back and relax while we securely review your information.";

export default function Spinner({
  loadingHeading = LOADING_HEADING,
  loadingSubheading = LOADING_SUBHEADING,
}) {
  return (
    <div>
      <div className="spinner m-auto"></div>
      <p className="w-full text-center text-gray-600 text-lg mt-5 px-3">
        {loadingHeading}
      </p>
      <p className="w-full text-center text-gray-800 text-md mt-3 px-3">
        {loadingSubheading}
      </p>
    </div>
  );
}

import React, { useEffect, useState, forwardRef } from "react";
import { Select } from "neetoui/formik";
import { Form, Formik } from "formik";
import { Radio, DateInput } from "neetoui";
import * as dayjs from "dayjs";

import {
  EMPLOYMENT_OPTIONS,
  PAYMENT_MODE_OPTIONS,
  PAYMENT_SCHEDULE,
} from "components/SignUp/constant";
import {
  FIRST_PAY_AVAILABLE_DAYS,
  MONTHLY_INCOME,
  SECOND_PAY_AVAILABLE_DAYS,
} from "common/constants";
import { toSentence, toSnakeCase } from "utils/styleUtils";
import formValidationSchemas from "components/SignUp/registerFormValidationSchemas";
import { convertArrayToHash } from "utils/objectFormatter";
import employmentDetailsApi from "apis/customers/employment-details";
import { DEFAULT_EMPLOYMENT_FORM_VALUES } from "./constants";

const EmploymentForm = forwardRef(
  ({ employmentDetail, showExisting, setIsLoading }, ref) => {
    const [showPayDays, setShowPayDays] = useState(false);
    const { pay_days, pay_cycle, monthly_income, mode_of_salary } =
      employmentDetail || {};

    let initialValues = DEFAULT_EMPLOYMENT_FORM_VALUES;

    if (showExisting) {
      const payDay1 = pay_days?.length
        ? convertArrayToHash(pay_days.splice(0, 1))
        : convertArrayToHash(FIRST_PAY_AVAILABLE_DAYS.splice(0, 1));
      const payDay2 = pay_days?.length
        ? convertArrayToHash(pay_days.splice(1, 2))
        : convertArrayToHash(SECOND_PAY_AVAILABLE_DAYS.splice(0, 1));

      initialValues = {
        ...employmentDetail,
        monthly_income: {
          value: monthly_income,
          label: toSentence(monthly_income),
        },
        mode_of_salary: {
          value: mode_of_salary,
          label: toSentence(mode_of_salary),
        },
        pay_cycle: toSnakeCase(pay_cycle),
        pay_day1: payDay1[0],
        pay_day2: payDay2[0],
      };
    } else {
      initialValues.mode_of_salary = {
        value: mode_of_salary,
        label: toSentence(mode_of_salary),
      };
    }

    const handleSubmit = async details => {
      try {
        setIsLoading(true);
        let payload = {
          employment_detail: {
            ...details,
            monthly_income: details.monthly_income.value,
            mode_of_salary: details.mode_of_salary.value,
          },
        };
        if (details.pay_cycle == "twice_a_month") {
          payload.employment_detail.pay_days =
            details.pay_day1 && details.pay_day2
              ? [details.pay_day1.value, details.pay_day2.value]
              : [];
        }

        await employmentDetailsApi.update(employmentDetail.id, payload);
      } catch (error) {
        logger.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    const onPayCycleChange = (e, setFieldValue) => {
      setFieldValue("pay_cycle", e.target.value);
      setShowPayDays(e.target.value === "twice_a_month");
    };

    const onPayDayChange = (obj, setFieldValue, pay_day1, setErrors) => {
      if (obj.value <= pay_day1.value) {
        setErrors({
          pay_day2: "Second date should be greater than first date",
        });
      } else {
        setFieldValue("pay_day2", obj);
      }
    };

    useEffect(() => {
      setShowPayDays(employmentDetail?.pay_cycle === "twice_a_month");
    }, []);

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={values => handleSubmit(values)}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={formValidationSchemas.workInfo}
        innerRef={ref}
      >
        {({ values, errors, setFieldValue, setErrors }) => (
          <Form>
            <div className="space-y-4 mt-8">
              <div className="flex justify-between pb-1 border-b border-gray-300">
                <p className="text-base">Employment Information</p>
              </div>
              <div className="py-2 space-y-3">
                <Radio
                  stacked
                  label={
                    <span className="font-semibold">Employment Status</span>
                  }
                  name="current_employment_status"
                  error={errors.current_employment_status}
                >
                  {EMPLOYMENT_OPTIONS.map((item, index) => {
                    return (
                      <Radio.Item
                        key={index}
                        label={item}
                        value={toSnakeCase(item)}
                        onChange={evt => {
                          setFieldValue(
                            "current_employment_status",
                            evt.target.value
                          );
                        }}
                        checked={
                          values.current_employment_status == toSnakeCase(item)
                        }
                        className="border-gray-200 border-b-1 border-t-1"
                      />
                    );
                  })}
                </Radio>
                {errors.current_employment_status && (
                  <p className="nui-input__error">
                    {errors.current_employment_status}
                  </p>
                )}
                <Radio
                  stacked
                  label={
                    <span className="font-semibold">
                      How often are you paid?
                    </span>
                  }
                  name="pay_cycle"
                  error={errors.pay_cycle}
                >
                  {PAYMENT_SCHEDULE.map((item, index) => {
                    return (
                      <Radio.Item
                        key={index}
                        label={item}
                        value={toSnakeCase(item)}
                        error={errors.pay_cycle}
                        onChange={e => {
                          onPayCycleChange(e, setFieldValue);
                        }}
                        checked={values.pay_cycle == toSnakeCase(item)}
                      />
                    );
                  })}
                </Radio>
                {errors.pay_cycle && (
                  <p className="nui-input__error">{errors.pay_cycle}</p>
                )}
                <div className="my-4 space-y-1">
                  <Select
                    label={
                      <span className="font-semibold">
                        What’s your monthly income?
                      </span>
                    }
                    placeholder="Select an option"
                    value={values.monthly_income}
                    isDisabled={false}
                    name="monthly_income"
                    options={MONTHLY_INCOME}
                    error={errors.monthly_income?.label}
                  />
                </div>
                <div className="my-4 space-y-1">
                  <p className="text-sm font-semibold">Last Pay Day</p>
                  {!showPayDays && (
                    <DateInput
                      placeholder="MM/DD/YYYY"
                      value={values.pay_day ? new Date(values.pay_day) : null}
                      name="pay_day"
                      onChange={date => {
                        setFieldValue("pay_day", date.toDateString());
                      }}
                      maxDate={dayjs().toDate()}
                      error={errors.pay_day}
                    />
                  )}
                  {showPayDays && (
                    <div className="space-y-2">
                      <Select
                        label="First pay day"
                        placeholder="Select first pay day"
                        value={values.pay_day1}
                        isDisabled={false}
                        name="pay_day1"
                        options={convertArrayToHash(FIRST_PAY_AVAILABLE_DAYS)}
                        error={errors.pay_day1}
                      />
                      <Select
                        label="Second pay day"
                        placeholder="Select second pay day"
                        value={values.pay_day2}
                        isDisabled={false}
                        name="pay_day2"
                        options={convertArrayToHash(SECOND_PAY_AVAILABLE_DAYS)}
                        onChange={obj =>
                          onPayDayChange(
                            obj,
                            setFieldValue,
                            values.pay_day1,
                            setErrors
                          )
                        }
                        error={errors.pay_day2}
                      />
                    </div>
                  )}
                </div>
                <Select
                  label={<span className="font-semibold">Payment Method</span>}
                  placeholder="Select an option"
                  value={values.mode_of_salary}
                  isDisabled={false}
                  name="mode_of_salary"
                  options={PAYMENT_MODE_OPTIONS}
                  error={errors.mode_of_salary}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
);

export default EmploymentForm;

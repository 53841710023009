export const PAYMENT_MODAL_DATA = [
  {
    count: "01",
    stepTitle: "Make Payment",
    stepDescription: "Choose payment method",
  },
  {
    count: "02",
    stepTitle: "Complete Payment",
    stepDescription: "Complete payment",
  },
];

export const CARD_LIST = [
  {
    id: "12",
    name: "__Charlie Smith_",
    number: "__8732_",
    expiryDate: "__03/23_",
  },
  {
    id: "12",
    name: "__Charlie Smith_",
    number: "__8732_",
    expiryDate: "__03/23_",
  },
];

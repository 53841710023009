import React from "react";
import { Dropdown } from "neetoui";
import { useHistory } from "react-router";

const LeaseSubHeader = ({ paymentInfo, setPaymentInfo }) => {
  const history = useHistory();
  const PaymentTowards = [
    { label: "Lease Payment", value: "invoice" },
    { label: "Early Payoff", value: "early_payoff" },
    { label: "Overdue Amount", value: "overdue" },
  ];

  const onLeaseItemChange = lease => {
    setPaymentInfo({ ...paymentInfo, lease: lease });
    history.push(`/myaccount/payment/${lease.slug}`);
  };

  return (
    <div className="flex justify-between p-4 border-b border-gray-200">
      <div className="flex flex-col items-start justify-center space-y-2">
        <p className="text-xs uppercase">Lease ID</p>
        <Dropdown
          label={paymentInfo?.lease?.slug}
          buttonStyle="text"
          buttonProps={{ className: "text-sm" }}
          position="bottom-right"
          closeOnSelect
        >
          {paymentInfo?.leaseList?.map(lease => (
            <li onClick={() => onLeaseItemChange(lease)} key={lease.id}>
              {lease.slug}
            </li>
          ))}
        </Dropdown>
      </div>
      <div className="flex flex-col items-start justify-center space-y-2">
        <p className="text-xs uppercase">Next payment</p>
        <p className="text-sm uppercase">
          {paymentInfo?.lease?.upcoming_invoice?.due_date}
        </p>
      </div>
      <div className="flex flex-col items-start justify-center space-y-2">
        <p className="text-xs uppercase">payment towards</p>
        <Dropdown
          label={PaymentTowards.find(t => t.value === paymentInfo?.type)?.label}
          buttonStyle="text"
          buttonProps={{ className: "text-sm" }}
          position="bottom-right"
          closeOnSelect
        >
          {PaymentTowards.map(({ label, value }) => (
            <li
              onClick={() => setPaymentInfo({ ...paymentInfo, type: value })}
              key={value}
            >
              {label}
            </li>
          ))}
        </Dropdown>
      </div>
    </div>
  );
};

export default LeaseSubHeader;

import React from "react";
import PropTypes from "prop-types";

import customerReducer from "reducers/customer";

const CustomerStateContext = React.createContext();
const CustomerDispatchContext = React.createContext();
const initialState = { customer: null, isCardAdded: false };

const CustomerProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(customerReducer, initialState);

  return (
    <CustomerStateContext.Provider value={state}>
      <CustomerDispatchContext.Provider value={dispatch}>
        {children}
      </CustomerDispatchContext.Provider>
    </CustomerStateContext.Provider>
  );
};

const useCustomerState = () => {
  const context = React.useContext(CustomerStateContext);
  if (context === undefined) {
    throw new Error("useCustomerState must be used within a CustomerProvider");
  }
  return context;
};

const useCustomerDispatch = () => {
  const context = React.useContext(CustomerDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useCustomerDispatch must be used within a CustomerProvider"
    );
  }
  return context;
};

const useCustomer = () => {
  return [useCustomerState(), useCustomerDispatch()];
};

CustomerProvider.propTypes = {
  children: PropTypes.node,
};

export { CustomerProvider, useCustomerState, useCustomerDispatch, useCustomer };

import React from "react";
import { Pane, Select, Button, DateInput, Switch } from "@bigbinary/neetoui";

import {
  ORDER_EXPORT_STATUS,
  CANCELLATION_EXPORT_STATUS,
  CANCELLATION_EXPORT_UPDATE_STATUS,
  RETURN_EXPORT_UPDATE_STATUS,
  RETURN_EXPORT_STATUS,
} from "../constants";

const ExportPane = ({ exportPane, setExportPane, pageTitle }) => {
  const title = () => {
    if (pageTitle == "orders") return "Export Orders Details";
    else if (pageTitle == "cancellations")
      return "Export Cancellations Details";
    else if (pageTitle == "returns") return "Export Returns Details";
  };

  let updatedStatusOptions = [];
  let StatusOptions = [];

  if (pageTitle == "orders") {
    StatusOptions = ORDER_EXPORT_STATUS.map(item => {
      return {
        label: item,
        value: item,
      };
    });
  } else if (pageTitle == "cancellations") {
    updatedStatusOptions = CANCELLATION_EXPORT_UPDATE_STATUS.map(item => {
      return {
        label: item,
        value: item,
      };
    });
    StatusOptions = CANCELLATION_EXPORT_STATUS.map(item => {
      return {
        label: item,
        value: item,
      };
    });
  } else if (pageTitle == "returns") {
    updatedStatusOptions = RETURN_EXPORT_UPDATE_STATUS.map(item => {
      return {
        label: item,
        value: item,
      };
    });
    StatusOptions = RETURN_EXPORT_STATUS.map(item => {
      return {
        label: item,
        value: item,
      };
    });
  }

  return (
    <Pane
      title={title()}
      isOpen={exportPane}
      onClose={() => setExportPane(false)}
      cancelButtonProps={{
        label: "Cancel",
        onClick: () => setExportPane(false),
      }}
      submitButtonProps={{
        label: "Export",
        onClick: () => setExportPane(false),
      }}
      showFooter
    >
      <div className="pb-20 mx-6 space-y-4 overflow-auto">
        <Select
          label="Vendor"
          options={[{ label: "Malouf Furniture", value: "Malouf Furniture" }]}
          value={{ label: "Malouf Furniture", value: "Malouf Furniture" }}
        />
        <Select
          label="Status"
          options={StatusOptions}
          value={StatusOptions[0]}
        />
        <div className="flex space-x-4">
          <DateInput className="w-full" label="Start Date" value={new Date()} />
          <DateInput className="w-full" label="End Date" value={new Date()} />
        </div>
        <div className="flex justify-center">
          <Button className="mt-6" style="link" label="24 results found" />
        </div>
        <div className="border-t border-gray-200 border-dashed">
          {pageTitle == "orders" ? (
            <div className="flex justify-between mt-6">
              <div className="flex space-x-1">
                <p>Update order status to</p>
                <Button label="Awaiting Fullfillment" style="link" />
              </div>
              <Switch />
            </div>
          ) : (
            <div className="mt-6">
              <Select
                label="Updated Status After Export"
                options={updatedStatusOptions}
                value={updatedStatusOptions[0]}
              />
            </div>
          )}
        </div>
      </div>
    </Pane>
  );
};

export default ExportPane;

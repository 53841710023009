import React from "react";
import { Button } from "neetoui";
import { useHistory } from "react-router-dom";
import { useUserState } from "contexts/user";
import { displayPayCycle } from "components/MyAccount/common/helper";
import { formatCurrency } from "utils/styleUtils";
import { formatDateWithoutTimeZone } from "common/dateTimeFormatter";
import { isPayableLease } from "common/utils";

const Details = ({ setIsAutoPaymentModalOpen, lease }) => {
  const history = useHistory();

  const { user } = useUserState();
  let defaultCard = user?.default_payment_method;
  let defaultCardEnding = defaultCard?.details.card?.last4;

  const getTotalAmount = lease =>
    formatCurrency(
      parseFloat(lease?.payment_amount) +
        parseFloat(lease?.tax_on_payment_amount)
    );

  const displayTotalPaymentAmountWithPayCycle = lease =>
    `${getTotalAmount(lease)}/${displayPayCycle(lease?.pay_cycle)}`;

  return (
    <div>
      <div className="px-4 space-y-2 bg-white rounded-t-md lg:max-w-6xl lg:mx-auto">
        <div className="flex justify-between">
          <div className="text-xl font-semibold text-purple-1000">
            {displayTotalPaymentAmountWithPayCycle(lease)}
          </div>
        </div>
        {lease?.upcoming_invoice && (
          <div className="font-semibold text-gray-700">
            Payment scheduled on{" "}
            {formatDateWithoutTimeZone(lease?.upcoming_invoice?.due_date)}
          </div>
        )}
      </div>

      <div className="px-4 pt-2 space-y-4 bg-white lg:max-w-6xl lg:mx-auto">
        {lease?.upcoming_invoice && (
          <p className="text-gray-600">
            Your primary payment method of Card Ending {defaultCardEnding} will
            be charged on{" "}
            {formatDateWithoutTimeZone(lease?.upcoming_invoice?.due_date)}
          </p>
        )}

        <div className="flex justify-between pb-6">
          <Button
            label="Autopay Settings"
            style="secondary"
            onClick={() => setIsAutoPaymentModalOpen(true)}
          />
          {isPayableLease(lease?.lease_status) && (
            <Button
              label="Make Payment"
              onClick={() => history.push(`/my-account/payment/${lease?.slug}`)}
            />
          )}
        </div>
        {lease?.overdue_amount !== 0 && (
          <div className="flex justify-between p-4 font-semibold text-red-400 border-t border-gray-200">
            <div>Overdue Amount</div>
            <div>{formatCurrency(lease?.due_amount_with_tax)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Details;

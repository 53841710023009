import React, { useState } from "react";
import { Button } from "@bigbinary/neetoui";

import BeccasHomeLogo from "common/images/beccas-home-logo.png";

const FlowDashBoardHeader = () => {
  const [menuState, setMenuState] = useState(false);

  const menuStatusChange = () => {
    setMenuState(state => !state);
  };

  return (
    <>
      <div className="flex items-center justify-between w-full p-2 border-b border-gray-200 ">
        <div>
          <img src={BeccasHomeLogo} width="50px" height="50px" />
        </div>
        <div className="flex space-x-4">
          <Button
            className="text-lg"
            style="text"
            icon="ri-question-line text-xl"
          />
          <Button
            className="text-2xl"
            style="text"
            icon="ri-menu-line"
            onClick={() => menuStatusChange()}
          />
        </div>
      </div>
      {menuState && (
        <div className="absolute top-0 z-40 w-full p-2 bg-white border border-gray-200 shadow-lg">
          <div className="flex justify-between">
            <div className="pt-1">
              <img src={BeccasHomeLogo} width="50px" height="50px" />
            </div>
            <Button
              className="text-2xl"
              style="text"
              icon="ri-close-line"
              onClick={() => menuStatusChange()}
            />
          </div>
          <div className="p-4">
            <div className="py-4">
              <Button style="secondary" label="Log Out" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FlowDashBoardHeader;

import React from "react";
import { Button } from "neetoui";

import PaymentSuccessIcon from "common/images/PaymentSuccess";
import PaymentFailedIcon from "common/images/FailedPayment";
import { useHistory, useLocation } from "react-router-dom";

const PaymentConfirm = () => {
  const history = useHistory();
  const isEarlyPayment = true;
  const onRetry = () => {
    history.push("/myaccount/payment");
  };
  const onContinue = () => {
    history.push("/myaccount/leases");
  };

  const SuccessMessageComponent = () => (
    <div className="p-4">
      <div className="flex justify-center py-8">
        <img src={PaymentSuccessIcon} height="150em" width="150em"></img>
      </div>
      <p className="py-6 text-lg font-medium">
        <span>Payment worth&nbsp;</span>
        <span className="font-semibold text-purple-1000">__$100_</span>
        <span>&nbsp;towards&nbsp;</span>
        {isEarlyPayment && <span>an early pay off for</span>}
        <span>&nbsp;your lease&nbsp;</span>
        <span className="font-semibold text-purple-1000">__#1345BH_</span>
        <span>
          &nbsp;using your debit card ending __8495_ was completed successfully.
          Your next scheduled payment is on&nbsp;
        </span>
        <span className="font-semibold text-purple-1000">__05/25/2021_</span>.
      </p>
      <div className="flex justify-start py-2 space-x-4">
        <Button label="Autopay Settings" style="secondary" />
        <Button label="Continue" onClick={() => onContinue()} />
      </div>
    </div>
  );

  const FailedMessageComponent = () => (
    <div className="p-4">
      <div className="flex justify-center py-8">
        <img src={PaymentFailedIcon} height="150em" width="150em"></img>
      </div>
      <p className="py-6 text-lg font-medium">
        <span>Payment worth&nbsp;</span>
        <span className="font-semibold text-purple-1000">__$100_</span>
        &nbsp;towards your lease&nbsp;
        <span className="font-semibold text-purple-1000">__#1345BH_</span>
        &nbsp;using your debit card ending __8495_ was failed
      </p>
      <div className="flex justify-start py-2 space-x-4">
        <Button label="Change Payment Settings" style="secondary" />
        <Button label="Retry" onClick={() => onRetry()} />
      </div>
    </div>
  );

  const confirmStatus =
    new URLSearchParams(useLocation().search).get("success") === "true";

  return (
    <div>
      {confirmStatus ? <SuccessMessageComponent /> : <FailedMessageComponent />}
    </div>
  );
};

export default PaymentConfirm;

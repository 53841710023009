export const TOTAL_TICKET_COUNT = 20;
export const SORT_BY_OPTIONS = [{ label: "Name" }, { label: "Date" }];
export const DEFAULT_PAGE_SIZE = 20;

export const HISTORY_DATA = [
  {
    desc: "__Order delayed due to Merchant Sale_",
    agentName: "__Adam Smith_",
    leaseNo: "__LID23390_",
    addOn: "__02/13/2020_",
    time: "__1:30PM ET_",
  },
  {
    desc: "__Order delayed due to Merchant Sale_",
    agentName: "__Adam Smith_",
    leaseNo: "__LID23390_",
    addOn: "__02/13/2020_",
    time: "__1:30PM ET_",
  },
];

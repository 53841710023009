import axios from "axios";

const verifyOtp = payload =>
  axios.post(`/api/v1/customers/phone_verifications`, payload);

const registerPhone = payload =>
  axios.post(`/api/v1/customers/phones`, payload);

const customersPhoneVerificationApi = {
  verifyOtp,
  registerPhone,
};

export default customersPhoneVerificationApi;

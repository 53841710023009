export const LEASE_INVOICE_ACTIONS = ["Credit Account", "Text Payment Link"];
export const LEASE_INVOICE_DETAILS_TAB = ["Invoices", "Payment Transaction"];
export const LEASE_INVOICE_TABLE = [
  {
    date: "__01/20/2020_",
    time: "__3:30PM_",
    amount: "__$30.3_",
    status: "__Autopay_",
    transaction_ID: "__pi_1IialNCCrM7V..._",
    payment_type: "__Auto-Charged_",
    card_no: "__4232_",
  },
  {
    date: "__01/20/2020_",
    time: "__3:30PM_",
    amount: "__$30.3_",
    status: "__Autopay_",
    transaction_ID: "__pi_1IialNCCrM7V..._",
    payment_type: "__Auto-Charged_",
    card_no: "__4232_",
  },
];

export const LEASE_PAYMENT_TABLE = [
  {
    payment_id: "__Invoice-001_",
    payment_date: "__01/20/2020_",
    invoice_id: "__Invoice-0001_",
    amount: "__$25 from Debit Card Ending 8866_",
    payment_type: "__Paid Scheduled Payment_",
  },
  {
    payment_id: "__Invoice-001_",
    payment_date: "__01/20/2020_",
    invoice_id: "__Invoice-0001_",
    amount: "__$25 from Debit Card Ending 8866_",
    payment_type: "__Paid Scheduled Payment_",
  },
];

export const CREDIT_TYPES = [
  {
    label: "Skip Upcoming Payment(s)",
    value: "Skip Upcoming Payment(s)",
  },
  {
    label: "Goodwill Credit",
    value: "Goodwill Credit",
  },
];

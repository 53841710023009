import axios from "axios";

const agents = () => axios.get("api/v1/agents");
const invite = payload => axios.post("api/v1/agents/invite", payload);
const update = (id, payload) => axios.put(`api/v1/agents/${id}`, payload);

const agentsApi = {
  agents,
  invite,
  update,
};

export default agentsApi;

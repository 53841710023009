import * as Yup from "yup";

export const TOTAL_TICKET_COUNT = 20;
export const SORT_BY_OPTIONS = [{ label: "Name" }, { label: "Date" }];
export const CARD_PAYMENT_METHOD_INFO_DATA = [
  {
    name: "__Debit Card Ending 8894_",
    icon: "",
    expireDate: "__02/23_",
    last_used: "__02/23_",
    default: false,
  },
  {
    name: "__Debit Card Ending 8894_",
    icon: "",
    expireDate: "__02/23_",
    last_used: "__02/23_",
    default: true,
  },
];

export const BANK_PAYMENT_METHOD_INFO_DATA = [
  {
    number: "__******1102_",
    name: "__Bank of America_",
    last_used: "__02/23_",
    default: false,
  },
];

export const LEASE_INFO_DATA = [
  {
    lease_no: "__LID23397_",
    date: "__01/20/2020_",
    time: "__3:30PM_",
    amount: "__$30.3_",
    status: false,
    trans_id: "__pi_1IialNCCrM7V..._",
    paymentType: "__Auto-Charged_",
    card_no: "__4232_",
  },
];

export const PaymentsStatus = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
});

export const NEW_CARD_FORM_INITIAL_VALUES = {
  number: "",
  cvc: "",
  is_default: false,
  exp_year: "",
  exp_month: "",
};

export const NEW_CARD_FORM_VALIDATION_SCHEMA = Yup.object({
  number: Yup.number()
    .typeError("you must specify number")
    .required("Card number is required")
    .test(
      "len",
      "Must be exactly 16 characters",
      val => val?.toString().length === 16
    ),
  cvc: Yup.number()
    .typeError("you must specify number")
    .required("CVC is required")
    .test(
      "len",
      "Must be exactly 3 characters",
      val => val?.toString().length === 3
    ),
  exp_year: Yup.number()
    .typeError("you must specify  number")
    .min(
      new Date().getFullYear(),
      "Expiry Year should be greater than current year"
    )
    .required("Expiry Year is required"),
  exp_month: Yup.number()
    .typeError("you must specify a number")
    .lessThan(13, "Expiry Month between 1 and 12")
    .moreThan(0, "Expiry Month between 1 and 12")
    .required("Expiry Month is required"),
  is_default: Yup.boolean(),
});

import axios from "axios";

const processFraudCheck = payload =>
  axios.post(`/api/v1/fraud_investigations/new`, payload);

const processFraudOtp = payload =>
  axios.post(`/api/v1/fraud_investigations/otp`, payload);

const processFraudKiq = payload =>
  axios.post(`/api/v1/fraud_investigations/kiq`, payload);

const experianFraudApi = {
  processFraudCheck,
  processFraudOtp,
  processFraudKiq,
};

export default experianFraudApi;

import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Select as FormikSelect } from "neetoui/formik";

import { SKIP_COUNT } from "../constants";
import { useCustomerState } from "contexts/customer";
import skipInstallmentsApi from "apis/skip-installments";

import LeaseDetailsList from "./Common/LeaseDetailsList";

const SkipCount = ({ formikRef, setModificationPane, fetchCustomer }) => {
  const { id } = useParams();
  const { customer } = useCustomerState();
  const skipCountOptions = SKIP_COUNT;
  const initialValues = {
    skipCount: skipCountOptions.find(
      element => element.value == (customer.installment_skip_count || 1)
    ),
    nix: false,
  };

  const [skipCount, setSkipCount] = useState(initialValues.skipCount);
  const [leaseList, setLeaseList] = useState([]);

  const fetchLeaseList = async () => {
    try {
      const {
        data: { extension_details },
      } = await skipInstallmentsApi.details(id, skipCount?.value, {
        cancel_overdue: false,
      });

      let leaseDataList = extension_details?.details;
      leaseDataList = leaseDataList.map(item => {
        return {
          data_1: {
            label: "Next pay day",
            value: item.next_pay_day,
          },
          data_2: {
            label: "New end date",
            value: item.end_date,
          },
          name: item.lease_slug,
        };
      });
      setLeaseList(leaseDataList);
    } catch (error) {
      logger.error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      await skipInstallmentsApi.update(id, skipCount?.value, {
        cancel_overdue: false,
      });
      await fetchCustomer(id);
      setModificationPane(false);
    } catch (error) {
      logger.error(error);
    }
  };

  useEffect(() => {
    skipCount && fetchLeaseList();
  }, [skipCount]);

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues, ...initialValues.employment_detail }}
      onSubmit={() => handleSubmit()}
      innerRef={formikRef}
    >
      {() => (
        <>
          <FormikSelect
            name="skipCount"
            value={skipCount}
            label="Number of payments to be skipped"
            options={skipCountOptions}
            onChange={value => setSkipCount(value)}
          />
          <LeaseDetailsList leaseList={leaseList} />
        </>
      )}
    </Formik>
  );
};

export default SkipCount;

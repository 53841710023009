import React, { useState, useEffect } from "react";
import { Radio, DateInput, Button, PageLoader } from "neetoui";
import { Select } from "neetoui/formik";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { toSnakeCase } from "utils/styleUtils";
import {
  EMPLOYMENT_OPTIONS,
  PAYMENT_SCHEDULE,
  PAYMENT_MODE_OPTIONS,
} from "../constant";
import {
  FIRST_PAY_AVAILABLE_DAYS,
  SECOND_PAY_AVAILABLE_DAYS,
  MONTHLY_INCOME,
} from "common/constants";

import formValidationSchemas from "../registerFormValidationSchemas";
import employmentDetailsApi from "apis/customers/employment-details";
import { convertArrayToHash } from "utils/objectFormatter";
import { setAuthHeaders } from "apis/axios";
import { useSignupDispatch, useSignupState } from "contexts/signup";
import * as dayjs from "dayjs";

const Workinfo = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [payDays, showPayDays] = useState(false);
  const signupDispatch = useSignupDispatch();
  const signupDetails = useSignupState();
  const [initialValues] = useState(signupDetails.workInfo);

  useEffect(() => {
    showPayDays(signupDetails.workInfo.pay_cycle === "twice_a_month");
  }, []);

  const sendGtmEvent = workData => {
    if (window.dataLayer)
      window.dataLayer.push({
        event: "application.work_info",
        ...signupDetails.personalInfo,
        ...workData,
      });
  };

  const handleSubmit = async details => {
    try {
      setLoading(true);
      let payload = {
        employment_detail: {
          ...details,
          monthly_income: details.monthly_income.value,
          mode_of_salary: details.mode_of_salary.value,
        },
      };
      if (details.pay_cycle == "twice_a_month") {
        payload.employment_detail.pay_days =
          details.pay_day1 && details.pay_day2
            ? [details.pay_day1.value, details.pay_day2.value]
            : [];
      }
      const { data } = await callApi(payload);

      signupDispatch({
        type: "WORK_INFO",
        payload: {
          ...details,
          id: data.employment_detail.id,
        },
      });

      sendGtmEvent(payload.employment_detail);
      history.push("/flow/register/address-info");
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const callApi = async payload => {
    setAuthHeaders();
    const employmentId = signupDetails.workInfo.id;
    if (employmentId) {
      return await employmentDetailsApi.update(employmentId, payload);
    } else {
      return await employmentDetailsApi.create(payload);
    }
  };

  const onPayCycleChange = (evt, setFieldValue) => {
    setFieldValue("pay_cycle", evt.target.value);
    showPayDays(evt.target.value === "twice_a_month");
  };

  const onPayDayChange = (
    currentField,
    setFieldValue,
    payDay1,
    payDay2,
    setErrors
  ) => {
    if (payDay2?.value <= payDay1?.value) {
      setErrors({ pay_day2: "Second date should be greater than first date" });
    } else {
      setFieldValue(
        currentField,
        currentField === "pay_day1" ? payDay1 : payDay2
      );
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center justify-center becca-homepage">
          <PageLoader />
        </div>
      ) : (
        <>
          <div className="px-4 mt-4 pb-2">
            <div>
              <p className="w-5/6 text-lg">
                Welcome, {signupDetails?.personalInfo?.first_name}! Next, what’s
                your employment status?
              </p>
              <p className="my-1 text-sm text-gray-500">
                This helps show your income sources.
              </p>
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validateOnBlur={true}
                validateOnChange={true}
                validationSchema={formValidationSchemas.workInfo}
              >
                {({ values, errors, setFieldValue, setErrors }) => (
                  <Form noValidate>
                    <div className="relative">
                      <div className="neeto-ui-radio__wrapper">
                        <Radio
                          stacked
                          name="current_employment_status"
                          error={errors.current_employment_status}
                        >
                          {EMPLOYMENT_OPTIONS.map((item, index) => {
                            return (
                              <Radio.Item
                                key={index}
                                label={item}
                                value={toSnakeCase(item)}
                                onChange={evt => {
                                  setFieldValue(
                                    "current_employment_status",
                                    evt.target.value
                                  );
                                }}
                                checked={
                                  values.current_employment_status ==
                                  toSnakeCase(item)
                                }
                                className="border-gray-200 border-b-1 border-t-1 my-2"
                              />
                            );
                          })}
                        </Radio>
                        {errors.current_employment_status && (
                          <p className="neeto-ui-radio-input__error">
                            {errors.current_employment_status}
                          </p>
                        )}
                      </div>
                      <p className="text-gray-800 mt-2 mb-1 font-semibold">
                        How often are you paid?
                      </p>
                      <div className="neeto-ui-radio__wrapper">
                        <Radio
                          stacked
                          name="pay_cycle"
                          error={errors.pay_cycle}
                        >
                          {PAYMENT_SCHEDULE.map((item, index) => {
                            return (
                              <Radio.Item
                                key={index}
                                label={item}
                                value={toSnakeCase(item)}
                                onChange={evt => {
                                  onPayCycleChange(evt, setFieldValue);
                                }}
                                checked={values.pay_cycle == toSnakeCase(item)}
                                className="my-2"
                              />
                            );
                          })}
                        </Radio>
                        {errors.pay_cycle && (
                          <p className="neeto-ui-radio-input__error">
                            {errors.pay_cycle}
                          </p>
                        )}
                      </div>
                      <div className="my-4 space-y-1">
                        <p className="my-1 text-sm text-gray-500">
                          What’s your monthly income?
                        </p>
                        <Select
                          placeholder="Select an option"
                          value={values.monthly_income}
                          isDisabled={false}
                          name="monthly_income"
                          options={MONTHLY_INCOME}
                          error={errors.monthly_income}
                        />
                      </div>
                      <div className="my-4 space-y-1">
                        <p className="my-1 text-sm text-gray-500">
                          When was your last payday?
                        </p>
                        {!payDays && (
                          <DateInput
                            placeholder="MM/DD/YYYY"
                            value={
                              values.pay_day ? new Date(values.pay_day) : null
                            }
                            name="pay_day"
                            format="MM/DD/YYYY"
                            onChange={date => {
                              setFieldValue("pay_day", date.toDateString());
                            }}
                            inputProps={{
                              readonly: "readonly",
                            }}
                            minDate={dayjs().subtract(30, "days").toDate()}
                            maxDate={dayjs().toDate()}
                            error={errors.pay_day}
                          />
                        )}
                        {payDays && (
                          <div className="my-4 space-y-1">
                            <Select
                              label="First pay day"
                              placeholder="Select first pay day"
                              value={values.pay_day1}
                              isDisabled={false}
                              name="pay_day1"
                              options={convertArrayToHash(
                                FIRST_PAY_AVAILABLE_DAYS
                              )}
                              onChange={obj =>
                                onPayDayChange(
                                  "pay_day1",
                                  setFieldValue,
                                  obj,
                                  values.pay_day2,
                                  setErrors
                                )
                              }
                              error={errors.pay_day1}
                            />
                            <Select
                              label="Second pay day"
                              placeholder="Select second pay day"
                              value={values.pay_day2}
                              isDisabled={false}
                              name="pay_day2"
                              options={convertArrayToHash(
                                SECOND_PAY_AVAILABLE_DAYS
                              )}
                              onChange={obj =>
                                onPayDayChange(
                                  "pay_day2",
                                  setFieldValue,
                                  values.pay_day1,
                                  obj,
                                  setErrors
                                )
                              }
                              error={errors.pay_day2}
                            />
                          </div>
                        )}
                      </div>
                      <div className="my-4 space-y-1">
                        <p className="my-1 text-sm text-gray-500">
                          How are you paid?
                        </p>
                        <Select
                          placeholder="Select an option"
                          value={values.mode_of_salary}
                          isDisabled={false}
                          name="mode_of_salary"
                          options={PAYMENT_MODE_OPTIONS}
                          error={errors.mode_of_salary}
                        />
                      </div>
                      <Button label="Next" type="submit" />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Workinfo;

import React, { useEffect, useState } from "react";
import { Tab, Button } from "@bigbinary/neetoui";
import { useHistory, useParams } from "react-router-dom";

import ProductOverview from "./Overview";
import ProductData from "./ProductData";
import ProductDataHeader from "./Header";
import { ITEM_DETAILS_TAB } from "./constants";

const renderTab = currentTab => {
  if (currentTab == ITEM_DETAILS_TAB[0]) {
    return <ProductOverview />;
  } else {
    return <ProductData />;
  }
};

const ProductDetails = () => {
  const [currentTab, setCurrentTab] = useState(ITEM_DETAILS_TAB[0]);

  const routerParams = useParams();
  let preDefinedTab = routerParams.tab;
  const history = useHistory();

  useEffect(() => {
    if (preDefinedTab) {
      if (preDefinedTab == "overview") {
        setCurrentTab(ITEM_DETAILS_TAB[0]);
      } else {
        setCurrentTab(ITEM_DETAILS_TAB[1]);
      }
    } else {
      setCurrentTab(ITEM_DETAILS_TAB[0]);
    }
  }, [preDefinedTab]);

  const onTabChange = tab => {
    setCurrentTab(tab);
    if (tab == ITEM_DETAILS_TAB[0]) {
      history.push({
        pathname: `/product-import/vendor-list/vendor-id/product/product-id/overview`,
      });
    } else {
      history.push({
        pathname: `/product-import//vendor-list/vendor-id/product/product-id/data`,
      });
    }
  };

  return (
    <div className="w-full">
      <ProductDataHeader />
      <div className="flex items-center justify-between mx-6">
        <Tab>
          {ITEM_DETAILS_TAB.map((tab, index) => {
            return (
              <Tab.Item
                active={currentTab === tab}
                onClick={() => onTabChange(tab)}
                key={index}
              >
                {tab}
              </Tab.Item>
            );
          })}
        </Tab>
        <div className="flex border border-gray-200 divide-x rounded-md">
          <Button
            style="icon"
            className="px-1 text-2xl"
            icon="ri-arrow-left-s-line"
          />
          <Button
            style="icon"
            className="px-1 text-2xl"
            icon="ri-arrow-right-s-line"
          />
        </div>
      </div>
      {renderTab(currentTab)}
    </div>
  );
};

export default ProductDetails;

import React from "react";
import classnames from "classnames";
import CardIcon from "common/images/card_logo.svg";
import { formatCurrency } from "utils/styleUtils";

const Card = ({ payment }) => {
  const isPaymentSuccessful = payment?.status == "successful" ? true : false;

  return (
    <div
      className={classnames(
        "p-4 my-4 space-y-2 border rounded-md lg:max-w-6xl lg:mx-auto",
        {
          "border-gray-200": isPaymentSuccessful,
          "border-red-600": !isPaymentSuccessful,
        }
      )}
    >
      <div className="flex justify-between font-bold">
        <p
          className={classnames({
            "text-red-600": !isPaymentSuccessful,
            "text-green-600": isPaymentSuccessful,
          })}
        >
          {isPaymentSuccessful ? "Payment Successful" : "Payment Failed"}
        </p>
        <div>
          <p className="font-semibold text-gray-700">
            {formatCurrency(payment?.amount)}
          </p>
        </div>
      </div>
      <div className="flex justify-between">
        <div>
          {new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          }).format(new Date(payment?.paid_at))}
        </div>
        <div className="flex justify-start space-x-1">
          <img src={CardIcon} width="40px" height="40px" />
          <p>Card Ending {payment?.payment_method?.details.card?.last4}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;

import React from "react";
import { Modal } from "@bigbinary/neetoui";
import { isMobile } from "react-device-detect";
import classnames from "classnames";

const ContactUs = ({ contactModal, setcontactModal }) => {
  return (
    <Modal
      size={isMobile ? "large" : "small"}
      isOpen={contactModal}
      onClose={() => setcontactModal(false)}
      autoHeight={isMobile ? false : true}
      showCloseButton
      className="p-0"
    >
      <div
        className={classnames(["justify-center py-4 bg-pink-50"], {
          "w-screen h-screen": isMobile,
        })}
      >
        <div className="flex flex-col items-center justify-center w-full h-full ">
          <div className="flex flex-col items-center justify-center px-6 space-y-4">
            <div className="text-3xl font-semibold text-purple-1000 ">
              Get In Touch
            </div>
            <div className="flex flex-col space-y-4">
              <div className="p-2 bg-gray-100 text-purple-1000">
                <div className="flex items-center justify-between opacity-50">
                  <div className="text-4xl ri-chat-1-line"></div>
                  <div className="text-xl font-semibold text-purple-1000">
                    Live Chat
                  </div>
                  <div className="text-4xl ri-arrow-right-s-line"></div>
                </div>
                <div>Our agent will be back online at 9AM tomorrow. </div>
              </div>
              <div className="p-2 bg-gray-100 text-purple-1000">
                <div className="flex items-center justify-between">
                  <div className="text-4xl ri-message-line"></div>
                  <div className="text-xl font-semibold text-purple-1000">
                    SMS Text
                  </div>
                  <div className="text-4xl ri-arrow-right-s-line"></div>
                </div>
              </div>
              <div className="p-2 bg-gray-100 text-purple-1000">
                <div className="flex items-center justify-between">
                  <div className="text-4xl ri-phone-line"></div>
                  <div className="text-xl font-semibold text-purple-1000">
                    Telephone
                  </div>
                  <div className="text-4xl ri-arrow-right-s-line"></div>
                </div>
              </div>
              <div className="py-2 font-semibold border-b border-purple-1000 text-purple-1000 ">
                How do I place file a return?
              </div>
              <div className="py-2 font-semibold border-b border-purple-1000 text-purple-1000">
                How do I make payments?
              </div>
              <div className="py-2 font-semibold border-b border-purple-1000 text-purple-1000">
                What are the terms of a lease agreement?
              </div>
              <div className="font-semibold text-center opacity-50 text-purple-1000">
                View All FAQs
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactUs;

import React from "react";
import { Label, Select } from "@bigbinary/neetoui";

import AddBadge from "components/Admin/ProductImport/common/AddBadge";
import {
  CATEGORIES_VALUES,
  VARIANTS_VALUES,
} from "components/Admin/ProductImport/constants";

const ProductDetails = () => {
  return (
    <div className="w-1/3 p-4 space-y-4 border border-gray-200">
      <Label className="font-semibold text-gray-400 uppercase">
        Product Details
      </Label>
      <div>
        <Select
          label="Categories"
          isMulti
          defaultValue={CATEGORIES_VALUES}
          options={CATEGORIES_VALUES}
        />
      </div>
      <div>
        <Select
          isMulti
          label="Variants"
          defaultValue={VARIANTS_VALUES}
          options={VARIANTS_VALUES}
        />
      </div>
      <div className="space-y-6">
        <AddBadge label="+ Add Tags" />
        <AddBadge label="+ Add Product Bundle" />
      </div>
    </div>
  );
};

export default ProductDetails;

import React from "react";
import { Button } from "neetoui";

const EmptyLeases = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full px-4 space-y-6">
      <i className="text-red-600 text-9xl ri-error-warning-line" />
      <div>
        <p>There seems to be no active leases currently.</p>
        <p>Head to our storefront for some great offers.</p>
      </div>
      <Button label="Shop Now" className="mt-4" />
    </div>
  );
};

export default EmptyLeases;

import { Formik } from "formik";
import React from "react";
import { Toastr } from "neetoui";
import LeaseDetailsList from "./Common/LeaseDetailsList";
import ReduceAmount from "./Common/ReduceAmount";
import { useParams } from "react-router-dom";
import { LEASEDATAMODIFY } from "../constants";
import skipInstallmentsApi from "apis/skip-installments";
import { useCustomerState } from "contexts/customer";

const ReduceComponent = ({
  isDollar,
  formikRef,
  setModificationPane,
  fetchCustomer,
}) => {
  const { customer } = useCustomerState();
  const { id } = useParams();
  const initialValues = {
    nix: true,
    split: false,
  };

  const handleSubmit = async () => {
    if (customer.overdue_balance_with_tax === 0) {
      Toastr.error({ error: "There is no overdue amount to nix." });
      return false;
    }

    try {
      await skipInstallmentsApi.update(id, 0, {
        cancel_overdue: true,
      });
      await fetchCustomer(id);
      setModificationPane(false);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      innerRef={formikRef}
      onSubmit={handleSubmit}
    >
      {() => (
        <>
          {isDollar && (
            <>
              <ReduceAmount />{" "}
              <LeaseDetailsList
                isDollar={isDollar}
                leaseList={LEASEDATAMODIFY}
              />
            </>
          )}
        </>
      )}
    </Formik>
  );
};

export default ReduceComponent;

export const LATE_CHART_DATA = [
  {
    lease: "__LID23990_",
    day_3: "__0_",
    day_6: "__1_",
    day_15: "__3_",
    day_30: "__4_",
    day_60: "__5_",
    day_90: "__5_",
    total: "__12_",
  },
  {
    lease: "__LID23990_",
    day_3: "__0_",
    day_6: "__1_",
    day_15: "__3_",
    day_30: "__4_",
    day_60: "__5_",
    day_90: "__5_",
    total: "__12_",
  },
];

export const DETAILS_DATA = {
  card: [
    { "OVERDUE BALANCE": "__$220_" },
    { "OUTSTANDING BALANCE": "__$420_" },
    { "Last payment": "__03/21_" },
    { "next payment date": "__04/10_" },
  ],
};

export const ACCOUNT_SUMMARY_DATA = [
  {
    name: "Active Leases",
    value: "_1_",
  },
  {
    name: "Delinquent Leases",
    value: "__0_",
  },
  {
    name: "Paid Off Leases",
    value: "__2_",
  },
  {
    name: "Total Leases",
    value: "__1_",
  },
  {
    name: "Account Creation Date",
    value: "__03/20/2021_",
  },
  {
    name: " First Application",
    value: "__03/20/2021_",
  },
  {
    name: "First Lease",
    value: "__03/20/2021_",
  },
  {
    name: "Lifetime value",
    value: "__$540_",
  },
  {
    name: "Credits Received",
    value: "__$31_",
  },
  {
    name: "Due Days Deferred",
    value: "__14_",
  },
  {
    name: "# of Applications",
    value: "__3_",
  },
  {
    name: "# of Approvals",
    value: "__2_",
  },
  {
    name: "# of Rejections",
    value: "__1_",
  },
  {
    name: "# of Charge Offs",
    value: "__1_",
  },
];

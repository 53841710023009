import React, { useCallback, useRef, useState } from "react";
import { Button, Label } from "neetoui";
import { useDropzone } from "react-dropzone";

const FileCard = ({ type = "", size = "", name = "", hideDownload = true }) => {
  return (
    <div className="flex items-center px-4 py-2 mr-4 transition-all duration-300 ease-in-out border rounded-md cursor-pointer File-card group hover:shadow">
      <div className=" p-1.5 mr-3 text-center rounded-md text-cool-gray-600 bg-cool-gray-100">
        {type == "csv" ? (
          <i className="text-lg ri-Files-book-line" />
        ) : (
          <i className="text-lg ri-file-pdf-line" />
        )}
        <p className="uppercase text-tiny -mt-0.5 truncate font-semibold">
          {type}
        </p>
      </div>
      <div>
        <p className="font-medium">{name}</p>
        <div className="flex items-center text-xs">
          <p>{size}</p>
          {hideDownload && (
            <div className="ml-2 text-sm text-white group-hover:text-gray-600 ">
              <i className="ri-download-2-line"></i>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const FileUpload = ({ label, fileType }) => {
  const imageRef = useRef(null);
  const [{ alt, src }, setImg] = useState({
    src: "",
    alt: "",
    flag: false,
  });

  const handleImg = e => {
    if (e.target.files[0]) {
      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        alt: e.target.files[0].name,
        flag: true,
      });
    }
  };

  const handleClick = () => {
    imageRef.current.click();
  };

  const onDrop = useCallback(files => {
    setImg({
      src: URL.createObjectURL(files[0]),
      alt: files[0].name,
      flag: true,
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const DragDropBaseClass =
    "mb-6 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md transition duration-500 ease-in-out hover:bg-gray-50 cursor-pointer";

  return (
    <div>
      <Label>{label}</Label>
      <div className={DragDropBaseClass}>
        {!src ? (
          <div className="space-y-1 text-center" {...getRootProps()}>
            <i className="text-6xl text-gray-400 ri-Files-book-upload-line"></i>
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              id="photo"
              className="hidden"
              onChange={handleImg}
              ref={imageRef}
              {...getInputProps()}
            />
            {isDragActive ? (
              <p className={"flex justify-center"}>Drop the file here</p>
            ) : (
              <div>
                <div className="text-6xl text-gray-400 ri-image-add-line" />
                <div className="flex items-center justify-between w-full text-sm text-gray-600">
                  <Button
                    style="link"
                    label="Upload a file"
                    onClick={handleClick}
                  />
                  <p className="px-1">or Drag and drop</p>
                  <label
                    htmlFor="file-upload"
                    className="font-medium text-purple-500 bg-transparent rounded-md cursor-pointer"
                  ></label>
                  <label
                    htmlFor="file-upload"
                    className="font-medium text-purple-500 bg-transparent rounded-md cursor-pointer"
                  ></label>
                </div>
                <p className="font-normal text-gray-400">
                  {fileType} of size upto 10MB
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className="relative flex justify-center m-5 overflow-hidden">
            <FileCard
              type={fileType}
              size="256KB"
              name={alt}
              hideDownload={false}
            />
            <div
              className="absolute top-0 flex items-center justify-center w-4 h-4 text-white bg-red-500 rounded-full cursor-pointer right-2"
              onClick={() =>
                setImg({ src: "", alt: "Upload an Image", flag: false })
              }
            >
              <Button
                style="icon"
                icon="ri-subtract-line"
                className="text-white"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUpload;

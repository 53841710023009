import { useReducer } from "react";

import { AGENT_ROLES } from "../constants";

export const initializeAgent = (agent = null) => ({
  error: null,
  firstName: agent?.first_name || "",
  lastName: agent?.last_name || "",
  email: agent?.email || "",
  role:
    (agent?.role && AGENT_ROLES.find(role => role.value === agent?.role)) || "",
  country: {
    emoji: "🇺🇸",
    label: "United States",
    phone: "1",
    value: "United States",
  },
  phone: agent?.phone?.slice(-10) || "",
});

const agentReducer = (state, { type, payload }) => {
  switch (type) {
  case "SET":
    return { ...state, ...payload };
  case "RESET":
    return initializeAgent();
  default:
    throw new Error(`Unhandled action type: ${type}`);
  }
};

export const useAgent = agent =>
  useReducer(agentReducer, agent, initializeAgent);

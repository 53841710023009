import React from "react";
import { Select } from "@bigbinary/neetoui";

const General = () => {
  return (
    <div className="w-full p-4 mt-6 space-y-2 border border-gray-200 rounded-md">
      <p className="font-semibold">General</p>
      <div className="flex items-center justify-between space-x-4">
        <div className="w-56">
          <Select label="Trigger" />
        </div>
        <div className="w-56">
          <Select label="Category" />
        </div>
        <div className="w-56">
          <Select label="From" />
        </div>
        <div className="w-56" />
      </div>
      <div className="flex justify-between space-x-4">
        <div className="w-56">
          <Select label="Timing" />
        </div>
        <div className="w-56">
          <Select label="Scheduled Time" />
        </div>
        <div className="w-56">
          <Select label="Minimum Wait" />
        </div>
        <div className="w-56">
          <Select label="Minimum Wait" />
        </div>
      </div>
    </div>
  );
};

export default General;

import React, { useState } from "react";
import { Button } from "@bigbinary/neetoui";

import RichTextInput from "../../../common/RichTextInput";
import VendorInformation from "./VendorInformation";
import ProductDetails from "./ProductDetails";
import Shipping from "./Shipping";
import SaleScheduling from "./SaleScheduling";
import Wholesale from "./Wholesale";
import Retail from "./Retail";
import ImagesSection from "./ProductImageSection";
import { OVERVIEW_SECTIONS } from "./constants";
import Rename from "components/Admin/ProductImport/common/Rename";

const ProductOverviewMain = () => {
  const [OverviewSectionArray, setOverviewSectionArray] =
    useState(OVERVIEW_SECTIONS);

  const OnAddSectionClick = () => {
    setOverviewSectionArray(state => [
      ...state,
      {
        name: "New Section",
        rename: true,
        value: "",
        deletable: true,
      },
    ]);
  };

  const onDeleteSection = index => {
    if (index) {
      setOverviewSectionArray(state => {
        let updatedstate = [...state];
        updatedstate.splice(index, 1);
        return updatedstate;
      });
    }
  };

  return (
    <div className="px-6 overflow-y-auto bg-white product_overview_main">
      <div className="py-4 mt-4 border-b border-gray-200">
        <Rename
          label={"Arcola Loveseat"}
          labelAction={true}
          className="text-2xl font-semibold"
        />
      </div>
      {OverviewSectionArray.map((item, index) => {
        return (
          <div className="mt-6 space-y-1" key={index}>
            <div className="flex items-center justify-start space-x-2">
              <Rename label={item.name} labelAction={item.rename} />
              {item.deletable && (
                <Button
                  style="text"
                  icon="ri-close-line"
                  onClick={() => onDeleteSection(index)}
                />
              )}
            </div>
            <RichTextInput label={item.name} value={item.value} />
          </div>
        );
      })}

      <div className="flex justify-end my-4">
        <Button
          label="+ Add New Section"
          style="link"
          onClick={() => OnAddSectionClick()}
        />
      </div>
      <ImagesSection />
      <div className="flex mt-6 space-x-6 ">
        <VendorInformation />
        <ProductDetails />
        <Shipping />
      </div>
      <div className="flex mt-6 space-x-6">
        <SaleScheduling />
        <Wholesale />
        <Retail />
      </div>
    </div>
  );
};

export default ProductOverviewMain;

import Trix from "trix";
import "trix/dist/trix.css";

addHeadingAttributes();
configureBlockedAttributes();
addUnderlineAttribute();

function addHeadingAttributes() {
  Array.from(["h1", "h2", "h3", "h4", "h5", "h6"]).forEach((tagName, i) => {
    Trix.config.blockAttributes[`heading${i + 1}`] = {
      tagName: tagName,
      terminal: true,
      breakOnReturn: true,
      group: false,
    };
  });
}

function configureBlockedAttributes() {
  Trix.config.blockAttributes.section = {
    tagName: "section",
    terminal: true,
    breakOnReturn: true,
    group: false,
  };
}

function addUnderlineAttribute() {
  Trix.config.textAttributes.underline = {
    style: { textDecoration: "underline" },
    parser: function (element) {
      var style = window.getComputedStyle(element);
      return style.textDecoration === "underline";
    },
    inheritable: true,
  };
}

export default Trix;

import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button } from "neetoui";

import { useHistory } from "react-router-dom";
import FloatingInput from "components/Common/FloatingInput";
// import BeccasHomeLogo from "common/images/beccas-home-logo.png";
import { fetchQueryParams } from "utils/pathUtils";
import useDocumentTitle from "hooks/useDocumentTitle";
import { getPageTitle } from "common/utils";

const Password = ({ handleSubmit, setPassword, password, email }) => {
  const [error, setError] = useState("");
  const { email: paramEmail } = fetchQueryParams;

  const history = useHistory();
  const getEmail = () => email || paramEmail;

  useDocumentTitle(getPageTitle("login", "enterPassword"));

  const handleReset = () => {
    history.push(`/login/reset?email=${getEmail()}`);
  };

  const onPasswordSubmit = e => {
    if (password == "") {
      setError("Invalid password");
    } else {
      handleSubmit(e);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen p-6 overflow-x-hidden overflow-y-auto bg-white">
      {/* <div className="flex items-center justify-center rounded-md w-14 h-14">
        <img src={BeccasHomeLogo} className="w-full" />
      </div> */}
      <div className="flex flex-col items-start justify-center w-full h-full mx-auto sm:max-w-md">
        <div className="flex flex-col justify-start">
          <p className="text-3xl ri-lock-password-line text-purple-1000"></p>
          <h2 className="mt-4 text-3xl font-bold text-gray-800 ">Sign in</h2>
          <p className="mt-3 font-normal text-gray-600">
            Sign in to your account using your password.
          </p>
          <p className="mt-3 font-normal text-gray-600">
            Email: <span className="font-bold">{getEmail()}</span>
          </p>
        </div>
        <form className="w-full mt-6 space-y-6 bg-white">
          <FloatingInput
            key="password"
            autoFocus="autoFocus"
            label="Password *"
            type="password"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
          />
          <p className="text-red-600">{error}</p>
          <Button
            type="submit"
            fullWidth
            label="Login"
            onClick={onPasswordSubmit}
          />
        </form>
        <div className="flex items-center justify-start w-full mt-8 space-x-1">
          <p>Forgot password?</p>
          <Button
            label="Reset it here."
            style="link"
            onClick={() => handleReset()}
          />
        </div>
      </div>
    </div>
  );
};

Password.propTypes = {
  history: PropTypes.object,
};

export default Password;

export const BANK_PAYMENT_OPTIONS = [
  {
    label: "Secure",
    info: "Transfer of your information is encrypted end to end",
    icon: "ri-secure-payment-line",
  },
  {
    label: "Private",
    info: "Your account information will never be made public.",
    icon: "ri-lock-2-line",
  },
];

import React from "react";
import { Button } from "@bigbinary/neetoui";

import Card from "../../../common/Card";
import { ACTIVITY_TIMELINE } from "./constants";

const ActivityTimeline = () => (
  <Card
    title="Activity TimeLine"
    titleAction={<Button style="link" label="View All" />}
  >
    <div className="py-2 space-y-2">
      <div className="flex flex-col">
        {ACTIVITY_TIMELINE.map((item, key) => {
          return (
            <div key={key}>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="h-full pr-2">
                    <p className="text-lg text-gray-300 ri-focus-2-fill" />
                  </div>
                  <p>{item.message}</p>
                  <Button className="p-1" style="link" label={item.name} />
                </div>
                <p className="text-gray-400">{item.date}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </Card>
);

export default ActivityTimeline;

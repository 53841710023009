import React, { useState } from "react";
import PropTypes from "prop-types";
import { setAuthHeaders } from "apis/axios";
import { useAuthDispatch } from "contexts/auth";
import { useUserDispatch } from "contexts/user";
import { Button, Toastr } from "neetoui";
import authenticationApi from "apis/authentication";
import { Input as FormikInput } from "neetoui/formik";
import { Formik, Form } from "formik";
import { LOGIN_SCHEMA } from "./constant";
import BeccasHomeLogo from "common/images/beccas-home-logo.png";

const Login = ({ history }) => {
  const [loading, setLoading] = useState(false);

  const authDispatch = useAuthDispatch();
  const userDispatch = useUserDispatch();

  const validationSchema = LOGIN_SCHEMA;

  const handleSubmit = async event => {
    try {
      setLoading(true);
      const {
        data: { auth_token, user },
      } = await authenticationApi.login({
        user: { email: event.email, password: event.password },
      });

      authDispatch({
        type: "LOGIN",
        payload: { auth_token, email: event.email, role: "staff" },
      });
      setAuthHeaders();
      userDispatch({ type: "SET_USER", payload: { user } });
      history.push("/customers");
      Toastr.success("Logged in successfully.");
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-row items-center justify-center w-screen h-screen p-6 overflow-x-hidden overflow-y-auto bg-gray-100">
      <div className="flex flex-col items-center justify-center w-full h-full mx-auto sm:max-w-md">
        <div className="flex items-center justify-center rounded-md w-16 h-16">
          <img src={BeccasHomeLogo} width="150px" height="150px" />
        </div>
        <h2 className="mb-5 text-3xl font-extrabold text-center text-gray-800">
          Sign In
        </h2>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="w-full p-8 space-y-6 bg-white border rounded-md shadow">
            <FormikInput
              name="email"
              label="Email *"
              placeholder="oliver@example.com"
            />
            <FormikInput
              name="password"
              type="password"
              label="Password *"
              placeholder="******"
            />
            <Button type="submit" loading={loading} fullWidth label="Login" />
          </Form>
        </Formik>
        <div className="flex flex-col items-center justify-center mt-4 space-y-2">
          <Button label="Forgot password?" style="link" to="/passwords/new" />
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object,
};

export default Login;

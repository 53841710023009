import React from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { Button } from "@bigbinary/neetoui";

import Card from "../Common/Card";
import { APPROVAL_STATUSES, STATUS_HEADER_TEXT_MAPPER } from "./constants";
import { formatCurrency } from "utils/styleUtils";

const ApprovalStatusCard = ({ creditApplication, storeURL, creditEnabled }) => {
  const history = useHistory();

  const showCreditAction =
    creditApplication?.credit_status === "credit_approved" || creditEnabled;

  const handleCreditAction = () => {
    if (creditApplication?.credit_status === "credit_approved") {
      window.location.href = storeURL;
    } else if (creditApplication?.credit_status === "credit_expired") {
      history.push("/my-account/credit-applications/new");
    }
  };

  return (
    <>
      <Card
        key={status}
        bottomText={
          creditApplication?.credit_status === "credit_expired" &&
          `Last refreshed on ${creditApplication?.created_at}`
        }
      >
        <Card.Header>
          <p className="text-white text-sm font-medium">
            {STATUS_HEADER_TEXT_MAPPER[creditApplication?.credit_status]}
          </p>
        </Card.Header>
        <Card.Body>
          <Card.Item>
            <p className="font-semibold text-sm text-gray-800 leading-5">
              Credit Status:
            </p>
            <p
              className={classnames("text-sm font-semibold capitalize", {
                "text-green-500":
                  creditApplication?.credit_status === "credit_approved",
                "text-red-500":
                  creditApplication?.credit_status !== "credit_approved",
              })}
            >
              {APPROVAL_STATUSES[creditApplication?.credit_status]}
            </p>
          </Card.Item>
          {creditApplication?.credit_status === "credit_approved" && (
            <>
              <Card.Item>
                <p className="font-semibold text-sm text-gray-800 leading-5">
                  Approved Amount:
                </p>
                <p className="text-gray-800 text-sm font-semibold">
                  {formatCurrency(creditApplication?.available_credit)}
                </p>
              </Card.Item>
              <Card.Item>
                <p className="font-semibold text-sm text-gray-800 leading-5">
                  Expiration Date:
                </p>
                <p className="text-gray-800 text-sm font-semibold">
                  {creditApplication?.expires_at}
                </p>
              </Card.Item>
            </>
          )}
          {showCreditAction && (
            <Card.Item className="flex justify-center w-full">
              <Button
                label={
                  creditApplication?.credit_status === "credit_approved"
                    ? "Go Shopping"
                    : "Refresh Approval"
                }
                onClick={handleCreditAction}
                size="small"
                style="primary"
                className="mx-auto"
              />
            </Card.Item>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ApprovalStatusCard;

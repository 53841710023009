import React from "react";
import classnames from "classnames";

export const Item = ({ className = "", children }) => {
  return (
    <div
      className={classnames("flex flex-row justify-between items-center", [
        className,
      ])}
    >
      {children}
    </div>
  );
};

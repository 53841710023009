import React, { useEffect, useState } from "react";
import { Button, Label, PageLoader } from "neetoui";
import { useHistory } from "react-router-dom";

import paymentDistributionsApi from "apis/customers/payment-distributions";
import paymentRemainingDistributionsApi from "apis/customers/payment-remaining-distributions";
import PaymentSelect from "./PaymentMethod";
import OvedueInvoices from "components/Common/AmountDistributions/OverdueInvoices";
import UpcomingInvoice from "components/Common/AmountDistributions/UpcomingInvoice";
import EpoOption from "components/Common/AmountDistributions/EpoOption";
import PaymentInputComponent from "components/Common/PaymentInput";

import PaymentConfirmModal from "../common/PaymentConfirmModal";
import PaymentResultModal from "./PaymentResultModal";
import { convertToDecimalString, formatCurrency } from "utils/styleUtils";
import LeaseSelectionComponent from "./LeaseComponent";
import { minus, add } from "utils/mathOperation";
import useStripePay from "./StripepayHook";
import { adjustAmount, verifyAmount } from "utils/PaymentFunctions";

const PaymentSelection = ({ paymentInfo, setPaymentInfo, paymentMethods }) => {
  const [loading, setLoading] = useState(false);
  const [initialStateValue, setInitialStateValue] = useState({
    lease: paymentInfo.lease,
    amount: "",
    amountError: "",
    remainingAmount: "",
    upcomingValue: {
      amount: "",
      isValid: false,
      isChecked: false,
      details: {},
    },
    epoValue: {
      amount: "",
      isValid: true,
      isChecked: false,
      details: {},
    },
    overdueValue: {
      amount: "",
      isValid: false,
      isChecked: true,
      details: {},
    },
    paymentType: "Lease Payment",
    method: paymentInfo.method,
  });

  const [paymentConfirm, setPaymentConfirm] = useState(false);
  const [paymentResultModal, setPaymentResultModal] = useState();
  const { buttonStatus } = useStripePay(0);

  const history = useHistory();

  const onNewCardChange = () => {
    history.push(
      "/my-account/payment/new/card/" + initialStateValue.lease?.value
    );
  };

  const goback = () => {
    history.goBack();
  };

  useEffect(() => {
    setLoading(true);
    initialStateValue.remainingAmount && fetchRemainingDistributions();
    setLoading(false);
  }, [
    initialStateValue.epoValue.amount,
    initialStateValue.upcomingValue.amount,
  ]);

  const getUpdatedState = (prevAmount, currentAmount, options = {}) => {
    const difference = minus(prevAmount, currentAmount);
    let clonedState = { ...initialStateValue };
    const epo = options?.epo;

    if (epo && epo.isChecked) {
      clonedState = {
        ...clonedState,
        epoValue: { ...epo, amount: add(epo.amount, difference) },
      };
    } else {
      clonedState = adjustAmount(
        difference,
        clonedState,
        options?.showToastr,
        initialStateValue
      );
    }

    return clonedState;
  };

  const onEpoChange = (type, value = "") => {
    const {
      epoValue: {
        amount,
        isChecked,
        details: { remaining_to_payoff },
      },
      upcomingValue,
    } = initialStateValue;
    const epoBalance = minus(remaining_to_payoff, upcomingValue.amount);

    if (type == "checked") {
      value = isChecked ? 0 : epoBalance;
      const updatedState = getUpdatedState(amount, value);

      setInitialStateValue(state => {
        return {
          ...updatedState,
          epoValue: {
            ...state.epoValue,
            amount: value,
            isChecked: !isChecked,
          },
        };
      });
    } else if (type == "amount") {
      const verifiedAmount = verifyAmount(value, epoBalance, "epoValue");
      const updatedState = getUpdatedState(amount, verifiedAmount, {
        showToastr: true,
      });

      setInitialStateValue(state => {
        return {
          ...updatedState,
          epoValue: { ...state.epoValue, amount: verifiedAmount },
        };
      });
    }
  };

  const onUpcomingChange = (type, value = "") => {
    const {
      lease: { upcoming_invoice },
      upcomingValue: { amount, isChecked },
      epoValue: epo,
    } = initialStateValue;
    const upcomingBalance = upcoming_invoice?.balance_amount_with_tax;

    if (type == "checked") {
      value = isChecked ? 0 : upcomingBalance;
      const updatedState = getUpdatedState(amount, value, { epo });

      setInitialStateValue(state => {
        return {
          ...updatedState,
          upcomingValue: {
            ...state.upcomingValue,
            amount: value,
            isChecked: !isChecked,
          },
        };
      });
    } else if (type == "amount") {
      const verifiedAmount = verifyAmount(
        value,
        upcomingBalance,
        "upcomingValue"
      );
      const updatedState = getUpdatedState(amount, verifiedAmount, {
        epo,
        showToastr: true,
      });

      setInitialStateValue(state => {
        return {
          ...updatedState,
          upcomingValue: { ...state.upcomingValue, amount: verifiedAmount },
        };
      });
    }
  };

  const onLeaseChange = async value => {
    if (!value) return;

    const { due_amount_with_tax, upcoming_invoice, epo_details } = value;
    const amount =
      due_amount_with_tax || upcoming_invoice?.balance_amount_with_tax;

    setInitialStateValue(state => {
      return {
        ...state,
        lease: value,
        amount: amount,
        upcomingValue: {
          ...state.upcomingValue,
          isValid: !!upcoming_invoice?.balance_amount_with_tax,
        },
        epoValue: {
          isValid: !!epo_details?.is_payable,
        },
        overdueValue: { ...state.overdueValue, isValid: !!due_amount_with_tax },
      };
    });
    await fetchDistributions(amount, value);
    formatAmounts();
  };

  const onPaymentAmountChange = async value => {
    setLoading(true);
    const {
      amountError,
      lease: { maximum_payable_amount: maxAmount },
    } = initialStateValue;

    setInitialStateValue(state => {
      return {
        ...state,
        amount: value,
      };
    });
    (maxAmount >= +value || !amountError) && (await fetchDistributions(value));
    handlePaymentAmountError(value, maxAmount, amountError);
    setLoading(false);
  };

  const handlePaymentAmountError = async (value, maxAmount, amountError) => {
    const exceededMax = maxAmount < +value;
    const error = exceededMax
      ? `You cannot pay more than ${formatCurrency(maxAmount)}.`
      : "";

    !amountError && exceededMax && (await fetchDistributions(maxAmount));
    setInitialStateValue(state => ({ ...state, amountError: error }));
  };

  const onPaymentMethodChange = value => {
    setInitialStateValue(state => {
      return {
        ...state,
        method: value,
      };
    });
  };

  const fetchDistributions = async (
    amount,
    selectedLease = initialStateValue.lease
  ) => {
    if (selectedLease?.id === undefined) return;
    try {
      const {
        data: { distributions },
      } = await paymentDistributionsApi.create(selectedLease?.id, {
        amount,
      });
      const { epo_option, upcoming_invoice, due_invoices, remaining_balance } =
        distributions;
      const epoAmount = epo_option?.amount;
      const upcomingAmount = upcoming_invoice?.total;
      const overdueAmount = due_invoices?.total;

      setInitialStateValue(state => {
        return {
          ...state,
          remainingAmount: remaining_balance,
          epoValue: {
            ...state.epoValue,
            amount: epoAmount,
            isChecked: !!epoAmount,
            details: epo_option,
          },
          upcomingValue: {
            ...state.upcomingValue,
            amount: upcomingAmount,
            isChecked: !!upcomingAmount,
            details: upcoming_invoice,
          },
          overdueValue: {
            ...state.overdueValue,
            amount: overdueAmount,
            isChecked: !!overdueAmount,
            details: due_invoices,
          },
        };
      });
    } catch (error) {
      logger.error(error);
    }
  };

  const fetchRemainingDistributions = async () => {
    const {
      lease,
      remainingAmount,
      epoValue: { amount: epoAmount },
      upcomingValue: { amount: upcomingAmount },
    } = initialStateValue;

    try {
      const {
        data: { distributions },
      } = await paymentRemainingDistributionsApi.create(lease?.id, {
        remaining_balance: remainingAmount,
        epo_amount: epoAmount,
        upcoming_invoice_amount: upcomingAmount,
      });

      setInitialStateValue(state => {
        return {
          ...state,
          epoValue: {
            ...state.epoValue,
            details: distributions?.epo,
          },
          upcomingValue: {
            ...state.upcomingValue,
            details: distributions?.upcoming_invoice,
          },
        };
      });
      setLoading(false);
    } catch (error) {
      logger.error(error);
    }
  };

  const formatAmounts = () => {
    setInitialStateValue(state => {
      const { amount, upcomingValue, epoValue } = state;

      return {
        ...state,
        amount: convertToDecimalString(amount),
        upcomingValue: {
          ...upcomingValue,
          amount: convertToDecimalString(upcomingValue.amount),
        },
        epoValue: {
          ...epoValue,
          amount: convertToDecimalString(epoValue.amount),
        },
      };
    });
  };

  const onSubmit = () => {
    const { epoValue, upcomingValue } = initialStateValue;

    setPaymentConfirm(true);
    setPaymentInfo(state => ({
      ...state,
      lease: initialStateValue.lease,
      amount: initialStateValue.amount,
      paymentType: initialStateValue.paymentType,
      method: initialStateValue.method,
      epoAmount: epoValue.amount,
      upcomingInvoiceAmount: upcomingValue.amount,
    }));
  };

  return (
    <>
      <div className="p-4">
        <div className="space-y-4">
          <div>
            <p className="text-2xl text-gray-800">Make a payment</p>
            <p className="text-sm text-gray-400">
              Choose a payment method along with the payment type.
            </p>
          </div>
          <PaymentSelect
            initialPaymenteMethod={initialStateValue.method}
            onPaymentMethodChange={onPaymentMethodChange}
            onNewCardChange={onNewCardChange}
            paymentMethods={paymentMethods}
            buttonStatus={buttonStatus}
          />
          <LeaseSelectionComponent
            leases={paymentInfo.leaseList}
            onLeaseChange={onLeaseChange}
            initialLease={paymentInfo.lease}
          />
          <PaymentInputComponent
            initialAmount={initialStateValue.amount}
            onPaymentAmountChange={onPaymentAmountChange}
            error={initialStateValue.amountError}
            onBlur={formatAmounts}
          />
          <div className="space-y-3">
            <Label>Apply towards</Label>
            {loading ? (
              <PageLoader />
            ) : (
              <>
                <OvedueInvoices
                  initialOverdueValue={initialStateValue.overdueValue}
                />
                <UpcomingInvoice
                  payCycle={initialStateValue.lease?.pay_cycle}
                  formatAmounts={formatAmounts}
                  onUpcomingValueChange={onUpcomingChange}
                  intialUpcomingValue={initialStateValue.upcomingValue}
                />
                <EpoOption
                  formatAmounts={formatAmounts}
                  intialEpoValue={initialStateValue.epoValue}
                  onEpoValueChange={onEpoChange}
                />
              </>
            )}
            {initialStateValue.overdueValue.isValid && (
              <div className="pt-4 text-gray-400">
                Overdue balance needs to be paid off in full before making a
                payment towards upcoming autopay or early payoff wallet.
              </div>
            )}
          </div>
          <Button
            fullWidth
            label="Pay"
            onClick={() => onSubmit()}
            disabled={
              (!initialStateValue.method ||
                !initialStateValue.lease ||
                !+initialStateValue.amount) &&
              !loading
            }
          />
          <Button style="text" label="Cancel" onClick={() => goback()} />
        </div>
      </div>
      <PaymentConfirmModal
        paymentInfo={paymentInfo}
        paymentConfirm={paymentConfirm}
        setPaymentConfirm={setPaymentConfirm}
        setPaymentResultModal={setPaymentResultModal}
      />
      <PaymentResultModal
        paymentInfo={paymentInfo}
        paymentResultModal={paymentResultModal}
        setPaymentResultModal={setPaymentResultModal}
      />
    </>
  );
};

export default PaymentSelection;

import React from "react";

const AcccountSummaryLeftSide = ({ customer }) => (
  <>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800">Active Leases</p>
      <p className="text-sm font-medium text-gray-800">
        {customer?.active_lease_count}
      </p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800">Delinquent Leases</p>
      <p className="text-sm font-medium text-gray-800">
        {customer?.delinquent_lease_count}
      </p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800">Paid Off Leases</p>
      <p className="text-sm font-medium text-gray-800">
        {customer?.paid_off_lease_count}
      </p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800">Total Leases</p>
      <p className="text-sm font-medium text-gray-800">
        {customer?.total_lease_count}
      </p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800">Account Creation Date</p>
      <p className="text-sm font-medium text-gray-800">
        {customer?.created_at_date}
      </p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800">First Application</p>
      <p className="text-sm font-medium text-gray-800">
        {customer?.first_credit_application_date}
      </p>
    </div>
    <div className="flex justify-between">
      <p className="text-sm font-medium text-gray-800">First Lease</p>
      <p className="text-sm font-medium text-gray-800">
        {customer?.first_lease_created_at}
      </p>
    </div>
  </>
);

export default AcccountSummaryLeftSide;

import React from "react";
import { Button } from "@bigbinary/neetoui";

import { CHARGEOFF_TABLE_DATA } from "./constants";

const ChargeOffsTableRow = ({ data }) => (
  <tr className="text-center">
    <td>
      <div className="flex">
        <Button style="link" label={data.customer_name} />
      </div>
    </td>
    <td className="text-center">{data.contact_no}</td>
    <td className="text-center">{data.chargeoff_amount}</td>
    <td className="text-center">{data.chargeoff_days}</td>
  </tr>
);

const ChargeOffsTableBody = () => (
  <tbody>
    {CHARGEOFF_TABLE_DATA.map((data, idx) => {
      return <ChargeOffsTableRow data={data} key={idx} />;
    })}
  </tbody>
);

const ChargeOffsTableHead = () => (
  <thead>
    <tr className="text-left uppercase">
      <th>Customer name</th>
      <th className="text-center ">Contact no</th>
      <th className="text-center ">Chargeoff amount</th>
      <th className="text-center ">Chargeoff days</th>
    </tr>
  </thead>
);

const ChargeOffs = () => {
  return (
    <div className="flex justify-center">
      <table className="w-11/12 nui-table">
        <ChargeOffsTableHead />
        <ChargeOffsTableBody />
      </table>
    </div>
  );
};

export default ChargeOffs;

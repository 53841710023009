import axios from "axios";

const updatePassword = payload =>
  axios.patch(`api/v1/customers/my/password/update`, payload);

const changePassword = payload =>
  axios.post(`api/v1/customers/my/password_changes/`, { customer: payload });

const customersPasswordApi = {
  updatePassword,
  changePassword,
};

export default customersPasswordApi;

import { minus } from "./mathOperation";
import { formatCurrency } from "./styleUtils";
import { Toastr } from "neetoui";

export const verifyAmount = (value, maxValue, type) => {
  const error =
    type == "epoValue"
      ? `Maximum allowed payment for epo is ${formatCurrency(maxValue)}.`
      : `Can't pay more than ${formatCurrency(maxValue)} for upcoming invoice.`;
  maxValue < +value && Toastr.error({ error });

  return maxValue < +value ? maxValue : value;
};

export const adjustAmount = (
  adjustableAmount,
  state,
  showToastr,
  initialStateValue
) => {
  if (!adjustableAmount) return state;

  const { amount, remainingAmount } = initialStateValue;
  const resultAmount = minus(amount, adjustableAmount);
  state = {
    ...state,
    amount: resultAmount,
    remainingAmount: minus(remainingAmount, adjustableAmount),
  };
  showToastr &&
    Toastr.success(
      `Final amount has been updated to ${formatCurrency(resultAmount)}`
    );

  return state;
};

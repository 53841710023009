import React, { useState } from "react";
import SettingHeaderComponent from "./HeaderComponent";
import SettingsTab from "./Tabs";
import NewWorkflow from "./Tabs/Workflows/NewWorkflow";

const SettingsPage = () => {
  const [newWorkFlow, setNewWorkFlow] = useState(false);

  const onNewWorkFlowClick = () => {
    setNewWorkFlow(true);
  };

  return (
    <>
      <SettingHeaderComponent
        isNewWorkFlow={newWorkFlow}
        onNewWorkFlowClick={onNewWorkFlowClick}
      />
      {newWorkFlow ? <NewWorkflow /> : <SettingsTab />}
    </>
  );
};

export default SettingsPage;

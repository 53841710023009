import React from "react";
import LeaseItem from "./Item";
import { Badge } from "neetoui";

const Lease = ({ leaseClick, lease }) => {
  return (
    <div
      className="p-2 bg-gray-100 rounded-md"
      onClick={() => leaseClick(lease.slug)}
    >
      <div className="flex items-center justify-between pb-2 border-b border-gray-200">
        <div className="font-semibold text-purple-1000">{lease.slug}</div>
        <div className="flex items-center space-x-2">
          <Badge color="gray" type="squared">
            Autopay - On
          </Badge>
          {lease.overdue_amount != 0 && (
            <div className="text-sm text-red-600">Overdue</div>
          )}
        </div>
      </div>
      <div className="py-4 space-y-3">
        {lease?.line_items?.map(item => (
          <LeaseItem key={item.key} item={item} />
        ))}
      </div>
    </div>
  );
};

export default Lease;

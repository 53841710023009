import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button } from "neetoui";
import { useHistory } from "react-router-dom";
import { setAuthHeaders } from "apis/axios";
import authenticationApi from "apis/customers/authentication";
import BeccasHomeLogo from "common/images/beccas-home-logo.png";
import { fetchQueryParams } from "utils/pathUtils";
import useDocumentTitle from "hooks/useDocumentTitle";
import { getPageTitle } from "common/utils";

const MagicLink = ({ email }) => {
  const { email: paramEmail } = fetchQueryParams;
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const getEmail = () => email || paramEmail;

  const onTypePassword = () => {
    history.push(`/login/password?email=${getEmail()}`);
  };

  useDocumentTitle(getPageTitle("login", "magicLink"));

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      setLoading(true);
      setAuthHeaders();

      if (getEmail()) {
        await authenticationApi.sendMagicLink({
          login_credentials: {
            email: getEmail(),
          },
        });
      }
      history.push("/login/magic-link/success?email=" + getEmail());
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen p-6 overflow-x-hidden overflow-y-auto bg-white">
      <div className="flex items-center justify-center rounded-md w-14 h-14">
        <img src={BeccasHomeLogo} width="100px" height="100px" />
      </div>
      <div className="flex flex-col items-start justify-center w-full h-full mx-auto sm:max-w-md">
        <div className="flex flex-col justify-start">
          <p className="text-3xl ri-lock-password-line text-purple-1000"></p>
          <h2 className="mt-4 text-3xl font-bold text-gray-800 ">
            Tired of typing passwords?
          </h2>
          <p className="mt-3 font-normal text-gray-600">
            Get a magic link sent to {getEmail()} to sign in instantly!
          </p>
        </div>
        <form
          className="w-full mt-6 space-y-6 bg-white"
          onSubmit={handleSubmit}
        >
          <Button
            type="submit"
            loading={loading}
            fullWidth
            label="Send magic link"
          />
        </form>
        <div className="flex flex-col items-center justify-center w-full mt-8">
          <div className="flex items-center w-full px-8 space-x-2">
            <div className="w-full h-0.5 border border-gray-200" />
            <p>or</p>
            <div className="w-full h-0.5 border border-gray-200" />
          </div>
          <div className="container max-w-xs">
            <Button
              label="Type password"
              style="secondary"
              className="flex justify-center w-full p-4 mt-5 text-center"
              onClick={() => onTypePassword()}
            />
          </div>
          <div className="container max-w-xs mt-6">
            <Button
              style="link"
              loading={loading}
              fullWidth
              to={`/login/reset?email=${getEmail()}`}
              label="Forgot your password?"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MagicLink.propTypes = {
  history: PropTypes.object,
};

export default MagicLink;

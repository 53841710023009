import React from "react";
import PropTypes from "prop-types";

import { REGISTRATION_FORM_INITIALS } from "../components/SignUp/constant";

import signupReducer from "reducers/signup";

const SignupStateContext = React.createContext();
const SignupDispatchContext = React.createContext();

const SignupProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    signupReducer,
    REGISTRATION_FORM_INITIALS
  );

  return (
    <SignupStateContext.Provider value={state}>
      <SignupDispatchContext.Provider value={dispatch}>
        {children}
      </SignupDispatchContext.Provider>
    </SignupStateContext.Provider>
  );
};

const useSignupState = () => {
  const context = React.useContext(SignupStateContext);
  if (context === undefined) {
    throw new Error("useSignupState must be used within a SignupProvider");
  }
  return context;
};

const useSignupDispatch = () => {
  const context = React.useContext(SignupDispatchContext);
  if (context === undefined) {
    throw new Error("useSignupDispatch must be used within a SignupProvider");
  }
  return context;
};

const useSignup = () => {
  return [useSignupState(), useSignupDispatch()];
};

SignupProvider.propTypes = {
  children: PropTypes.node,
};

export { SignupProvider, useSignupState, useSignupDispatch, useSignup };

import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import axios from "axios";

import PrivateRoute from "components/Common/PrivateRoute";
import CustomerLogin from "components/Authentication/CustomerLogin";
import Navbar from "components/Common/Navbar";
import { CUSTOMERS_ROUTE, LOGIN_ROUTE, STAFF_ROUTES } from "routes/constant";
import { getPageTitle } from "common/utils";
import withTitle from "components/Common/withTitle";

const StaffRoutes = ({ isLoggedIn }) => {
  return (
    <div className="flex h-screen">
      <Navbar />
      <div className="flex flex-col items-start justify-start flex-grow h-screen overflow-y-auto">
        <Switch>
          {STAFF_ROUTES.map(({ titleKey, component, path, exact }) => {
            const title = getPageTitle("staff", titleKey);
            const Component = withTitle({ Component: component, title });

            return (
              <Route
                component={Component}
                exact={exact}
                key={path}
                path={path}
              />
            );
          })}
          {axios.defaults.headers["X-Auth-Token"] && (
            <Redirect from="/" to={CUSTOMERS_ROUTE} />
          )}
          <PrivateRoute
            path="/"
            redirectRoute={LOGIN_ROUTE}
            condition={!isLoggedIn}
            component={CustomerLogin}
          />
        </Switch>
      </div>
    </div>
  );
};

export default StaffRoutes;

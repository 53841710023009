import React from "react";
import { formatCurrency } from "utils/styleUtils";

const LeaseDetailsFooter = ({ lease }) => {
  const epo_details = lease?.epo_details;
  const modified_type =
    epo_details?.type == "retail_price" ? epo_details?.period : "Early";

  return (
    <div className="flex bg-gray-100 ">
      <div className="w-1/2 p-4 border-r border-gray-200">
        <div className="space-y-3">
          <div className="flex justify-between">
            <p>{modified_type} Payoff Option</p>
            <p>{formatCurrency(epo_details?.option_amount)}</p>
          </div>
          <div className="flex justify-between">
            <p>Payoff Installments</p>
            <p>{formatCurrency(epo_details?.balance)}</p>
          </div>
          <div className="flex justify-between py-2 font-semibold border-t border-gray-200">
            <p>Amount To Payoff</p>
            <p>{formatCurrency(epo_details?.payoff_amount)}</p>
          </div>
        </div>
      </div>
      <div className="w-1/2 p-4">
        <div className="space-y-3">
          <div className="flex justify-between">
            <p>Lease Subtotal</p>
            <p>{formatCurrency(lease?.payment_amount)}</p>
          </div>
          <div className="flex justify-between">
            <p>VA Tax</p>
            <p>{formatCurrency(lease?.tax_on_payment_amount)}</p>
          </div>
          <div className="flex justify-between py-2 font-semibold border-t border-gray-200">
            <p>{lease?.pay_cycle} Payment</p>
            <p>
              {formatCurrency(
                +lease?.payment_amount + lease?.tax_on_payment_amount
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaseDetailsFooter;

import React, { useEffect, useState } from "react";
import { Tab, PageLoader, Button } from "neetoui";
import { MainLayout, Container, Scrollable } from "neetoui/layouts";
import { useParams } from "react-router-dom";

import { CustomerStatus, CUSTOMER_DETAILS_TAB } from "../constants";
import CustomerDetails from "./CustomerDetails";
import Leases from "./Leases";
import Payments from "./Payments";
import Applications from "./Applications";

import CustomerSynopsis from "../common/Synopsis/Customer";
import customersApi from "apis/customer";
import CustomerHeaderComponent from "../common/HeaderComponent";
import { useCustomer } from "contexts/customer";
import Notes from "./Notes";
import classNames from "classnames";
import CreditReport from "./CreditReport";

const renderTabContents = (
  currentTab,
  onLeaseClickFunction,
  leaseSlug,
  customer,
  leaseId
) => {
  switch (currentTab) {
  case "Customer Detail":
    return <CustomerDetails customer={customer} />;
  case "Leases":
    return (
      <Leases onLeaseClickFunction={onLeaseClickFunction} leaseId={leaseId} />
    );
  case "Payments":
    return <Payments customer={customer} />;
  case "Applications":
    return <Applications />;
  case "Notes":
    return <Notes />;
  case "Credit Reporting":
    return <CreditReport />;
  }
};

const DetailsTabs = () => {
  const [status, setStatus] = useState(CustomerStatus.LOADING);
  const [{ customer }, customerDispatch] = useCustomer();

  const [currentTab, setCurrentTab] = useState(CUSTOMER_DETAILS_TAB[0]);
  const [leaseSlug, setLeaseSlug] = useState(false);
  const [leaseId, setLeaseId] = useState();

  const routerParams = useParams();
  const CustomerId = routerParams?.id;
  const PreDefinedtab = routerParams?.tab;
  let PreDefinedLeaseID = routerParams.leaseId;

  const changeAccountStatus = async (status, setAccountLoadingState) => {
    try {
      setAccountLoadingState(true);
      const { data } = await customersApi.updateCustomer(CustomerId, {
        account_status: status,
      });
      customerDispatch({
        type: "SET_CUSTOMER",
        payload: data,
      });
      setAccountLoadingState(false);
    } catch (error) {
      logger.log(error);
    }
  };

  const onLeaseClickFunction = (leaseSlug, id) => {
    setLeaseSlug(leaseSlug);
    setLeaseId(id);
  };

  const fetchCustomer = async id => {
    try {
      const { data } = await customersApi.getCustomerDetails(id);

      customerDispatch({ type: "SET_CUSTOMER", payload: data });
      setStatus(CustomerStatus.SUCCESS);
    } catch (error) {
      setStatus(CustomerStatus.ERROR);
    }
  };

  useEffect(() => {
    fetchCustomer(routerParams.id);
  }, []);

  useEffect(() => {
    let PreDefinedtab = routerParams.tab;
    let PreDefinedLeaseID = routerParams.leaseId;

    if (PreDefinedtab) {
      PreDefinedtab = PreDefinedtab.replace(/\b\w/g, c =>
        c.toUpperCase()
      ).replace(/-/g, " ");

      if (PreDefinedtab == "Leases") {
        if (!PreDefinedLeaseID) {
          setLeaseId(null);
          setLeaseSlug(null);
        }
        setLeaseId(PreDefinedLeaseID);
        setCurrentTab(CUSTOMER_DETAILS_TAB[1]);
      } else if (PreDefinedtab == "details") {
        setCurrentTab(CUSTOMER_DETAILS_TAB[0]);
      } else {
        setCurrentTab(PreDefinedtab);
      }
    } else {
      setCurrentTab(CUSTOMER_DETAILS_TAB[0]);
    }
  }, [currentTab, PreDefinedtab, PreDefinedLeaseID, leaseId]);

  return (
    <div className="w-full">
      {status != CustomerStatus.LOADING ? (
        <MainLayout>
          {() => (
            <Container>
              <CustomerHeaderComponent
                currentTab={currentTab}
                leaseSlug={leaseSlug}
                customerName={customer?.name}
                accountNumber={customer?.account_number}
                onLeaseClickFunction={onLeaseClickFunction}
                changeAccountStatus={changeAccountStatus}
                fetchCustomer={fetchCustomer}
                isLeaseDetailsPage={leaseSlug}
                customerStatus={customer?.account_status}
              />
              <>
                <div className="w-full border-b border-gray-200">
                  <Tab className="w-full px-4">
                    {CUSTOMER_DETAILS_TAB.map((tab, index) => {
                      let status = currentTab === tab;
                      return (
                        <Button
                          key={index}
                          className={classNames(["nui-tab"], {
                            "border-b-2 border-purple-1000": status,
                          })}
                          // onClick={() => onTabChange(tab)}
                          style="link"
                          to={`/customers/${CustomerId}/${tab
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replaceAll("_", "")}`}
                          label={tab}
                        />
                      );
                    })}
                  </Tab>
                </div>
                <div className="flex flex-row items-start justify-start w-full">
                  <Scrollable size="small">
                    <div className="w-full">
                      {renderTabContents(
                        currentTab,
                        onLeaseClickFunction,
                        leaseSlug,
                        customer,
                        leaseId
                      )}
                    </div>
                  </Scrollable>
                  {!leaseSlug && <CustomerSynopsis />}
                </div>
                {/* AlertComponenet when status is changed */}
                {/* <DefferalAlertComponent
                  defferalAlert={defferalAlert}
                  setDefferalAlert={setDefferalAlert}
                /> */}
                {/* Note pane component when no specific lease item is selected */}
                {/* <AddNotePane
                  notePane={notePane}
                  setNotePane={setNotePane}
                  data={ADDNOTE}
                />
                {/* Note pane component when a specific lease item is selected from lease list tab */}
                {/* <AddLeaseNotesPane
                  data={ADDNOTE}
                  leaseNotePane={leaseNotePane}
                  setLeaseNotePane={setLeaseNotePane}
                />  */}
              </>
            </Container>
          )}
        </MainLayout>
      ) : (
        <div className="flex flex-row items-center justify-center w-screen h-screen">
          <PageLoader />
        </div>
      )}
    </div>
  );
};

export default DetailsTabs;

import React, { useRef, useState } from "react";
import { Pane, Select, Button } from "neetoui";

import UpdatePayCycle from "./UpdatePayCycle";
import LeasesModifyDetails from "./ModifyAmount";
import ReduceComponent from "./ReduceComponent";
import AvailableCredit from "./AvailableCredit";
import PaymentPromise from "./PaymentPromise";
import SkipCount from "./SkipCount";

import { MODIFICATION_ACTION } from "../constants";

export const ModificationPaneComponent = ({
  modificationPane,
  setModificationPane,
  fetchCustomer,
}) => {
  const [action, SetActions] = useState(MODIFICATION_ACTION[0]);
  const [loading, setLoading] = useState(false);

  const formikRef = useRef();

  return (
    <Pane
      title="Account Modifications"
      isOpen={modificationPane}
      onClose={() => setModificationPane(false)}
      cancelButtonProps={{
        label: "Cancel",
        onClick: () => setModificationPane(false),
      }}
    >
      <div className="h-full p-8 pb-20 space-y-6 overflow-auto">
        <Select
          label="Action"
          value={action}
          onChange={e => {
            SetActions(e);
          }}
          className="pb-6 border-b border-gray-400 border-dashed"
          placeholder="Select an action"
          options={MODIFICATION_ACTION}
        />
        {action?.value == "skip" && (
          <SkipCount
            fetchCustomer={fetchCustomer}
            setModificationPane={setModificationPane}
            formikRef={formikRef}
          />
        )}
        {action?.value == "reduce" && (
          <ReduceComponent
            isDollar={false}
            formikRef={formikRef}
            setModificationPane={setModificationPane}
            fetchCustomer={fetchCustomer}
          />
        )}
        {action?.value == "reduceDollar" && (
          <ReduceComponent
            isDollar={true}
            formikRef={formikRef}
            setModificationPane={setModificationPane}
            fetchCustomer={fetchCustomer}
          />
        )}
        {action?.value == "modify" && (
          <LeasesModifyDetails
            setModificationPane={setModificationPane}
            formikRef={formikRef}
          />
        )}
        {action?.value == "update" && (
          <UpdatePayCycle
            setModificationPane={setModificationPane}
            formikRef={formikRef}
            setLoading={setLoading}
          />
        )}
        {action?.value == "credit" && (
          <AvailableCredit
            setModificationPane={setModificationPane}
            modificationPane={modificationPane}
            fetchCustomer={fetchCustomer}
            formikRef={formikRef}
          />
        )}
        {action?.value == "promise" && (
          <PaymentPromise
            setModificationPane={setModificationPane}
            modificationPane={modificationPane}
            fetchCustomer={fetchCustomer}
            formikRef={formikRef}
          />
        )}
        <div className="flex justify-end w-full space-x-2 nui-pane__footer nui-pane__footer--absolute neeto-ui-shadow-m ">
          <Button
            style="secondary"
            label="Cancel"
            onClick={() => {
              setModificationPane(false);
            }}
          />
          <Button
            style="primary"
            label={"Submit"}
            loading={loading}
            onClick={() => {
              formikRef?.current.handleSubmit();
            }}
          />
        </div>
      </div>
    </Pane>
  );
};

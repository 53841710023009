import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PageLoader } from "neetoui";

import leasesApi from "apis/customers/leases";
import useDocumentTitle from "hooks/useDocumentTitle";
import LeaseDetails from "./LeaseDetails";

const LeaseDetailsHome = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [lease, setLease] = useState(null);
  const [payments, setPayments] = useState(null);
  const [lineItems, setLineItems] = useState(null);

  useDocumentTitle(`Lease detail ${lease?.slug || id}`);

  const fetchLease = async () => {
    setIsLoading(true);
    try {
      const {
        data: { lease, payments },
      } = await leasesApi.lease(id);
      setLease(lease);
      setPayments(payments);
      setLineItems(lease?.line_items);
    } catch (error) {
      logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    !lease && fetchLease();
  }, []);

  if (isLoading)
    return (
      <div className="flex flex-col items-center justify-center w-screen becca-homepage">
        <PageLoader />
      </div>
    );

  return (
    <div className="justify-center w-full">
      <div className="flex flex-col items-center justify-start">
        <div className="w-full max-w-lg overflow-y-auto">
          <LeaseDetails
            lease={lease}
            items={lineItems}
            payments={payments}
            fetchLease={fetchLease}
          />
        </div>
      </div>
    </div>
  );
};

export default LeaseDetailsHome;

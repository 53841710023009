export const EMPLOYMENT_OPTIONS = [
  "Employed",
  "Self-employed",
  "Retired",
  "Student",
  "Unemployed",
];

export const PAYMENT_SCHEDULE = [
  "Weekly",
  "Every other week",
  "Twice a month",
  "Monthly",
];

export const ADDRESS_SAMPLE = ["113 Miami", "112 St New York", "Texas", "LA"];

export const EMPLOYER_SAMPLE = ["BigBinary", "Google", "Apple", "Tesla"];

export const DEFAULT_ERROR_MESSAGE =
  "Sorry, something went wrong. We are investigating it.";

export const TIMEOUT_ERROR_MESSAGE =
  "Sorry, timeout error. You shall be notified about your application via email.";

export const REQUEST_TIMEOUT_IN_MS = 150000;

export const QUOTES = [
  "The greatest glory in living lies not in never falling, but in rising every time we fall. -Nelson Mandela",
  "The way to get started is to quit talking and begin doing. -Walt Disney",
  "Your time is limited, so don't waste it living someone else's life. Don't be trapped by dogma – which is living with the results of other people's thinking. -Steve Job",
  "If you look at what you have in life, you'll always have more. If you look at what you don't have in life, you'll never have enough. -Oprah Winfrey",
  "Whoever is happy will make others happy too. -Anne Frank",
  "The purpose of our lives is to be happy. -Dalai Lama",
];

export const MONTHLY_INCOME = [
  "Less than $2000",
  "$2000 - $3000",
  "$3000 - $4000",
  "$4000 - $5000",
  "$5000 - $6000",
  "$6000+",
];

export const PAYMENT_MODE_OPTIONS = [
  { value: "direct_deposit", label: "Direct Deposit" },
  { value: "check", label: "Check" },
  { value: "cash", label: "Cash" },
  { value: "other", label: "Other" },
];

export const SUBMIT_PAGE_TEXT =
  "I understand that by clicking on the Submit application button immediately following this notice, I am providing “written instructions” to Beccas Home under the Fair Credit Reporting Act authorizing Beccas Home to obtain information from my personal credit profile or other information from consumer reporting agencies. I authorize Beccas Home to obtain information only for Furniture Leasing and Fraud Prevention.";

export const ROUTES = {
  OTP: "/flow/register/otp",
  PERSONAL: "/flow/register/personal-info",
  WORK: "/flow/register/work-info",
  ADDRESS: "/flow/register/address-info",
  SUMBIT: "/flow/register/submit",
  ADDITIONAL: "/flow/register/additional-info",
  APPROVE: "/flow/register/result/approved",
  REJECTED: "/flow/register/result/rejected",
  FAILED: "/flow/register/result/failed",
  ERROR: "/flow/register/result/error",
  CREDITLOCK: "/flow/register/result/credit-lock",
};

export const REGISTRATION_STEPS = {
  personal_info: "/flow/register/personal-info",
  work_info: "/flow/register/work-info",
  address_info: "/flow/register/address-info",
  submit_application: "/flow/register/submit",
  completed: "/flow/register/result/approved",
};

export const CONFIRMSTATE = {
  APPROVED: "approved",
  REJECTED: "rejected",
  LOCKED: "locked",
};

export const REGISTRATION_FORM_INITIALS = {
  personalInfo: {
    first_name: "",
    last_name: "",
    middle_name: "",
    email: "",
    confirm_email: "",
    suffix: "",
    date_of_birth: "",
  },
  workInfo: {
    id: "",
    current_employment_status: "",
    pay_cycle: "",
    monthly_income: null,
    pay_day: "",
    mode_of_salary: null,
    pay_day1: null,
    pay_day2: null,
  },
  addressInfo: {
    id: "",
    area: "",
    street_number: "",
    city: "",
    state: null,
    zip_code: "",
    ssn: "",
  },
  phone_number: "",
  id: "",
};

export const GOOGLE_MAPS_API_KEY = "AIzaSyD_vZeXHomnv18izelExzgtkTZjJMp-w4I";

import React from "react";

const Footer = () => {
  return (
    <div className="absolute bottom-0 right-0 w-full text-white bg-purple-1000">
      <div className="flex justify-center py-2">
        <p>
          Save&nbsp;<span className="font-semibold">&nbsp;$87.33&nbsp;</span>
          &nbsp;on this lease.&nbsp;<span>&nbsp;Buy Now!&nbsp;</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PageLoader, Button } from "neetoui";
import leasesApi from "apis/customers/leases";

import LeaseItem from "./Item";

const MyAccount = ({ storeURL }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [leases, setLeases] = useState([]);

  const fetchLeases = async () => {
    setIsLoading(true);
    try {
      const {
        data: { leases },
      } = await leasesApi.leases();
      setLeases(leases);
    } catch (error) {
      logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLeases();
  }, []);

  if (isLoading)
    return (
      <div className="flex flex-col items-center justify-center w-screen becca-homepage">
        <PageLoader />
      </div>
    );
  return (
    <>
      <div className="sticky w-full becca-tab max-w-lg mx-auto">
        <div className="flex flex-col justify-end px-4 pt-6 bg-white border-b border-gray-200">
          <div className="pb-2 font-semibold bg-white border-b border-purple-1000 w-max">
            My leases
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-start m-4 w-full mx-auto max-w-lg lg:mt-8">
        <div className="w-full space-y-4 overflow-y-auto px-4 md:px-0">
          {leases.length === 0 && (
            <>
              <p className="text-center text-md text-gray-800">
                It seems that you have not placed any order yet.
              </p>
              <p className="text-center text-md text-gray-800">
                Please visit the store to place the order to see your leases
                here.
              </p>
              <div className="flex flex-col items-center ">
                <Button
                  className="text-sm"
                  label="Continue Shopping"
                  type="link"
                  icon="ri-external-link-line"
                  href={storeURL}
                />
              </div>
            </>
          )}
          {leases?.map(lease => {
            return (
              <LeaseItem
                onLeaseClick={() =>
                  history.push(`/my-account/leases/${lease.id}`)
                }
                key={lease.id}
                lease={lease}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MyAccount;

import React, { useState } from "react";

import Body from "../Item/Body";
import Footer from "./Footer";
import Header from "./Header";
import Progress from "./Progress";
import Documents from "./Documents";
import Details from "./Details";
import PaymentHistory from "./PaymentHistory";
import AutoPaymentModal from "components/MyAccount/common/AutoPayModal";
import { Button } from "neetoui";
import EarlyPayment from "./EarlyPayment";
import { useHistory } from "react-router-dom";
import { isPayableLease } from "common/utils";

const LeaseDetails = ({ lease, items, fetchLease, payments }) => {
  const [isAutoPayModalOpen, setIsAutoPaymentModalOpen] = useState(false);

  const history = useHistory();

  const onAutopayModaleClose = (value, isAutopayChanged) => {
    if (isAutopayChanged) {
      fetchLease();
    }
    setIsAutoPaymentModalOpen(value);
  };

  const remainingDays = epoExpiryDate => {
    const now = new Date();
    const expiryDate = new Date(epoExpiryDate);
    return Math.round(Math.abs((expiryDate - now) / (1000 * 60 * 60 * 24)));
  };

  const payOff = () => {
    history.push(`/my-account/payment/${lease?.slug}`);
  };

  return (
    <>
      <Header lease={lease} />
      <div className="bg-purple-1000">
        <div className="px-4 pt-4 mx-4 font-semibold bg-white rounded-t-md">
          <p className="pb-2 border-b border-gray-20">Upcoming Payment</p>
        </div>
      </div>
      <div className="pt-6 mx-4 space-y-6 bg-white border-b border-l border-r border-gray-200 rounded-b-md">
        <Details
          lease={lease}
          setIsAutoPaymentModalOpen={setIsAutoPaymentModalOpen}
        />
      </div>
      {isPayableLease(lease?.lease_status) && (
        <div className="p-4 m-4 space-y-2 bg-white border border-gray-200 rounded-md">
          <div className="text-xl font-semibold border-b border-gray-200 text-purple-1000">
            {remainingDays(lease?.epo_details?.expiry_date)} days remaining
          </div>
          <p className="text-gray-600">
            Payoff your lease in the first 90 days and save money.
          </p>
          <div className="flex justify-center w-full">
            <Button
              label="Payoff Now"
              onClick={() => {
                payOff();
              }}
            />
          </div>
        </div>
      )}
      <div className="m-4 font-semibold bg-white border border-gray-200 rounded-md">
        <EarlyPayment lease={lease} />
      </div>
      <div className="m-4 font-semibold bg-white border border-gray-200 rounded-md">
        <Body lineItems={items} payCycle={lease?.pay_cycle} />
        <Progress lease={lease} />
        <Footer lease={lease} />
      </div>
      <PaymentHistory payments={payments} />
      <Documents documents={lease?.documents} />
      {isAutoPayModalOpen && (
        <AutoPaymentModal
          setIsAutoPayModalOpen={onAutopayModaleClose}
          isAutoPayModalOpen={isAutoPayModalOpen}
          leaseDetails={lease}
        />
      )}
    </>
  );
};

export default LeaseDetails;

import React, { useState } from "react";
import { Label, Switch } from "@bigbinary/neetoui";

const ContactInfo = () => {
  const [switchState, setSwitchState] = useState(false);
  return (
    <div className="w-3/5 pt-12 pl-6">
      <p className="pb-3 text-xl font-bold border-b">Contact Information</p>
      <div className="flex space-x-6">
        <div className="w-full pt-3 space-y-1 ">
          <div className="flex ">
            <Label className="w-1/2 font-semibold">Credit Status:</Label>
            <Switch
              checked={switchState}
              onClick={() => setSwitchState(state => !state)}
            />
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">First name:</Label>
            <p>_Adam_</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">Last name:</Label>
            <p>_Smith_</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">SSN:</Label>
            <p>_202-202-2222_</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">DOB:</Label>
            <p>_August 12,1992_</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">email address:</Label>
            <p>_adam.smith@example.com_</p>
          </div>
        </div>
        <div className="w-full pt-3 space-y-1 ">
          <div className="flex ">
            <Label className="w-1/2 font-semibold">Phone:</Label>
            <p>_123-123-1231_</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">Address:</Label>
            <p>_2344 Hill Street_</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">Address 2:</Label>
            <p>_Apt 1_</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">City:</Label>
            <p>_Huntington park_</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">State:</Label>
            <p>_CA_</p>
          </div>
          <div className="flex">
            <Label className="w-1/2 font-semibold">Zip:</Label>
            <p>_90255_</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;

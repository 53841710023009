import React from "react";
import { useLocation } from "react-router-dom";
import HeaderIcon from "../Header";

import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "./PaymentFailed";

const PaymentStatus = () => {
  const confirmStatus =
    new URLSearchParams(useLocation().search).get("success") === "true";

  return (
    <div className="p-4">
      <HeaderIcon />
      {confirmStatus ? <PaymentSuccess /> : <PaymentFailed />}
    </div>
  );
};
export default PaymentStatus;

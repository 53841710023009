import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Label, PageLoader } from "neetoui";

import CardIcon from "common/images/card_logo.svg";
import paymentDistributionsApi from "apis/payment-distributions";
import paymentRemainingDistributionsApi from "apis/payment-remaining-distributions";
import { minus, add } from "utils/mathOperation";
import { convertToDecimalString, formatCurrency } from "utils/styleUtils";

import PaymentSelect from "./PaymentMethod";
import OvedueInvoices from "components/Common/AmountDistributions/OverdueInvoices";
import UpcomingInvoice from "components/Common/AmountDistributions/UpcomingInvoice";
import EpoOption from "components/Common/AmountDistributions/EpoOption";
import LeaseSelectionComponent from "./LeaseSelection";
import PaymentInputComponent from "components/Common/PaymentInput";
import CardForm from "../../../Payments/CardForm";
import { adjustAmount, verifyAmount } from "utils/PaymentFunctions";

const MakePayment = ({ changeStep, setPaymentInformation, modalClose }) => {
  const { id } = useParams();

  const [initialStateValue, setInitialStateValue] = useState({
    lease: null,
    amount: "",
    amountError: "",
    remainingAmount: "",
    upcomingValue: {
      amount: "",
      isValid: false,
      isChecked: false,
      details: {},
    },
    epoValue: {
      amount: "",
      isValid: true,
      isChecked: false,
      details: {},
    },
    overdueValue: {
      amount: "",
      isValid: false,
      isChecked: true,
      details: {},
    },
    paymentType: "Invoice",
    paymentMethod: {},
  });

  const [newCardPane, setNewCardPane] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initialStateValue.remainingAmount && fetchRemainingDistributions();
  }, [
    initialStateValue.epoValue.amount,
    initialStateValue.upcomingValue.amount,
  ]);

  const getUpdatedState = (prevAmount, currentAmount, options = {}) => {
    const difference = minus(prevAmount, currentAmount);
    let clonedState = { ...initialStateValue };
    const epo = options?.epo;

    if (epo && epo.isChecked) {
      clonedState = {
        ...clonedState,
        epoValue: { ...epo, amount: add(epo.amount, difference) },
      };
    } else {
      clonedState = adjustAmount(
        difference,
        clonedState,
        options?.showToastr,
        initialStateValue
      );
    }

    return clonedState;
  };

  const onEpoChange = (type, value = "") => {
    const {
      epoValue: {
        amount,
        isChecked,
        details: { remaining_to_payoff },
      },
      upcomingValue,
    } = initialStateValue;
    const epoBalance = minus(remaining_to_payoff, upcomingValue.amount);

    if (type == "checked") {
      value = isChecked ? 0 : epoBalance;
      const updatedState = getUpdatedState(amount, value);

      setInitialStateValue(state => {
        return {
          ...updatedState,
          epoValue: {
            ...state.epoValue,
            amount: value,
            isChecked: !isChecked,
          },
        };
      });
    } else if (type == "amount") {
      const verifiedAmount = verifyAmount(value, epoBalance, "epoValue");
      const updatedState = getUpdatedState(amount, verifiedAmount, {
        showToastr: true,
      });

      setInitialStateValue(state => {
        return {
          ...updatedState,
          epoValue: { ...state.epoValue, amount: verifiedAmount },
        };
      });
    }
  };

  const onUpcomingChange = (type, value = "") => {
    const {
      lease: { upcoming_invoice },
      upcomingValue: { amount, isChecked },
      epoValue: epo,
    } = initialStateValue;
    const upcomingBalance = upcoming_invoice?.balance_amount_with_tax;

    if (type == "checked") {
      value = isChecked ? 0 : upcomingBalance;
      const updatedState = getUpdatedState(amount, value, { epo });

      setInitialStateValue(state => {
        return {
          ...updatedState,
          upcomingValue: {
            ...state.upcomingValue,
            amount: value,
            isChecked: !isChecked,
          },
        };
      });
    } else if (type == "amount") {
      const verifiedAmount = verifyAmount(
        value,
        upcomingBalance,
        "upcomingValue"
      );
      const updatedState = getUpdatedState(amount, verifiedAmount, {
        epo,
        showToastr: true,
      });

      setInitialStateValue(state => {
        return {
          ...updatedState,
          upcomingValue: { ...state.upcomingValue, amount: verifiedAmount },
        };
      });
    }
  };

  const onLeaseChange = async value => {
    if (!value) return;

    const { due_amount_with_tax, upcoming_invoice, epo_details } = value;
    const amount =
      due_amount_with_tax || upcoming_invoice?.balance_amount_with_tax;

    setInitialStateValue(state => {
      return {
        ...state,
        lease: value,
        amount: amount,
        upcomingValue: {
          ...state.upcomingValue,
          isValid: !!upcoming_invoice?.balance_amount_with_tax,
        },
        epoValue: {
          isValid: !!epo_details?.is_payable,
        },
        overdueValue: { ...state.overdueValue, isValid: !!due_amount_with_tax },
      };
    });
    await fetchDistributions(amount, true, value);
    formatAmounts();
  };

  const onPaymentAmountChange = async value => {
    const {
      amountError,
      lease: { maximum_payable_amount: maxAmount },
    } = initialStateValue;

    setInitialStateValue(state => {
      return {
        ...state,
        amount: value,
      };
    });
    (maxAmount >= +value || !amountError) &&
      (await fetchDistributions(value, true));
    handlePaymentAmountError(value, maxAmount, amountError);
  };

  const handlePaymentAmountError = async (value, maxAmount, amountError) => {
    const exceededMax = maxAmount < +value;
    const error = exceededMax
      ? `You cannot pay more than ${formatCurrency(maxAmount)}.`
      : "";

    !amountError && exceededMax && (await fetchDistributions(maxAmount));
    setInitialStateValue(state => ({ ...state, amountError: error }));
  };

  const onPaymentMethodChange = value => {
    setInitialStateValue(state => {
      return {
        ...state,
        paymentMethod: value,
      };
    });
  };

  const fetchDistributions = async (
    amount,
    loading = false,
    selectedLease = initialStateValue.lease
  ) => {
    try {
      setLoading(loading);
      const {
        data: { distributions },
      } = await paymentDistributionsApi.create(selectedLease?.id, id, {
        amount,
      });
      const { epo_option, upcoming_invoice, due_invoices, remaining_balance } =
        distributions;
      const epoAmount = epo_option?.amount;
      const upcomingAmount = upcoming_invoice?.total;
      const overdueAmount = due_invoices?.total;

      setInitialStateValue(state => {
        return {
          ...state,
          remainingAmount: remaining_balance,
          epoValue: {
            ...state.epoValue,
            amount: epoAmount,
            isChecked: !!epoAmount,
            details: epo_option,
          },
          upcomingValue: {
            ...state.upcomingValue,
            amount: upcomingAmount,
            isChecked: !!upcomingAmount,
            details: upcoming_invoice,
          },
          overdueValue: {
            ...state.overdueValue,
            amount: overdueAmount,
            isChecked: !!overdueAmount,
            details: due_invoices,
          },
        };
      });
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRemainingDistributions = async () => {
    const {
      lease,
      remainingAmount,
      epoValue: { amount: epoAmount },
      upcomingValue: { amount: upcomingAmount },
    } = initialStateValue;

    try {
      const {
        data: { distributions },
      } = await paymentRemainingDistributionsApi.create(lease?.id, id, {
        remaining_balance: remainingAmount,
        epo_amount: epoAmount,
        upcoming_invoice_amount: upcomingAmount,
      });

      setInitialStateValue(state => {
        return {
          ...state,
          epoValue: {
            ...state.epoValue,
            details: distributions?.epo,
          },
          upcomingValue: {
            ...state.upcomingValue,
            details: distributions?.upcoming_invoice,
          },
        };
      });
    } catch (error) {
      logger.error(error);
    }
  };

  const formatAmounts = () => {
    setInitialStateValue(state => {
      const { amount, upcomingValue, epoValue } = state;

      return {
        ...state,
        amount: convertToDecimalString(amount),
        upcomingValue: {
          ...upcomingValue,
          amount: convertToDecimalString(upcomingValue.amount),
        },
        epoValue: {
          ...epoValue,
          amount: convertToDecimalString(epoValue.amount),
        },
      };
    });
  };

  const onSubmit = () => {
    const { epoValue, upcomingValue } = initialStateValue;

    changeStep(1);
    setPaymentInformation({
      lease: initialStateValue.lease,
      amount: initialStateValue.amount,
      paymentType: initialStateValue.paymentType,
      paymentMethod: initialStateValue.paymentMethod,
      epoAmount: epoValue.amount,
      upcomingInvoiceAmount: upcomingValue.amount,
    });
  };

  return (
    <>
      <div className="relative w-2/5 mt-20 space-y-4">
        <div className="flex justify-between space-x-8">
          <div className="w-1/2">
            <LeaseSelectionComponent
              id={id}
              onLeaseChange={onLeaseChange}
              initialLease={initialStateValue.lease}
            />
          </div>
          <div className="w-1/2">
            <PaymentInputComponent
              initialAmount={initialStateValue.amount}
              onPaymentAmountChange={onPaymentAmountChange}
              error={initialStateValue.amountError}
              onBlur={formatAmounts}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <PaymentSelect
            id={id}
            newCardPane={newCardPane}
            initialPaymenteMethod={initialStateValue.paymentMethod}
            onPaymentMethodChange={onPaymentMethodChange}
            onNewCardChange={() => setNewCardPane(true)}
          />
        </div>
        <div className="space-x-1">
          <div className="space-y-4">
            <Label>Apply towards</Label>
            {loading ? (
              <PageLoader />
            ) : (
              <>
                <OvedueInvoices
                  initialOverdueValue={initialStateValue.overdueValue}
                />
                <UpcomingInvoice
                  payCycle={initialStateValue.lease?.pay_cycle}
                  formatAmounts={formatAmounts}
                  onUpcomingValueChange={onUpcomingChange}
                  intialUpcomingValue={initialStateValue.upcomingValue}
                />
                <EpoOption
                  formatAmounts={formatAmounts}
                  intialEpoValue={initialStateValue.epoValue}
                  onEpoValueChange={onEpoChange}
                />
              </>
            )}
          </div>
          {initialStateValue.overdueValue.isValid && (
            <div className="pt-4 text-gray-400">
              Overdue balance needs to be paid off in full before making a
              payment towards upcoming autopay or early payoff wallet.
            </div>
          )}
        </div>
        <div className="relative flex justify-end space-x-2 text-4xl cursor-pointer">
          <Button onClick={modalClose} style="secondary" label="Close" />
          <Button
            onClick={onSubmit}
            label="Next"
            disabled={
              !initialStateValue.paymentMethod ||
              !initialStateValue.lease ||
              !+initialStateValue.amount
            }
          />
        </div>
      </div>
      <CardForm
        CardIcon={CardIcon}
        addCardPane={newCardPane}
        setAddCardPane={setNewCardPane}
      />
    </>
  );
};

export default MakePayment;

import React, { useState } from "react";
import { SubHeader } from "@bigbinary/neetoui/layouts";
import { Button } from "@bigbinary/neetoui";

import {
  DEFAULT_PAGE_SIZE,
  HISTORY_DATA,
  SORT_BY_OPTIONS,
  TOTAL_TICKET_COUNT,
} from "./constants";

const HistoryTableHead = () => (
  <thead>
    <tr className="text-left">
      <th>description</th>
      <th className="text-center">Agent Name</th>
      <th className="text-center">Lease Id</th>
      <th className="text-center">Added On</th>
      <th className="text-center">Time</th>
    </tr>
  </thead>
);

const HistoryRow = ({ data }) => (
  <tr className="text-left">
    <td>{data.desc}</td>
    <td className="text-center">{data.agentName}</td>
    <td>
      <div className="flex justify-center">
        <Button className="underline" label={data.leaseNo} style="link" />
      </div>
    </td>
    <td className="text-center">{data.addOn}</td>
    <td className="text-center">{data.time}</td>
  </tr>
);

const HistoryTableBody = ({ data }) => (
  <tbody>
    {data.map((data, idx) => (
      <HistoryRow key={idx} data={data} />
    ))}
  </tbody>
);

const History = () => {
  const [searchString, setSearchString] = useState("");
  const [pageIndex, setPageIndex] = useState(5);
  const sortFunction = {};

  return (
    <>
      <div className="flex becca-main-container">
        <div className="becca-details">
          <div>
            <SubHeader
              searchProps={{
                value: searchString,
                onChange: e => setSearchString(e.target.value),
                clear: () => setSearchString(" "),
              }}
              toggleFilter={() => {}}
              sortProps={{
                options: SORT_BY_OPTIONS,
                onClick: sortFunction,
              }}
              paginationProps={{
                count: TOTAL_TICKET_COUNT,
                pageNo: pageIndex,
                pageSize: DEFAULT_PAGE_SIZE,
                navigate: index => setPageIndex(index),
              }}
            />
          </div>
          <div className="p-6">
            <table className="nui-table">
              <HistoryTableHead />
              <HistoryTableBody data={HISTORY_DATA} />
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;

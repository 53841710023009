import React, { useState } from "react";
import { Dropdown, Button } from "neetoui";
import { CUSTOMER_DETAILS_TAB } from "../constants";
import { ACCOUNT_STATUS_OPTIONS } from "common/constants";
import { Header } from "@bigbinary/neetoui/layouts";
import LeaseActionAlert from "./LeaseAlertComponent";

import PaymentModal from "../Tabs/Leases/common/PaymentModal";
import { generateAccountStatusBadge } from "common/utils";
import { ModificationPaneComponent } from "./PaneComponents/ModificationComponent";

const PaymentModifyAccount = ({
  setPaymentModal,
  setAccountModificationPane,
}) => (
  <>
    <Button
      label="Make Payment"
      onClick={() => {
        setPaymentModal(true);
      }}
    />
    <Button
      label="Modify Account"
      onClick={() => {
        setAccountModificationPane(true);
      }}
    />
  </>
);

const LeaseHeadActionBlock = ({
  setPaymentModal,
  setAccountModificationPane,
  customerStatus,
  changeAccountStatus,
}) => {
  let oldStatus = "Cancelled";
  const [leaseAlertState, setLeaseAlertState] = useState(false);

  const [accountLoadingState, setAccountLoadingState] = useState(false);

  return (
    <div className="flex justify-end space-x-4">
      <PaymentModifyAccount
        setPaymentModal={setPaymentModal}
        setAccountModificationPane={setAccountModificationPane}
      />
      <Dropdown
        buttonProps={{
          label: generateAccountStatusBadge(customerStatus)?.label,
          loading: accountLoadingState,
        }}
      >
        {ACCOUNT_STATUS_OPTIONS.map((item, idx) => {
          return (
            <li
              key={idx}
              onClick={() => {
                changeAccountStatus(item.value, setAccountLoadingState);
              }}
            >
              {item.label}
            </li>
          );
        })}
      </Dropdown>
      <LeaseActionAlert
        fromStatus={oldStatus}
        toStatus={leaseAlertState}
        leaseAlertState={leaseAlertState}
        setLeaseAlertState={setLeaseAlertState}
      />
    </div>
  );
};

const AccountHeaderActionBlock = ({
  changeAccountStatus,
  setPaymentModal,
  paymentModal,
  customerStatus,
  setAccountModificationPane,
}) => {
  const [accountLoadingState, setAccountLoadingState] = useState(false);
  return (
    <>
      <div className="flex justify-end space-x-4">
        <PaymentModifyAccount
          setPaymentModal={setPaymentModal}
          setAccountModificationPane={setAccountModificationPane}
        />
      </div>

      <Dropdown
        buttonProps={{
          label: generateAccountStatusBadge(customerStatus)?.label,
          loading: accountLoadingState,
        }}
      >
        {ACCOUNT_STATUS_OPTIONS.map((item, idx) => {
          return (
            <li
              key={idx}
              onClick={() => {
                changeAccountStatus(item.value, setAccountLoadingState);
              }}
            >
              {item.label}
            </li>
          );
        })}
      </Dropdown>
      <PaymentModal
        paymentModal={paymentModal}
        setPaymentModal={setPaymentModal}
      />
    </>
  );
};

const HeaderActionblock = ({
  changeAccountStatus,
  isLeaseDetailsPage,
  customerStatus,
  fetchCustomer,
}) => {
  const [paymentModal, setPaymentModal] = useState(false);
  const [accountModificationPane, setAccountModificationPane] = useState(false);
  return (
    <div className="flex space-x-4">
      {isLeaseDetailsPage ? (
        <LeaseHeadActionBlock
          setPaymentModal={setPaymentModal}
          accountModificationPane={accountModificationPane}
          setAccountModificationPane={setAccountModificationPane}
          customerStatus={customerStatus}
          changeAccountStatus={changeAccountStatus}
        />
      ) : (
        <AccountHeaderActionBlock
          changeAccountStatus={changeAccountStatus}
          setPaymentModal={setPaymentModal}
          paymentModal={paymentModal}
          fetchCustomer={fetchCustomer}
          customerStatus={customerStatus}
          accountModificationPane={accountModificationPane}
          setAccountModificationPane={setAccountModificationPane}
        />
      )}
      <PaymentModal
        paymentModal={paymentModal}
        setPaymentModal={setPaymentModal}
      />
      <ModificationPaneComponent
        modificationPane={accountModificationPane}
        setModificationPane={setAccountModificationPane}
        fetchCustomer={fetchCustomer}
      />
    </div>
  );
};

const CustomerHeaderComponent = ({
  currentTab,
  leaseSlug,
  customerName,
  onLeaseClickFunction,
  changeAccountStatus,
  isLeaseDetailsPage,
  customerStatus,
  accountNumber,
  fetchCustomer,
}) => {
  const titleNameFunction = () => {
    if (currentTab == CUSTOMER_DETAILS_TAB[1] && leaseSlug) {
      return leaseSlug;
    } else {
      return (
        <span className="flex space-x-1">
          <p>{customerName}</p>
          <p className="text-sm font-normal">{"[" + accountNumber + "]"}</p>
        </span>
      );
    }
  };

  const breadcrumbsFunction = () => {
    if (currentTab == CUSTOMER_DETAILS_TAB[1] && leaseSlug) {
      return [
        { text: "Customers", link: "/customers" },
        {
          text: (
            <p>
              {customerName}{" "}
              <span className="text-sm font-normal">
                {" "}
                {"[" + accountNumber + "]"}{" "}
              </span>
            </p>
          ),
          link: "/customers",
        },
      ];
    } else {
      onLeaseClickFunction(null, null);
      return [{ text: "Customers", link: "/customers" }];
    }
  };

  return (
    <Header
      title={titleNameFunction()}
      breadcrumbs={breadcrumbsFunction()}
      actionBlock={
        <HeaderActionblock
          changeAccountStatus={changeAccountStatus}
          isLeaseDetailsPage={isLeaseDetailsPage}
          customerStatus={customerStatus}
          fetchCustomer={fetchCustomer}
        />
      }
    />
  );
};

export default CustomerHeaderComponent;

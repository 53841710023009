import React from "react";
import { formatCurrency } from "utils/styleUtils";
import { Button, Tooltip } from "neetoui";

const EarlyPayment = ({ lease }) => (
  <div className="p-4 bg-gray-100">
    <div className="flex justify-between pb-2 border-b border-gray-200">
      <div className="text-lg font-bold ">Early payoff balance</div>
      <Tooltip
        content="Own your items quicker by making payments to your early payoff wallet."
        position="right"
      >
        <Button style="icon" icon="ri-question-line" />
      </Tooltip>
    </div>
    <div className="pt-4 space-y-2">
      <div className="flex justify-between font-semibold">
        <p className="text-gray-600">Current payoff option</p> 
        <p className="text-black">
          {formatCurrency(lease?.epo_details?.option_amount)}
        </p>
      </div>
      <div className="flex justify-between font-semibold">
        <p className="text-gray-600">Early payoff wallet</p>
        <p className="text-black">{`-  ${formatCurrency(
          lease?.epo_details?.balance
        )}`}</p>
      </div>
      <div className="flex justify-between pb-2 font-semibold border-b border-gray-300">
        <p className="text-gray-600">Sales tax</p>
        <p className="text-black">{`+  ${formatCurrency(
          lease?.epo_estimated_tax
        )}`}</p>
      </div>
      <div className="flex justify-between font-semibold">
        <p className="text-gray-600">Total to payoff</p> 
        <p className="text-black">
          {formatCurrency(
            parseFloat(lease?.epo_details?.option_amount) +
              parseFloat(lease?.epo_estimated_tax) -
              parseFloat(lease?.epo_details?.balance)
          )}
        </p>
      </div>
    </div>
  </div>
);

export default EarlyPayment;

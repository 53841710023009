import * as Yup from "yup";
export const SIGN_UP_INITIAL_VALUE = {
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  password_confirmation: "",
};
export const RESET_PASSWORD_INITIAL_VALUE = {
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  password_confirmation: "",
};

export const RESET_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string()
    .email("Incorrect Email address")
    .required("Email is required"),
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  password: Yup.string().required("Password is required"),
  password_confirmation: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const LOGIN_SCHEMA = Yup.object({
  email: Yup.string()
    .email("Incorrect Email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

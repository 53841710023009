import axios from "axios";

const leases = customer_id =>
  axios.get(`api/v1/customers/${customer_id}/leases`);

const lease = (customer_id, lease_id) =>
  axios.get(`api/v1/customers/${customer_id}/leases/${lease_id}`);

const update = (customer_id, lease_id, payload) =>
  axios.patch(`api/v1/customers/${customer_id}/leases/${lease_id}`, payload);

const leasesApi = {
  leases,
  lease,
  update,
};

export default leasesApi;

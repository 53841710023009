import React, { useState } from "react";
import { Alert, Input } from "@bigbinary/neetoui";

const ConfirmAlertComponent = ({
  defferalConfirmAlert,
  setDefferalConfirmAlert,
}) => (
  <Alert
    isOpen={defferalConfirmAlert}
    icon="ri-alarm-warning-fill text-red-500"
    title="Account Status Changed"
    message={
      <div>
        Account status for Charlie Smith has been changed from
        <span className="text-red-600"> Active</span> to
        <span className="text-red-600"> Deferral</span>. The account will be
        re-activated in 30 days on __05/21/2021_
      </div>
    }
    hideConfirmation
    showFooter={false}
    onClose={() => setDefferalConfirmAlert(false)}
  />
);

const DefferalAlertComponent = ({ defferalAlert, setDefferalAlert }) => {
  const [defferalConfirmAlert, setDefferalConfirmAlert] = useState(false);
  return (
    <>
      <Alert
        isOpen={defferalAlert}
        icon="ri-alarm-warning-fill text-red-500"
        title="Change Account Status"
        message={
          <div>
            Are you sure you want to change the account status for Charlie Smith
            from <span className="text-red-600">Active</span> to
            <span className="text-red-600"> Deferral</span>. If so, please
            specify a reason for it below.
          </div>
        }
        onClose={() => setDefferalAlert(false)}
        confirmationText={
          <div>
            <Input label="Days" infoText="Days" value="30" />
            <Input
              className="mt-2"
              label="Reason"
              value="Charlie has skipped last two lease payments."
            />
          </div>
        }
        cancelButtonProps={{
          onClick: () => setDefferalAlert(false),
        }}
        submitButtonProps={{
          onClick: () => {
            setDefferalConfirmAlert(true);
            setDefferalAlert(false);
          },
          label: "Continue",
        }}
      />
      <ConfirmAlertComponent
        defferalConfirmAlert={defferalConfirmAlert}
        setDefferalConfirmAlert={setDefferalConfirmAlert}
      />
    </>
  );
};

export default DefferalAlertComponent;

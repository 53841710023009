import React, { useEffect, useState } from "react";

import { formatCurrency } from "utils/styleUtils";
import AmountBox from "components/Admin/Customer/common/Amountbox";

const EpoOption = ({ onEpoValueChange, intialEpoValue, formatAmounts }) => {
  const [epoAmount, setEpoAmount] = useState();
  const [epoCheckbox, setEpoCheckbox] = useState();

  const onEpoCheckboxChange = () => {
    setEpoCheckbox(state => !state);
    onEpoValueChange("checked");
  };

  useEffect(() => {
    setEpoAmount(intialEpoValue.amount);
    setEpoCheckbox(intialEpoValue.isChecked);
  }, [intialEpoValue]);

  const onEpoAmountChange = value => {
    setEpoAmount(value);
    onEpoValueChange("amount", value);
  };

  return (
    <AmountBox
      title={"Early Payoff Wallet"}
      amount={epoAmount}
      formatAmounts={formatAmounts}
      onAmountChange={onEpoAmountChange}
      onCheckboxChange={onEpoCheckboxChange}
      isChecked={epoCheckbox}
      isValid={intialEpoValue.isValid}
    >
      <div className="space-y-2 text-gray-400">
        <div className="flex justify-between font-semibold">
          <p>Total to Payoff</p>
          <p>{formatCurrency(intialEpoValue?.details?.remaining_to_payoff)}</p>
        </div>
      </div>
    </AmountBox>
  );
};

export default EpoOption;

import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";

import { PageLoader } from "neetoui";
import { initializeLogger } from "common/logger";
import {
  setAuthHeaders,
  registerIntercepts,
  resetAuthTokens,
} from "apis/axios";
import SignUpDataFormatter from "utils/signUpDataFormatter";

import { useAuthDispatch } from "contexts/auth";
import { useUserDispatch } from "contexts/user";
import { useSignupDispatch } from "contexts/signup";

import Routes from "routes";

const Main = props => {
  const [loading, setLoading] = useState(true);
  const userDispatch = useUserDispatch();
  const authDispatch = useAuthDispatch();
  const signupDispatch = useSignupDispatch();
  const { credit_enabled, store_url, redirect_uri } = props;

  useEffect(() => {
    if (props.customer) {
      authDispatch({
        type: "LOGIN",
        payload: {
          auth_token: props.customer.authentication_token,
          email: props.customer.email,
          role: "customer",
        },
      });
    }

    if (!(props.customer || props.user) && localStorage.getItem("authToken")) {
      authDispatch({
        type: "LOGOUT",
      });
      resetAuthTokens();
    }

    if (props.customer_signup_details) {
      setSignupDetails(props.customer_signup_details);
    }

    userDispatch({
      type: "SET_USER",
      payload: {
        user: props.user || {
          ...props.customer,
          default_payment_method: props.default_payment_method,
        },
      },
    });
    initializeLogger();
    registerIntercepts(authDispatch);
    setAuthHeaders(setLoading);
    if (redirect_uri) window.location.href = redirect_uri;
  }, []);

  const setSignupDetails = signup_details => {
    const dataFormatter = new SignUpDataFormatter(signup_details);

    if (dataFormatter.phoneNumber)
      signupDispatch({ type: "OTP", payload: dataFormatter.getPhoneNumber() });
    if (dataFormatter.personalInfo)
      signupDispatch({
        type: "PERSONAL_INFO",
        payload: dataFormatter.getPersonalInfo(),
      });
    if (dataFormatter.addressInfo)
      signupDispatch({
        type: "ADDRESS_INFO",
        payload: dataFormatter.getAddressInfo(),
      });
    if (dataFormatter.workInfo)
      signupDispatch({
        type: "WORK_INFO",
        payload: dataFormatter.getWorkInfo(),
      });
  };

  if (loading) {
    return (
      <div className="h-screen">
        <PageLoader />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes credit_enabled={credit_enabled} store_url={store_url} />
    </BrowserRouter>
  );
};

Main.propTypes = {
  user: PropTypes.object,
};

export default Main;

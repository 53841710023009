import React from "react";
import { Alert, Input } from "neetoui";

const LeaseActionAlert = ({
  fromStatus,
  toStatus,
  leaseAlertState,
  setLeaseAlertState,
}) => (
  <Alert
    isOpen={leaseAlertState}
    icon="ri-alarm-warning-fill text-red-500"
    title="Confirm Order Return Request"
    message={
      <div className="inline-block">
        <span>
          Are you sure you want to change the item status for Charlie Smith from
        </span>
        <span className="mx-1 text-red-500">{fromStatus}</span> to
        <span className="mx-1 text-red-500">{toStatus}</span>
      </div>
    }
    confirmationText={
      leaseAlertState === "Return Requested" && (
        <div className="space-y-2">
          <Input label="Reason For Return" value="The colors were dull." />
          <Input label="Courtesy Credit" value="12" />
          <Input label="Return Fees" value="8$" disabled={true} />
        </div>
      )
    }
    onClose={() => setLeaseAlertState(false)}
    cancelButtonProps={{
      onClick: () => setLeaseAlertState(false),
    }}
    submitButtonProps={{
      onClick: () => {
        setLeaseAlertState(true);
      },
      label: "Continue",
    }}
  />
);

export default LeaseActionAlert;

import React from "react";
import { Button } from "neetoui";
import BeccasHomeLogo from "common/images/beccas-home-logo.png";

const SplashScreen = ({ onPaynowClick }) => {
  return (
    <div className="justify-center w-screen h-screen bg-overduePink">
      <div className="flex flex-col items-center justify-center w-full h-full ">
        <div className="absolute top-0 flex items-center justify-between w-full p-2 ">
          <img src={BeccasHomeLogo} width="100px" height="100px" />
          <Button className="text-2xl" style="text" icon="ri-close-line" />
        </div>
        <div className="flex flex-col items-center justify-center px-6">
          <i className="text-red-600 text-9xl ri-error-warning-line" />
          <div className="text-xl font-semibold text-red-600 uppercase">
            overdue balance
          </div>
          <div className="flex justify-center p-4 font-medium text-red-600">
            You have an overdue balance of $100 for your lease #3430BH due to an
            autopayment failure on 3rd March 2021.
          </div>
          <Button
            fullWidth
            style="secondary"
            label="Pay now"
            onClick={() => {
              onPaynowClick();
            }}
          />
        </div>
        <div className="absolute bottom-0 flex justify-center w-full p-2 bg-white ">
          <Button
            className="underline"
            style="link"
            label="Have any issues? Get in touch with us."
          />
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;

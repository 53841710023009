import React from "react";

import { useAuthState } from "contexts/auth";
import { useUserState } from "contexts/user";

import CustomerRoutes from "./customers";
import StaffRoutes from "./staff";
import PublicRoutes from "./public";

const Routes = ({ credit_enabled, store_url }) => {
  const { isLoggedIn, role } = useAuthState();
  const { user } = useUserState();
  const isStaff = role === "staff";

  return isLoggedIn && user ? (
    isStaff ? (
      <StaffRoutes isLoggedIn={isLoggedIn} />
    ) : (
      <CustomerRoutes
        isLoggedIn={isLoggedIn}
        credit_enabled={credit_enabled}
        store_url={store_url}
      />
    )
  ) : (
    <PublicRoutes isLoggedIn={isLoggedIn} />
  );
};

export default Routes;

import React, { useEffect, useState } from "react";
import { Input } from "neetoui";
import { useDebouncedCallback } from "use-debounce/lib";

const AmountInput = ({ initialAmount, onPaymentAmountChange, ...props }) => {
  const [amountState, setAmountState] = useState(0);

  useEffect(() => {
    setAmountState(initialAmount);
  }, [initialAmount]);

  const onAmountChange = value => {
    setAmountState(value);
    debouncedeCallback(value);
  };

  const debouncedeCallback = useDebouncedCallback(value => {
    onPaymentAmountChange(value);
  }, 1000);

  return (
    <Input
      {...props}
      prefix={"$"}
      label="Payment Amount"
      value={amountState}
      onChange={e => onAmountChange(e.target.value)}
      type="number"
    />
  );
};

export default AmountInput;

import React from "react";
import { Button } from "neetoui";
import classNames from "classnames";
import { useHistory } from "react-router";
import { displayPayCycle } from "components/MyAccount/common/helper";
import { formatCurrency } from "utils/styleUtils";

const LeaseDetailsHeader = ({ lease, setIsAutoPayModalOpen }) => {
  const history = useHistory();

  const onMakePaymentClick = () => {
    history.push(`/myaccount/payment/${lease?.slug}`);
  };

  return (
    <div className="flex justify-between">
      <div className="space-y-4">
        <div className="flex justify-between space-x-12">
          <div className="font-semibold text-purple-300 uppercase ">
            Lease ID
          </div>
          <div className="flex items-center justify-between px-2 space-x-1 text-black bg-white rounded-xl">
            <div
              className={classNames(
                ["text-tiny ri-checkbox-blank-circle-fill"],
                {
                  "text-green-600": lease?.lease_status == "active",
                },
                {
                  "text-yellow-400": lease?.lease_status != "active",
                }
              )}
            ></div>
            <p>
              {lease?.lease_status.charAt(0).toUpperCase() +
                lease?.lease_status.slice(1)}
            </p>
          </div>
        </div>
        <div className="text-4xl font-extrabold text-white">{lease?.slug}</div>
        <div className="text-2xl font-extrabold text-white">
          {formatCurrency(lease?.payment_amount)}/
          {displayPayCycle(lease?.pay_cycle)}
        </div>
        <div className="text-lg font-semibold text-purple-300">
          Next payment on {lease?.upcoming_invoice?.due_date}
        </div>
      </div>
      <div className="space-y-4">
        <div className="font-semibold text-purple-300 uppercase ">
          Remaining lease balance
        </div>
        <div className="text-4xl font-extrabold text-white">
          {formatCurrency(lease?.outstanding_amount)}
        </div>
        <div className="flex justify-between space-x-12">
          <Button
            label="Autopay Settings"
            style="secondary"
            onClick={() => setIsAutoPayModalOpen(true)}
          />
          <Button
            label="Make Payment"
            style="secondary"
            onClick={() => {
              onMakePaymentClick();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LeaseDetailsHeader;

import Image2 from "common/images/furniture/image-2.jpeg";
import Image1 from "common/images/furniture/image-1.jpeg";
import Image3 from "common/images/furniture/image-3.jpeg";
import Image4 from "common/images/furniture/image-4.jpeg";

export const DEFAULT_PAGE_SIZE = 20;
export const FILTER_BY_OPTIONS = [
  { label: "Last Inventory Update" },
  { label: "Last Product Update" },
];
export const SORT_BY_OPTIONS = [
  { label: "Last Inventory Update" },
  { label: "Last Product Update" },
];
export const SORT_BY_OPTIONS_VENDOR = [
  { label: "Monthly Price" },
  { label: "Weekly Price" },
];
export const LOAD_SORT_PROPS = {};

export const PRODUCT_IMPORT_DATA = [
  {
    vendor_name: "Ashley’s Furniture",
    public: "220",
    hidden: "220",
    last_inventory_update: "05/07/2021",
    last_product_update: "05/07/2021",
    last_pricing_update: "05/07/2021",
    import_status: "completed",
    visibilty: true,
  },
  {
    vendor_name: "Ashley’s Furniture",
    public: "220",
    hidden: "220",
    last_inventory_update: "05/07/2021",
    last_product_update: "05/07/2021",
    last_pricing_update: "05/07/2021",
    import_status: "QA",
    visibilty: false,
  },
  {
    vendor_name: "Ashley’s Furniture",
    public: "220",
    hidden: "220",
    last_inventory_update: "05/07/2021",
    last_product_update: "05/07/2021",
    last_pricing_update: "05/07/2021",
    import_status: "awaiting",
    visibilty: false,
  },
];

export const SKU_SELECT_OPTIONS = [
  { label: "BH11002321", value: "BH11002321" },
  { label: "BH110023241", value: "BH110042321" },
];

export const CATEGORIES_VALUES = [
  { label: "Sofas & Loveseat", value: "Sofas & Loveseat" },
  { label: "Sofas", value: "Sofas" },
];

export const VARIANTS_VALUES = [
  { label: "Love Seat Blue", value: "Love Seat Blue" },
  { label: "Love Seat Cyan", value: "Love Seat Cyan" },
];

export const PRODUCT_TABLE_CONTENT = [
  {
    published: true,
    title: "Material",
    desc: "Leather",
    status: "true",
  },
  {
    published: true,
    title: "Material",
    desc: "Leather",
    status: "true",
  },
  {
    published: true,
    title: "Material",
    desc: "Leather",
    status: "true",
  },
  {
    published: false,
    title: "Material",
    desc: "Leather",
    status: "true",
  },
];

export const IMPORT_PANE_TYPE = [
  { label: "Pricing Update", value: "Pricing Update" },
  { label: "Inventory Update", value: "Inventory Update" },
  { label: "Prpduct Update", value: "Product Update" },
];

export const VENDOR_DETAILS_TABLE = Array(20).fill({
  category: "Sofas & Loveseats",
  monthly: "$435.54",
  inventory: true,
  brand: "Modway’s Furniture",
  name: "Arcola Loveseat",
  wholesale: "$343.33",
  shipping: "LTL",
  first_seen: "07/03/2021",
  images: [[Image1], [Image2], [Image3], [Image4]],
});

import React from "react";
import { Label, Button } from "neetoui";

import Card from "components/Admin/Customer/common/Card";
import { convertSnakeToTitle, formatCurrency } from "utils/styleUtils";
import { CREDIT_STATUSES, FRAUD_STATUSES } from "common/constants";
import { formatDateWithoutTimeZone } from "common/dateTimeFormatter";

const CreditInfoCard = ({ setIsUpdatePayCyclePaneOpen, customer }) => (
  <Card
    title={"CREDIT INFORMATION"}
    titleAction={
      <Button
        style="icon"
        className="text-base"
        icon="ri-pencil-line text-basetext-base"
        onClick={() => setIsUpdatePayCyclePaneOpen(true)}
      />
    }
  >
    <div className="flex justify-between mt-4">
      <Label>Credit Status </Label>
      <p className="font-normal text-gray-600">
        {CREDIT_STATUSES[customer.credit_status] || "Pending"}
      </p>
    </div>

    <div className="flex justify-between mt-2">
      <Label>Fraud Status</Label>
      <p className="font-normal text-gray-600">
        {FRAUD_STATUSES[customer.fraud_status] || "Pending"}
      </p>
    </div>
    <div className="flex justify-between mt-2">
      <Label>Credit Limit</Label>
      <p className="font-normal text-gray-600">
        {formatCurrency(customer.credit_limit)}
      </p>
    </div>
    <div className="flex justify-between mt-2">
      <Label>Credit Available</Label>
      <p className="font-normal text-gray-600">
        {formatCurrency(customer.available_credit)}
      </p>
    </div>
    <div className="flex justify-between mt-2">
      <Label>Expiration</Label>
      <p className="font-normal text-gray-600">{customer.credit_valid_till}</p>
    </div>

    <div className="flex justify-between mt-2">
      <Label>Internal Credit Status</Label>
      <p className="font-normal text-gray-600">
        {customer.credit_apply_enabled ? "Not blocked" : "Blocked"}
      </p>
    </div>
    <div className="flex justify-between mt-2">
      <Label>Monthly Income</Label>
      <p className="font-normal text-gray-600">
        {customer.employment_detail?.monthly_income}
      </p>
    </div>
    <div className="flex justify-between mt-2">
      <Label>Pay Frequency</Label>
      <p className="font-normal text-gray-600">
        {customer.employment_detail?.pay_cycle}
      </p>
    </div>
    <div className="flex justify-between mt-2">
      <Label>Payment Mode</Label>
      <p className="font-normal text-gray-600">
        {convertSnakeToTitle(customer.employment_detail?.mode_of_salary)}
      </p>
    </div>
    <div className="flex justify-between mt-2">
      <Label>Employment Status</Label>
      <p className="font-normal text-gray-600">
        {convertSnakeToTitle(
          customer.employment_detail?.current_employment_status
        )}
      </p>
    </div>
    {customer.employment_detail?.pay_cycle === "Twice A Month" ? (
      <div className="flex justify-between mt-2">
        <Label>Pay days</Label>
        <p className="font-normal text-gray-600">
          {customer.employment_detail?.pay_days.join(" ,") || "-"}
        </p>
      </div>
    ) : (
      <div className="flex justify-between mt-2">
        <Label>Next Pay day</Label>
        <p className="font-normal text-gray-600">
          {formatDateWithoutTimeZone(customer.employment_detail?.next_pay_day)}
        </p>
      </div>
    )}
  </Card>
);

export default CreditInfoCard;

import {
  PAGE_TITLES,
  LEASE_STATUSES,
  ACCOUNT_STATUS_OPTIONS,
  INVOICE_STATUSES,
  NON_PAYABLE_LEASE_STATUSES,
  LIVE_HOST,
  US_STATES,
} from "./constants";
import { getOptions } from "utils/objectFormatter";

export const generatePaymentStatusLabel = ({ status, payment_type }) => {
  switch (status) {
  case "processing":
    return "Payment Processing";
  case "successful":
    return `${generatePaymentTypeLabel(payment_type)}`;
  case "failed":
    return `Failed ${generatePaymentTypeLabel(payment_type)}`;
  default:
    return "";
  }
};

const generatePaymentTypeLabel = paymentType => {
  switch (paymentType) {
  case "autopay":
    return "Autopay Payment";
  case "manual":
    return "Manual Payment";
  default:
    return "";
  }
};

export const generatePayableTypeLabel = payableType => {
  switch (payableType) {
  case "Lease":
  case "Invoice":
    return "Invoice Payment";
  case "Epo":
    return "Payoff Wallet Payment";
  default:
    return "";
  }
};

export const filterOutFailedManualPayments = payments => {
  return payments.filter(payment => {
    return !isFailedManualPayment(payment);
  });
};

const isFailedManualPayment = payment => {
  return payment.status === "failed" && payment.payment_type === "manual";
};

export const generatePaymentStatusBadgeLabel = status => {
  switch (status) {
  case "processing":
    return "Processing";
  case "successful":
    return "Success";
  case "failed":
    return "Failed";
  default:
    return "";
  }
};

export const generatePaymentStatusBadgeColor = status => {
  switch (status) {
  case "processing":
    return "yellow";
  case "successful":
    return "green";
  case "failed":
    return "red";
  default:
    return "grey";
  }
};

export const getInvoiceSlug = ({ payable_type, payable }) => {
  if (payable_type === "Invoice") return payable.slug;
  else return "- -";
};

export const generateLeaseStatusBadge = status =>
  LEASE_STATUSES.find(leaseStatus => {
    return leaseStatus.value === status;
  }) || { label: "", color: "grey" };

export const generateAccountStatusBadge = status =>
  ACCOUNT_STATUS_OPTIONS.find(accountStatus => {
    return accountStatus.value === status;
  }) || { label: "", color: "grey" };

export const generateInvoiceStatusBadge = status =>
  INVOICE_STATUSES.find(invoiceStatus => {
    return invoiceStatus.value === status;
  }) || { label: "", color: "grey" };

export const creditStatusBadge = status => {
  const greenStatuses = ["Approved", "Skipped", "Passed"];
  const redStatuses = ["Rejected", "Failed", "Internally Rejected"];
  const yellowStatuses = ["Pending"];

  switch (true) {
  case greenStatuses.includes(status):
    return "green";
  case redStatuses.includes(status):
    return "red";
  case yellowStatuses.includes(status):
    return "yellow";
  default:
    return "grey";
  }
};

export const isEmptyObject = obj => {
  return obj === null || obj === undefined || Object.keys(obj).length === 0;
};

export const omitKey = (object, key) => {
  let cloned = Object.assign({}, object);
  delete cloned[key];
  return cloned;
};

export const isString = variable => {
  return typeof variable === "string" || variable instanceof String;
};

export const maskSSN = plainSSN => {
  let maskedSsn = plainSSN;

  maskedSsn = maskedSsn.replace(/^(\d{3})/, "$1-");
  maskedSsn = maskedSsn.replace(/-(\d{2})/, "-$1-");
  maskedSsn = maskedSsn.replace(/(\d)-(\d{4}).*/, "$1-$2");

  return maskedSsn;
};

export const maskPhoneNumber = plainPhone => {
  let maskedPhone = plainPhone.match(/(\d{3})(\d{3})(\d{4})/);
  maskedPhone = `+1 (${maskedPhone[1]}) ${maskedPhone[2]}-${maskedPhone[3]}`;

  return maskedPhone;
};

export const objectToQueryString = initialObj => {
  const reducer =
    (obj, parentPrefix = null) =>
      (prev, key) => {
        const val = obj[key];
        key = Array.isArray(val) ? key : encodeURIComponent(key);
        const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

        if (Array.isArray(val)) {
          val.forEach(el => prev.push(`${prefix}[]=${el}&`));
          return prev;
        }

        if (val == null || typeof val === "function") {
          prev.push(`${prefix}=`);
          return prev;
        }

        if (["number", "boolean", "string"].includes(typeof val)) {
          prev.push(`${prefix}=${encodeURIComponent(val)}`);
          return prev;
        }

        prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join("&"));
        return prev;
      };

  return Object.keys(initialObj).reduce(reducer(initialObj), []).join("&");
};

export const isPayableLease = leaseStatus =>
  !NON_PAYABLE_LEASE_STATUSES.includes(leaseStatus);

export const findFromGPlaces = (
  addressComponents,
  type,
  name = "long_name"
) => {
  const addressComponent = addressComponents.find(comp =>
    comp.types.includes(type)
  );

  return addressComponent && addressComponent[name];
};

export const formValuesFromPlaces = addressComponents => {
  const mapStreetNumber = concatStrings(
    findFromGPlaces(addressComponents, "street_number"),
    findFromGPlaces(addressComponents, "route")
  );
  const mapZipCode = findFromGPlaces(addressComponents, "postal_code");
  const mapState = findFromGPlaces(
    addressComponents,
    "administrative_area_level_1",
    "short_name"
  );
  const mapCity = findFromGPlaces(addressComponents, "locality");

  const mapStateOption = getOptions(US_STATES).find(
    opt => opt.value == mapState
  );

  return {
    city: mapCity,
    area: "",
    state: mapStateOption,
    zip_code: mapZipCode,
    street_number: mapStreetNumber,
  };
};

export const concatStrings = (string1, string2) => {
  return [string1, string2].filter(Boolean).join(" ");
};

export const getPageTitle = (namespace, page) => {
  return PAGE_TITLES[namespace][page];
};

export const getTimeZoneOffset = () => {
  const tzOffset = new Date().toTimeString().match(/([+-]\d{4})/)[0];

  return [tzOffset.slice(0, 3), ":", tzOffset.slice(3)].join("");
};

export const liveMode = () => window.location.host === LIVE_HOST;

import React from "react";
import { EXCLUSION_TABLE_DATA } from "./constants";
import ExclusionTable from "./ExclusionTable";

const Exclusion = () => {
  return (
    <div className="flex justify-center">
      <div className="w-4/6">
        <ExclusionTable loading={false}>
          <ExclusionTable.Header />
          <ExclusionTable.Body data={EXCLUSION_TABLE_DATA} />
        </ExclusionTable>
      </div>
    </div>
  );
};

export default Exclusion;

import React from "react";
import { Switch, Route } from "react-router-dom";

import MobileHeader from "components/MyAccount/common/MobileHeader";
import PrivateRoute from "components/Common/PrivateRoute";
import Payment from "components/MyAccount/Payment";
import LeaseDetails from "components/MyAccount/LeaseDetails";
import MyAccount from "components/MyAccount";
import CreditApproval from "components/MyAccount/CreditApproval";
import Dashboard from "components/Dashboard";
import { CUSTOMER_ROUTES } from "../constant";
import { getPageTitle } from "common/utils";
import withTitle from "components/Common/withTitle";

const CustomerRoutes = ({
  isLoggedIn,
  credit_enabled,
  store_url = "https://beccashome.com",
}) => {
  const dashBoardTitle = getPageTitle("account", "dashboard");
  const myAccountTitle = getPageTitle("account", "myAccount");
  const newApplicationTitle = getPageTitle("account", "newApplication");

  const DashboardWithTitle = withTitle({
    Component: Dashboard,
    title: dashBoardTitle,
  });

  const MyAccountWithTitle = withTitle({
    Component: MyAccount,
    title: myAccountTitle,
  });

  const CreditApprovalWithTitle = withTitle({
    Component: CreditApproval,
    title: newApplicationTitle,
  });

  return (
    <div className="justify-center w-full h-screen bg-gray-100">
      <div className="max-w-lg mx-auto h-full w-full bg-white overflow-y-scroll">
        <div className="flex flex-col items-stretch justify-center w-full ">
          <div className="sticky top-0 z-10 w-full bg-white">
            <MobileHeader
              credit_enabled={credit_enabled}
              store_url={store_url}
            />
          </div>
          <div>
            <Switch>
              {credit_enabled && (
                <Route
                  exact
                  path="/my-account/credit-applications/new"
                  component={CreditApprovalWithTitle}
                />
              )}
              <Route
                exact
                path="/dashboard"
                render={() => (
                  <DashboardWithTitle
                    storeURL={store_url}
                    creditEnabled={credit_enabled}
                  />
                )}
              />
              <Route
                exact
                path="/my-account"
                render={() => <MyAccountWithTitle storeURL={store_url} />}
              />
              {CUSTOMER_ROUTES.map(({ titleKey, component, path, exact }) => {
                const title = getPageTitle("account", titleKey);
                const Component = withTitle({ Component: component, title });

                return (
                  <Route
                    component={Component}
                    exact={exact}
                    key={path}
                    path={path}
                  />
                );
              })}
              <Route
                path="/my-account/leases/:id"
                component={LeaseDetails}
                exact
              />
              <Route path="/my-account/payment/:leaseId" component={Payment} />
              <PrivateRoute
                path="/"
                redirectRoute={"/dashboard"}
                condition={!isLoggedIn}
                render={() => (
                  <Dashboard
                    storeURL={store_url}
                    creditEnabled={credit_enabled}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerRoutes;

import React from "react";
import { Header } from "@bigbinary/neetoui/layouts";
import { Button } from "@bigbinary/neetoui";

const SettingHeaderComponent = ({ isNewWorkFlow, onNewWorkFlowClick }) => {
  const breadcrumbsFunction = () => {
    if (isNewWorkFlow) {
      return [
        { text: "Settings", link: "/settings" },
        { text: "Communication", link: "/settings" },
      ];
    } else {
      return [{ text: "Settings", link: "/settings" }];
    }
  };

  return (
    <Header
      title={isNewWorkFlow ? "New Workflow" : "Communication"}
      breadcrumbs={breadcrumbsFunction()}
      actionBlock={
        isNewWorkFlow ? (
          <div className="flex space-x-2">
            <Button
              label="Discard Changes"
              style="secondary"
              onClick={() => {
                onNewWorkFlowClick();
              }}
            />
            <Button
              label="Save Changes"
              style="primary"
              onClick={() => {
                onNewWorkFlowClick();
              }}
            />
          </div>
        ) : (
          <div className="flex space-x-2">
            <Button
              label="+ New Workflow"
              onClick={() => {
                onNewWorkFlowClick();
              }}
            />
          </div>
        )
      }
    />
  );
};

export default SettingHeaderComponent;

export const APPROVAL_STATUSES = {
  credit_approved: "Approved",
  credit_expired: "Expired",
  credit_blocked: "Expired",
  internally_rejected: "Not approved",
};

export const STATUS_HEADER_TEXT_MAPPER = {
  credit_approved: "You're approved",
  credit_expired: "Refresh your approval now!",
  credit_blocked: "Your credit approval has expired.",
};

import React, { useState } from "react";
import { Label, Button, Dropdown } from "neetoui";
import { formatCurrency } from "utils/styleUtils";
import { formatDate } from "common/dateTimeFormatter";
import { displayPayCycle } from "components/MyAccount/common/helper";
import classNames from "classnames";
import { useCustomerState } from "contexts/customer";
import { generateLeaseStatusBadge } from "common/utils";
import { LEASE_STATUSES } from "common/constants";
import leasesApi from "apis/leases";

const LeaseHeader = ({
  slug,
  id,
  overdue,
  outstanding,
  status,
  onLeaseItemClick,
  customer,
  updateSelectedLeaseStatus,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const updateLeaseStatus = async status => {
    setIsSubmitting(true);
    try {
      await leasesApi.update(customer.id, id, {
        lease_status: status,
      });
      updateSelectedLeaseStatus(id, status);
    } catch (error) {
      logger.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const { label, color } = generateLeaseStatusBadge(status);
  const dropDownLabelColor = `text-${color}-600`;
  return (
    <div className="flex justify-between px-4 py-2 bg-gray-100">
      <div className="w-1/3">
        <Label className="uppercase">Lease ID</Label>
        <Button
          style="link"
          label={slug}
          onClick={() => {
            onLeaseItemClick(slug, id);
          }}
          to={`/customers/${customer.id}/leases/${id}`}
          className="mt-2 text-base font-semibold underline"
        />
      </div>
      <div className="flex justify-between w-2/3">
        <div>
          <Label className="uppercase">Overdue</Label>
          <p className="mt-2 font-semibold text-red-600">
            {formatCurrency(overdue)}
          </p>
        </div>
        <div>
          <Label className="uppercase">Outstanding</Label>
          <p className="mt-2 font-semibold text-red-600">
            {formatCurrency(outstanding)}
          </p>
        </div>
        <div>
          <Label className="uppercase">Status</Label>
          {/* <p className="mt-2 font-semibold text-green-500">{status}</p> */}
          <Dropdown
            buttonStyle="secondary"
            label={label}
            buttonProps={{
              className: classNames({ [dropDownLabelColor]: !isSubmitting }),
              loading: isSubmitting,
            }}
            position="bottom-right"
            data-cy="change-status-dropdown"
            className="mr-3"
          >
            {LEASE_STATUSES.map(option => (
              <li
                key={option.label}
                onClick={() => updateLeaseStatus(option.value)}
              >
                {option.label}
              </li>
            ))}
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const LeaseSubHeading = () => (
  <div className="flex justify-between">
    <p className="font-semibold text-gray-600">ITEMS</p>
    <div className="flex justify-center w-1/2 font-semibold text-gray-400">
      <p className="font-semibold text-gray-600">QTY</p>
    </div>
  </div>
);

const LeaseItemRow = ({ eachItem, borderFlag }) => (
  <div
    className={classNames(["flex justify-between py-2"], {
      "border-b border-gray-200": borderFlag,
    })}
    key={eachItem.id}
  >
    <div className="flex">
      <img
        src={eachItem.woo_image_url}
        className="object-contain"
        width="56px"
        height="56px"
      />
      <div className="ml-4 space-y-2">
        <p className="text-sm font-bold">{eachItem.product_name}</p>
        <div className="flex space-x-1">
          <Label className="text-xs font-bold uppercase "> SKU: </Label>
          <p className="font-normal text-gray-600">{eachItem.sku}</p>
        </div>
        <p className="font-normal text-gray-600">{eachItem.vendor_name}</p>
      </div>
    </div>
    <div className="flex items-center justify-center w-1/2 font-semibold">
      <p className="font-normal text-gray-600">{eachItem.quantity}</p>
    </div>
  </div>
);

const LeaseBody = ({ items }) => (
  <>
    {items.map((eachItem, index) => {
      const borderFlag = index !== items.length - 1;
      return (
        <LeaseItemRow eachItem={eachItem} borderFlag={borderFlag} key={index} />
      );
    })}
  </>
);

const LeaseFooter = ({
  lastSuccessfulPayment,
  startDate,
  amount,
  pay_cycle,
  tax,
}) => (
  <div className="flex justify-between p-4">
    <div>
      <Label className="uppercase">PAYMENT</Label>
      <p className="font-normal text-gray-600">{`${formatCurrency(
        parseFloat(amount) + parseFloat(tax)
      )}/${displayPayCycle(pay_cycle)}`}</p>
    </div>
    <div>
      <Label className="uppercase">LAST PAYMENT</Label>
      <p className="font-normal text-gray-600">
        {lastSuccessfulPayment
          ? formatDate(lastSuccessfulPayment.paid_at)
          : "- -"}
      </p>
    </div>
    <div>
      <Label className="uppercase">START DATE</Label>
      <p className="font-normal text-gray-600">{formatDate(startDate)}</p>
    </div>
  </div>
);

const LeaseItem = ({ onLeaseItemClick, item, updateSelectedLeaseStatus }) => {
  const { customer } = useCustomerState();
  return (
    <div className="border border-gray-200 rounded-md">
      <LeaseHeader
        slug={item.slug}
        id={item.id}
        overdue={item.due_amount_with_tax}
        outstanding={item.outstanding_amount}
        status={item.lease_status}
        onLeaseItemClick={onLeaseItemClick}
        customer={customer}
        updateSelectedLeaseStatus={updateSelectedLeaseStatus}
      />
      <div className="p-4">
        <LeaseSubHeading />
        <LeaseBody items={item.line_items} />
      </div>
      <LeaseFooter
        amount={item.payment_amount}
        lastSuccessfulPayment={item.last_successful_payment}
        startDate={item.start_date}
        pay_cycle={item.pay_cycle}
        tax={item.tax_on_payment_amount}
      />
    </div>
  );
};

export default LeaseItem;

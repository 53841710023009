export const PAYMENT_METHODS = [
  {
    icon: "ri-bank-line",
    label: "Bank Account",
    type: "bank",
  },
  {
    icon: "ri-bank-card-line",
    label: "Debit/Credit Card",
    type: "card",
  },
];

import * as R from "ramda";

export const FIRST_PAY_AVAILABLE_DAYS = R.range(1, 20);

export const SECOND_PAY_AVAILABLE_DAYS = R.range(13, 29);

export const MONTHLY_INCOME = [
  { label: "Less than $2,000", value: "Less than $2000" },
  { label: "$2,000 - $3,000", value: "$2000 - $3000" },
  { label: "$3,000 - $4,000", value: "$3000 - $4000" },
  { label: "$4,000 - $5,000", value: "$4000 - $5000" },
  { label: "$5,000 - $6,000", value: "$5000 - $6000" },
  { label: "$6,000+", value: "$6000+" },
];

export const LEASE_STATUSES = [
  { label: "Active", value: "active", color: "green" },
  { label: "Cancelled", value: "cancelled", color: "red" },
  { label: "Paid Off", value: "paid_off", color: "green" },
  { label: "Delinquent", value: "delinquent", color: "yellow" },
  { label: "Repossessed", value: "repossessed", color: "red" },
  { label: "Charged Off", value: "charged_off", color: "red" },
  { label: "Initiated", value: "initiated", color: "yellow" },
  { label: "Deferred", value: "deferred", color: "yellow" },
];
export const ACCOUNT_STATUS_OPTIONS = [
  { label: "Active", value: "active", color: "green" },
  { label: "Delinquent", value: "delinquent", color: "red" },
  { label: "Inactive", value: "inactive", color: "grey" },
  { label: "Charged Off", value: "charged_off", color: "red" },
  { label: "Repossessed", value: "repossessed", color: "red" },
];

export const CREDIT_STATUS_OPTIONS = [
  { label: "Approved", value: "credit_approved", color: "green" },
  { label: "Rejected", value: "credit_rejected", color: "red" },
  { label: "Internally Rejected", value: "internally_rejected", color: "red" },
  { label: "Expired", value: "credit_expired", color: "red" },
  { label: "Locked", value: "credit_locked", color: "red" },
  { label: "Blocked", value: "credit_blocked", color: "red" },
  { label: "Pending", value: "credit_pending", color: "yellow" },
];

export const REGISTRATION_STEP_OPTIONS = [
  { label: "OTP verification", value: "otp_verification" },
  { label: "Personal Info", value: "personal_info" },
  { label: "Work Info", value: "work_info" },
  { label: "Address Info", value: "address_info" },
  { label: "Submit Application", value: "submit_application" },
  { label: "Completed", value: "completed" },
];

export const INVOICE_STATUSES = [
  { label: "Paid", value: "paid", color: "green" },
  { label: "Overdue", value: "overdue", color: "red" },
  { label: "Cancelled", value: "cancelled", color: "grey" },
  { label: "Partially Paid", value: "partially_paid", color: "blue" },
  { label: "Scheduled", value: "scheduled", color: "yellow" },
];

export const CREDIT_STATUSES = Object.freeze({
  credit_approved: "Approved",
  credit_pending: "Pending",
  credit_expired: "Expired",
  credit_rejected: "Rejected",
  internally_rejected: "Internally Rejected",
  credit_locked: "Credit Locked",
  credit_blocked: "Credit Blocked",
});

export const FRAUD_STATUSES = Object.freeze({
  fraud_passed: "Passed",
  fraud_failed: "Failed",
  fraud_pending: "Pending",
  fraud_skipped: "Skipped",
});

export const US_STATES = {
  AK: "Alaska",
  AL: "Alabama",
  AR: "Arkansas",
  AZ: "Arizona",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VA: "Virginia",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "West Virginia",
  WY: "Wyoming",
};

export const NON_LEASE_STATES = ["AK", "HI", "MN", "NJ", "VT", "WI", "WY"];

export const LEASE_UNAVAILABLE_IN_STATES_MESSAGE =
  "Sorry, lease-to-own is not available in the following states: AK, HI, MN, NJ, VT, WI, WY";

export const NON_PAYABLE_LEASE_STATUSES = [
  "paid_off",
  "cancelled",
  "charged_off",
];

export const APP_NAME = "Becca's Home";

export const PAGE_TITLES = {
  register: {
    otp: "Get approved for new furniture",
    personalInfo: "Registration - personal information",
    workInfo: "Registration - work information",
    addressInfo: "Registration - address information",
    submit: "Registration - submit application",
    fraudOtp: "Registration - verify experian OTP",
    additionalInfo: "Registration - additional info",
    creditApproved: "Registration - congratulations credit approved",
    applicationFailed: "Registration - application failed",
    creditRejected: "Registration - credit rejected",
    creditProfileLocked: "Registration - credit profile locked",
    applicationError: "Registration - application error",
  },
  public: {
    passwordReset: "Reset your password",
    customerLogin: "Login | Sign In",
    staffLogin: "Admin login",
  },
  account: {
    myProfile: "My profile",
    paymentMethods: "Payments methods | Cards list",
    newPayment: "New payment",
    newPassword: "Set your new password",
    payment: "Payment",
    dashboard: "Dashboard",
    myAccount: "My account",
    newApplication: "New credit application",
  },
  staff: {
    profile: "Update profile",
    agents: "Agents list",
    collections: "Collections list",
    customers: "Customers list",
    changePassword: "Change password",
    experianEmulation: "Experian Emulation",
  },
  login: {
    magicLink: "Login with magic link",
    magicLinkSuccess: "Magic link sent",
    enterPassword: "Enter password",
    sendPassword: "Reset password",
  },
};

export const LIVE_HOST = "app.beccashome.com";

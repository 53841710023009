import React, { useState } from "react";
import { Select, Input } from "@bigbinary/neetoui";
import classNames from "classnames";

const CustomButton = ({ value, onClick }) => {
  return (
    <div
      className="p-2 mx-2 text-black uppercase bg-gray-200 rounded cursor-pointer"
      onClick={() => onClick()}
    >
      {value}
    </div>
  );
};

const Rules = () => {
  const [rulesArray, setRulesArray] = useState([""]);

  const onCustomButtonClick = () => {
    setRulesArray(state => [...state, ""]);
  };

  const onDeleteButtonClick = index => {
    setRulesArray(state => {
      let updatedState = [...state];
      updatedState.splice(index, 1);
      return updatedState;
    });
  };

  return (
    <div className="w-full p-4 mt-6 space-y-2 border border-gray-200 rounded-md">
      <p className="font-semibold">Rules</p>
      {rulesArray.map((item, index) => {
        return (
          <div
            className="flex items-center justify-between space-x-4"
            key={index}
          >
            <div className="w-56">
              <Select label="Trigger" />
            </div>
            <div className="w-56">
              <Select label="Category" />
            </div>
            <div className="w-56">
              <Input label="From" />
            </div>
            <div className="flex items-center justify-between w-56 mt-5">
              <div className="flex divide-x divide-grey-500">
                <CustomButton value={"and"} onClick={onCustomButtonClick} />
                <CustomButton value={"or"} onClick={onCustomButtonClick} />
              </div>
              <div
                className={classNames(
                  "text-lg cursor-pointer ri-delete-bin-line",
                  { "text-gray-400": [rulesArray.length] !== [index + 1] }
                )}
                onClick={() => onDeleteButtonClick(index)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Rules;

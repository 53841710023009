import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageLoader } from "@bigbinary/neetoui";

import PaymentHistory from "./Payments";
import LeaseItem from "./Item";
import Status from "./Status";
import LeaseDetailsHeader from "./Header";
import LeaseDetailsFooter from "./Footer";
import leasesApi from "apis/customers/leases";
import AutoPaymentModal from "components/MyAccount/common/AutoPayModal";

const LeaseDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [lease, setLease] = useState(null);
  const [payments, setPayments] = useState(null);
  const [lineItems, setLineItems] = useState(null);
  const [isAutoPayModalOpen, setIsAutoPayModalOpen] = useState(false);

  const fetchLease = async () => {
    try {
      setLoading(true);
      const {
        data: { lease, payments, line_items },
      } = await leasesApi.lease(id);
      setLease(lease);
      setPayments(payments);
      setLineItems(line_items);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onAutopayModalClose = (value, isAutopayChanged) => {
    if (isAutopayChanged) {
      fetchLease();
    }
    setIsAutoPayModalOpen(value);
  };

  useEffect(() => {
    !lease && fetchLease();
  }, [isAutoPayModalOpen]);

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center justify-center w-screen h-screen">
          <PageLoader />
        </div>
      ) : (
        <>
          <div className="h-full">
            <div className="lease-detail-background">
              <div className="px-10 py-4">
                <LeaseDetailsHeader
                  lease={lease}
                  setIsAutoPayModalOpen={setIsAutoPayModalOpen}
                />
              </div>
              <div className="flex justify-center mb-4">
                <div className="container w-3/6 bg-white border border-gray-200 rounded-md">
                  <div className="flex justify-start px-3 py-2 space-x-6 bg-gray-100">
                    <div>
                      <p className="text-gray-500 uppercase">Order number</p>
                      <p className="font-semibold text-gray-500">
                        {lease?.slug}
                      </p>
                    </div>
                    <div>
                      <p className="text-gray-500 uppercase">Order Placed</p>
                      <p className="font-semibold text-gray-500">
                        {lease?.start_date}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between p-4 px-16 space-x-6">
                    {lineItems?.map(item => (
                      <LeaseItem
                        key={item.id}
                        item={item}
                        payCycle={lease?.pay_cycle}
                      />
                    ))}
                  </div>
                  <div className="flex justify-center mb-8">
                    <Status lease={lease} />
                  </div>
                  <LeaseDetailsFooter lease={lease} />
                </div>
              </div>
            </div>
          </div>
          <PaymentHistory payments={payments} />
          {isAutoPayModalOpen && (
            <AutoPaymentModal
              setIsAutoPayModalOpen={onAutopayModalClose}
              isAutoPayModalOpen={isAutoPayModalOpen}
              leaseDetails={lease}
            />
          )}
        </>
      )}
    </>
  );
};

export default LeaseDetails;

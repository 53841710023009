import React, { useState } from "react";
import { Label, Button, Dropdown } from "@bigbinary/neetoui";

import { DEBIT_CARD_LIST } from "components/MyAccount/constants";
import AutoPayModal from "./common/AutoPayModal";
import HeaderIcon from "./NewPayment/Header";
import { useHistory } from "react-router-dom";

const AutoPaySettings = ({
  nextPaymentDate = "23 March 2021",
  paymentFrequency = "Friday/Weekly",
  paymentAmount = "$100",
}) => {
  const [autoPaymentModalState, setAutoPaymentModalState] = useState(false);

  const history = useHistory();
  const onCancel = () => {
    history.goBack();
  };

  return (
    <>
      <div className="w-full p-4 bg-white border border-gray-200 rounded-md shadow-md">
        <HeaderIcon />
        <div>
          <div className="py-3 text-lg font-semibold border-b border-gray-200">
            Autopayment Details
          </div>
          <div className="flex justify-between py-3 border-b border-gray-200">
            <Label>Next Payment Date</Label>
            <p>{nextPaymentDate}</p>
          </div>
          <div className="flex justify-between py-3 border-b border-gray-200">
            <Label>Pays Every</Label>
            <p>{paymentFrequency}</p>
          </div>
          <div className="flex justify-between py-3 border-b border-gray-200">
            <Label>Payment Method</Label>
            <Dropdown
              label={DEBIT_CARD_LIST[0].label}
              buttonStyle="text"
              buttonProps={{ className: "text-sm" }}
              position="bottom-right"
              closeOnSelect
            >
              {DEBIT_CARD_LIST.map((item, idx) => (
                <li key={idx}>{item.label}</li>
              ))}
              <li>
                <Button
                  style="icon"
                  icon="ri-add-fill"
                  className="text-sm"
                  label="Add New Payment Method"
                />
              </li>
            </Dropdown>
          </div>
          <div className="flex justify-between pt-3 pb-1 ">
            <Label>Payment Amount</Label>
            <p>{paymentAmount}</p>
          </div>
          <div className="flex justify-end py-3 space-x-4">
            <Button
              label="Cancel"
              style="secondary"
              onClick={() => onCancel()}
            />
            <Button
              label="Submit"
              onClick={() => setAutoPaymentModalState(true)}
            />
          </div>
        </div>
        <AutoPayModal
          setAutoPaymentModalState={setAutoPaymentModalState}
          autoPaymentModalState={autoPaymentModalState}
        />
      </div>
    </>
  );
};

export default AutoPaySettings;

import React from "react";
import { formatCurrency } from "utils/styleUtils";

const Footer = ({ lease }) => (
  <div className="px-6 py-4 mt-4 space-y-2 bg-gray-100">
    <div className="flex justify-between font-semibold">
      <p className="text-gray-600">Recurring Payment</p>
      <p className="text-black">{formatCurrency(lease?.payment_amount)}</p>
    </div>
    <div className="flex justify-between pb-2 font-semibold border-b border-gray-300">
      <p className="text-gray-600">Sales Tax</p>
      <p className="text-black">
        {formatCurrency(lease?.tax_on_payment_amount)}
      </p>
    </div>
    <div className="flex justify-between font-semibold">
      <p className="text-gray-600">{lease?.pay_cycle} Total</p>
      <p className="text-black">
        {formatCurrency(
          parseFloat(lease?.payment_amount) +
            parseFloat(lease?.tax_on_payment_amount)
        )}
      </p>
    </div>
  </div>
);

export default Footer;

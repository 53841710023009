import React from "react";
import { Switch, Route } from "react-router-dom";

import PrivateRoute from "components/Common/PrivateRoute";
import CustomerLogin from "components/Authentication/CustomerLogin";
import Login from "components/Authentication/Login";
import SignupRoutes from "routes/signup";
import { getPageTitle } from "common/utils";
import withTitle from "components/Common/withTitle";
import { PUBLIC_ROUTES } from "routes/constant";

const PublicRoutes = ({ isLoggedIn }) => {
  return (
    <Switch>
      {PUBLIC_ROUTES.map(({ titleKey, component, path, exact }) => {
        const title = getPageTitle("public", titleKey);
        const Component = withTitle({ Component: component, title });

        return (
          <Route component={Component} exact={exact} key={path} path={path} />
        );
      })}
      <Route path="/flow" component={SignupRoutes} />
      <PrivateRoute
        path="/staff"
        redirectRoute="/staff/login"
        condition={isLoggedIn}
        component={Login}
      />
      <PrivateRoute
        path="/"
        redirectRoute="/login"
        condition={isLoggedIn}
        component={CustomerLogin}
      />
    </Switch>
  );
};

export default PublicRoutes;

import React from "react";
import { Badge, Checkbox } from "@bigbinary/neetoui";
import { Draggable, Droppable } from "react-beautiful-dnd";

import { PRODUCT_TABLE_CONTENT } from "../../constants";

const FeatureTable = ({ tableDetails }) => {
  return (
    <table className="border border-collapse border-gray-200 nui-table">
      <thead>
        <tr>
          <th></th>
          <th className="text-center uppercase border border-gray-200">
            Published
          </th>
          <th className="text-center uppercase border border-gray-200">
            Title
          </th>
          <th className="text-center uppercase border border-gray-200">
            Description
          </th>
          <th className="text-center uppercase border border-gray-200">
            Status
          </th>
        </tr>
      </thead>
      <Droppable droppableId={tableDetails}>
        {provided => (
          <tbody ref={provided.innerRef} {...provided.droppableProps}>
            {PRODUCT_TABLE_CONTENT.map((item, index) => {
              return (
                <Draggable
                  draggableId={(tableDetails + index).toString()}
                  key={index}
                  index={index}
                >
                  {provided => (
                    <tr
                      className="text-left"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <td className="border border-gray-200">
                        <div
                          className="flex items-center justify-center"
                          {...provided.dragHandleProps}
                        >
                          <p className="ri-drag-move-line"></p>
                        </div>
                      </td>
                      <td className="border border-gray-200 ">
                        <div className="flex items-center justify-center">
                          {item.published ? "*" : <Checkbox />}
                        </div>
                      </td>
                      <td className="border border-gray-200 ">
                        <div className="flex items-center justify-center">
                          {item.title}
                        </div>
                      </td>
                      <td className="border border-gray-200 ">
                        <div className="flex items-center justify-center">
                          {item.desc}
                        </div>
                      </td>
                      <td className="border border-gray-200 ">
                        <div className="flex items-center justify-center">
                          <Badge color={item.status ? "green" : "yellow"}>
                            {item.status ? "Mapped" : "Unmapped"}
                          </Badge>
                        </div>
                      </td>
                    </tr>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </tbody>
        )}
      </Droppable>
    </table>
  );
};

export default FeatureTable;

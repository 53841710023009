import axios from "axios";

const create = payload => axios.post(`api/v1/payments`, payload);
const paymentIntent = (leaseId, payload) =>
  axios.post(`api/v1/leases/${leaseId}/payment_intents`, payload);

const paymentsApi = {
  create,
  paymentIntent,
};

export default paymentsApi;

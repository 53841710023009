import axios from "axios";

const baseUrl = "api/v1";
const baseAdminUrl = `${baseUrl}/admin/customers`;

const customers = searchParams => axios.get(`${baseAdminUrl}/?${searchParams}`);
const getCustomerDetails = id => axios.get(`${baseAdminUrl}/${id}`);
const updateCustomer = (id, payload) =>
  axios.patch(`${baseAdminUrl}/${id}`, payload);
const resetPassword = payload =>
  axios.post(`${baseUrl}/customers/password`, payload);
const updatePassword = payload =>
  axios.patch(`${baseUrl}/customers/password`, payload);

const customersApi = {
  customers,
  getCustomerDetails,
  updateCustomer,
  resetPassword,
  updatePassword,
};

export default customersApi;

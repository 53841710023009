import * as yup from "yup";

export default {
  qaForm: yup.object().shape({
    ekata: yup
      .object()
      .shape({
        label: yup.string().required("Ekata is required"),
        value: yup.string().required("Ekata is required"),
      })
      .required("Ekata is required"),
    preciseId: yup
      .object()
      .shape({
        label: yup.string().required("Precise ID is required"),
        value: yup.string().required("Precise ID is required"),
      })
      .required("Precise ID is required"),
    credit: yup
      .object()
      .shape({
        label: yup.string().required("Credit is required"),
        value: yup.string().required("Credit is required"),
      })
      .required("Credit is required"),
    creditLimit: yup.number().required("Credit Limit is required"),
    failure: yup
      .object()
      .shape({
        label: yup.string().required("Failure is required"),
        value: yup.string().required("Failure is required"),
      })
      .required("Failure is required"),
  }),
};
